import cn from "classnames"
import { Fragment } from "react"
import { styled } from "styled-components"

import getIconOrError from "icons"
import useWindowSize from "ui/hooks/useWindowSize"
import Loading from "ui/Loading"
import Tooltip from "ui/Tooltip"

const IconGroupRatingFieldAggregatedMultiUserBreakoutBarChart = styled(
  function IconGroupRatingFieldAggregatedMultiUserBreakoutBarChart({
    identifiers,
    useAggregatedResultsData,
    has_sensitive_data,
    additional_data,
    refetch_interval,
    className,
  }) {
    const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
      component: "IconGroupRatingFieldAggregatedMultiUserBreakoutBarChart",
      identifiers,
      has_sensitive_data,
      additional_data,
      refetch_interval,
    })
    const { isMobileOrSmaller } = useWindowSize()

    if (!aggregatedResults && isFetching) {
      return <Loading />
    }

    if (!aggregatedResults) {
      return null
    }

    if (aggregatedResults.insufficient_data) {
      return (
        <p className="p-large bg-gray-1">
          To maintain anonymity, we only share results when there are at least three team members with complete results.
        </p>
      )
    }

    const { results, components } = aggregatedResults
    const options = components[identifiers[0]]?.options
    const percentageDisplayCutoff = 20

    return (
      <div className={cn(className, "results-grid")}>
        <div className="label-column" />
        {options.map(({ short_label }, idx) => {
          const first = idx === 0
          const last = idx + 1 === options.length
          return (
            <div
              key={short_label}
              className={cn("column-header text-gray-7", {
                "text-right": first,
                "text-center": !first && !last,
                "text-left": last,
              })}
            >
              {short_label}
            </div>
          )
        })}
        <div className="column-header text-gray-7 text-center">
          {results.some(({ related_rating }) => !!related_rating) && "Average"}
        </div>
        {results.map(({ identifier, label, rating_percentages, rating_counts, related_rating }) => {
          const {
            label: relatedLabel = null,
            rating_average: relatedAverage = null,
            component: relatedComponent = null,
          } = related_rating ?? {}
          const { options: relatedOptions = null } = relatedComponent ?? {}
          const roundedRelatedAverage = Math.round(relatedAverage)
          const {
            icon: relatedIcon = null,
            color: relatedColor = null,
            icon_label: relatedRatingLabel = null,
          } = relatedOptions?.find((option) => option.value === roundedRelatedAverage) ??
          relatedOptions?.[roundedRelatedAverage - 1] ??
          {}
          const Icon = relatedIcon && getIconOrError(relatedIcon)

          return (
            <Fragment key={identifier}>
              <div className="label-column text-right text-semi-bold">{label}</div>
              {options.map((ratingOption, idx) => {
                const percentage = rating_percentages?.[ratingOption.value ?? idx + 1] ?? 0
                const count = rating_counts?.[ratingOption.value ?? idx + 1] ?? 0
                const first = idx === 0
                const last = idx + 1 === options.length
                const ChartTooltip = (props) => (
                  <Tooltip
                    top
                    float
                    wrapInView={false}
                    maxWidth={350}
                    title={
                      <>
                        <div>
                          <strong>
                            {label}: {Math.round(percentage)}%
                          </strong>
                        </div>
                        <div>{ratingOption.icon_label}</div>
                        <div className="text-nowrap">
                          <strong>Count: </strong>
                          {props.count}
                        </div>
                      </>
                    }
                  >
                    {props.children}
                  </Tooltip>
                )
                return percentage > 0 ? (
                  <ChartTooltip key={idx} count={count}>
                    <div
                      style={{
                        backgroundColor: first || last ? ratingOption.color : "var(--gray-7)",
                        height: "100%",
                        width: `${percentage}%`,
                      }}
                      className={cn("rating-bar-segment text-semi-bold px-xs", {
                        "first-segment": first,
                        "middle-segment": !first && !last,
                        "last-segment": last,
                      })}
                    >
                      {percentage > percentageDisplayCutoff && !isMobileOrSmaller && `${Math.round(percentage)}%`}
                    </div>
                  </ChartTooltip>
                ) : (
                  <div key={idx} />
                )
              })}
              <div className="text-center neg-my-xxs">
                {!!Icon && (
                  <Tooltip
                    top
                    float
                    wrapInView={false}
                    maxWidth={350}
                    title={
                      <>
                        <div>
                          <strong>{relatedLabel}</strong>
                        </div>
                        <div className="text-nowrap">
                          <strong>{relatedRatingLabel}</strong> on average
                        </div>
                      </>
                    }
                  >
                    <Icon color={relatedColor} className="fa-2x" />
                  </Tooltip>
                )}
              </div>
            </Fragment>
          )
        })}
      </div>
    )
  }
)`
  display: grid;

  /* TODO(shrm-resilience): update to take a variable number of columns */
  grid-template-columns: max-content repeat(3, auto) max-content;
  gap: var(--spacing-2);

  .rating-bar-segment {
    font-size: 1.25rem;
    color: var(--fg);
    text-shadow: var(--gray-8) 0 0 4px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-small);

    &.first-segment {
      justify-content: flex-end;
      margin-left: auto;
    }

    &.middle-segment {
      justify-content: center;
      background-color: var(--gray-7);
      margin: 0 auto;
    }

    &.last-segment {
      justify-content: flex-start;
      margin-right: auto;
    }
  }
`

export default IconGroupRatingFieldAggregatedMultiUserBreakoutBarChart
