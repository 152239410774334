import cn from "classnames"

import { StarIcon, PeopleGroupIcon } from "icons/FontAwesomeIcons"
import BulletedList from "ui/BulletedList"
import Tooltip from "ui/Tooltip"
import View from "ui/View"
import { plural } from "utils/string"

const KitsTeamDetails = ({ selectedTeam, user, className }) => {
  const teamLeadId = selectedTeam?.team_lead_id
  const isTeamLead = selectedTeam.team_lead_id === user.id
  const teamLeadDetails = selectedTeam.members.find((member) => member.id === teamLeadId)

  const teamLeadToolTip = <p>Team Lead: {isTeamLead ? "You" : `${teamLeadDetails?.short_name}`}</p>
  const teamMembersToolTip = (
    <BulletedList className="neg-ml-small">
      {selectedTeam?.members.map((member) => (
        <li key={member.id}>{member?.short_name}</li>
      ))}
    </BulletedList>
  )

  return (
    <View $gap="16px" $alignItems="center" className={cn(className, "text-nowrap cursor-default")}>
      <Tooltip bottom title={teamLeadToolTip} className="fit-content">
        <View $alignItems="center" $justifyContent="flex-start">
          <StarIcon color="var(--rising-yellow)" className="fa-sm pr-xxs" />
          <p>{isTeamLead ? "You" : `${teamLeadDetails?.first_name}`}</p>
        </View>
      </Tooltip>
      <Tooltip bottom title={teamMembersToolTip} className="fit-content">
        <View $alignItems="center" $alignContent="center" $justifyContent="flex-start">
          <PeopleGroupIcon color="var(--orange-2)" className="fa-sm pr-xxs" />
          <p>{plural(selectedTeam?.members?.length ?? 0, "member")}</p>
        </View>
      </Tooltip>
    </View>
  )
}

export default KitsTeamDetails
