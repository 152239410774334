import * as Sentry from "@sentry/browser"
import cn from "classnames"
import { addMinutes, format as formatDate, parseISO } from "date-fns"
import { Formik } from "formik"
import { useState } from "react"
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc"
import { styled } from "styled-components"
import { tzlib_get_ical_block, tzlib_get_timezones } from "timezones-ical-library"

import Dropdown from "./Dropdown"

import { getTeamLead } from "domains/Exercise/results_utils"
import DateField from "forms/fields/DateField"
import { useUpdateOrCreateKitInstanceBySlug } from "resources/monthly_kit"
import { useLoginOptions } from "resources/users"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import useWindowSize from "ui/hooks/useWindowSize"
import { useModal } from "ui/ModalContext"
import View from "ui/View"
import { getTimeZone } from "utils/date"
import { isKitScheduled } from "utils/kit"
import { buildUrl } from "utils/string"
import { useHasTeamFeature } from "utils/team"

const ScheduleForm = ({ className, kitInstance: initialKitInstance, kitInfo, onAddToCalendar, team }) => {
  const { mutateAsync: updateKitInstance } = useUpdateOrCreateKitInstanceBySlug({ teamId: team.id, slug: kitInfo.slug })
  const [kitInstance, setKitInstance] = useState(initialKitInstance)
  const scheduled_time = isKitScheduled(kitInstance) ? kitInstance.scheduled_time : ""
  const [calendarTime, setCalendarTime] = useState(scheduled_time)
  const { isMobileOrSmaller, isMobileLandscapeOrLarger } = useWindowSize()
  const { setModal } = useModal()

  const onChangeSessionTime = async (_, value) => {
    if (value === scheduled_time) {
      return
    }

    setCalendarTime(value)
    const data = await updateKitInstance({
      scheduled_time: value || null,
    })
    // Here we manage the current kitInstance with state, rather than invalidating
    // the original query to avoid a full re-render of parent components
    setKitInstance(data)
  }
  useEffectAfterChange(() => {
    setKitInstance(initialKitInstance)
  }, [initialKitInstance, kitInfo])

  const initialValues = { scheduled_time }

  function safeFormatFileNameDate(date) {
    try {
      return !date ? null : formatDate(parseISO(date), "MMM-dd-yyyy")
    } catch {
      return null
    }
  }
  const filenameSession = kitInfo.title.replace(/[ :]+/g, "-")
  const filenameDate = safeFormatFileNameDate(calendarTime)
  const filename = `Rising-Team-${filenameSession}-${filenameDate}`

  function setInfoModal(event) {
    const hrefAttrValue = event.target?.getAttribute("href") ?? ""
    const isWebCalendar = hrefAttrValue.startsWith("https://")
    const isICSDownload = hrefAttrValue.startsWith("BEGIN:VCALENDAR")
    const isICSDataHref = hrefAttrValue.startsWith("data:text/calendar;charset=utf8,BEGIN:VCALENDAR")
    if (!isWebCalendar && !isICSDownload && !isICSDataHref) {
      Sentry.captureMessage(
        `ScheduleForm.js: Unexpected calendar href value: "${hrefAttrValue}".` +
          " We should update info modal logic to handle this value type."
      )
    }
    if (isICSDownload) {
      const calendarAppDesc = event.target?.textContent ?? "your calendar app"
      setModal({
        title: `How to open in ${calendarAppDesc}`,
        content: (
          <>
            <p className="mb-large">
              Look for the ICS file that was downloaded by your browser and open it to add your session to{" "}
              {calendarAppDesc}.
            </p>
            <p className="mb-xl">
              If you don’t see a file called
              <br />
              <b>{filename}.ics</b>
              <br /> try looking for it in your Downloads folder.
            </p>
            <Button onClick={() => setModal(null)}>OK</Button>
          </>
        ),
      })
    }
  }

  return (
    <View className={cn(className, { "mt-medium": isMobileOrSmaller })} $width="auto">
      <Formik initialValues={initialValues} enableReinitialize>
        <View $flexDirectionMobile="column">
          <DateField
            name="scheduled_time"
            className="date-field"
            showTimeSelect
            dateFormat="MM/dd/yyyy h:mm aa"
            saveOnChange={onChangeSessionTime}
            size="full-width"
            placeholder="Set date and time"
          />
          {!!scheduled_time && (
            <AddToCalendarButton
              team={team}
              kitInstance={kitInstance}
              kitInfo={kitInfo}
              calendarTime={calendarTime}
              filename={filename}
              onAddToCalendar={(event) => {
                onAddToCalendar?.(event)
                setInfoModal(event)
              }}
              className={cn({ "ml-medium": isMobileLandscapeOrLarger, "mt-small": isMobileOrSmaller })}
            />
          )}
        </View>
      </Formik>
    </View>
  )
}

const AddToCalendarButton = ({ team, kitInstance, kitInfo, calendarTime, filename, onAddToCalendar, className }) => {
  const buttonText = "Add to calendar"
  const buttonProps = { className: "add-to-calendar-button tertiary px-medium py-xs" }
  const linkProps = { onItemClick: onAddToCalendar }
  const teamLead = getTeamLead(team.members, team)
  const { data: loginOptions } = useLoginOptions({ email: teamLead?.email })
  // Note: Don't check isFetching for loginOptions because it doesn't matter if
  // the user generates a calendar invite prior to loginOptions being loaded.
  // In that case they'll get the standard start-session url which just takes them
  // through normal login flow, instead of short-cutting directly to SSO-login page.
  const { enabled: coleadsEnabled, isInitialLoading: coleadsInitialLoading } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_COLEADS
  )

  if (coleadsInitialLoading) {
    return null
  }

  if (!calendarTime) {
    const onNoCalendarTimeClick = () => {
      alert("Please select a session time.")
    }
    return (
      <Button {...buttonProps} onClick={onNoCalendarTimeClick}>
        {buttonText}
      </Button>
    )
  }

  const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown)
  const durationMinutes = kitInfo.session_schedule_minutes || kitInfo.rounded_session_time_minutes
  const startDatetime = parseISO(calendarTime)
  const endDatetime = addMinutes(startDatetime, durationMinutes)

  const timezone = getTimeZone()
  const vtimezoneOptions = tzlib_get_timezones()
  let [vtimezone = null] = vtimezoneOptions.includes(timezone) ? tzlib_get_ical_block(timezone) : []
  // tzlib uses \r\n as newline characters which we don't want, replace them with \n here:
  vtimezone = vtimezone?.replaceAll("\r\n", "\n") ?? null

  const event = {
    timezone,
    vtimezone,
    duration: durationMinutes / 60,
    endDatetime: formatDate(endDatetime, "yyyyMMdd'T'HHmmss"),
    startDatetime: formatDate(startDatetime, "yyyyMMdd'T'HHmmss"),
    title: `Rising Team session: ${kitInfo.title}`,
    description: getCalendarDescription({ kitInfo, kitInstance, loginOptions, coleadsEnabled }),
  }
  return (
    <AddToCalendarDropdown
      buttonText={buttonText}
      buttonProps={buttonProps}
      dropdownProps={linkProps}
      filename={filename}
      items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL, SHARE_SITES.OUTLOOK]}
      event={event}
      className={cn("calendar-dropdown", className)}
    />
  )
}

const getCalendarDescription = ({ kitInfo, kitInstance, loginOptions = null, coleadsEnabled }) => {
  const originUrl = window.location.origin
  let facilitatorUrl = buildUrl([originUrl, kitInstance.session_start_url])
  let kitDetailUrl = buildUrl([originUrl, kitInstance.session_url])

  // If SSO login is available, build start-session link that will take unauthed
  // users directly to the right SSO login page (based on facilitator email domain)
  // rather than going through the extra step of /auth/login -> /auth/sso/<domain>
  const ssoDomain = loginOptions?.sso_providers?.[0]?.provider
  if (ssoDomain) {
    facilitatorUrl = buildUrl([originUrl, "auth", "sso", ssoDomain], {
      urlQueryParams: { next: kitInstance.session_start_url },
    })
    kitDetailUrl = buildUrl([originUrl, "auth", "sso", ssoDomain], {
      urlQueryParams: { next: kitInstance.session_url },
    })
  }

  const startSessionDesc = `${kitInfo.description}

How to start your session:
Facilitator:
  - Go to ${facilitatorUrl} to start the session.
  - Share your screen with the group.
  - Share the session link and code with the team so they can join the session.

Participants:
  - Follow the shared link or QR code to join the session
  - Follow the prompts and enjoy!
`

  const whatYoullNeedDesc = `
What you'll need:
  - A computer or a mobile device
  - A video conference line or conference room (if in person)
  - An open mind; come ready to learn more about each other and how to work better together as a team!
`
  const previewSessionDesc = !coleadsEnabled
    ? `
Want to preview the kit or prep for your team's session?
If you want extra time to think about the session in advance and have a Rising Team account:
  - Visit ${originUrl}, log in and navigate to the Kits tab.
  - Locate the ${kitInfo.title} kit and select "view or take exercise"
  - Your responses will be saved for when your team does the session together, and will still be editable

If you are not already a member of the team:
  - Contact your team lead to have them invite you to the team.
  - Once your Rising Team account is created, see the above steps to access the session in advance.
`
    : `
Want to preview the kit or prep for your team's session?
If you want extra time to think about the session in advance and have a Rising Team account:
  - Visit ${kitDetailUrl}, log in, and select "Do the exercise in advance"
  - Your responses will be saved for when your team does the session together, and will still be editable

If you are not already a member of the team:
  - Contact your team lead to have them invite you to the team.
  - Once your Rising Team account is created, see the above steps to access the session in advance.
`
  const rtExplainDesc = `
What is Rising Team?
Rising Team helps leaders and their teams grow together through meaningful team development experiences around key leadership themes.`

  const additionalInviteText = kitInstance.additional_invite_text
    ? `
${kitInstance.additional_invite_text}
`
    : ""

  return kitInfo.has_standalone_exercise
    ? `${startSessionDesc}${additionalInviteText}${whatYoullNeedDesc}${previewSessionDesc}${rtExplainDesc}${additionalInviteText}`
    : `${startSessionDesc}${additionalInviteText}${whatYoullNeedDesc}${rtExplainDesc}`
}

export default styled(ScheduleForm)`
  .date-field {
    max-width: 246px;

    input::placeholder {
      color: var(--gray-8);
      opacity: 1;
    }
  }

  .calendar-dropdown {
    div {
      position: absolute;
      margin-top: 1px;
      width: 209px;
    }
  }

  .add-to-calendar-button {
    height: 44px;
    position: relative;
  }
`
