import { useMutation, useQuery, useQueryClient, type QueryClient } from "@tanstack/react-query"

import api from "api"
import { getAccountReportCacheKeyRoot, getAccountTeamsCacheKey } from "resources/billing"
import { getDemoTeamsCacheKey, getTeamSelectorTeamsCacheKey, getTeamsCacheKey } from "resources/teams_cache_keys"
import { addShortNameFieldToTeamMembers, meUserCacheKey } from "resources/users"
import { checkNamedArguments } from "utils/function"
import { fetcher } from "utils/query"
import { buildUrl } from "utils/string"

enum DemoTeamType {
  STANDARD = "STANDARD",
  RT4S = "RT4S",
}

enum DemoTeamTypeLabel {
  STANDARD = "Standard",
  RT4S = "RT4S",
}

function getTeamQueryKey(teamId: TeamID | null) {
  return [`/visible_teams/${teamId}/`]
}

function getTeam({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("getTeam", arguments, { required: ["teamId"] })
  return async () => {
    const { data } = await api.get(`/visible_teams/${teamId}/`)
    return addShortNameFieldToTeamMembers({ team: data })
  }
}

function useTeam({ teamId = null, enabled = true }: { teamId: TeamID | null; enabled?: boolean }) {
  checkNamedArguments("useTeam", arguments, { required: ["teamId"], optional: ["enabled"] })
  return useQuery(getTeamQueryKey(teamId), teamId ? getTeam({ teamId }) : () => {}, {
    enabled: !!enabled && !!teamId,
  })
}

async function getCurrentDemoTeam() {
  const { data } = await api.get("/demoer_teams/current_demo_team/")
  return addShortNameFieldToTeamMembers({ team: data })
}

function useCurrentDemoTeam() {
  return useQuery(["/demoer_teams/current_demo_team/"], getCurrentDemoTeam)
}

function getTeams() {
  return async () => {
    const url = "/teams/active_teams/"
    const { data } = await api.get(url)
    return data.map((team: TeamData) => addShortNameFieldToTeamMembers({ team }))
  }
}

function useTeams({ enabled = true }: { enabled?: boolean } = {}) {
  checkNamedArguments("useTeams", arguments, { optional: ["enabled"] })
  return useQuery(getTeamsCacheKey(), getTeams(), { enabled })
}

function getTeamSelectorTeams() {
  return async () => {
    const url = "/teams/team_selector_teams"
    const { data } = await api.get(url)
    return data.map((team: TeamData) => addShortNameFieldToTeamMembers({ team }))
  }
}

function useTeamSelectorTeams({ enabled = true }: { enabled?: boolean } = {}) {
  checkNamedArguments("useTeamSelectorTeams", arguments, { optional: ["enabled"] })
  return useQuery(getTeamSelectorTeamsCacheKey(), getTeamSelectorTeams(), { enabled })
}

function useReportTeam({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("useReportTeam", arguments, { required: ["teamId"] })
  const url = `/team_reports/${teamId}/`
  return useQuery([url], () => fetcher({ url }), { enabled: !!teamId })
}

function useReportTeamKitInstances({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("useReportTeamKitInstances", arguments, { required: ["teamId"] })
  const url = `/team_reports/${teamId}/kit_instances/`
  return useQuery([url], () => fetcher({ url }), { enabled: !!teamId })
}

function useReportTeamKitInstanceBySlug({
  teamId,
  kitSlug,
  includeArchivedTeams = false,
  enabled = true,
}: {
  teamId: TeamID
  kitSlug: KitSlug
  includeArchivedTeams?: boolean
  enabled?: boolean
}) {
  checkNamedArguments("useReportTeamKitInstanceBySlug", arguments, {
    required: ["teamId", "kitSlug"],
    optional: ["includeArchivedTeams", "enabled"],
  })
  const url = `/team_reports/${teamId}/kit_instance_by_slug/`
  const params = { kit_slug: kitSlug, ...(includeArchivedTeams ? { include_archived_teams: true } : {}) }
  return useQuery(["team_reports", teamId, "kit_instance_by_slug", params], () => fetcher({ url, params }), {
    enabled: !!enabled && !!teamId && !!kitSlug,
  })
}

/// TEAM ADMIN ///

function refetchTeams({ accountId, queryClient }: { accountId: AccountID; queryClient: QueryClient }) {
  checkNamedArguments("refetchTeams", arguments, { required: ["accountId", "queryClient"] })
  queryClient.refetchQueries({ queryKey: getTeamsCacheKey(), exact: true })
  queryClient.refetchQueries({ queryKey: getTeamSelectorTeamsCacheKey(), exact: true })
  queryClient.refetchQueries({ queryKey: getAccountTeamsCacheKey(accountId), exact: true })
}

function updateTeam({ teamId }: { teamId: TeamID | null }) {
  checkNamedArguments("updateTeam", arguments, { required: ["teamId"] })
  return async (values: Partial<TeamData>) => {
    if (teamId) {
      const { data } = await api.patch(`/manage_team/${teamId}/`, values)
      return data
    } else {
      return null
    }
  }
}

function useUpdateTeam({ team, currentUser }: { team: TeamData | null; currentUser: UserData | null }) {
  checkNamedArguments("useUpdateTeam", arguments, { required: ["team", "currentUser"] })

  const wasCurrentTeamLead = team?.team_lead_id === currentUser?.id

  const onSuccess = (data: TeamData) => {
    if (team) {
      refetchTeams({ accountId: team.account_id, queryClient })
    }
    if (
      (wasCurrentTeamLead && data.team_lead_id !== currentUser?.id) ||
      (!wasCurrentTeamLead && data.team_lead_id === currentUser?.id)
    ) {
      // if team lead changed to/from the currentUser, need to refetch me user as well
      queryClient.refetchQueries(meUserCacheKey, { exact: true })
    }
  }

  const queryClient = useQueryClient()
  return useMutation(updateTeam({ teamId: team?.id ?? null }), { onSuccess })
}

function useEngagementSurveyInfo({ token, uid, tid = null }: { token: string; uid: string; tid?: string | null }) {
  checkNamedArguments("useEngagementSurveyInfo", arguments, { required: ["token", "uid"], optional: ["tid"] })
  const url = buildUrl(["engagement_survey"], { urlQueryParams: { token, uid, ...(tid ? { tid } : {}) } })
  return useQuery([url], () => fetcher({ url }), { enabled: !!token && !!uid })
}

async function postEngagementSurvey({ surveyData }: { surveyData: EngagementSurveyData }) {
  checkNamedArguments("postEngagementSurvey", arguments, { required: ["surveyData"] })
  const { data } = await api.post(buildUrl(["engagement_survey"]), surveyData)
  return data
}

function getEngagementSurveyAverage({
  accountId,
  teamId = null,
  teamTags = null,
  includeArchivedTeams = false,
  hideIncompleteSurveyeeResponses = false,
}: {
  accountId: AccountID
  teamId?: TeamID | null
  teamTags?: string[] | null
  includeArchivedTeams?: boolean
  hideIncompleteSurveyeeResponses?: boolean
}) {
  checkNamedArguments("getEngagementSurveyAverage", arguments, {
    required: ["accountId"],
    optional: ["teamId", "teamTags", "includeArchivedTeams", "hideIncompleteSurveyeeResponses"],
  })
  return async () => {
    const { data } = await api.get(`/billing/account_reports/${accountId}/engagement_survey_average/`, {
      params: {
        ...(teamId ? { team_id: teamId } : {}),
        ...(teamTags ? { team_tags: teamTags } : {}),
        ...(includeArchivedTeams ? { include_archived_teams: true } : {}),
        ...(hideIncompleteSurveyeeResponses ? { hide_incomplete_surveyee_responses: true } : {}),
      },
    })
    return data
  }
}

function useEngagementSurveyAverage({
  accountId,
  teamId = null,
  teamTags = null,
  includeArchivedTeams = false,
  hideIncompleteSurveyeeResponses = false,
}: {
  accountId: AccountID
  teamId?: TeamID | null
  teamTags?: string[] | null
  includeArchivedTeams?: boolean
  hideIncompleteSurveyeeResponses?: boolean
}) {
  checkNamedArguments("useEngagementSurveyAverage", arguments, {
    required: ["accountId"],
    optional: ["teamId", "teamTags", "includeArchivedTeams", "hideIncompleteSurveyeeResponses"],
  })
  return useQuery(
    [
      "billing",
      "account_reports",
      "engagement_survey_average",
      { accountId, teamId, teamTags, includeArchivedTeams, hideIncompleteSurveyeeResponses },
    ],
    getEngagementSurveyAverage({ accountId, teamId, teamTags, includeArchivedTeams, hideIncompleteSurveyeeResponses }),
    { enabled: !!accountId }
  )
}

function getEngagementSurveyAISummary({
  accountId,
  teamId = null,
  teamTags = null,
  includeArchivedTeams = false,
  hideIncompleteSurveyeeResponses = false,
}: {
  accountId: AccountID
  teamId?: TeamID | null
  teamTags?: string[] | null
  includeArchivedTeams?: boolean
  hideIncompleteSurveyeeResponses?: boolean
}) {
  checkNamedArguments("getEngagementSurveyAISummary", arguments, {
    required: ["accountId"],
    optional: ["teamId", "teamTags", "includeArchivedTeams", "hideIncompleteSurveyeeResponses"],
  })
  return async () => {
    const { data } = await api.get(`/billing/account_reports/${accountId}/summarize_latest_survey_run_comments/`, {
      params: {
        ...(teamId ? { team_id: teamId } : {}),
        ...(teamTags ? { team_tags: teamTags } : {}),
        ...(includeArchivedTeams ? { include_archived_teams: true } : {}),
        ...(hideIncompleteSurveyeeResponses ? { hide_incomplete_surveyee_responses: true } : {}),
      },
    })
    return data
  }
}

function useAccountReportAIAccess({ accountId, enabled = false }: { accountId: AccountID; enabled?: boolean }) {
  checkNamedArguments("useAccountReportAIAccess", arguments, { required: ["accountId"], optional: ["enabled"] })
  const url = buildUrl(["billing", "account_reports", accountId, "ai_access"])
  return useQuery([url], () => fetcher({ url }), { enabled: !!enabled && !!accountId })
}

function useEngagementSurveyAISummary({
  accountId,
  teamId = null,
  teamTags = null,
  includeArchivedTeams = false,
  hideIncompleteSurveyeeResponses = false,
  enabled = false,
}: {
  accountId: AccountID
  teamId?: TeamID | null
  teamTags?: string[] | null
  includeArchivedTeams?: boolean
  hideIncompleteSurveyeeResponses?: boolean
  enabled?: boolean
}) {
  checkNamedArguments("useEngagementSurveyAISummary", arguments, {
    required: ["accountId"],
    optional: ["teamId", "teamTags", "includeArchivedTeams", "hideIncompleteSurveyeeResponses", "enabled"],
  })
  return useQuery(
    [
      "billing",
      "account_reports",
      "summarize_latest_survey_run_comments",
      { accountId, teamId, teamTags, includeArchivedTeams },
    ],
    getEngagementSurveyAISummary({
      accountId,
      teamId,
      teamTags,
      includeArchivedTeams,
      hideIncompleteSurveyeeResponses,
    }),
    { enabled: !!enabled && !!accountId }
  )
}

function useEngagementSurveyLink({ accountId, teamId = null }: { accountId: AccountID; teamId?: TeamID | null }) {
  checkNamedArguments("useEngagementSurveyLink", arguments, { required: ["accountId"], optional: ["teamId"] })
  const url = buildUrl(["billing", "account_reports", accountId, "engagement_survey_link"], {
    urlQueryParams: teamId ? { team_id: teamId } : null,
  })
  return useQuery([url], () => fetcher({ url }), { enabled: !!accountId })
}

async function getEngagementSurveyResultsExport({
  accountId,
  teamId = null,
  teamTags = null,
  includeArchivedTeams = false,
  hideIncompleteSurveyeeResponses = false,
}: {
  accountId: AccountID
  teamId?: TeamID | null
  teamTags?: string[] | null
  includeArchivedTeams?: boolean
  hideIncompleteSurveyeeResponses?: boolean
}) {
  checkNamedArguments("getEngagementSurveyResultsExport", arguments, {
    required: ["accountId"],
    optional: ["teamId", "teamTags", "includeArchivedTeams", "hideIncompleteSurveyeeResponses"],
  })
  const { data } = await api.get(`/billing/account_reports/${accountId}/get_engagement_survey_results_export/`, {
    params: {
      ...(teamId ? { team_id: teamId } : {}),
      ...(teamTags ? { team_tags: teamTags } : {}),
      ...(includeArchivedTeams ? { include_archived_teams: true } : {}),
      ...(hideIncompleteSurveyeeResponses ? { hide_incomplete_surveyee_responses: true } : {}),
    },
  })
  return data
}

function useBenchmarkSurveyInfo({ token, uid, tid = null }: { token: string; uid: string; tid?: string | null }) {
  checkNamedArguments("useBenchmarkSurveyInfo", arguments, { required: ["token", "uid"], optional: ["tid"] })
  const url = buildUrl(["benchmark_survey"], { urlQueryParams: { token, uid, ...(tid ? { tid } : {}) } })
  return useQuery([url], () => fetcher({ url }), { enabled: !!token && !!uid })
}

function useBenchmarkSurveyLink({ accountId, teamId = null }: { accountId: AccountID; teamId?: TeamID | null }) {
  checkNamedArguments("useBenchmarkSurveyLink", arguments, { required: ["accountId"], optional: ["teamId"] })
  const url = buildUrl(["billing", "account_reports", accountId, "benchmark_survey_link"], {
    urlQueryParams: teamId ? { team_id: teamId } : null,
  })
  return useQuery([url], () => fetcher({ url }), { enabled: !!accountId })
}

function getAggregatedResultsDataForTeam({
  teamId,
  kitSlug,
  component,
  identifiers,
  has_sensitive_data,
  additional_data,
  apiResource,
}: {
  teamId: TeamID
  kitSlug: KitSlug
  component: string
  identifiers: string[]
  has_sensitive_data: boolean
  additional_data: Record<string, unknown>
  apiResource: string
}) {
  checkNamedArguments("getAggregatedResultsDataForTeam", arguments, {
    required: ["teamId", "kitSlug", "component", "identifiers", "has_sensitive_data", "additional_data", "apiResource"],
  })
  return async () => {
    // apiResource = "teams" or "team_reports"
    const { data } = await api.post(`/${apiResource}/${teamId}/aggregated_results_data/`, {
      kitSlug,
      component,
      identifiers,
      has_sensitive_data,
      additional_data,
    })
    return data
  }
}

function getUseGetAggregatedResultsDataForTeamFunction({
  teamId,
  kitSlug,
  apiResource,
}: {
  teamId: TeamID
  kitSlug: KitSlug
  apiResource: string
}) {
  checkNamedArguments("getUseGetAggregatedResultsDataForTeamFunction", arguments, {
    required: ["teamId", "kitSlug", "apiResource"],
  })
  return ({
    component,
    identifiers,
    has_sensitive_data,
    additional_data,
    refetch_interval = false,
  }: {
    component: string
    identifiers: string[]
    has_sensitive_data: boolean
    additional_data: Record<string, unknown>
    refetch_interval?: number | false
  }) =>
    useQuery(
      ["team", teamId, "aggregated_results_data", component, identifiers, additional_data, apiResource],
      getAggregatedResultsDataForTeam({
        teamId,
        kitSlug,
        component,
        identifiers,
        has_sensitive_data,
        additional_data,
        apiResource,
      }),
      {
        refetchInterval: refetch_interval,
      }
    )
}

function inviteTeamMember({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("inviteTeamMember", arguments, { required: ["teamId"] })
  return async (email: string) => {
    const { data } = await api.post(`/manage_team/${teamId}/invite_team_member/`, { email })
    return data
  }
}

function useInviteTeamMember({ teamId, accountId }: { teamId: TeamID; accountId: AccountID }) {
  checkNamedArguments("useInviteTeamMember", arguments, { required: ["teamId", "accountId"] })
  const queryClient = useQueryClient()
  return useMutation(inviteTeamMember({ teamId }), {
    onSuccess: () => refetchTeams({ accountId, queryClient }),
  })
}

function resendTeamMemberInvite({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("resendTeamMemberInvite", arguments, { required: ["teamId"] })
  return async (teamMemberId: UserID) => {
    const { data } = await api.post(`/manage_team/${teamId}/resend_team_member_invite/`, {
      team_member_id: teamMemberId,
    })
    return data
  }
}

function useResendTeamMemberInvite({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("useResendTeamMemberInvite", arguments, { required: ["teamId"] })
  return useMutation(resendTeamMemberInvite({ teamId }))
}

function removeTeamMember({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("removeTeamMember", arguments, { required: ["teamId"] })
  return async (teamMemberId: UserID) => {
    const { data } = await api.post(`/manage_team/${teamId}/remove_team_member/`, { team_member_id: teamMemberId })
    return data
  }
}

function useRemoveTeamMember({ teamId, accountId }: { teamId: TeamID; accountId: AccountID }) {
  checkNamedArguments("useRemoveTeamMember", arguments, { required: ["teamId", "accountId"] })
  const queryClient = useQueryClient()
  return useMutation(removeTeamMember({ teamId }), {
    onSuccess: () => refetchTeams({ accountId, queryClient }),
  })
}

function updateReportTeamsData({ data, queryClient }: { data: Record<string, unknown>; queryClient: QueryClient }) {
  checkNamedArguments("updateReportTeamsData", arguments, { required: ["data", "queryClient"] })
  const cacheKeyRoot = getAccountReportCacheKeyRoot(data.account_id, { pathArray: ["teams"] })
  for (const [queryKey, teams] of queryClient.getQueriesData(cacheKeyRoot)) {
    if (Array.isArray(teams)) {
      queryClient.setQueryData(
        queryKey,
        teams.map((team) => (team.id === data.id ? data : team))
      )
    }
  }
}

function addTeamTag({ includeArchivedTeams = false }: { includeArchivedTeams?: boolean } = {}) {
  checkNamedArguments("addTeamTag", arguments, { optional: ["includeArchivedTeams"] })
  return async ({ teamId, tag }: { teamId: TeamID; tag: string }) => {
    const params = { ...(includeArchivedTeams ? { include_archived_teams: true } : {}) }
    const { data } = await api.post(`/manage_team/${teamId}/add_tag/`, { tag }, { params })
    return data
  }
}

function useAddTeamTag({ includeArchivedTeams = false }: { includeArchivedTeams?: boolean } = {}) {
  checkNamedArguments("useAddTeamTag", arguments, { optional: ["includeArchivedTeams"] })
  const queryClient = useQueryClient()
  return useMutation(addTeamTag({ includeArchivedTeams }), {
    onSuccess: (data) => updateReportTeamsData({ data, queryClient }),
  })
}

function removeTeamTag({ includeArchivedTeams = false }: { includeArchivedTeams?: boolean } = {}) {
  checkNamedArguments("removeTeamTag", arguments, { optional: ["includeArchivedTeams"] })
  return async ({ teamId, tag }: { teamId: TeamID; tag: string }) => {
    const params = { ...(includeArchivedTeams ? { include_archived_teams: true } : {}) }
    const { data } = await api.post(`/manage_team/${teamId}/remove_tag/`, { tag }, { params })
    return data
  }
}

function useRemoveTeamTag({ includeArchivedTeams = false }: { includeArchivedTeams?: boolean } = {}) {
  checkNamedArguments("useRemoveTeamTag", arguments, { optional: ["includeArchivedTeams"] })
  const queryClient = useQueryClient()
  return useMutation(removeTeamTag({ includeArchivedTeams }), {
    onSuccess: (data) => updateReportTeamsData({ data, queryClient }),
  })
}

function removeTeamTagSuffix({ includeArchivedTeams = false }: { includeArchivedTeams?: boolean } = {}) {
  checkNamedArguments("removeTeamTagSuffix", arguments, { optional: ["includeArchivedTeams"] })
  return async ({ teamId, tag, suffix }: { teamId: TeamID; tag: string; suffix: string }) => {
    const params = { ...(includeArchivedTeams ? { include_archived_teams: true } : {}) }
    const { data } = await api.post(`/manage_team/${teamId}/remove_tag_suffix/`, { tag, suffix }, { params })
    return data
  }
}

function useRemoveTeamTagSuffix({ includeArchivedTeams = false }: { includeArchivedTeams?: boolean } = {}) {
  checkNamedArguments("useRemoveTeamTagSuffix", arguments, { optional: ["includeArchivedTeams"] })
  const queryClient = useQueryClient()
  return useMutation(removeTeamTagSuffix({ includeArchivedTeams }), {
    onSuccess: (data) => updateReportTeamsData({ data, queryClient }),
  })
}

function sortUserTeams({ user, teams }: { user: UserData; teams: TeamData[] }) {
  checkNamedArguments("sortUserTeams", arguments, { required: ["user", "teams"] })
  if (!teams) {
    return []
  }
  return [...teams].sort((t1, t2) => {
    if (t1.demo !== t2.demo) {
      return t1.demo ? 1 : -1
    }

    if (t1.team_lead_id !== t2.team_lead_id && (t1.team_lead_id === user?.id || t2.team_lead_id === user?.id)) {
      return t1.team_lead_id === user?.id ? -1 : 1
    }

    return t1.name.toLowerCase().localeCompare(t2.name.toLowerCase())
  })
}

/// DEMO TEAM ADMIN ///

async function getDemoTeams() {
  const { data } = await api.get("/demoer_teams/get_demo_teams/")
  return data
}

function prefetchDemoTeams({ queryClient }: { queryClient: QueryClient }) {
  checkNamedArguments("prefetchDemoTeams", arguments, { required: ["queryClient"] })
  return queryClient.prefetchQuery({ queryKey: getDemoTeamsCacheKey(), queryFn: getDemoTeams })
}

function useDemoTeams() {
  return useQuery(getDemoTeamsCacheKey(), getDemoTeams)
}

function onDemoRequestSuccess({
  onSuccess,
  result,
  queryClient,
}: {
  onSuccess: (result: unknown) => void
  result: unknown
  queryClient: QueryClient
}) {
  checkNamedArguments("onDemoRequestSuccess", arguments, { required: ["onSuccess", "result", "queryClient"] })
  // clear query cache so we fetch updated current team data:
  queryClient.clear()
  // navigate function must be provided to ensure updated data throughout ui:
  onSuccess(result)
}

async function createDemoTeam({ name, type = null }: { name: string; type?: DemoTeamType | null }) {
  checkNamedArguments("createDemoTeam", arguments, { required: ["name"], optional: ["type"] })
  const { data } = await api.post("/demoer_teams/create_demo_team/", { name, ...(type ? { type } : {}) })
  return data
}

function useCreateDemoTeam({ onSuccess }: { onSuccess: (result: unknown) => void }) {
  checkNamedArguments("useCreateDemoTeam", arguments, { required: ["onSuccess"] })
  const queryClient = useQueryClient()
  return useMutation(
    ({ org_name, demo_type }: { org_name: string; demo_type: DemoTeamType }) =>
      createDemoTeam({ name: org_name, type: demo_type }),
    {
      onSuccess: (data) => {
        onDemoRequestSuccess({ onSuccess, result: data, queryClient })
      },
    }
  )
}

async function deleteDemoTeam({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("deleteDemoTeam", arguments, { required: ["teamId"] })
  const { data } = await api.delete(`/demoer_teams/${teamId}/delete_demo_team/`)
  return data
}

function useDeleteDemoTeam({ onSuccess }: { onSuccess: (result: unknown) => void }) {
  checkNamedArguments("useDeleteDemoTeam", arguments, { required: ["onSuccess"] })
  const queryClient = useQueryClient()
  return useMutation((teamId: TeamID) => deleteDemoTeam({ teamId }), {
    onSuccess: (data) => {
      onDemoRequestSuccess({ onSuccess, result: data, queryClient })
    },
  })
}

async function selectDemoTeam({ teamId }: { teamId: TeamID }) {
  checkNamedArguments("selectDemoTeam", arguments, { required: ["teamId"] })
  const { data } = await api.post("/demoer_teams/switch_demo_team/", { id: teamId })
  return data
}

function useSelectDemoTeam({ onSuccess }: { onSuccess: (result: unknown) => void }) {
  checkNamedArguments("useSelectDemoTeam", arguments, { required: ["onSuccess"] })
  const queryClient = useQueryClient()
  return useMutation((teamId: TeamID) => selectDemoTeam({ teamId }), {
    onSuccess: (data) => {
      onDemoRequestSuccess({ onSuccess, result: data, queryClient })
    },
  })
}

async function toggleDemoMode() {
  const { data } = await api.post("/demoer_teams/toggle_demo_mode/")
  return data
}

function useToggleDemoMode({ onSuccess }: { onSuccess: (result: unknown) => void }) {
  checkNamedArguments("useToggleDemoMode", arguments, { required: ["onSuccess"] })
  const queryClient = useQueryClient()
  return useMutation(toggleDemoMode, {
    onSuccess: (data) => {
      onDemoRequestSuccess({ onSuccess, result: !!data?.is_active, queryClient })
    },
  })
}

export {
  DemoTeamType,
  DemoTeamTypeLabel,
  useTeam,
  getTeamQueryKey,
  useCurrentDemoTeam,
  useTeams,
  useTeamSelectorTeams,
  useReportTeam,
  useReportTeamKitInstances,
  useReportTeamKitInstanceBySlug,
  useUpdateTeam,
  useEngagementSurveyInfo,
  postEngagementSurvey,
  useEngagementSurveyAverage,
  useEngagementSurveyLink,
  getEngagementSurveyResultsExport,
  useInviteTeamMember,
  useResendTeamMemberInvite,
  useRemoveTeamMember,
  useAddTeamTag,
  useRemoveTeamTag,
  useRemoveTeamTagSuffix,
  sortUserTeams,
  prefetchDemoTeams,
  useDemoTeams,
  useCreateDemoTeam,
  useDeleteDemoTeam,
  useSelectDemoTeam,
  useToggleDemoMode,
  getDemoTeams,
  getUseGetAggregatedResultsDataForTeamFunction,
  useAccountReportAIAccess,
  useEngagementSurveyAISummary,
  useBenchmarkSurveyInfo,
  useBenchmarkSurveyLink,
}
