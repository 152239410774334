import { createContext, useContext } from "react"
import { useLocation } from "react-router"

import { useKitInstance } from "resources/monthly_kit"
import { sortUserTeams, useTeamSelectorTeams, useTeam } from "resources/teams"
import { useUser } from "resources/users"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useQueryParams from "ui/hooks/useQueryParams"
import { parseIdOrError } from "utils/parse"

interface SelectedTeamContextData {
  selectedTeamId: TeamID | null
  setSelectedTeamId: (teamId: TeamID) => void
}

const SelectedTeamContext = createContext<SelectedTeamContextData>({
  selectedTeamId: null,
  setSelectedTeamId: () => {},
})

function useSelectedTeamId() {
  return useContext(SelectedTeamContext) || {}
}

function useSelectedTeam() {
  const { selectedTeamId } = useSelectedTeamId()
  const { data: selectedTeam } = useTeam({ teamId: selectedTeamId })
  return { selectedTeam }
}

function useSelectedTeamIdWithTeamIdQueryParam({ allowJumboTeams = true, redirectIfNotSelectableTeam = false } = {}): {
  selectedTeamId: TeamID | null
  redirectTeamId: TeamID | null
  needsRedirect: boolean
} {
  const location = useLocation()
  const { data: user = null } = useUser({ userId: "me" })
  const { selectedTeamId, setSelectedTeamId } = useSelectedTeamId()
  const { team_id, teamId } = useQueryParams()
  const queryParamTeamIdStr = (team_id ?? teamId)?.toString() ?? ""
  const queryParamTeamId = parseInt(queryParamTeamIdStr)
  const queryParamTeamIdAlreadySelected = selectedTeamId === queryParamTeamId

  const isScheduleModeActive = location?.state?.fetchScheduleModeStatus
  const { data: scheduleModeTeam } = useTeam({
    teamId: queryParamTeamId ? parseIdOrError<TeamID>(queryParamTeamId) : null,
    enabled: isScheduleModeActive,
  })

  const { data: regularModeTeams } = useTeamSelectorTeams({
    enabled: !isScheduleModeActive && !queryParamTeamIdAlreadySelected,
  })

  const nonJumboTeams = regularModeTeams?.filter(({ jumbo }: { jumbo: boolean }) => !jumbo) ?? []
  const scheduleModeTeams = scheduleModeTeam ? [scheduleModeTeam] : []
  const selectableTeams = isScheduleModeActive ? scheduleModeTeams : !allowJumboTeams ? nonJumboTeams : regularModeTeams

  const queryParamTeam = (selectableTeams ?? []).find(({ id }: { id: TeamID }) => id === queryParamTeamId)

  useEffectAfterChange(() => {
    if (!queryParamTeamIdAlreadySelected && queryParamTeam) {
      setSelectedTeamId(queryParamTeam.id)
    }
  }, [queryParamTeamIdAlreadySelected, setSelectedTeamId, queryParamTeam])

  const redirectTeamId = getRedirectTeamId({
    user,
    selectableTeams,
    selectedTeamId,
    queryParamTeamIdStr,
    queryParamTeam,
  })

  let needsRedirect = !queryParamTeamIdAlreadySelected
  if (redirectIfNotSelectableTeam && !queryParamTeam) {
    needsRedirect = true
  }

  return { selectedTeamId, redirectTeamId, needsRedirect }
}

function getRedirectTeamId({
  user,
  selectableTeams,
  selectedTeamId,
  queryParamTeamIdStr,
  queryParamTeam,
}: {
  user: UserData | null
  selectableTeams: TeamData[] | null
  selectedTeamId: TeamID | null
  queryParamTeamIdStr: string
  queryParamTeam: TeamData | null
}): TeamID | null {
  const selectableTeamIds = new Set((selectableTeams ?? []).map(({ id }) => id))

  // if there is a selected team but no team_id in the query params, redirect to include the team_id
  if (selectedTeamId && selectableTeamIds.has(selectedTeamId) && !queryParamTeamIdStr) {
    return selectedTeamId
  }

  // if we already have a team don't redirect
  if (selectedTeamId && selectableTeamIds.has(selectedTeamId)) {
    return null
  } else if (queryParamTeam && selectableTeamIds.has(queryParamTeam.id)) {
    return null
  }

  // if we don't have an eligible team don't redirect
  if (!user || !selectableTeams?.length) {
    return null
  }

  const [firstTeam] = sortUserTeams({ user, teams: selectableTeams })

  return firstTeam?.id ?? null
}

function useKitInstanceByID(
  kitInstanceId: KitInstanceID,
  {
    realtimeCacheKey = null,
    onSuccess = null,
  }: { realtimeCacheKey?: string | null; onSuccess?: ((data: KitInstanceData) => void) | null } = {}
): {
  kitInstance: KitInstanceData | null
  isFetching: boolean
} {
  const { data: kitInstance = null, isFetching } = useKitInstance({ kitInstanceId, realtimeCacheKey, onSuccess })
  const { selectedTeamId, setSelectedTeamId } = useSelectedTeamId()

  useEffectAfterChange(() => {
    if (!selectedTeamId && kitInstance) {
      setSelectedTeamId(kitInstance.team_id)
    }
  }, [setSelectedTeamId, kitInstance, selectedTeamId])

  return { kitInstance, isFetching }
}

export default SelectedTeamContext

export { useSelectedTeam, useSelectedTeamId, useSelectedTeamIdWithTeamIdQueryParam, useKitInstanceByID }
