import cn from "classnames"
import { useState } from "react"
import { styled } from "styled-components"

import { updateArtiExchangeInappropriateFlag } from "../resource"

import { FlagIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import Tooltip from "ui/Tooltip"

const ArtiFlagButton = styled(function ArtiFlagButton({ exchangeId, initialValue, className }) {
  const [flagged, setFlagged] = useState(initialValue)
  async function onFlagClick() {
    const newFlaggedValue = !flagged
    setFlagged(newFlaggedValue)
    await updateArtiExchangeInappropriateFlag(exchangeId, newFlaggedValue)
  }
  return (
    <Tooltip title={!flagged ? "Flag this message as inappropriate or offensive" : "Unflag this message"}>
      <Button
        className={cn(className, {
          flagged,
          // Need to flag these as important, since styled-components
          // is giving the default color a higher specificity in stage and prod.
          "text-error-red-important": !!flagged,
          "text-gray-7-important": !flagged,
        })}
        onClick={onFlagClick}
      >
        <FlagIcon />
      </Button>
    </Tooltip>
  )
})`
  background: none;
  box-shadow: none;
  border: none;
  padding: 6px;
  height: fit-content;
  width: fit-content;
  transition: color 150ms ease;

  &:active {
    border: none;
    background: none;
  }

  &:hover {
    box-shadow: none;

    &:not(.flagged) {
      color: var(--gray-8) !important;
    }
  }
`

export default ArtiFlagButton
