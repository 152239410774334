import cn from "classnames"
import { Fragment } from "react"
import { styled } from "styled-components"

import { getExerciseAnswer, getUserAnswer } from "../results_utils"

import Tooltip from "ui/Tooltip"
import View from "ui/View"

const RadioGroupMultiUserTextButtonGrid = styled(function RadioGroupMultiUserTextButtonGrid({
  identifier,
  exerciseInstances,
  exerciseComponent,
  sortedUsers,
  singleMultiUserGrid,
  className,
}) {
  const choiceOptions = exerciseComponent.options
  return (
    <div className={cn("mb-xl", className)}>
      {!singleMultiUserGrid ? (
        <PerUserTextBoxGrid
          identifier={identifier}
          exerciseInstances={exerciseInstances}
          choiceOptions={choiceOptions}
          sortedUsers={sortedUsers}
          className={className}
        />
      ) : (
        <MultiUserSingleTextBoxGrid
          identifier={identifier}
          exerciseInstances={exerciseInstances}
          choiceOptions={choiceOptions}
          sortedUsers={sortedUsers}
          className={className}
        />
      )}
    </div>
  )
})`
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: var(--spacing-3);
  column-gap: var(--spacing-7);

  .user-label {
    text-align: right;
    align-self: center;
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    grid-template-columns: auto 1fr;
    row-gap: var(--spacing-0);
    column-gap: var(--spacing-2);

    .user-label {
      text-align: left;
    }
  }
`

const PerUserTextBoxGrid = styled(function PerUserTextBoxGrid({
  identifier,
  exerciseInstances,
  choiceOptions,
  sortedUsers,
  className,
}) {
  return sortedUsers.map((user) => {
    const userSelections = getUserAnswer(exerciseInstances, user, identifier) ?? []
    return (
      <Fragment key={user.id}>
        <div className="user-label text-gray-9 text-normal text-thin">{user.short_name}</div>
        <View className={cn(className, "mb-xl-mobile-only")} $flexWrap="wrap">
          {choiceOptions.map(({ value, short_label, tooltip }) => (
            <Tooltip bottom float title={tooltip} disabled={!tooltip} key={value}>
              <div
                key={value}
                className={cn("answer-box text-gray-9 text-center text-semi-bold", {
                  selected: userSelections.includes(value),
                })}
              >
                {short_label}
              </div>
            </Tooltip>
          ))}
        </View>
      </Fragment>
    )
  })
})`
  display: grid;
  grid-template-columns: repeat(${({ columns = 6 }) => Math.min(columns, 5)}, minmax(0, 1fr));
  gap: 16px;

  .answer-box {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding: var(--spacing-3) var(--spacing-2);
    height: 100%;
    min-width: 120px;
    min-height: 60px;
    max-width: 176px;
    box-sizing: border-box;
    border-radius: 8px;
    background: var(--gray-1);
    border: 1px solid var(--gray-3);

    &.selected {
      background: var(--green-3);
      color: var(--fg);
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    grid-template-columns: repeat(${({ columns = 6 }) => Math.min(columns, 3)}, minmax(0, 1fr));
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    grid-template-columns: repeat(${({ columns = 6 }) => Math.min(columns, 2)}, minmax(0, 1fr));
  }
`

const MultiUserSingleTextBoxGrid = styled(function MultiUserSingleTextBoxGrid({
  identifier,
  exerciseInstances,
  choiceOptions,
  className,
}) {
  const answers = exerciseInstances.map((instance) => getExerciseAnswer(instance, identifier))
  const answerCounts = Object.fromEntries(
    choiceOptions.map(({ value }) => [value, answers.filter((a) => a?.includes(value)).length])
  )

  return (
    <View className={cn(className, "mb-xl")} $flexWrap="wrap">
      {choiceOptions.map(({ value, short_label, tooltip }) => (
        <Tooltip bottom float title={tooltip} disabled={!tooltip} key={value}>
          <div
            className={cn("answer-box", {
              notSelected: answerCounts[value] === 0,
            })}
          >
            {answerCounts[value] > 0 && (
              <span className="answer-count mr-xs text-bold text-orange-2">{answerCounts[value]}</span>
            )}
            <span key={value} className="text-gray-9">
              {short_label}
            </span>
          </div>
        </Tooltip>
      ))}
    </View>
  )
})`
  display: grid;
  grid-template-columns: repeat(${({ columns = 6 }) => Math.min(columns, 4)}, minmax(0, 1fr));
  gap: 16px;

  .answer-box {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px 15px;
    height: 100%;
    min-width: 120px;
    min-height: 60px;
    max-width: 176px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--gray-4);

    /* stylelint-disable-next-line selector-class-pattern */
    &.notSelected {
      background: var(--gray-1);
      color: var(--gray-7);
      border: 1px solid var(--gray-2);
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    grid-template-columns: repeat(${({ columns = 6 }) => Math.min(columns, 3)}, minmax(0, 1fr));
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    grid-template-columns: repeat(${({ columns = 6 }) => Math.min(columns, 2)}, minmax(0, 1fr));
  }
`

export default RadioGroupMultiUserTextButtonGrid
