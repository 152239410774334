import { useKitSession } from "../KitSessionContext"

import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import NumPeopleCompleted from "domains/KitSession/components/NumPeopleCompleted"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { useKitParticipants } from "resources/monthly_kit"
import Callout from "ui/Callout"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import TextContainer from "ui/TextContainer"
import { useHasTeamFeature } from "utils/team"

const TakeABreakStep = ({ kitInstance, sessionStep, className }) => {
  const { team, preview } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })

  if (!participants) {
    return <Loading />
  }

  const participantCount = participants.length
  const { description, display_time_estimate, display_num_people_completed, callout, tip } = sessionStep

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <TextContainer className="mr-xl">
          {description.map((text, index) => (
            <p className="text-gray-9 mb-small" key={index}>
              {text}
            </p>
          ))}

          {!!display_time_estimate && (
            <p className="text-gray-9 mb-xl">
              Each person will get{" "}
              <span className="text-bold">{teamShareMemberTime(participantCount)} minutes to present.</span> (Based on
              your team size of {participantCount}.)
            </p>
          )}

          {!!display_num_people_completed && (
            <NumPeopleCompleted
              kitInstance={kitInstance}
              sessionStep={sessionStep}
              label="Each circle is a person on your team. Wait for them all to turn green!"
            />
          )}

          {!!callout && (
            <Callout title={callout.title}>
              <p> {callout.description} </p>
            </Callout>
          )}
        </TextContainer>
        <FacilitatorTip className="mb-xl sidebar-right" tip={tip} />
      </SidebarContainer>
    </StepContent>
  )
}

const teamShareMemberTime = (participantCount) => {
  if (participantCount <= 6) {
    return 5
  } else if (participantCount === 7) {
    return 4
  } else {
    return 3
  }
}

export default TakeABreakStep
