import { set, pickBy, merge } from "lodash-es"
import { useCallback } from "react"

import {
  useOrCreateExerciseInstance,
  useMutateExerciseAnswer,
  useUpdateExerciseAnswerImage,
  useUpdateExerciseAnswerImageURL,
} from "resources/exercise"
import { useTeam } from "resources/teams"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useHasTeamFeature } from "utils/team"

export default function useExerciseForm({ teamId, slug, version, teamLevelExercise }) {
  const { data: exerciseInstance, isFetching } = useOrCreateExerciseInstance({
    teamId,
    slug,
    version,
    teamLevelExercise,
  })
  const { data: team } = useTeam({ teamId })
  const { enabled: uploadImageThroughHeroku } = useHasTeamFeature(team, SHARED_FLAGS.UPLOAD_IMAGE_THROUGH_HEROKU)
  const { mutateAsync: mutateExerciseAnswer } = useMutateExerciseAnswer(exerciseInstance?.id)
  const { mutateAsync: updateExerciseAnswerImage } = useUpdateExerciseAnswerImage(exerciseInstance?.id)
  const { mutateAsync: updateExerciseAnswerImageURL } = useUpdateExerciseAnswerImageURL(exerciseInstance?.id)

  // memoizes saveonchange, to only include things that have changed
  const saveOnChange = useCallback(
    async (name, value, { image, objectKeyAWS, onSuccess, onError } = {}) => {
      if (uploadImageThroughHeroku && image) {
        updateExerciseAnswerImage({ identifier: name, file: image }, { onSuccess, onError })
      } else if (!uploadImageThroughHeroku && objectKeyAWS) {
        updateExerciseAnswerImageURL({ identifier: name, object_key: objectKeyAWS }, { onSuccess, onError })
      } else {
        mutateExerciseAnswer(set({}, name, value), { onSuccess, onError })
      }
    },
    [mutateExerciseAnswer, updateExerciseAnswerImage, updateExerciseAnswerImageURL, uploadImageThroughHeroku]
  )

  if (!exerciseInstance) {
    return {
      exerciseInstance,
      isFetching,
      isInvalid: !isFetching, // invalid because without an exercise, we can't save exercise answers.
    }
  }

  const allComponents = exerciseInstance.definition.steps.flatMap((step) => step.components).filter(Boolean)

  const definitionInitialValues = allComponents.reduce((acc, component) => {
    const { identifier, initialValue } = component
    return { ...acc, [identifier]: initialValue }
  }, {})

  const saveInitialValueOnSubmitIdentifiers = allComponents
    .filter(({ saveInitialValueOnSubmit }) => saveInitialValueOnSubmit)
    .map(({ identifier }) => identifier)

  const answerValues = exerciseInstance.answers.reduce((acc, answer) => {
    const { identifier, data } = answer
    return { ...acc, [identifier]: data }
  }, {})

  // Use a deep instead of shallow merge here. This handles certain cases
  // such as array values where we always want to display N entries from
  // definitionInitialValues, even if the user saves fewer than N answers
  // into answerValues (with shallow merge, we'd overwrite the N entries).
  const initialValues = merge({}, definitionInitialValues, answerValues)

  function onSubmit(values) {
    const changed = pickBy(values, (value, key) => {
      if (saveInitialValueOnSubmitIdentifiers.includes(key)) {
        return answerValues[key] !== value
      }
      return initialValues[key] !== value
    })
    mutateExerciseAnswer(changed)
  }

  return {
    initialValues,
    isFetching,
    onSubmit,
    saveOnChange,
    definition: exerciseInstance.definition,
    exerciseInstance,
  }
}
