// Note: Keep in sync with LLMType in backend/rtai/constants.py
enum LLMType {
  GPT_4o = "gpt-4o",
  GOOGLE_GEMINI_2_0_FLASH_LATEST = "gemini-2.0-flash-001",
  GOOGLE_GEMINI_2_5_PRO_EXP_03_25 = "gemini-2.5-pro-exp-03-25",
  ANTHROPIC_CLAUDE_3_5_SONNET = "claude-3-5-sonnet-latest",
  ANTHROPIC_CLAUDE_3_7_SONNET = "claude-3-7-sonnet-latest",
  LLAMA3_70B = "llama3-70b-8192",
  GPT_4 = "gpt-4",
  GPT_4o_MINI = "gpt-4o-mini",
  GPT_3_5 = "gpt-3.5-turbo",
  GPT_4o_VANILLA = "gpt-4o-vanilla",
  GOOGLE_GEMINI_2_0_FLASH_LATEST_VANILLA = "gemini-2.0-flash-001-vanilla",
  GOOGLE_GEMINI_2_5_PRO_EXP_03_25_VANILLA = "gemini-2.5-pro-exp-03-25-vanilla",
  ANTHROPIC_CLAUDE_3_5_SONNET_VANILLA = "claude-3-5-sonnet-vanilla",
  ANTHROPIC_CLAUDE_3_7_SONNET_VANILLA = "claude-3-7-sonnet-vanilla",
}

// Note: Keep in sync with LLMName in backend/rtai/constants.py
enum LLMName {
  GPT_4o = "GPT-4o",
  GOOGLE_GEMINI_2_0_FLASH_LATEST = "Gemini 2.0 Flash Latest",
  GOOGLE_GEMINI_2_5_PRO_EXP_03_25 = "Gemini 2.5 Pro Exp 03/25",
  ANTHROPIC_CLAUDE_3_5_SONNET = "Claude 3.5 Sonnet",
  ANTHROPIC_CLAUDE_3_7_SONNET = "Claude 3.7 Sonnet",
  LLAMA3_70B = "LLaMA3 70b",
  GPT_4 = "GPT-4",
  GPT_4o_MINI = "GPT-4o Mini",
  GPT_3_5 = "GPT-3.5",
  GPT_4o_VANILLA = "GPT-4o Vanilla",
  GOOGLE_GEMINI_2_0_FLASH_LATEST_VANILLA = "Gemini 2.0 Flash Latest Vanilla",
  GOOGLE_GEMINI_2_5_PRO_EXP_03_25_VANILLA = "Gemini 2.5 Pro Exp 03/25 Vanilla",
  ANTHROPIC_CLAUDE_3_5_SONNET_VANILLA = "Claude 3.5 Sonnet Vanilla",
  ANTHROPIC_CLAUDE_3_7_SONNET_VANILLA = "Claude 3.7 Sonnet Vanilla",
}

// Note: Keep in sync with VANILLA_LLM_TYPES in backend/rtai/constants.py
const VANILLA_LLM_TYPES = [
  LLMType.GPT_4o_VANILLA,
  LLMType.GOOGLE_GEMINI_2_0_FLASH_LATEST_VANILLA,
  LLMType.GOOGLE_GEMINI_2_5_PRO_EXP_03_25_VANILLA,
  LLMType.ANTHROPIC_CLAUDE_3_5_SONNET_VANILLA,
  LLMType.ANTHROPIC_CLAUDE_3_7_SONNET_VANILLA,
]

// Note: Keep in sync with ArtiSender in backend/rtai/constants.py
enum ArtiSender {
  USER = "user",
  BOT = "bot",
}

// Note: Keep in sync with ArtiHistoryOrder in backend/rtai/constants.py
enum ArtiHistoryOrder {
  FREQUENCY = "frequency",
  RECENCY = "recency",
  RATING = "rating",
  OLDEST = "oldest",
}

const DEFAULT_ARTI_HISTORY_ORDER = ArtiHistoryOrder.RECENCY

const ARTI_RATING_NA_VALUE = -1

const MIN_RATEABLE_MESSAGE_LENGTH = 250

const CHAT_TYPE = {
  TEAM_MEMBER: "team_member",
  TEAM: "team",
  ME: "me",
  NO_ONE: "no_one",
  OTHER: "other",
  PERFORMANCE_REVIEW: "performance_review",
  ACTION_ITEMS: "action_items",
  PSYCHOLOGICAL_SAFETY_ANIMAL: "psychological_safety_animal",
  AT_MY_BEST_NICKNAME: "at_my_best_nickname",
}

// Note: Keep in sync with REVIEW_TYPE in backend/rtai/constants.py
const REVIEW_TYPE = {
  SELF_REVIEW: "self_review",
  MGR_REVIEW: "mgr_review",
}

// Note: Keep in sync with PERFORMANCE_REVIEW_OPTIONS in backend/rtai/constants.py
const PERFORMANCE_REVIEW_OPTIONS = {
  UPLOAD_REVIEW: "upload_review",
  CHAT_REVIEW: "chat_review",
}

// Note: Keep in sync with NO_ONE_MEMBER in backend/rtai/constants.py
const NO_ONE_MEMBER = {
  id: 0 as UserID,
  short_name: "N/A",
  full_name: "No one in particular",
  pronouns: ["they", "them", "their", "theirs", "they/them/their"],
  first_name: "",
  last_name: "",
  name: "",
  email: "",
  is_registered: false,
  invited_at: null,
  onboarded_at: null,
  is_a_team_lead: false,
  is_staff: false,
  is_demo_mode_active: false,
  can_create_teams: false,
}

const ARTI_IN_SESSION_ASK_ME_MESSAGE = {
  [CHAT_TYPE.ACTION_ITEMS]:
    "OK, those ideas aren't quite right. Let me help you find better options.\n\n" +
    "Tell me more about what kinds of action items would be helpful to you?\n\n" +
    "Is there a specific area or topics you want to focus on?\n\n" +
    "Would you like actions that are quick and easy or more challenging?",
  [CHAT_TYPE.PSYCHOLOGICAL_SAFETY_ANIMAL]:
    "OK, those ideas aren't quite right. Let me help you find better options.\n\n" +
    "Tell me a little more about what you're looking for.\n\n" +
    "Is there a quality, behavior, or feeling you'd like to highlight or capture?",
  [CHAT_TYPE.AT_MY_BEST_NICKNAME]:
    "OK, those ideas aren't quite right. Let me help you find better options.\n\n" +
    "Tell me a little more about what you're looking for.\n\n" +
    "Is there a quality or behavior you'd like to highlight or capture?",
}

const ARTI_ACTION_ITEM_INITIAL_MESSAGE =
  "Give me a few action items to follow up on my reflections and the learnings from this session."
const ARTI_PSYCHOLOGICAL_SAFETY_ANIMAL_INITIAL_MESSAGE = "Give me an animal best describes me at work."
// const ARTI_AT_MY_BEST_NICKNAME_INITIAL_MESSAGE = "Give me a nickname."

const ARTI_MORE_IDEAS_MESSAGE = "Give me ideas"
const ARTI_TRY_AGAIN_MESSAGE = "OK, let's try again"

// TODO: Remove once design is finalized and not needed
const IGNORE_USER_MESSAGE = [
  // ARTI_ACTION_ITEM_INITIAL_MESSAGE,
  // ARTI_PSYCHOLOGICAL_SAFETY_ANIMAL_INITIAL_MESSAGE,
  // ARTI_MORE_IDEAS_MESSAGE,
  // ARTI_AT_MY_BEST_NICKNAME_INITIAL_MESSAGE,
]

export {
  LLMType,
  LLMName,
  VANILLA_LLM_TYPES,
  ArtiSender,
  ArtiHistoryOrder,
  DEFAULT_ARTI_HISTORY_ORDER,
  ARTI_RATING_NA_VALUE,
  MIN_RATEABLE_MESSAGE_LENGTH,
  CHAT_TYPE,
  NO_ONE_MEMBER,
  REVIEW_TYPE,
  PERFORMANCE_REVIEW_OPTIONS,
  IGNORE_USER_MESSAGE,
  ARTI_ACTION_ITEM_INITIAL_MESSAGE,
  ARTI_PSYCHOLOGICAL_SAFETY_ANIMAL_INITIAL_MESSAGE,
  ARTI_MORE_IDEAS_MESSAGE,
  ARTI_IN_SESSION_ASK_ME_MESSAGE,
  ARTI_TRY_AGAIN_MESSAGE,
}
