import { Form, Formik, ErrorMessage, FormikContext } from "formik"
import { useState, useContext } from "react"
import { styled } from "styled-components"

import Accordion from "components/Accordion"
import SaveButton from "domains/Admin/SaveButton"
import { canUserManageAccount, getTeamLeadOptions } from "domains/Admin/utils"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import EmailField from "forms/fields/EmailField"
import ValidatedField from "forms/fields/ValidatedField"
import handleErrors from "forms/handleErrors"
import Yup from "forms/yup"
import { searchAccountUsers } from "resources/billing"
import { useUpdateTeam } from "resources/teams"
import useFeatures from "ui/hooks/useFeatures"
import View from "ui/View"

const radioIconStyles = `
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: 0 0 0 1px var(--gray-9);
`
const UncheckedRadioIcon = styled.div`
  ${radioIconStyles}
  background-color: transparent;
`
const CheckedRadioIcon = styled.div`
  ${radioIconStyles}
  background-color: var(--rising-green);
`

interface TeamLeadFieldProps {
  className: string
  account: AccountData
  user: UserData
  team?: TeamData | null
  initialEmailAccordionOpen?: boolean
  onEmailAccordionOpen?: null | (() => void)
  onIdAccordionOpen?: null | (() => void)
}

interface TeamLeadFieldValues {
  team_lead_id: UserID | null
  team_lead_email: string
}

const TeamLeadField = ({
  className,
  account,
  user,
  team = null,
  initialEmailAccordionOpen = true,
  onEmailAccordionOpen = null,
  onIdAccordionOpen = null,
}: TeamLeadFieldProps) => {
  const features = useFeatures()
  const { mutateAsync: updateTeam } = useUpdateTeam({ team, currentUser: user })
  const [emailAccordionOpen, setEmailAccordionOpen] = useState(initialEmailAccordionOpen)
  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isError, setIsError] = useState(false)
  const formikContext = useContext(FormikContext)

  const canSearchUsers = canUserManageAccount(account, user, features)
  const teamLeadOptions = getTeamLeadOptions(account, user, team, canSearchUsers)

  const initialValues: TeamLeadFieldValues = {
    team_lead_id: null,
    team_lead_email: "",
  }

  const teamLeadId = Yup.number().nullable()
  const teamLeadEmail = Yup.string()
  const schema = Yup.object().shape({
    team_lead_email: emailAccordionOpen ? teamLeadEmail.required("Please enter an email.") : teamLeadEmail,
    team_lead_id: emailAccordionOpen ? teamLeadId : teamLeadId.required("Please select a new team lead"),
  })

  const asyncSearchFunction = canSearchUsers
    ? async (searchTerm: string) => {
        if (!searchTerm?.length || searchTerm.length < 3) {
          return null
        }
        const users = await searchAccountUsers(searchTerm, account.id)
        return users.map((user: UserData) => ({
          value: user.id.toString(),
          label: user.name || user.email,
        }))
      }
    : null

  const onSubmit = handleErrors(
    async (values: TeamLeadFieldValues) => {
      const { team_lead_email, team_lead_id } = values
      setIsSaved(false)
      setIsSaving(true)
      if (emailAccordionOpen && team_lead_email) {
        await updateTeam({ team_lead_email })
      } else if (team_lead_id) {
        await updateTeam({ team_lead_id })
      }
      setIsSaving(false)
      setIsSaved(true)
    },
    (error) => {
      setIsError(true)
      return error
    }
  )

  function reset(setErrors: Function) {
    setIsSaving(false)
    setIsSaved(false)
    setIsError(false)
    setErrors({})
  }

  const renderField = ({ setValues, setErrors, values }: typeof formikContext) => (
    <>
      <Accordion
        externalOpenState={emailAccordionOpen}
        beforeOpen={() => {
          reset(setErrors)
          setEmailAccordionOpen(true)
          onEmailAccordionOpen?.()
        }}
        closedIcon={UncheckedRadioIcon}
        openIcon={CheckedRadioIcon}
        title={() => <>Invite new team lead by email</>}
      >
        <View className="mb-medium">
          <ValidatedField
            base={EmailField}
            className="team-lead-email-field mr-large"
            name="team_lead_email"
            size="medium"
            autoComplete="off"
            value={values.team_lead_email}
            onClick={() => reset(setErrors)}
            onClear={() => setValues({ ...values, team_lead_email: "" })}
          />
          {!formikContext && (
            <SaveButton saving={isSaving} saved={!!isSaved && "Invite sent!"} error={isError}>
              Send invite
            </SaveButton>
          )}
        </View>
      </Accordion>
      <Accordion
        externalOpenState={!emailAccordionOpen}
        beforeOpen={() => {
          reset(setErrors)
          setEmailAccordionOpen(false)
          onIdAccordionOpen?.()
        }}
        closedIcon={UncheckedRadioIcon}
        openIcon={CheckedRadioIcon}
        title={() => <>Assign an existing user as team lead</>}
      >
        <View>
          <div onClick={() => reset(setErrors)}>
            <AdvancedSelectField
              className="team-lead-select mr-large"
              name="team_lead_id"
              placeholder="Pick a team lead"
              options={teamLeadOptions}
              asyncSearchFunction={asyncSearchFunction}
              width={330} // same as default input width
            />
            <ErrorMessage component="div" className="text-danger mt-xs" data-cy="field-message" name="team_lead_id" />
          </div>
          {!formikContext && (
            <SaveButton saving={isSaving} saved={!!isSaved && "Assigned!"} error={isError}>
              Assign
            </SaveButton>
          )}
        </View>
      </Accordion>
    </>
  )

  return (
    <div className={className}>
      {formikContext ? (
        renderField(formikContext)
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({ values, setValues, setErrors }) => (
            <Form>{renderField({ setValues, setErrors, values } as typeof formikContext)}</Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default TeamLeadField
