import { styled } from "styled-components"

// Common chat styles to be used across different Arti chat components
export default styled.div`
  .conversation-container {
    width: 100%;

    .hidden {
      height: 0;
    }
  }

  /* Message css styles start */
  /* Need to keep this here because a lot of the styles
  in child components are dependent on these classes */
  .message {
    position: relative;
    color: var(--white);
    font-weight: 600;
    border-radius: 8px;

    /* nubbin */
    &::before {
      content: "";
      position: absolute;
      bottom: 100%;
      height: 0.5rem;
      width: 1rem;
      border-radius: 3px 3px 0 0;
      z-index: var(--z-above-zero);
    }
  }

  /* stylelint-disable-next-line csstools/use-nesting */
  .message.user {
    background-color: var(--orange-4);
    text-shadow: var(--blur-4);
    font-weight: 500;

    /* nubbin */
    &::before {
      right: 1rem;
      background-color: var(--orange-4);
      transform: skew(0, -30deg);
      transform-origin: 100% 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%);
    }
  }

  .message.bot {
    color: var(--default);
    font-weight: 400;
    background-color: var(--gray-1);
    border: 1px solid var(--gray-4);
    box-shadow: var(--blur-4);

    /* nubbin */
    &::before {
      left: 1rem;
      background-color: var(--gray-1);
      border: 1px solid var(--gray-4);
      border-bottom: none;
      padding-bottom: 1px;
      box-shadow: var(--blur-4);
      transform: skew(0, 30deg);
      transform-origin: 0 0;
      clip-path: polygon(-10px -10px, 35px -10px, -10px 16px);
    }

    .icon-buttons {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: flex-end;
      float: right;
      color: var(--gray-7);
      width: fit-content;
      z-index: var(--z-below-sticky);
      /* z-index needed to allow flag and copy buttons to be interactive */
    }
  }
  /* Message css styles end */

  /* Chat area type start */
  /* This is needed for the chat content */
  li {
    margin-left: var(--spacing-4);
  }

  table {
    width: 100%;
    border-spacing: 0; /* no white lines between table cells */
    margin-bottom: 12px;
  }

  tr {
    height: var(--row-height);
  }

  th {
    padding: 8px 12px;

    &.shrink {
      width: 1px; /* make column "shrink" to minimum width to contain header text */
      padding-right: 15px; /* adjust to ensure header text remains centered */
    }
  }

  td {
    padding: 4px 12px;
  }

  th,
  td {
    text-align: left;
    position: relative;
  }

  .arti-footer {
    position: relative;
    bottom: 0;
    margin-top: auto;
  }
`
