import { useQueryClient } from "@tanstack/react-query"
import { isAfter, parseISO, subHours } from "date-fns"
import { useState } from "react"
import { Link, Navigate, Route, useNavigate, useParams } from "react-router-dom"

import { GroupTimersContext, getInitialGroupTimers } from "./GroupTimersContext"
import { KitSessionContext, useKitSession } from "./KitSessionContext"
import KitSessionStep from "./KitSessionStep"

import { usePreviewOverlayContext } from "components/PreviewOverlayContext"
import { RouteSteps } from "components/Steps/Steps"
import { getExerciseInstanceCacheKey } from "resources/exercise"
import { useRegenerateKitSessionCode, useJoinKitInstanceById } from "resources/monthly_kit"
import { useTeam, getTeamQueryKey } from "resources/teams"
import { useUser } from "resources/users"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import useQueryParams from "ui/hooks/useQueryParams"
import useWindowSize from "ui/hooks/useWindowSize"
import Loading from "ui/Loading"
import { useModal } from "ui/ModalContext"
import PageTitle from "ui/PageTitle"
import { useKitInstanceByID } from "ui/SelectedTeamContext"
import { useUpdateTheme } from "ui/ThemeUpdateContext"
import View from "ui/View"
import { KitGroupSize } from "utils/kit"
import { useHasTeamFeature } from "utils/team"

const KitSessionContent = () => {
  const { kitInstance, visibleSteps, preview, team, isTeamLead, isFacilitator } = useKitSession()
  const [groupTimers, setGroupTimers] = useState(getInitialGroupTimers(visibleSteps))
  const { enabled: syncSessionTeamFeatureEnabled, isInitialLoading: syncSessionTeamFeatureIsInitialLoading } =
    useHasTeamFeature(team, SHARED_FLAGS.RTDEV_SESSION_AUTO_FOLLOW)
  const syncSessionFeatureEnabled = syncSessionTeamFeatureEnabled && !isFacilitator
  const wildcardStep = getWildcardStep({ kitInstance, team, visibleSteps, preview, syncSessionFeatureEnabled })
  const { enableWidescreen, enableSessionTheme, resetTheme } = useUpdateTheme()
  const [themeIsUpdating, setThemeIsUpdating] = useState(true)
  const { isDesktopOrLarger } = useWindowSize()

  useEffectAfterChange(() => {
    if (kitInstance.kit.group_size === KitGroupSize.JUMBO && isTeamLead && isDesktopOrLarger) {
      enableWidescreen()
    } else {
      enableSessionTheme()
    }
    setThemeIsUpdating(false)
    return () => resetTheme()
  }, [kitInstance.kit.group_size, isTeamLead, isDesktopOrLarger, enableWidescreen, enableSessionTheme, resetTheme])

  if (themeIsUpdating || syncSessionTeamFeatureIsInitialLoading) {
    return null
  }

  return (
    <GroupTimersContext.Provider value={{ groupTimers, setGroupTimers }}>
      <RouteSteps>
        {visibleSteps.map((step, currentStepIndex) => (
          <Route
            path={step.path}
            key={step.path}
            element={
              <KitSessionStep
                sessionStep={step}
                currentStepIndex={currentStepIndex}
                allSessionSteps={visibleSteps}
                isTeamLead={isTeamLead}
              />
            }
          />
        ))}
        <Route
          path="*"
          ignore
          element={<Navigate to={wildcardStep.path + (preview ? "?preview=true" : "")} replace />}
        />
      </RouteSteps>
    </GroupTimersContext.Provider>
  )
}

const getWildcardStep = ({ kitInstance, visibleSteps, preview, syncSessionFeatureEnabled }) => {
  if (preview || !kitInstance.session_current_step || !!syncSessionFeatureEnabled) {
    return visibleSteps[0]
  }

  return visibleSteps.find((step) => step.path === kitInstance.session_current_step) ?? visibleSteps[0]
}

const ExitPreviewModeModal = ({ kitInstance, closeModal }) => {
  const navigate = useNavigate()

  const onExitClick = async () => {
    navigate(kitInstance.home_url)
    closeModal()
  }

  return (
    <>
      <div className="space-y-medium mb-large">
        You will return to the theme introduction page after exiting preview mode.
      </div>
      <View>
        <Button className="secondary" onClick={onExitClick}>
          Exit
        </Button>
        <Button className="link secondary" onClick={closeModal}>
          Cancel
        </Button>
      </View>
    </>
  )
}

export default function KitSession() {
  const { data: user } = useUser({ userId: "me" })
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { kitInstance, isFetching: kitInstanceIsFetching } = useKitInstanceByID(id, {
    realtimeCacheKey: "facilitator",
    onSuccess: (kitInstance) => {
      queryClient.invalidateQueries({ queryKey: getTeamQueryKey(kitInstance?.team_id) })
      queryClient.invalidateQueries({
        queryKey: getExerciseInstanceCacheKey({
          teamId: kitInstance?.team_id,
          slug: kitInstance?.kit.exercise.slug,
          teamLevelExercise: true,
        }),
      })
    },
  })
  const { data: team } = useTeam({ teamId: kitInstance?.team_id })
  const { mutateAsync: regenerateKitSessionCode } = useRegenerateKitSessionCode()
  const { mutateAsync: joinKitInstanceById } = useJoinKitInstanceById()
  const preview = useQueryParams()["preview"] === "true"
  const { setPreviewOverlay, setPreviewOverlayText, setOnPreviewExitClick } = usePreviewOverlayContext()
  const { setModal } = useModal()
  const previewOverlayText = team?.preview
    ? "Use preview mode to review or prepare for a team session. To run a team session with " +
      "participants, assign yourself as a team lead in the Admin section."
    : "Use preview mode to review or prepare for a team session. When you are ready " +
      "to run the session with participants, press the Start session button on the Kits page."

  useEffectAfterChange(() => {
    setPreviewOverlay(preview)
    setPreviewOverlayText(previewOverlayText)
    setOnPreviewExitClick(() => async () => {
      setModal({
        title: "Exit preview mode?",
        content: <ExitPreviewModeModal kitInstance={kitInstance} closeModal={() => setModal(null)} />,
      })
    })

    return function cleanup() {
      setPreviewOverlay(false)
      setPreviewOverlayText(null)
      setOnPreviewExitClick(null)
    }
  }, [
    preview,
    previewOverlayText,
    setPreviewOverlay,
    setPreviewOverlayText,
    setOnPreviewExitClick,
    kitInstance,
    setModal,
  ])

  useEffectAfterChange(() => {
    const ensureSessionCode = async (kitInstanceId) => await regenerateKitSessionCode({ kitInstanceId })

    if (kitInstance && user && team) {
      if (!kitInstance.session_code && !team.preview && !preview) {
        ensureSessionCode(kitInstance.id)
      }
    }
  }, [kitInstance, user, team, preview, regenerateKitSessionCode])

  useEffectAfterChange(() => {
    const asyncJoinKitInstanceById = async (kitInstanceId) => await joinKitInstanceById({ kitInstanceId })
    if (kitInstance && !kitInstance.is_participant && !kitInstance.is_session_full && !preview) {
      asyncJoinKitInstanceById(kitInstance.id)
    }
  }, [kitInstance, joinKitInstanceById, preview])

  if (!kitInstance) {
    return kitInstanceIsFetching ? <Loading /> : <Navigate to="/code" />
  }

  if (!user || !team) {
    return null
  }

  if (!kitInstance.is_participant && kitInstance.is_session_full) {
    return (
      <div className="p-large">
        Session is currently full. Please have your team lead <Link to="/contact">contact us</Link> if you'd like to
        join.
      </div>
    )
  }

  if (!team.demo && !kitInstance.is_participant && !preview) {
    return null
  }

  if (kitInstance.session_completed_at) {
    const oneHourAgo = subHours(new Date(), 1)
    if (isAfter(oneHourAgo, parseISO(kitInstance.session_completed_at))) {
      return <Navigate to={kitInstance.home_url} replace />
    }
  }

  const isTeamLead = team.team_lead_id === user.id
  const teamLead = team.members.find((member) => member.id === team.team_lead_id)
  const facilitatorId = preview ? user.id : kitInstance.facilitator_id
  const facilitator = team.members.find((member) => member.id === facilitatorId)
  const isFacilitator = facilitator?.id === user.id

  if (!facilitator) {
    return null
  }

  const visibleSteps = kitInstance.kit.session.steps.filter((step) => {
    // TODO(facilitator): rename team_lead_only and team_member_only for facilitator
    if (step.team_lead_only && !isFacilitator) {
      return false
    }
    if (step.team_member_only && isFacilitator) {
      return false
    }
    if (step.skip_if_preview && preview) {
      return false
    }

    return true
  })

  return (
    <>
      <PageTitle>Session</PageTitle>
      <KitSessionContext.Provider
        value={{ kitInstance, visibleSteps, preview, team, teamLead, isTeamLead, facilitator, isFacilitator }}
      >
        <KitSessionContent
          kitInstance={kitInstance}
          visibleSteps={visibleSteps}
          preview={preview}
          team={team}
          isTeamLead={isTeamLead}
        />
      </KitSessionContext.Provider>
    </>
  )
}
