import { useKitSession } from "../KitSessionContext"

import { useKitParticipants, useStartKitSession } from "resources/monthly_kit"
import { useUser } from "resources/users"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const ParticipantCountList = ({ className, kitInstance }) => {
  const { mutateAsync: startKitSession } = useStartKitSession()
  const { team, preview, facilitator } = useKitSession()
  const { data: user } = useUser({ userId: "me" })
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { enabled: coleadsEnabled, isInitialLoading: coleadsFeatureIsLoading } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_COLEADS
  )

  const startSessionIfNeeded = async (data) => {
    if (!kitInstance.session_started_at && data.length > 1 && !coleadsEnabled) {
      await startKitSession({ kitInstanceId: kitInstance.id })
    }
  }

  const { data: participants } = useKitParticipants({
    kitInstance,
    refetchInterval: 3000,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    onSuccess: startSessionIfNeeded,
    preview,
    enabled: !coleadsFeatureIsLoading,
  })

  if (!user || !participants) {
    return <Loading />
  }

  return (
    <div className={className}>
      <span className="text-bold text-gray-9 mr-xxs">{participants.length} Total</span>
      <ParticipantsNames
        className="mb-medium"
        currentUser={user}
        participants={participants}
        facilitator={facilitator}
      />
    </div>
  )
}

const ParticipantsNames = ({ currentUser, participants, facilitator, className }) => {
  const formatTooltip = (user) => `${user.name}, ${user.email}`
  const TRUNCATION_LIMIT = 50
  return (
    <span className={className}>
      (<span title={formatTooltip(currentUser)}>You</span>
      {participants
        .filter((user) => user.id !== currentUser.id)
        .slice(0, TRUNCATION_LIMIT - 1)
        .map((user) => (
          <span key={user.id} title={formatTooltip(user)}>
            , {user.short_name}
            {user.id === facilitator.id && " (facilitator)"}
          </span>
        ))}
      {participants.length > TRUNCATION_LIMIT && <span>, ...</span>}
      {participants.length === 1 && <span> (When others join, they will appear here)</span>})
    </span>
  )
}

export default ParticipantCountList
