import cn from "classnames"
import { useField } from "formik"
import { Fragment, useState } from "react"

import ExerciseTextareaField from "./ExerciseTextareaField"

import { EditIcon, StarIcon } from "icons/FontAwesomeIcons"
import { useUser } from "resources/users"
import Button from "ui/Button"
import HorizontalRule from "ui/HorizontalRule"
import { colors } from "ui/theme"
import View from "ui/View"

const ExerciseTeamLevelNormsEditableResults = ({ className, name, saveOnChange, selectedTeam }) => {
  const [editTeamNorms, setEditTeamNorms] = useState(false)
  const [{ value: teamNormStickyNoteFavorites }, _, __] = useField(name)
  const { data: user } = useUser({ userId: "me" })
  const isTeamLead = user.id === selectedTeam.team_lead_id

  if (!teamNormStickyNoteFavorites?.length) {
    return <p className="text-gray-8 text-italic">Nothing added.</p>
  }
  function handleOnChange() {
    const updatedUserStickyNotes = teamNormStickyNoteFavorites.filter((stickyNote) => stickyNote.value.length > 0)
    if (updatedUserStickyNotes.length === 0) {
      saveOnChange(name, [{ value: "" }])
    } else {
      saveOnChange(name, updatedUserStickyNotes)
    }
  }

  return (
    <>
      <View $alignItems="center" $gap="16px" $justifyContent="space-between" className="mb-medium">
        <h2 className="text-bold">Our Team Norms</h2>
        {!!isTeamLead && (
          <Button onClick={() => setEditTeamNorms(!editTeamNorms)} className="button tertiary">
            <EditIcon className="fa-lg mr-xs" />
            {editTeamNorms ? "Save" : "Edit"}
          </Button>
        )}
      </View>
      {!!teamNormStickyNoteFavorites &&
        teamNormStickyNoteFavorites
          .map((stickyNote, stickyNoteIndex) => ({ ...stickyNote, stickyNoteIndex }))
          .filter(({ isFavorite }) => isFavorite)
          .map(({ value: favoredNote, stickyNoteIndex }) => {
            const id = `${name}.${stickyNoteIndex}`
            return (
              <Fragment key={stickyNoteIndex}>
                <View className={cn(className, "pr-large", "mb-medium")} $gap="16px" $alignItems="center">
                  <StarIcon color={colors.risingOrange} className="fa-xl" />
                  {!!editTeamNorms && !!isTeamLead ? (
                    <ExerciseTextareaField
                      className="sticky-note-text blur-4"
                      placeholder="For example: We are all one team."
                      saveOnChange={handleOnChange}
                      name={id}
                      key={id}
                      id={id}
                    />
                  ) : (
                    <p>{favoredNote}</p>
                  )}
                </View>
                <HorizontalRule margin="mb-medium" height={1} />
              </Fragment>
            )
          })}
    </>
  )
}

export default ExerciseTeamLevelNormsEditableResults
