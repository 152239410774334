import { useSearchParams } from "react-router-dom"

import useQueryParams from "ui/hooks/useQueryParams"

function usePageSyncEnabled(): [boolean, (enabled: boolean) => void] {
  const queryParams = useQueryParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const pageSyncParam = queryParams["page-sync"] ?? "true"
  const pageSyncEnabled = pageSyncParam === "true"
  const setPageSyncEnabled = (enabled: boolean) => {
    if (enabled) {
      const newParams = new URLSearchParams(searchParams)
      newParams.delete("page-sync")
      setSearchParams(newParams)
    } else {
      setSearchParams({ "page-sync": "false" })
    }
  }

  return [pageSyncEnabled, setPageSyncEnabled]
}

export default usePageSyncEnabled
