import cn from "classnames"
import { styled } from "styled-components"

import Tooltip from "ui/Tooltip"
import View from "ui/View"

const AnswerDisplayIconBox = ({ answers, className }) => (
  <View className={className}>
    {answers.map(({ title, icon, text, value }, idx) => {
      const Icon = icon
      return (
        <Tooltip
          top
          float
          className={cn("answer border border-radius", { "answer-highlight": value })}
          key={idx}
          title={title}
        >
          <div role="checkbox" aria-checked={value} aria-label={title} className={cn({ "answer-icon-wrapper": !text })}>
            {!!text && <div className="answer-content text-xs text-thin">{text}</div>}
            {!text && !!Icon && <Icon className="answer-content" />}
          </div>
        </Tooltip>
      )
    })}
  </View>
)

export default styled(AnswerDisplayIconBox)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  gap: var(--spacing-1);
  flex-wrap: wrap;

  .answer {
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    background-color: var(--gray-2);
    border-color: var(--gray-6);
    color: var(--gray-6);
  }

  .answer-icon-wrapper {
    line-height: 0;
  }

  .answer-content {
    width: 24px;
    height: 24px;
  }

  .answer-highlight {
    background-color: var(--green-3);
    border-color: var(--green-3);
  }

  /* stylelint-disable-next-line csstools/use-nesting */
  .answer-highlight .answer-content {
    color: var(--fg);
  }
`
