enum TrackElement {
  KitFiltersSearchInput = "KitFiltersSearchInput",

  KitFiltersMinLengthSelect = "KitFiltersMinLengthSelect",
  KitFiltersMaxLengthSelect = "KitFiltersMaxLengthSelect",
  KitFiltersStatusSelect = "KitFiltersStatusSelect",
  KitFiltersTypeSelect = "KitFiltersTypeSelect",
  KitFiltersGoalSelect = "KitFiltersGoalSelect",

  KitFiltersMinLengthOption = "KitFiltersMinLengthOption",
  KitFiltersMaxLengthOption = "KitFiltersMaxLengthOption",
  KitFiltersStatusOption = "KitFiltersStatusOption",
  KitFiltersTypeOption = "KitFiltersTypeOption",
  KitFiltersGoalOption = "KitFiltersGoalOption",
}

function trackClassName(
  elm: TrackElement,
  { value = null }: { value?: string | number | boolean | null } = {}
): string {
  const className = `track__${elm}`
  return value == null ? className : `${className} ${className}__${value}`
}

export { TrackElement, trackClassName }
