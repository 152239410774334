import { Navigate, useLocation, useNavigate } from "react-router-dom"

import { useSetAccountBrandByTeam } from "components/AccountBrandContext"
import { useSetAccountTypeByTeam } from "components/AccountTypeContext"
import NameAndProfileForm from "domains/GetStarted/Register/NameAndProfileForm"
import handleErrors from "forms/handleErrors"
import { useTeam } from "resources/teams"
import { useUpdateCurrentUser, useUser } from "resources/users"
import Loading from "ui/Loading"
import PageTitle from "ui/PageTitle"

const TeamCodeProfile = ({ className }) => {
  const { data: user, isFetching: userIsFetching } = useUser({ userId: "me" })
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { data: team } = useTeam({ teamId: state?.kitInstance?.team_id })

  useSetAccountTypeByTeam(team)
  useSetAccountBrandByTeam(team)

  if (!state || !state.kitInstance) {
    return <Navigate to="/code" replace />
  }

  if (userIsFetching) {
    return <Loading />
  }

  const hasFirstAndLastName = !!(user.first_name && user.last_name)
  if (hasFirstAndLastName) {
    return <Navigate to={state.kitInstance.session_url} replace />
  }

  const showFirstAndLastName = !hasFirstAndLastName

  const onSubmit = handleErrors(async (values) => {
    await updateCurrentUser(values)
    navigate(state.kitInstance.session_url)
  })

  return (
    <div className={className}>
      <PageTitle>Get Started</PageTitle>
      <h2>Get Started</h2>
      <NameAndProfileForm
        user={user}
        showFirstAndLastName={showFirstAndLastName}
        showProfile={true}
        onSubmit={onSubmit}
      />
    </div>
  )
}

export default TeamCodeProfile
