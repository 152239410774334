import cn from "classnames"
import { Formik } from "formik"
import Sticky from "react-stickynode"
import { styled } from "styled-components"

import ErrorPage from "components/ErrorPage"
import ExerciseContent from "domains/Exercise/ExerciseContent"
import ExerciseForm from "domains/Exercise/ExerciseForm"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import useWindowSize from "ui/hooks/useWindowSize"
import Loading from "ui/Loading"

const SessionTeamExerciseForm = ({ kitInstance, components, isSticky = false, className }) => {
  const exercise = kitInstance?.kit?.exercise
  const { initialValues, onSubmit, saveOnChange, isInvalid, isFetching } = useExerciseForm({
    teamId: kitInstance?.team_id,
    slug: exercise?.slug,
    version: exercise?.version,
    teamLevelExercise: true,
  })
  const { isMobileOrSmaller, isTabletOrSmaller } = useWindowSize()

  if (!initialValues && isFetching) {
    return <Loading />
  }

  if (isInvalid) {
    return <ErrorPage />
  }

  // Allows us to use components not defined in the exercise
  const componentInitialValues = Object.fromEntries(
    (components ?? []).map(({ identifier, initialValue }) => [identifier, initialValue])
  )

  const form = (
    <Formik initialValues={{ ...componentInitialValues, ...initialValues }} enableReinitialize onSubmit={onSubmit}>
      <ExerciseForm>
        <ExerciseContent
          kit={kitInstance.kit}
          components={components}
          saveOnChange={saveOnChange}
          is_full_exercise={true}
          is_session={true}
        />
      </ExerciseForm>
    </Formik>
  )

  return !isSticky ? (
    <div className={className}>{form}</div>
  ) : (
    <Sticky
      innerZ={100}
      top={isMobileOrSmaller ? 48 : isTabletOrSmaller ? 128 : 72}
      className={cn("full-width sticky-container", className)}
    >
      {form}
    </Sticky>
  )
}

export default styled(SessionTeamExerciseForm)`
  &.sticky-container.active > :first-child {
    background-color: var(--fg);
    box-sizing: content-box;
    box-shadow: 0 4px 4px -4px rgb(35 35 35 / 20%);
    padding-top: 8px;
    padding-bottom: 16px;
    padding-left: 48px;
    padding-right: 48px;
    margin-top: -8px;
    margin-left: -48px;
    margin-right: -48px;
  }
`
