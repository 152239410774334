import { Route, Routes, useNavigate } from "react-router"
import { styled } from "styled-components"

import PEPMSubscribeOffersForm from "domains/Subscribe/PEPMSubscribeOffersForm"
import { BoltIcon } from "icons/FontAwesomeIcons"
import { SubscriptionType, useAccountOffers } from "resources/billing"
import Loading from "ui/Loading"
import PageTitle from "ui/PageTitle"
import View from "ui/View"
import { buildUrl } from "utils/string"

const CUSTOM_BILLING_VALUE = "intuit"

const IntuitIntegration = () => (
  <Routes>
    <Route path="/signup" element={<IntuitSignup />} />
  </Routes>
)

const IntuitSignup = () => (
  <View>
    <div className="main-container full-width">
      <PageTitle>Intuit</PageTitle>
      <IntuitSubscribeOffers />
    </div>
  </View>
)

const IntuitSubscribeOffers = styled(function IntuitSubscribeOffers({ className }) {
  const navigate = useNavigate()
  const customBilling = CUSTOM_BILLING_VALUE
  const { data: accountOffers, isFetching: accountOffersIsFetching } = useAccountOffers({ customBilling })

  if (!accountOffers && accountOffersIsFetching) {
    return <Loading />
  }

  const onOfferSelected = async ({ yearly, numMembers, productTier }) => {
    const subscriptionType = yearly ? SubscriptionType.yearly : SubscriptionType.monthly
    const url = buildUrl(["get-started", "sub", subscriptionType, numMembers], {
      urlQueryParams: { customBilling, productTier },
    })
    navigate(url)
  }

  return (
    <div className={className}>
      <h1 className="text-gray-9 mb-large">Subscribe</h1>
      <h2 className="text-gray-9 mb-medium">
        <BoltIcon color="var(--rising-yellow)" className="mr-xs" />
        Intuit managers, get your special offer!
      </h2>
      <p className="text-gray-8 inline-block">Each team includes up to 14 people, including the facilitator</p>
      <PEPMSubscribeOffersForm accountOffers={accountOffers} onOfferSelected={onOfferSelected} />
    </div>
  )
})`
  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    text-align: center;
  }
`

export default IntuitIntegration
