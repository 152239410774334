import cn from "classnames"
import { Form, Formik, type FormikHelpers } from "formik"
import { useRef, useState, useCallback, type MutableRefObject } from "react"
import { Link, useNavigate } from "react-router-dom"
import { styled } from "styled-components"
import * as Yup from "yup"
import "wicg-inert" // polyfill for "inert" HTML attribute in older browsers

import { useLogin } from "../resource"

import EmailField from "forms/fields/EmailField"
import PasswordField from "forms/fields/PasswordField"
import FormMessage from "forms/FormMessage"
import handleErrors from "forms/handleErrors"
import { getLoginOptions } from "resources/users"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useEffectOnComponentUnmount from "ui/hooks/useEffectOnComponentUnmount"
import useQueryParams from "ui/hooks/useQueryParams"
import SubmitButton from "ui/SubmitButton"
import View from "ui/View"
import { isDevelopmentEnv, isStagingEnv } from "utils/env"
import { buildUrl, asStringsOrNulls } from "utils/string"

interface LoginFormProps {
  className?: string
  initialEmail?: string | null
  redirectUrl?: string | null
  showCodeLink?: boolean
}

interface LoginFormValues {
  email: string
  password: string
}

const LoginForm = ({ className, initialEmail = null, redirectUrl = null, showCodeLink = true }: LoginFormProps) => {
  const { next = null, email = null } = asStringsOrNulls(useQueryParams())
  initialEmail = initialEmail?.trim() || email?.trim() || ""
  const navigate = useNavigate()
  const { mutateAsync: login } = useLogin()
  const [hasChosenLoginMethod, setHasChosenLoginMethod] = useState(false)
  const [hasAutoChosenLoginMethod, setHasAutoChosenLoginMethod] = useState(false)
  const [showPasswordField, setShowPasswordField] = useState(false)
  const passwordFieldRef = useRef<HTMLElement>()

  const onSubmitPassword = handleErrors(
    async (values: LoginFormValues) => {
      await login(values)
      navigate(redirectUrl || next || "/")
    },
    (
      data: Record<string, unknown>,
      _values: LoginFormValues,
      _formik: unknown,
      exception: Error & { response?: Response }
    ) => {
      // If there was a server error, reload page so we can
      // display error page if necessary:
      if (exception.response?.status === 500) {
        window.location.reload()
      }
      return data // return data so that other errors are handled normally
    }
  )

  async function updateLoginMethodsForEmail(
    values: { email: string },
    { setErrors }: { setErrors?: (errors: Record<string, unknown>) => void } = {}
  ) {
    if (!hasChosenLoginMethod) {
      const isValidEmail = await Yup.string().email().required().isValid(values.email)

      if (!isValidEmail) {
        setErrors?.({ email: "Please enter a valid email address." })
      } else {
        const options = await getLoginOptions({ email: values.email })
        const forcePasswordLogin = (isDevelopmentEnv() || isStagingEnv()) && window.location.hash.includes("password")

        const ssoProviders = options?.sso_providers ?? []
        const provider = ssoProviders[0]?.provider ?? null

        setHasChosenLoginMethod(true)

        if (!provider || forcePasswordLogin) {
          setShowPasswordField(true)
          passwordFieldRef.current?.focus()
        } else {
          navigate(buildUrl(["auth", "sso", provider], { urlQueryParams: { next } }))
        }
      }
    }
  }

  // If an initial email value was provided, show 2nd step of login automatically.
  // First, double-check that initial email is valid - if not, do nothing.
  const updateLoginMethodsForEmailCallback = useCallback(updateLoginMethodsForEmail, [
    hasChosenLoginMethod,
    next,
    navigate,
  ])
  const [hasUnmounted, setHasUnmounted] = useState(false)
  useEffectOnComponentUnmount(() => setHasUnmounted(true))
  useEffectAfterChange(() => {
    async function autoUpdateLoginMethods() {
      const isValidEmail = await Yup.string().email().required().isValid(initialEmail)
      if (initialEmail && isValidEmail && !hasUnmounted) {
        updateLoginMethodsForEmailCallback({ email: initialEmail })
      }
    }
    if (initialEmail?.trim()?.length && !hasAutoChosenLoginMethod) {
      setHasAutoChosenLoginMethod(true)
      autoUpdateLoginMethods()
    }
  }, [initialEmail, updateLoginMethodsForEmailCallback, hasAutoChosenLoginMethod, hasUnmounted])

  async function onSubmit(values: LoginFormValues, formikActions: FormikHelpers<LoginFormValues>) {
    if (showPasswordField) {
      onSubmitPassword(values, formikActions)
    } else if (!hasChosenLoginMethod) {
      updateLoginMethodsForEmail(values, formikActions)
    }
  }

  function revertLoginMethodsForEmail() {
    if (hasChosenLoginMethod) {
      setHasChosenLoginMethod(false)
      setShowPasswordField(false)
    }
  }

  const initialValues = {
    email: initialEmail,
    password: "",
  }

  // Use autoComplete="username" instead of autoComplete="email" on EmailField
  // below, per Chromium recommendations:
  // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
  // Further reading: https://stackoverflow.com/a/57902690
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values }) => (
        <Form name="login" className={className}>
          <EmailField
            className="mb-large full-width"
            name="email"
            label="Email address"
            placeholder="Enter your work email address here"
            autoComplete="username"
            autoFocus
            onInput={revertLoginMethodsForEmail}
          />
          <PasswordField
            className={cn("full-width", { "hidden-password-field": !showPasswordField })}
            inputRef={passwordFieldRef as MutableRefObject<HTMLInputElement>}
            name="password"
            label="Password"
            placeholder="Enter your password here"
            autoComplete="current-password"
            eye
            inert={!showPasswordField}
          />
          <FormMessage className="mt-xl text-align-left text-semi-bold" />
          {!hasChosenLoginMethod ? (
            <SubmitButton className="continue mr-none mb-xl" label="Continue" labelSubmitting={false} />
          ) : (
            !!showPasswordField && (
              <>
                <View $justifyContent="center">
                  <SubmitButton className="px-xxxxl mt-xxl">Log in</SubmitButton>
                </View>
                <View $justifyContent="center" className="pt-large my-large">
                  <Link to="/auth/forgot" state={{ email: values.email }}>
                    Forgot your password?
                  </Link>
                </View>
              </>
            )
          )}
          {!!showCodeLink && (
            <p className="text-gray-9 mt-medium">
              Have a code? <Link to="/code">Click here</Link>
            </p>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default styled(LoginForm)`
  display: flex;
  flex-direction: column;
  flex: 1;

  /* Hide password field prior to choosing login method, using visual CSS props rather
  than "display: none" to increase chances of triggering password manager autofill.
  See https://hidde.blog/making-password-managers-play-ball-with-your-login-form/#heading-4 */
  .hidden-password-field {
    opacity: 0;
    height: 0;
    pointer-events: none;
  }

  input::placeholder {
    font-style: italic;
    color: var(--gray-7);
    font-size: 15px;
  }
`
