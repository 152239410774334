import cn from "classnames"
import { styled } from "styled-components"

import { CHAT_TYPE, PERFORMANCE_REVIEW_OPTIONS } from "../constants"
import { getPerformanceReviewSelectedMessage } from "../utils"

import ArtiPerformanceReviewSelectField from "./ArtiPerformanceReviewSelectField"

import { CommentIcon, UploadIcon } from "icons/FontAwesomeIcons"
import View from "ui/View"

const ArtiPerformanceReviewSelectorMessage = styled(function ArtiPerformanceReviewSelectorMessage({
  user,
  team,
  teamMember,
  showPerformanceReview,
  chatTypeSelected,
  reviewType,
  onPerformanceReviewOptionsChange,
  performanceReviewOptions,
  onSubmit,
  llm,
  sessionStartedAt,
  startExchangeId,
  prevExchangeId,
  className,
}) {
  return (
    <View $flexDirection="column" className={cn(className, "flex-shrink-0")}>
      {!!showPerformanceReview && chatTypeSelected === CHAT_TYPE.PERFORMANCE_REVIEW && (
        <View $flexDirection="column">
          <div className="mb-xxs text-semi-bold">
            <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="16px" alt="arti-chat-icon" />
            <span className="ml-xxs">aRTi</span>
          </div>
          <View $flexDirection="column" className="mb-medium px-medium py-small message bot">
            <div>{getPerformanceReviewSelectedMessage({ user, teamMember, chatTypeSelected })}</div>
          </View>
        </View>
      )}
      <div className="text-right mb-xxs text-semi-bold full-width">you</div>
      {!performanceReviewOptions ? (
        <div className="mb-medium pr-medium py-small message user full-width">
          <ArtiPerformanceReviewSelectField
            onPerformanceReviewOptionsChange={onPerformanceReviewOptionsChange}
            performanceReviewOptions={performanceReviewOptions}
            onSubmit={onSubmit}
            llm={llm}
            sessionStartedAt={sessionStartedAt}
            startExchangeId={startExchangeId}
            prevExchangeId={prevExchangeId}
            team={team}
            selectedChatMember={teamMember}
            reviewType={reviewType}
            chatTypeSelected={chatTypeSelected}
          />
        </div>
      ) : (
        <div className="mb-medium pr-medium py-small message user full-width">
          <View
            $alignItems="center"
            $justifyContent="center"
            className={cn("box fit-content p-xs mx-small", { disabled: !!performanceReviewOptions })}
            $flexDirection="row"
          >
            <div>
              <div className={cn("text-button", { disabled: !!performanceReviewOptions })}>
                <div className="text-nowrap">
                  {performanceReviewOptions === PERFORMANCE_REVIEW_OPTIONS.CHAT_REVIEW ? (
                    <div>
                      <CommentIcon className="mr-xs" />
                      Ask me questions
                    </div>
                  ) : (
                    <div>
                      <UploadIcon className="mr-xs" />
                      Upload their self-review
                    </div>
                  )}
                </div>
              </div>
            </div>
          </View>
        </div>
      )}
    </View>
  )
})`
  width: 100%;

  .box {
    height: 100%;
    min-height: 46px;
    border: 1px solid var(--white);
    border-radius: 4px;
    background-color: #fdc774;
    box-shadow: 0 0 4px 0 rgb(255 211 89 / 44%);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;

    &.disabled {
      background-color: white;
    }
  }

  .text-button {
    font-size: 0.9375rem; /* ~15px */
    line-height: 1.375rem;
    color: var(--gray-9);
    font-weight: 600;
  }
`

export default ArtiPerformanceReviewSelectorMessage
