import type { ReactNode, CSSProperties } from "react"
import { styled } from "styled-components"

interface ViewProps {
  className?: string
  children?: ReactNode
  $width?: CSSProperties["width"] | null
  $minWidth?: CSSProperties["minWidth"] | null
  $maxWidth?: CSSProperties["maxWidth"] | null
  $minHeight?: CSSProperties["minHeight"] | null
  $maxHeight?: CSSProperties["maxHeight"] | null
  $alignItems?: CSSProperties["alignItems"] | null
  $alignItemsMobile?: CSSProperties["alignItems"] | null
  $alignItemsTablet?: CSSProperties["alignItems"] | null
  $justifyContent?: CSSProperties["justifyContent"] | null
  $justifyContentMobile?: CSSProperties["justifyContent"] | null
  $justifyContentTablet?: CSSProperties["justifyContent"] | null
  $radius?: CSSProperties["borderRadius"] | null
  $flex?: CSSProperties["flex"] | null
  $flexDirection?: CSSProperties["flexDirection"] | null
  $flexDirectionMobile?: CSSProperties["flexDirection"] | null
  $flexDirectionTablet?: CSSProperties["flexDirection"] | null
  $flexDirectionWidescreen?: CSSProperties["flexDirection"] | null
  $flexShrink?: CSSProperties["flexShrink"] | null
  $flexGrow?: CSSProperties["flexGrow"] | null
  $flexBasis?: CSSProperties["flexBasis"] | null
  $flexBasisMobile?: CSSProperties["flexBasis"] | null
  $flexBasisTablet?: CSSProperties["flexBasis"] | null
  $flexWrap?: CSSProperties["flexWrap"] | null
  $flexWrapMobile?: CSSProperties["flexWrap"] | null
  $height?: CSSProperties["height"] | null
  $alignSelf?: CSSProperties["alignSelf"] | null
  $alignContent?: CSSProperties["alignContent"] | null
  $gridArea?: CSSProperties["gridArea"] | null
  $gap?: CSSProperties["gap"] | null
  $gapMobile?: CSSProperties["gap"] | null
  $gapTablet?: CSSProperties["gap"] | null
}

type PropsWithTheme = ViewProps & { theme: RTTheme }

const View = styled.div<ViewProps>`
  display: flex;
  width: ${({ $width }: PropsWithTheme) => $width};
  min-width: ${({ $minWidth }: PropsWithTheme) => $minWidth};
  max-width: ${({ $maxWidth }: PropsWithTheme) => $maxWidth};
  height: ${({ $height }: PropsWithTheme) => $height};
  min-height: ${({ $minHeight }: PropsWithTheme) => $minHeight};
  max-height: ${({ $maxHeight }: PropsWithTheme) => $maxHeight};
  border-radius: ${({ $radius }: PropsWithTheme) => $radius};
  ${({ $flexDirection, $flexDirectionWidescreen, theme }: PropsWithTheme) =>
    $flexDirectionWidescreen && theme.isWidescreen
      ? `flex-direction: ${$flexDirectionWidescreen};`
      : `flex-direction: ${$flexDirection};`}
  align-items: ${({ $alignItems }: PropsWithTheme) => $alignItems};
  align-content: ${({ $alignContent }: PropsWithTheme) => $alignContent};
  justify-content: ${({ $justifyContent }: PropsWithTheme) => $justifyContent};
  flex-shrink: ${({ $flexShrink }: PropsWithTheme) => $flexShrink};
  flex-grow: ${({ $flexGrow }: PropsWithTheme) => $flexGrow};
  flex-basis: ${({ $flexBasis }: PropsWithTheme) => $flexBasis};
  flex-wrap: ${({ $flexWrap }: PropsWithTheme) => $flexWrap};
  grid-area: ${({ $gridArea }: PropsWithTheme) => $gridArea};
  ${({ $flex }: PropsWithTheme) => `flex: ${$flex};`}
  align-self:  ${({ $alignSelf }: PropsWithTheme) => $alignSelf};
  gap: ${({ $gap }: PropsWithTheme) => $gap};
  /* stylelint-disable-next-line declaration-empty-line-before */

  ${({ $flexDirectionMobile, theme }: PropsWithTheme) =>
    $flexDirectionMobile ? `@media (max-width: ${theme.mobileMax}) { flex-direction: ${$flexDirectionMobile}; }` : ""}

  ${({ $flexDirectionTablet, theme }: PropsWithTheme) =>
    $flexDirectionTablet ? `@media (max-width: ${theme.tabletMax}) { flex-direction: ${$flexDirectionTablet}; }` : ""}

  ${({ $flexBasisMobile, theme }: PropsWithTheme) =>
    $flexBasisMobile ? `@media (max-width: ${theme.mobileMax}) { flex-basis: ${$flexBasisMobile}; }` : ""}

  ${({ $flexWrapMobile, theme }: PropsWithTheme) =>
    $flexWrapMobile ? `@media (max-width: ${theme.mobileMax}) { flex-wrap: ${$flexWrapMobile}; }` : ""}

  ${({ $alignItemsMobile, theme }: PropsWithTheme) =>
    $alignItemsMobile ? `@media (max-width: ${theme.mobileMax}) { align-items: ${$alignItemsMobile}; }` : ""}

    ${({ $alignItemsTablet, theme }: PropsWithTheme) =>
    $alignItemsTablet ? `@media (max-width: ${theme.tabletMax}) { align-items: ${$alignItemsTablet}; }` : ""}

  ${({ $justifyContentTablet, theme }: PropsWithTheme) =>
    $justifyContentTablet
      ? `@media (max-width: ${theme.tabletMax}) { justify-content: ${$justifyContentTablet}; }`
      : ""}

  ${({ $justifyContentMobile, theme }: PropsWithTheme) =>
    $justifyContentMobile
      ? `@media (max-width: ${theme.mobileMax}) { justify-content: ${$justifyContentMobile}; }`
      : ""}

  ${({ $gapMobile, theme }: PropsWithTheme) =>
    $gapMobile ? `@media (max-width: ${theme.mobileMax}) { gap: ${$gapMobile}; }` : ""}

  ${({ $gapTablet, theme }: PropsWithTheme) =>
    $gapTablet ? `@media (max-width: ${theme.tabletMax}) { gap: ${$gapTablet}; }` : ""}
`

View.defaultProps = {
  $width: "100%",
  $alignItems: "flex-start",
  $justifyContent: "flex-start",
  $flexDirection: "row",
  $radius: "0",
  $flexShrink: "initial",
  $flexBasis: "initial",
  $flexWrap: "initial",
  $height: "initial",
  $alignSelf: "initial",
  $alignContent: "center",
  $gap: "normal",
}

export default View
export type { ViewProps }
