import { styled } from "styled-components"

const ExpectationsTraining2 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-large">
        Now that you understand the benefits of clarifying expectations, how do you help the team build that discipline
        together? During this Rising Team session, your team will learn how to use the ROAD framework for clarifying
        expectations, put it into action with a team challenge, and then apply it to clarifying an individual workplace
        goal.
      </p>
      <h2 className="text-rising-orange mb-medium">Introducing the ROAD model</h2>
      <p className="mb-medium">
        Whether setting a goal for someone on your team or clarifying expectations for your own goals, the ROAD
        framework outlines specific criteria needed to set both parties up for success. This framework can be used
        alongside other goal setting frameworks—think OKRs or SMART goals—it's about <strong>how</strong> you set and
        commit to goals, whichever model you use. The ROAD framework helps ensure you have clarity and commitment around
        goals and makes it more likely you will reach them. The ROAD model can be especially helpful to revisit
        expectations and commitments as things change.
      </p>
      <p className="mb-large">This session breaks down each step and questions the team can ask to gain clarity.</p>
      <div className="road-framework">
        <div className="dash-border">
          <div className="letter-bubble-container">
            <div className="letter-bubble">R</div>
            <div className="mb-large">
              <h3 className="mb-medium">Request and measurement</h3>
              <p className="mb-medium">Clarify what needs to be done by when and how success will be measured.</p>
              <p className="mb-medium">Questions to consider:</p>
              <ul className="ml-xl">
                <li>What does success ultimately look like?</li>
                <li>How will success be measured? (How much? What is the deliverable? By when?).</li>
                <li>What does it look like to surpass expectations?</li>
              </ul>
            </div>
          </div>
          <div className="letter-bubble-container">
            <div className="letter-bubble">O</div>
            <div className="mb-large">
              <h3 className="mb-medium">Obstacles and support</h3>
              <p className="mb-medium">
                Highlight potential roadblocks that could derail success. Identify and agree on the type of support and
                resources needed along the way.
              </p>
              <p className="mb-medium">Questions to consider:</p>
              <ul className="ml-xl">
                <li>What existing or new barriers could get in the way?</li>
                <li>Do we have the resources to make this happen?</li>
                <li>Whose support is needed and what can they do to remove obstacles?</li>
              </ul>
            </div>
          </div>
          <div className="letter-bubble-container">
            <div className="letter-bubble">A</div>
            <div className="mb-large">
              <h3 className="mb-medium">Align and commit</h3>
              <p className="mb-medium">
                Once both parties have a common understanding of the criteria for success, it’s time to commit.
              </p>
              <p className="mb-medium">Questions to consider:</p>
              <ul className="ml-xl">
                <li>Do you believe the expectations are reasonable? If not, what can be negotiated?</li>
                <li>Is there commitment to meet these expectations? If not, why not?</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="letter-bubble-container">
          <div className="letter-bubble">D</div>
          <div className="mb-xl">
            <h3 className="mb-medium">Delivery</h3>
            <p className="mb-medium">After results are complete, weigh them against the original request.</p>
            <p className="mb-medium">Questions to consider:</p>
            <ul className="ml-xl">
              <li>Did the final result align with what was originally requested?</li>
              <li>What worked well?</li>
              <li>What could be done differently next time around?</li>
            </ul>
          </div>
        </div>
      </div>
      <p className="mb-medium">
        Walk through this framework before committing to new responsibilities, then continue to reassess along the way
        because things can change.
      </p>
      <h2 className="text-rising-orange mb-medium">Learn the ROAD as a team</h2>
      <p className="mb-medium">This Rising Team session is a two-part exercise.</p>
      <h3 className="mb-medium">Part 1: Build a Fleet</h3>
      <p className="mb-medium">
        In this exercise the team will be tasked with creating a fleet of paper planes. Using the ROAD model the team
        will work together to define the request, identify and resolve potential obstacles, align on their commitment,
        and finally evaluate their results.
      </p>
      <p className="mb-medium">
        The Build a Fleet Team Challenge requires someone to respond to team questions as they work through the ROAD
        framework. You will play the role of 'Account Rep'. As 'Account Rep,' you will be provided a list of Client
        Requirements that only you can see. The team will be tasked with identifying clarifying questions and your role
        will be to answer them as well as you can based on the Client Requirements. If you don't know the answer to
        something, say just that. After the team has asked all of their questions, you may share anything important they
        might have missed.
      </p>
      <h3 className="mb-medium">Part 2: Map Your ROAD</h3>
      <p className="mb-medium">
        The second part of the exercise asks team members to identify a personal workplace goal. In this exercise, team
        members will apply the ROAD model by answering specific questions in each of the four steps. Using a red,
        yellow, green "traffic light" rating, they will evaluate where they are clear and where there is potential for
        more clarity. The outcome of this exercise is a set of actions to take after the meeting to get the clarity
        needed to succeed.
      </p>
      <p className="mb-xxxxl">
        And finally, as with prior Rising Team sessions, remaining open, curious, and positive in this discussion is key
        to ensuring each of your team members can feel comfortable working through real workplace goals. Knowing that
        you are also learning and sharpening your own skills will give your team members more confidence to look for
        opportunities to practice asking clarifying questions.
      </p>
    </div>
  </div>
)

export default styled(ExpectationsTraining2)`
  .dash-border {
    border-left: 3px dashed var(--rising-yellow);
    margin-left: 24px;
  }

  .letter-bubble-container {
    display: grid;
    width: 100%;
    grid-template-columns: 48px auto;
    grid-gap: 24px;
    margin-left: -25px;
  }

  .letter-bubble {
    font-size: 2rem;
    font-weight: 600;
    color: white;
    background-color: var(--gray-9);
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .road-framework > :last-child {
    margin-left: 0;
  }
`
