import cn from "classnames"
import { Form, Formik } from "formik"
import { styled } from "styled-components"

import { CHAT_TYPE, NO_ONE_MEMBER } from "../constants"

import { useArtiChatContext } from "./ArtiChatContext"
import ArtiChoiceInput from "./ArtiChoiceInput"

import { ArtiTourHintNames } from "domains/Arti/components/ArtiTour"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import ChoicesField from "forms/fields/ChoicesField"
import { MagnifyingGlassIcon, PlusIcon } from "icons/FontAwesomeIcons"
import { sortUsersByShortName } from "resources/users"
import Button from "ui/Button"
import View from "ui/View"
import { uniqueById } from "utils/array"

const ArtiMemberSelectField = styled(function ArtiMemberSelectField({
  className,
  user,
  team,
  selectedChatMember,
  teamMembers,
  chatMemberId,
  onChatMemberChange,
  memberIdTeamNameMap,
  onStartNewConversation,
  showTeamSummary,
  showPerformanceReview,
  chatTypeSelected,
}) {
  const { showSampleTeam, showSampleTeamButton, setShowSampleTeamButton, sampleTeamMembers } = useArtiChatContext()

  const currentTeamMembersMinusUser = showSampleTeamButton
    ? sampleTeamMembers
    : team?.members.filter(({ id }) => id !== user.id)
  const sortedCurrentTeamMembersMinusUser = sortUsersByShortName({ users: currentTeamMembersMinusUser })
  const selfAndNone = [user, NO_ONE_MEMBER]
  const availableTeamMembersOptions = team?.jumbo
    ? selfAndNone
    : chatTypeSelected === CHAT_TYPE.PERFORMANCE_REVIEW
      ? uniqueById([...sortedCurrentTeamMembersMinusUser])
      : uniqueById([...sortedCurrentTeamMembersMinusUser, ...selfAndNone])

  const currentTeamMembersOptions = selectedChatMember
    ? availableTeamMembersOptions.filter(({ id }) => id === chatMemberId)
    : availableTeamMembersOptions

  return (
    <Formik initialValues={{ chatMemberId }} enableReinitialize>
      <Form
        className={cn(className, "arti-member-select pl-small")}
        data-testid="arti-member-select"
        data-arti-tour-hint={ArtiTourHintNames.MemberSelector}
      >
        <View $flexDirection="row" $flexWrap="wrap" $gap="var(--spacing-2)">
          <ChoicesField
            noContainer
            name="chatMemberId"
            type="radio"
            saveOnChange={(_name, value) => onChatMemberChange({ value })}
            options={currentTeamMembersOptions.map(({ id, short_name }) => ({
              value: id,
              label: id === user.id ? "Myself" : short_name,
            }))}
            component={ArtiChoiceInput}
            disabled={!!selectedChatMember}
            childrenAfterChoices={
              <>
                <AdvancedSelectField
                  className="arti-member-search"
                  name="chatMemberId"
                  width="100%"
                  hideCaret
                  disabled={!!selectedChatMember}
                  placeholder={<span className="text-gray-9">Search my teams</span>}
                  icon={<MagnifyingGlassIcon className="mr-xxs text-gray-9" />}
                  onChange={onChatMemberChange}
                  options={teamMembers.map(({ id, short_name }) => ({
                    value: id,
                    label: id === user.id ? "Myself" : short_name,
                  }))}
                  formatOptionLabel={({ value, label }) => (
                    <View $justifyContent="space-between">
                      <span className="text-semi-bold" title={label}>
                        {label}
                      </span>
                      <span className="text-gray-9 ml-large hide-on-mobile text-thin">
                        {memberIdTeamNameMap?.get(value) ?? ""}
                      </span>
                    </View>
                  )}
                />
                {!!showSampleTeam && !selectedChatMember && !showSampleTeamButton && (
                  <Button className="arti-member-select-button" onClick={() => setShowSampleTeamButton(true)}>
                    Sample Team
                  </Button>
                )}
                {/* TODO: remove start new conversation from member selector after conversation type release */}
                {(!showTeamSummary || !showPerformanceReview) && !!selectedChatMember && (
                  <Button
                    className={cn("arti-member-select-button")}
                    onClick={onStartNewConversation}
                    data-testid="arti-member-select-new-conversation"
                  >
                    <PlusIcon className="ml-small" />
                    New conversation
                  </Button>
                )}
              </>
            }
          />
        </View>
      </Form>
    </Formik>
  )
})`
  /* stylelint-disable selector-class-pattern */
  .arti-member-search {
    display: inline-block;
    min-width: 184px;
    vertical-align: top;
    font-weight: 500;
    color: var(--gray-9); /* select options inherit their text color from this */
    &.advanced-select--is-disabled {
      display: none;
    }

    .advanced-select__control {
      padding-bottom: 45px; /* align select bottom with adjacent choice options */
      border: 1px solid var(--white);
      border-radius: 4px;
      background-color: #fdc774;
      box-shadow: 0 0 4px 0 rgb(255 211 89 / 44%);

      .advanced-select__input-container,
      .advanced-select__single-value {
        color: var(--gray-9);
      }

      &:hover:not(.disabled) {
        border: 1px solid var(--white);
        background-color: #fbe8ca;
      }
    }
  }

  .advanced-select__control--menu-is-open {
    font-size: 16px;
    font-weight: 600;
    background-color: var(--white) !important; /* important is necessary here to override hover bg color */
  }

  .arti-member-select-button {
    height: 100%;
    min-height: 46px;
    border: 1px solid var(--white);
    border-radius: 4px;
    background-color: #fdc774;
    box-shadow: 0 0 4px 0 rgb(255 211 89 / 44%);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;
    font-size: 0.9375rem; /* ~15px */
    line-height: 1.375rem;
    color: var(--gray-9);
    font-weight: 500;

    &:hover,
    &:focus-visible {
      border-radius: 4px;
      background-color: #fbe8ca;
      box-shadow: 0 2px 4px 0 rgb(255 211 89 / 75%);
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .arti-member-search {
      /* place search input on its own line on mobile so menu doesn't go offscreen: */
      display: block;
    }
  }
`
export default ArtiMemberSelectField
