import { Link } from "react-router-dom"

import ParticipantReflections from "../components/ParticipantReflections"
import { useKitSession } from "../KitSessionContext"

import SelectedUsersView from "components/SelectedUsersView"
import { useTeamReflections, useKitParticipants } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const ReflectionsReview = ({ kitInstance }) => {
  const { team, preview } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })
  const { data: reflections, isFetching } = useTeamReflections({
    kitInstanceId: kitInstance.id,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })

  if (!participants || (isFetching && !reflections)) {
    return <Loading />
  }

  return (
    <div className="main-container">
      <h2 className="text-gray-9 mb-medium">Session Reflections</h2>
      <p className="mb-large">
        Here are the reflections and action items your team recorded as part of the session on team dynamics.
      </p>

      <SelectedUsersView users={participants}>
        <ParticipantReflections reflections={reflections} kitInstance={kitInstance} />
      </SelectedUsersView>

      <View className="mt-xl">
        <Link to={kitInstance.home_url}>Back to session homepage</Link>
      </View>
    </div>
  )
}

export default ReflectionsReview
