import { isDevelopmentEnv, isStagingEnv } from "utils/env"
import { safeDynamicImport } from "utils/import"

const SAFE_IMPORT_FIREBASE_MODULES_DELAY = 500

type FirebaseImportResult = {
  firebaseApp: null | typeof import("firebase/app")
  firebaseAuth: null | typeof import("firebase/auth")
  firebaseDB: null | typeof import("firebase/database")
  firebaseUI: null | typeof import("firebaseui")
}

// Set up a promise which allows multiple concurrent safeImportFirebaseModules calls
// to await one another so that all Firebase module imports occur sequentially.
// This helps avoid "u[v] is not a function" errors which break FirebaseUI on iOS.
let _firebaseImportPromise: Promise<FirebaseImportResult> | null = null

// Diagnostic util for visibility of loading operations in dev and staging envs:
function _importLog(...parts: Array<string | number | object>): void {
  if (isDevelopmentEnv() || isStagingEnv()) {
    const text = parts
      .filter(Boolean)
      .map(
        (part) =>
          ["string", "number"].includes(typeof part)
            ? part.toString()
            : Object.entries(part)
                .filter(([_, value]) => value)
                .map(([key]) => key) // filter null key/value pairs from object part
      )
      .join(" ")
      .replace(/\s+/, " ")
    // eslint-disable-next-line no-console
    console.debug(`[safeImportFirebaseModules] ${text}`)
  }
}

async function safeImportFirebaseModules(
  ...moduleNames: Array<keyof FirebaseImportResult>
): Promise<FirebaseImportResult> {
  patchSetAttributeToExecuteGoogleApiJsScriptsSerially()

  let importedModules: FirebaseImportResult = {
    firebaseApp: null,
    firebaseAuth: null,
    firebaseDB: null,
    firebaseUI: null,
  }

  // Set up new promise representing the current import operation that subequent
  // safeImportFirebaseModules calls can wait for, and then (if it exists) await prior
  // safeImportFirebaseModules promise before performing imports requested by this call:
  const priorFirebaseImportPromise = _firebaseImportPromise
  let currentImportPromiseResolve = null as ((result: FirebaseImportResult) => void) | null
  const currentFirebaseImportPromise: Promise<FirebaseImportResult> = new Promise(
    (resolve) => (currentImportPromiseResolve = resolve)
  )
  _firebaseImportPromise = currentFirebaseImportPromise
  if (priorFirebaseImportPromise) {
    _importLog("waiting for prior safeImportFirebaseModules call")
    importedModules = await priorFirebaseImportPromise
    _importLog("received", importedModules, "from prior call")
  }

  // Note: Using non-arrow functions below makes Prettier format this more clearly.
  const importFunctions = {
    firebaseApp: () =>
      safeDynamicImport(
        function () {
          return import("firebase/app")
        },
        ["initializeApp"]
      ),
    firebaseAuth: () =>
      safeDynamicImport(
        function () {
          return import("firebase/auth")
        },
        ["getAuth", "onAuthStateChanged", "getIdToken", "signOut"]
      ),
    firebaseDB: () =>
      safeDynamicImport(
        function () {
          return import("firebase/database")
        },
        ["getDatabase", "ref", "onValue", "connectDatabaseEmulator"]
      ),
    firebaseUI: () =>
      safeDynamicImport(
        function () {
          return import("firebaseui")
        },
        ["auth"]
      ),
  }

  // Load Firebase modules serially (w/ small delay between each) to avoid conflicting
  // behavior when these modules internally fetch and execute Google's api.js script,
  // which can cause "Uncaught TypeError: u[v] is not a function" errors that put
  // FirebaseUI into a crashed/stuck state.
  // See: https://github.com/gladly-team/next-firebase-auth/issues/711
  for (const moduleName of moduleNames) {
    if (!importedModules[moduleName]) {
      if (moduleName !== moduleNames[0] || priorFirebaseImportPromise) {
        // If this isn't the first module import requested, OR there was a prior import
        // promise which is being awaited, then Google's api.js script is likely still
        // in the process of being loaded/evaluated/executed by the browser.
        // We add a delay in these cases to avoid api.js execution conflicts.
        _importLog("waiting", SAFE_IMPORT_FIREBASE_MODULES_DELAY, "between module imports")
        await new Promise((resolve) => setTimeout(resolve, SAFE_IMPORT_FIREBASE_MODULES_DELAY))
        // At least 200ms delay is needed here to prevent "u[v] is not a function"
        // according to iOS simulator observation; requestAnimationFrame not sufficient.
        // Increasing this number may futher reduce chance of these errors, if needed.
        // This delay currently happens N-1 times during an average SSO login page load,
        // where N is the number of different Firebase modules loaded.
        // (currently 4 modules, so we'll add 200ms * 3 = 600ms delay)
      }
      _importLog("importing", moduleName, "...")
      importedModules = {
        ...importedModules,
        [moduleName]: await importFunctions[moduleName](),
      }
      _importLog("finished importing", moduleName)
    }
  }

  _importLog("successfully imported", importedModules)
  currentImportPromiseResolve?.(importedModules)

  // Check whether this is the only ongoing Firebase import operation,
  // which is a good proxy for "last Firebase import operation" for our purposes.
  // We want to restore patched DOM methods to original state after the last Firebase
  // import op has completed (further ops will re-patch and re-restore DOM methods).
  const isOnlyOngoingFirebaseImport = currentFirebaseImportPromise === _firebaseImportPromise
  if (isOnlyOngoingFirebaseImport) {
    setTimeout(() => {
      // Check again, in case further import ops have started since timeout init:
      if (currentFirebaseImportPromise === _firebaseImportPromise) {
        restorePatchedSetAttributeToOriginalState()
      }
    }, 1_000)
  }

  return importedModules
}

// Set up mechanism for ensuring that multiple Google api.js script executions do
// not overlap at all in time (ensure these scripts are executed serially). This
// helps avoid "u[v] is not a function" errors which break our SSO flow.
const originalSetAttribute = HTMLScriptElement.prototype.setAttribute
function restorePatchedSetAttributeToOriginalState() {
  HTMLScriptElement.prototype.setAttribute = originalSetAttribute
  _importLog("restored HTMLScriptElement.setAttribute to original state")
}
function patchSetAttributeToExecuteGoogleApiJsScriptsSerially() {
  if (HTMLScriptElement.prototype.setAttribute !== originalSetAttribute) {
    return // setAttribute already patched; no need to patch them again.
  }

  _importLog("patching HTMLScriptElement.setAttribute so that Google api.js scripts are executed serially")

  let googleApiJsPromise: Promise<Event> | null = null

  HTMLScriptElement.prototype.setAttribute = function (...args: Parameters<typeof originalSetAttribute>) {
    const [attr, value] = args
    if (attr !== "src" || !value?.includes?.("apis.google.com") || !value?.includes?.("/api.js")) {
      // If the attribute isn't "src"
      // or the attribute value doesn't match Google's api.js script url,
      // then call the original setAttribute method as normal and do nothing else:
      originalSetAttribute.apply(this, args)
    } else if (!googleApiJsPromise) {
      // If we haven't set up an "api.js promise" yet, then this is the first
      // occurence of the api.js script being loaded. Create a promise that will
      // be resolved once the script has fully loaded, and then load the script
      // immediately: call original setAttribute function with src="<script url>"
      googleApiJsPromise = new Promise((resolve) => {
        this.addEventListener("load", (ev: Event) => {
          _importLog(`Firebase finished loading internal script src=${value}`)
          resolve(ev)
        })
      })
      originalSetAttribute.apply(this, args)
      _importLog(`Firebase started loading internal script src=${value}`)
    } else {
      // Otherwise, a prior "api.js promise" already exists, meaning that an
      // earlier occurence of the api.js script is already being loaded. Wait
      // for this promise, and then load this next api.js script by calling
      // original setAttribute function:
      googleApiJsPromise.then(() => {
        originalSetAttribute.apply(this, args)
        _importLog(`Firebase started loading internal script src=${value}`)
      })
      // Then, also set up a new promise that won't be resolved until THIS
      // occurence of the api.js script load has completed. Further api.js
      // script loads by Firebase modules will wait for this new promise.
      googleApiJsPromise = new Promise((resolve) => {
        this.addEventListener("load", (ev: Event) => {
          _importLog(`Firebase finished loading internal script src=${value}`)
          resolve(ev)
        })
      })
    }
  }
}

export default safeImportFirebaseModules
