import cn from "classnames"
import { useState } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import { AccountBrandContext, useSetAccountBrand } from "./AccountBrandContext"
import { AccountTypeContext, useSetAccountType } from "./AccountTypeContext"
import Header from "./Header"
import { PreviewOverlayContext } from "./PreviewOverlayContext"

import RTKitViewBase from "components/RTKitViewBase"
import { XmarkIcon } from "icons/FontAwesomeIcons"
import { useTeam } from "resources/teams"
import { useUser, useUpdateCurrentUser } from "resources/users"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useEffectAfterLocationChange from "ui/hooks/useEffectAfterLocationChange"
import SelectedTeamContext from "ui/SelectedTeamContext"
import { useCurrentTheme } from "ui/ThemeUpdateContext"
import Tooltip from "ui/Tooltip"
import View from "ui/View"
import { getTimeZone } from "utils/date"
import { handleErrorsWithAlerting } from "utils/errors"
import { buildUrl } from "utils/string"

const RTKitView = styled(function RTKitView({
  className,
  sidebar = null,
  hideSidebarIf = null,
  hideHeader = false,
  showSmallHeaderLogo = false,
}) {
  const { data: user } = useUser({ userId: "me" })
  const [accountType, setAccountType] = useState()
  const [accountBrand, setAccountBrand] = useState()
  const [previewOverlay, setPreviewOverlay] = useState()
  const [previewOverlayText, setPreviewOverlayText] = useState()
  const [onPreviewExitClick, setOnPreviewExitClick] = useState()
  const [selectedTeamId, setSelectedTeamId] = useState()
  const { data: selectedTeam } = useTeam({ teamId: selectedTeamId })
  const theme = useCurrentTheme()
  const { pathname, search } = useLocation()

  // set the account type if there is a selected team or use the user default only if there is not already an account type
  const defaultAccountType = selectedTeam?.account_type ?? (!accountType && user?.default_account_type)
  useSetAccountType({ accountType: defaultAccountType, shouldSet: !!defaultAccountType, setAccountType })

  // set the account brand if there is a selected team or use the user default only if there is not already an account brand
  const defaultAccountBrand = selectedTeam?.account_brand ?? (!accountBrand && user?.default_account_brand)
  useSetAccountBrand({ accountBrand: defaultAccountBrand, shouldSet: !!defaultAccountBrand, setAccountBrand })

  // Whenever the user navigates from a /setup page to a non-setup page,
  // set their setup_exited_at field in database if it isn't set already:
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()
  useEffectAfterLocationChange((location, prevLocation) => {
    if (prevLocation?.pathname.startsWith("/setup") && !location.pathname.startsWith("/setup")) {
      handleErrorsWithAlerting(
        () => updateCurrentUser({ setup_exited_at__set_if_null: true }),
        "Error updating setup_exited_at for user"
      )
    }
  })

  useEffectAfterChange(() => {
    if (user && !user.time_zone) {
      const timeZone = getTimeZone()
      if (timeZone) {
        updateCurrentUser({ time_zone: timeZone })
      }
    }
  }, [user, updateCurrentUser])

  if (!user) {
    return null
  }

  if (!user.first_name?.trim().length || !user.last_name?.trim().length) {
    const registerNameUrl = buildUrl(["get-started", "register-name"], { urlQueryParams: { next: pathname + search } })
    return <Navigate to={registerNameUrl} replace />
  }

  if (!user.has_an_active_account) {
    if (pathname.startsWith("/session") || pathname.startsWith("/startsession")) {
      return <Navigate to="/code" replace />
    }
    return <Navigate to="/select-or-update-payment" replace />
  }

  const hideSidebar = hideSidebarIf?.(user)
  const sidebarVisible = !!sidebar && !hideSidebar

  return (
    <AccountTypeContext.Provider value={{ accountType, setAccountType }}>
      <AccountBrandContext.Provider value={{ accountBrand, setAccountBrand }}>
        <PreviewOverlayContext.Provider value={{ setPreviewOverlay, setPreviewOverlayText, setOnPreviewExitClick }}>
          <SelectedTeamContext.Provider value={{ selectedTeamId, setSelectedTeamId }}>
            <div className={cn(className, theme.themeClassname, { "preview-frame": previewOverlay })}>
              {!!previewOverlay && (
                <Tooltip bottom wrapInView={false} title={previewOverlayText}>
                  <div className="preview-text text-fg text-semi-bold text-nowrap bg-orange-3">
                    <View $alignItems="center">
                      Preview Mode
                      <Button onClick={onPreviewExitClick} className="link ml-small">
                        <XmarkIcon color="var(--fg)" className="ml-xs mr-none" />
                      </Button>
                    </View>
                  </div>
                </Tooltip>
              )}
              {!hideHeader && <Header sidebarVisible={sidebarVisible} showSmallHeaderLogo={showSmallHeaderLogo} />}
              <RTKitViewBase sidebar={!!sidebarVisible && sidebar}>
                <Outlet />
              </RTKitViewBase>
            </div>
          </SelectedTeamContext.Provider>
        </PreviewOverlayContext.Provider>
      </AccountBrandContext.Provider>
    </AccountTypeContext.Provider>
  )
})`
  .preview-text {
    padding: 2px 30px 6px;
    border-radius: 0 0 8px 8px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: var(--z-sticky);
  }

  &.preview-frame::after {
    content: "";
    position: fixed;
    inset: 0;
    border: 4px solid var(--orange-3);
    z-index: var(--z-sticky);
    pointer-events: none;
  }

  /* Avoid showing the preview frame/text when printing: */
  @media print {
    .preview-text,
    &.preview-frame::after {
      display: none;
    }
  }
`

export default RTKitView
