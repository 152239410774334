import cn from "classnames"
import { styled } from "styled-components"

import View from "ui/View"

const ArtiChoiceInput = styled(function ArtiChoiceInput({
  name,
  value,
  label,
  type,
  className,
  onBlur,
  checked,
  disabled,
  onChange,
  initialValue: _, // omit from props to avoid "React does not recognize the `initialValue` prop on a DOM element" warning.
  onCustomChange: __, // omit from props to avoid "React does not recognize the `onCustomChange` prop on a DOM element" warning.
  ...props
}) {
  return (
    <View
      as="label"
      className={cn(className, "text-gray-9", {
        "cursor-default": disabled,
        disabled,
      })}
      data-testid="arti-member-choice"
    >
      <input
        type={type}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        checked={checked}
        disabled={disabled}
        {...props}
      />
      <View
        $alignItems="center"
        $justifyContent="center"
        className={cn("box fit-content p-xs", { disabled })}
        $flexDirection="row"
        role={type}
        aria-checked={checked}
        aria-disabled={disabled}
      >
        <div>
          <div className={cn("text-button", { disabled })}>
            <div className="text-nowrap">{label}</div>
          </div>
        </div>
      </View>
    </View>
  )
})`
  display: inline-block;
  text-align: center;
  user-select: none;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;

  .box {
    height: 100%;
    min-height: 46px;
    border: 1px solid var(--white);
    border-radius: 4px;
    background-color: #fdc774;
    box-shadow: 0 0 4px 0 rgb(255 211 89 / 44%);
    transition-property: background-color, border-color, box-shadow, color;
    transition-duration: 0.1s;
    transition-timing-function: ease-in-out;

    &.disabled {
      opacity: 0.44;
      background-color: #feb139;
    }
  }

  &:hover&:not(.disabled) > div,
  &:focus-visible&:not(.disabled) > div {
    border-radius: 4px;
    background-color: #fbe8ca;
    box-shadow: 0 2px 4px 0 rgb(255 211 89 / 75%);
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .text-button {
    font-size: 0.9375rem; /* ~15px */
    line-height: 1.375rem;
    color: var(--gray-9);
    font-weight: 500;
  }

  input {
    display: none;
    appearance: none;
  }

  /* stylelint-disable-next-line csstools/use-nesting */
  input[type="radio"]:checked + div .icon,
  input[type="checkbox"]:checked + div .icon,
  input[type="radio"]:checked + div .text-button,
  input[type="checkbox"]:checked + div .text-button {
    color: var(--gray-9);
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
  }

  &:hover input[type="radio"]:checked + div .icon,
  &:hover input[type="checkbox"]:checked + div .icon,
  &:hover input[type="radio"]:checked + div .text-button,
  &:hover input[type="checkbox"]:checked + div .text-button,
  &:focus-visible input[type="radio"]:checked + div .icon,
  &:focus-visible input[type="checkbox"]:checked + div .icon,
  &:focus-visible input[type="radio"]:checked + div .text-button,
  &:focus-visible input[type="checkbox"]:checked + div .text-button {
    color: var(--gray-9);
    font-size: 16px;
    font-weight: 600;
    background-color: var(--white);
    opacity: 1;
  }

  input[type="radio"]:checked + div,
  input[type="checkbox"]:checked + div {
    box-shadow: none;
    color: var(--gray-9);
    font-size: 16px;
    font-weight: 600;
    background-color: var(--white);
    opacity: 1;
  }

  &:hover input[type="radio"]:checked + div,
  &:hover input[type="checkbox"]:checked + div,
  &:focus-visible input[type="radio"]:checked + div,
  &:focus-visible input[type="checkbox"]:checked + div {
    background-color: var(--white);
  }
`

export default ArtiChoiceInput
