import { useKitSession } from "../KitSessionContext"

import Accordion from "components/Accordion"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import ParticipantReflections from "domains/KitSession/components/ParticipantReflections"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { useTeamReflections, useKitParticipants } from "resources/monthly_kit"
import { sortUsersByShortName } from "resources/users"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import TextContainer from "ui/TextContainer"
import { useHasTeamFeature } from "utils/team"

const ReflectionsResultsStep = ({ kitInstance }) => {
  const { team, preview } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })
  const { data: reflections, isFetching } = useTeamReflections({
    kitInstanceId: kitInstance.id,
    refetchInterval: 30000,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })

  if (!participants || (isFetching && !reflections)) {
    return <Loading />
  }

  const sortedParticipants = sortUsersByShortName({ users: participants })

  return (
    <StepContent>
      <SidebarContainer>
        <TextContainer>
          <p className="text-gray-9 mb-xl mr-small">
            Now we’ll share our main takeaways from the session and anything we each want to do differently based on
            what we learned. Not everyone has to share if they don’t want to.
          </p>
        </TextContainer>
        <FacilitatorTip className="sidebar-right" icon="share-screen" />
      </SidebarContainer>
      {sortedParticipants.map((teamMember) => (
        <Accordion
          title={<h3 className="text-capitalize">{teamMember.short_name || teamMember.email}</h3>}
          key={teamMember.id}
          isOpen={false}
        >
          <ParticipantReflections reflections={reflections} teamMember={teamMember} />
        </Accordion>
      ))}
    </StepContent>
  )
}

export default ReflectionsResultsStep
