import cn from "classnames"
import { styled } from "styled-components"

import Table from "components/Table"
import ConfirmOperationButton from "domains/Admin/ConfirmOperationButton"
import ResendInviteLink from "domains/Admin/ResendInviteLink"
import { canUserManageAccount, canUserEditAccountTeam } from "domains/Admin/utils"
import { PenToSquareIcon } from "icons/FontAwesomeIcons"
import { useResendTeamLeadInvite, useRemoveTeamLeadInvite } from "resources/billing"
import useFeatures from "ui/hooks/useFeatures"
import View from "ui/View"
import { useTeamInfo } from "utils/team"

const TeamsTable = ({ className, user, account, teams, query, editFn = null, showInviteStatus = true }) => {
  const features = useFeatures()
  const { mutateAsync: resendTeamLeadInvite } = useResendTeamLeadInvite(account?.id)
  const { mutateAsync: removeTeamLeadInvite } = useRemoveTeamLeadInvite(account?.id)
  const { getLead, getLeadNameOrEmail, isLeadInvitePending } = useTeamInfo()

  const additionalSearchKeys = [
    (team) => {
      const lead = getLead(team)
      return lead?.name
    },
    (team) => {
      const lead = getLead(team)
      return lead?.email
    },
  ]

  return (
    <Table
      className={cn(className, { "is-editable": editFn })}
      query={query}
      rows={teams ?? []}
      empty="You don't have any teams yet."
      notFound="No matching teams found."
      defaultSort={[{ id: "status" }, { id: "name" }]}
      additionalSearchKeys={additionalSearchKeys}
      maxColumnWidth={230}
      columns={[
        {
          id: "name",
          Header: "Team name" + (!teams?.length ? "" : ` (${teams.length})`),
          searchable: true,
          className: (team) =>
            cn("team-name", {
              "text-italic": isLeadInvitePending(team),
              "text-rising-blue text-semi-bold": editFn && !isLeadInvitePending(team),
            }),
          accessor: (team) => (isLeadInvitePending(team) ? `${team.name} (pending)` : team.name),
          tooltip: (team) =>
            isLeadInvitePending(team)
              ? `Lead invite is pending for ${team.name}`
              : team.name?.length > 35
                ? team.name // team name will be truncated, so show full name in tooltip
                : null,
          onClick: (team) => canUserEditAccountTeam(account, user, team, features) && editFn(team),
        },
        {
          id: "members",
          Header: "Members",
          shrink: true,
          accessor: (team) => team.members.length,
          tooltip: (team) =>
            team.members
              .map((u) => u.name || u.email)
              .filter(Boolean)
              .join(", ") || "No members",
        },
        {
          id: "lead",
          Header: "Team lead",
          searchable: true,
          accessor: (team) => (team.team_lead_id === user.id ? <b>You</b> : getLeadNameOrEmail(team)),
          tooltip: (team) => (getLead(team)?.name ? getLead(team).email : null),
          // This looks odd, but it is correct - if team lead has name set, show their email in a tooltip.
          // Otherwise, don't show a tooltip at all (send null) since email will already be showing in table cell.
          title: (team) => (getLead(team)?.name ? null : `No user name set for ${getLead(team).email}`),
          // When email is showing, use a standard HTML title attr to show a less-obtrusive tooltip
          // that only appears after a delay. If someone _really_ needs to know why a name isn't showing up,
          // they'll end up seing this browser-native tip when the hover and delay.
        },
        ...(!showInviteStatus
          ? []
          : [
              {
                id: "status",
                Header: "Invite status",
                accessor: (team) =>
                  !isLeadInvitePending(team) ? (
                    "Accepted"
                  ) : !canUserManageAccount(account, user, features) ? (
                    "Not yet accepted"
                  ) : (
                    <View>
                      <ResendInviteLink resendInvite={resendTeamLeadInvite} resendInviteArgs={[team.id]} />
                      <ConfirmOperationButton
                        buttonText="Remove"
                        confirmTitle="Remove invite?"
                        confirmBody={
                          <>
                            <p>
                              Are you sure you want to remove this invite? The team will be deleted and you'll need to
                              start over.
                            </p>
                            <p className="mt-small">
                              If you don't want to delete the team, instead edit the team and choose a different team
                              lead.
                            </p>
                          </>
                        }
                        confirmText="Remove"
                        cancelText="Cancel"
                        onConfirm={() => removeTeamLeadInvite(team.team_lead_id)}
                      />
                    </View>
                  ),
              },
            ]),
        ...(!editFn
          ? []
          : [
              {
                id: "edit",
                className: "edit-button",
                title: "Edit team",
                shrink: true,
                disableSortBy: true,
                accessor: (team) => canUserEditAccountTeam(account, user, team, features) && <PenToSquareIcon />,
                onClick: (team) => canUserEditAccountTeam(account, user, team, features) && editFn(team),
              },
            ]),
      ]}
    />
  )
}

export default styled(TeamsTable)`
  &.is-editable {
    .team-name {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .edit-button {
      cursor: pointer;

      &:hover {
        color: var(--rising-blue);
      }
    }
  }
`
