import { createContext, useContext } from "react"

import type { ModalProps } from "ui/Modal"

interface ModalContextData {
  modal: ModalProps | null
  setModal: (modalProps: ModalProps | null) => void
}

const ModalContext = createContext<ModalContextData>({
  modal: null,
  setModal: () => {},
})

const useModal = () => useContext(ModalContext) || {}

export { ModalContext, useModal }
