import { useState } from "react"
import { styled } from "styled-components"

const SoundBoardButton = ({ className, title, soundUrl, normalStateUrl, pressedStateUrl }) => {
  const audio = new Audio(soundUrl)
  const [isPressed, setIsPressed] = useState(false)

  const image = (srcUrl) => <img src={srcUrl} className="button-image mt-small" alt="" />

  const handlePointerDown = () => {
    setIsPressed(true)
    audio.play()
  }

  const handlePointerUp = () => {
    setIsPressed(false)
  }

  return (
    <div className={className}>
      <button className="sound-button" onPointerDown={handlePointerDown} onPointerUp={handlePointerUp}>
        {isPressed ? image(pressedStateUrl) : image(normalStateUrl)}
        <label className="sound-title text-semi-bold text-normal px-xl">{title}</label>
      </button>
    </div>
  )
}

export default styled(SoundBoardButton)`
  .sound-button {
    border: none;
    background: none;
    height: 11rem;
    width: 11rem;
    background-size: cover;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
  }

  .sound-title {
    color: #fff;
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .button-image {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .sound-button {
      height: 12rem;
      width: 12rem;
    }
  }
`
