import { Formik, Form } from "formik"

import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { CircleInfoIcon } from "icons/FontAwesomeIcons"
import { useUpdateKitInstance } from "resources/monthly_kit"
import { useUser } from "resources/users"
import Tooltip from "ui/Tooltip"
import View from "ui/View"

function FacilitatorSelectForm({ label = "Facilitator:", kitInstance, team, options = [], subtle = false, className }) {
  const { mutate: updateKitInstance } = useUpdateKitInstance({ kitInstanceId: kitInstance.id })
  const { data: currentUser } = useUser({ userId: "me" })
  const currentFacilitator = team.members.find((member) => member.id === kitInstance.facilitator_id)
  const currentFacilitatorInOptions = options.find((option) => option.id === currentFacilitator.id)

  if (!currentUser || options.length === 0) {
    return null
  }

  const facilitatorOptions = [...options, ...(currentFacilitatorInOptions ? [] : [currentFacilitator])]
    .sort((a, b) => {
      // Sort current user to the top, then sort by name
      if (a.id === currentUser.id) {
        return -1
      }
      if (b.id === currentUser.id) {
        return 1
      }
      return a.name.localeCompare(b.name)
    })
    .map((member) => {
      const name = member.short_name ?? member.name
      const label = member.id === currentUser.id ? `${name} (you)` : name

      return {
        value: member.id,
        label,
      }
    })

  const setFacilitator = (option) => {
    updateKitInstance({ assigned_facilitator_id: option.value })
  }

  return (
    <div className={className}>
      <View $alignItems="center" $width="auto">
        {!!label && <p className="text-semi-bold mr-small">{label}</p>}
        {facilitatorOptions.length > 1 ? (
          <Formik
            initialValues={{ assigned_facilitator_id: kitInstance?.facilitator_id }}
            enableReinitialize
            className={className}
          >
            <Form>
              <AdvancedSelectField
                name="assigned_facilitator_id"
                options={facilitatorOptions}
                onChange={setFacilitator}
                subtle={subtle}
                isSearchable={false}
              />
            </Form>
          </Formik>
        ) : (
          <Tooltip
            title={`
            Once members are added, you'll be able to select
            a facilitator. It can be changed at any time
            during a session.
          `}
          >
            <p className="m-none">
              {currentUser.short_name ?? currentUser.name} (you) <CircleInfoIcon className="text-gray-8" />
            </p>
          </Tooltip>
        )}
      </View>
    </div>
  )
}

export default FacilitatorSelectForm
