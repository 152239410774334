import { createContext, useContext } from "react"

const KitSessionContext = createContext({
  kitInstance: null,
  visibleSteps: null,
  preview: null,
  team: null,
  facilitator: null,
  teamLead: null,
  isTeamLead: false,
  isFacilitator: false,
})

const useKitSession = () => useContext(KitSessionContext) || {}

export { KitSessionContext, useKitSession }
