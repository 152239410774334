import { useState } from "react"
import { Outlet } from "react-router-dom"

import { AccountBrandContext } from "./AccountBrandContext"
import { AccountTypeContext } from "./AccountTypeContext"
import Header from "./Header"

import RTKitViewBase from "components/RTKitViewBase"

const RTKitPublicView = ({ showSmallHeaderLogo = false }) => {
  const [accountType, setAccountType] = useState()
  const [accountBrand, setAccountBrand] = useState()

  return (
    <AccountTypeContext.Provider value={{ accountType, setAccountType }}>
      <AccountBrandContext.Provider value={{ accountBrand, setAccountBrand }}>
        <Header sidebarVisible={false} showSmallHeaderLogo={showSmallHeaderLogo} />
        <RTKitViewBase>
          <Outlet />
        </RTKitViewBase>
      </AccountBrandContext.Provider>
    </AccountTypeContext.Provider>
  )
}

export default RTKitPublicView
