import cn from "classnames"
import { useState } from "react"

import Button from "ui/Button"
import Modal from "ui/Modal"
import { colors } from "ui/theme"
import View from "ui/View"

const ConfirmOperationButton = ({
  className,
  onConfirm,
  buttonText,
  confirmTitle,
  confirmBody,
  confirmText,
  cancelText,
}) => {
  const [showModal, setShowModal] = useState(false)
  const onClick = async () => {
    await onConfirm()
    setShowModal(false)
  }
  return (
    <>
      <Button
        color={colors.risingBlue}
        className={cn("remove-invite-button", className, "link-semi-bold text-field-label")}
        onClick={() => setShowModal(true)}
      >
        {buttonText}
      </Button>
      <Modal
        title={confirmTitle}
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        size="small"
        content={
          <>
            <div className="pb-large">{confirmBody}</div>
            <View>
              <Button className="tertiary" onClick={onClick}>
                {confirmText}
              </Button>
              <Button
                color={colors.risingBlue}
                className="link-semi-bold text-field-label"
                onClick={() => setShowModal(false)}
              >
                {cancelText}
              </Button>
            </View>
          </>
        }
      />
    </>
  )
}

export default ConfirmOperationButton
