import { Form, Formik, useFormikContext } from "formik"
import { useState } from "react"
import { useNavigate } from "react-router"
import { Link, useParams, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import { useScrollToTopContext } from "components/ScrollToTop"
import { useAuth, useLogin } from "domains/Authentication/resource"
import EmailField from "forms/fields/EmailField"
import PasswordField from "forms/fields/PasswordField"
import TextField from "forms/fields/TextField"
import FormMessage from "forms/FormMessage"
import handleErrors from "forms/handleErrors"
import Yup from "forms/yup"
import { GolfBallTeeIcon, CircleInfoIcon } from "icons/FontAwesomeIcons"
import { useJoinKitInstanceByCode, validateSessionCode } from "resources/monthly_kit"
import { useUserHasOnlyInactiveAccounts } from "resources/users"
import Loading from "ui/Loading"
import MailToLink from "ui/MailToLink"
import NextButton from "ui/NextButton"
import PageTitle from "ui/PageTitle"
import Tooltip from "ui/Tooltip"
import View from "ui/View"
import { buildUrl } from "utils/string"

// Keep in sync with INVALID_CODE_MESSAGE in backend/src/resources/monthly_kit.py
const INVALID_CODE_MESSAGE =
  "Invalid session code or invalid email domain. " +
  "Please double check your session code and make sure to use your company email."

const TOO_MANY_REQUESTS_MESSAGE = "Too many requests. Please try again later."

const TeamCodeHome = ({ className }) => {
  const { pathname, state } = useLocation()
  const { sessionCode } = useParams()
  const { data: auth, isFetching: authIsFetching } = useAuth()
  const navigate = useNavigate()
  const { mutateAsync: joinKitInstanceByCode } = useJoinKitInstanceByCode()
  const [isJoiningKitInstance, setIsJoiningKitInstance] = useState(false)
  const [showPasswordField, setShowPasswordField] = useState()
  const { mutateAsync: login } = useLogin()
  const { setNoScrollToTopForPaths } = useScrollToTopContext()
  const checkInactiveAccounts = state?.referrer === "SelectOrUpdatePayment"
  const { data: userHasOnlyInactiveAccounts } = useUserHasOnlyInactiveAccounts({ enabled: checkInactiveAccounts })

  if (authIsFetching) {
    return <Loading />
  }

  async function joinKitInstanceAndRedirect(code, setFormErrors = null) {
    setIsJoiningKitInstance(true)
    try {
      const kitInstance = await joinKitInstanceByCode({ code })
      navigate("/code/profile", { state: { kitInstance } })
    } catch (err) {
      setIsJoiningKitInstance(false)
      if (setFormErrors) {
        if (err.status === 429) {
          setFormErrors({ session_code: TOO_MANY_REQUESTS_MESSAGE })
        } else {
          setFormErrors({ session_code: INVALID_CODE_MESSAGE })
        }
      }
    }
  }

  const teamCodeInitialValues = {
    session_code: sessionCode ?? "",
    email: "",
    password: "",
  }

  const showEmailField = !auth || showPasswordField
  const teamCodeSchema = getTeamCodeSchema({ showEmailField, showPasswordField })

  function getSessionCodeFromValues(values) {
    return values.session_code.toUpperCase()
  }

  function updateSessionCodeInUrl(values) {
    const newCode = getSessionCodeFromValues(values)
    if (newCode !== sessionCode) {
      const updatedCodePath = pathname.replace(sessionCode, newCode)
      setNoScrollToTopForPaths([pathname, updatedCodePath])
      navigate(updatedCodePath)
    }
  }

  const onSubmit = handleErrors(async (values, { setErrors }) => {
    const newCode = getSessionCodeFromValues(values)
    if (auth) {
      await joinKitInstanceAndRedirect(newCode, setErrors)
    } else if (values.password) {
      await login(values)
      await joinKitInstanceAndRedirect(newCode, setErrors)
    } else {
      const { user_exists, is_demo, account_type, kit_group_size, sso_providers } = await validateSessionCode({
        code: newCode,
        email: values.email,
      })
      updateSessionCodeInUrl(values) // run again just to double-check code is updated
      if (!!sso_providers?.length) {
        const provider = sso_providers[0].provider
        const next = buildUrl(["code", newCode])

        navigate(buildUrl(["auth", "sso", provider], { urlQueryParams: { next } }))
      } else if (user_exists) {
        setShowPasswordField(true)
      } else {
        navigate(`/code/${newCode}/registration`, {
          state: {
            email: values.email,
            is_demo,
            accountType: account_type,
            kitGroupSize: kit_group_size,
          },
        })
      }
    }
  })

  return (
    <div className={className}>
      <PageTitle>Team Code</PageTitle>
      <View $alignItems="center" className="mb-xl">
        <GolfBallTeeIcon className="text-yellow-2 title-icon mr-xs" />
        <h2 className="text-gray-9">Join a session</h2>
      </View>
      <Formik onSubmit={onSubmit} validationSchema={teamCodeSchema} initialValues={teamCodeInitialValues}>
        {isJoiningKitInstance ? (
          <Loading />
        ) : (
          <TeamCodeForm
            showEmailField={showEmailField}
            showPasswordField={showPasswordField}
            onSessionCodeChange={updateSessionCodeInUrl}
          />
        )}
      </Formik>
      {!!userHasOnlyInactiveAccounts && (
        <div className="mt-xl">
          <p>
            If you are trying to access a team you previously had access to, please contact your account administrator
            or email us at <MailToLink email="support@risingteam.com" />.
          </p>
        </div>
      )}
    </div>
  )
}

const TeamCodeForm = ({ showEmailField, showPasswordField, onSessionCodeChange }) => {
  const { values } = useFormikContext()
  // Use autoComplete="username" instead of autoComplete="email" on EmailField
  // below, per Chromium recommendations:
  // https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
  // Further reading: https://stackoverflow.com/a/57902690
  return (
    <Form name="team-code" autoComplete="off" className="team-code-form space-y-large" data-testid="session-code-form">
      <TextField
        label="Session code"
        name="session_code"
        maxLength="4"
        autoFocus
        id="codeField"
        size="medium"
        placeholder="Enter your session code here"
        onBlur={() => onSessionCodeChange(values)}
        data-testid="session-code-input"
      />
      {!!showEmailField && (
        <EmailField
          label="Email address"
          name="email"
          size="medium"
          autoComplete="username"
          placeholder="Enter your work email here"
          data-testid="session-email-input"
        />
      )}
      {!!showPasswordField && (
        <>
          <View>
            <PasswordField
              className="fit-content"
              label="Enter password"
              name="password"
              autoComplete="current-password"
              size="medium"
              eye
              placeholder="Enter your password here"
              data-testid="session-password-input"
            />
          </View>
          <div>
            <Link to="/auth/forgot" state={{ email: values.email }}>
              Forgot your password?
            </Link>
          </div>
        </>
      )}
      <div>
        <Tooltip top float className="no-code-tooltip" wrapInView={false} title={<NoCodeTooltipContent />}>
          <p>
            <span className="mr-xxs">Need a code?</span>
            <CircleInfoIcon className="mb-xxs" />
          </p>
        </Tooltip>
        The session code is unique for this team and session.{" "}
      </div>
      <View className="mt-auto pt-xl" data-testid="session-step-navigation">
        <NextButton type="submit" />
      </View>
      <FormMessage />
    </Form>
  )
}

const NoCodeTooltipContent = () => (
  <div>
    <p className="text-semi-bold">Need a code?</p>
    <p>
      The session code is created when a facilitator starts a session. A unique code is generated for every team and
      session. Your facilitator can find the code on the start page when they begin the session.
    </p>
  </div>
)

const getTeamCodeSchema = ({ showEmailField, showPasswordField }) => {
  const emailSchemaBase = Yup.string().email().label("Email")
  const emailSchema = showEmailField
    ? emailSchemaBase.required("This field is required.")
    : emailSchemaBase.notRequired()
  const pwSchemaBase = Yup.string().label("Password")
  const pwSchema = showPasswordField ? pwSchemaBase.required("This field is required.") : pwSchemaBase.notRequired()

  return Yup.object().shape({
    session_code: Yup.string().required("This field is required.").label("Team Code"),
    email: emailSchema,
    password: pwSchema,
  })
}

export default styled(TeamCodeHome)`
  /* stylelint-disable-next-line selector-id-pattern */
  .team-code-form #codeField {
    text-transform: uppercase;
  }

  .no-code-tooltip {
    display: inline;
    cursor: pointer;
  }
`
