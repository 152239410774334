import { createContext, useContext, useCallback, useMemo } from "react"

const ThemeUpdateContext = createContext({
  defaultTheme: null,
  theme: null,
  setTheme: () => {},
})

const useThemeContext = () => useContext(ThemeUpdateContext) || {}

const useUpdateTheme = () => {
  const { defaultTheme, setTheme } = useThemeContext()
  const updateTheme = useCallback((styles) => setTheme({ ...defaultTheme, ...styles }), [setTheme, defaultTheme])
  const resetTheme = useCallback(() => setTheme(defaultTheme), [setTheme, defaultTheme])

  const sessionTheme = useMemo(() => ({}), [])

  const enableSessionTheme = useCallback(() => updateTheme(sessionTheme), [updateTheme, sessionTheme])

  const enableWidescreen = useCallback(
    () =>
      updateTheme({
        isWidescreen: true,
        themeClassname: "theme-widescreen",
        maxContent: "1680px",
        maxScreen: "1920px",
        normalFontSize: "1.5rem", // 24px
        normalLineHeight: "2rem",
        spacing0: "6px", // xxs
        spacing1: "12px", // xs
        spacing2: "24px", // small
        spacing3: "28px", // medium
        spacing4: "36px", // large
        spacing5: "48px", // xl
        spacing6: "60px", // xxl
        spacing7: "72px", // xxxl
        spacing8: "96px", // xxxxl
        textContainerDefaultMaxWidth: 1300,
        ...sessionTheme,
      }),
    [updateTheme, sessionTheme]
  )

  return { updateTheme, resetTheme, enableWidescreen, enableSessionTheme }
}

const useCurrentTheme = () => {
  const { theme } = useThemeContext()
  return theme
}

export { ThemeUpdateContext, useUpdateTheme, useCurrentTheme }
