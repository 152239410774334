import cn from "classnames"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import { CheckIcon } from "icons/FontAwesomeIcons"
import useWindowSize from "ui/hooks/useWindowSize"

const ProgressCircle = styled(function ProgressCircle({ to, onClick, children, className }) {
  return (
    <li className={cn(className, "text-semi-bold")}>
      <Link to={to} onClick={onClick}>
        {children}
      </Link>
    </li>
  )
})`
  list-style-type: none;
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  margin-bottom: 0;
  font-weight: 500;
  border-width: 2px;
  border-color: var(--gray-5);
  border-style: solid;
  background-color: var(--fg);
  border-radius: 50%;

  &.active {
    border-color: var(--rising-orange);
  }

  &.complete,
  &.blank.complete {
    background-color: var(--gray-5);
  }

  &.blank.active {
    background-color: var(--rising-orange);
  }

  a {
    color: inherit;
    display: block;
    text-align: center;
    width: 100%;

    /* These two rules keep icons and numbers centered vertically within bubbles: */
    margin-top: 50%;
    transform: translateY(-50%);

    &:hover {
      text-decoration: none;
    }
  }

  &.complete {
    a {
      line-height: calc(100% + 6px);
    }
  }

  /* Line between steps */
  &:not(:first-child)::before {
    content: "";
    position: absolute;
    left: -10px; /* try to calculate these */
    right: 22px;
    top: calc(50% - 1px);
    border-width: 2px;
    border-style: solid;
    border-color: var(--gray-5);
    background-color: var(--gray-5);
  }

  .theme-widescreen & {
    height: 30px;
    width: 30px;

    &:not(:first-child)::before {
      left: -15px; /* try to calculate these */
      right: 28px;
    }
  }
`

const StepProgressBar = styled(function StepProgressBar({ currentStep, allSteps, getStepUrl, onStepClick, className }) {
  const { isMobileLandscapeOrSmaller } = useWindowSize()
  const mobileStepMax = 6

  if (allSteps.length <= 1 || currentStep.progress_bar_hide) {
    return null
  }

  const currentStepIdx = allSteps.findIndex((step) => step.path === currentStep.path)

  const showMobileProgress = isMobileLandscapeOrSmaller && allSteps.length > mobileStepMax
  if (showMobileProgress) {
    return (
      <p className="text-rising-orange text-semi-bold">
        Step {currentStepIdx + 1} of {allSteps.length}
      </p>
    )
  }

  let stepNumber = 1
  return (
    <ul className={cn(className, "space-x-xs")}>
      {allSteps.map((step, idx) => {
        const active = idx === currentStepIdx
        const complete = idx < currentStepIdx
        const blank = step.progress_bar_blank
        const currentStepNumber = stepNumber

        if (step.progress_bar_hide) {
          return null
        }

        if (!blank) {
          stepNumber += 1
        }

        const ariaLabel = `Step ${idx + 1} of ${allSteps.length}`
        const to = getStepUrl ? getStepUrl(step, idx) : null
        const onClick = onStepClick ? () => onStepClick(step, idx) : null

        return (
          <ProgressCircle key={step.path} to={to} onClick={onClick} className={cn({ active, complete, blank })}>
            <div aria-label={ariaLabel}>{blank ? <div /> : complete ? <CheckIcon /> : currentStepNumber}</div>
          </ProgressCircle>
        )
      })}
    </ul>
  )
})`
  display: flex;
  width: calc(100% - 18px);
`

export default StepProgressBar
