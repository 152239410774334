import cn from "classnames"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import Tooltip from "ui/Tooltip"
import View from "ui/View"

const RadioGroupTextButtonEmptyBlocks = ({ identifier, exerciseComponent, exerciseInstance }) => {
  const choiceOptions = exerciseComponent.options
  const columns = choiceOptions.length
  const userSelections = getExerciseAnswer(exerciseInstance, identifier) ?? []

  return (
    <span>
      <TextBoxGrid choiceOptions={choiceOptions} userSelections={userSelections} columns={columns} />
    </span>
  )
}

const TextBoxGrid = styled(function TextBoxGrid({ choiceOptions, userSelections, className }) {
  return (
    <View className={className} $flexWrap="wrap">
      {choiceOptions.map(({ value, short_label }) => (
        <Tooltip bottom float title={short_label} key={value}>
          <div
            key={value}
            className={cn("answer-box text-gray-9 text-center text-semi-bold", {
              selected: userSelections.includes(value),
            })}
          ></div>
        </Tooltip>
      ))}
    </View>
  )
})`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 30px));
  grid-auto-rows: 1fr;
  gap: 2px;

  .answer-box {
    display: flex;
    flex-direction: row;
    padding: 3px 0;
    height: 32px;
    width: 30px;
    box-sizing: border-box;
    background: var(--gray-3);

    &.selected {
      background: var(--green-3);
      color: var(--fg);
    }
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    grid-template-columns: repeat(${({ columns }) => Math.min(columns / 3)}, minmax(0, 1fr));

    .answer-box {
      height: 24px;
      width: 36px;
    }
  }
`
export default RadioGroupTextButtonEmptyBlocks
