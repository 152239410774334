import { styled } from "styled-components"

const SidebarContainer = styled.div`
  position: relative; /* needed for absolutely-positioned facilitator tips */
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .sidebar-right {
    margin-left: 24px;
    max-width: 229px;
    height: fit-content;
  }
`

export default SidebarContainer
