import type { FormikHelpers, FormikErrors } from "formik"

type SubmitFn<FormValues> = (
  values: FormValues,
  formik: FormikHelpers<FormValues>
) => Promise<void | (Response & { message?: string })>

type Exception = Error & { message?: string; response?: Response }

type ErrorFn<FormValues> = (
  data: Record<string, unknown>,
  values: FormValues,
  formik: FormikHelpers<FormValues>,
  exception: Exception,
  responseStatus: number | null
) => null | Record<string, unknown> | Promise<Record<string, unknown>>

function handleErrors<FormValues>(
  onSubmit: SubmitFn<FormValues>,
  handleErrorDataFn?: ErrorFn<FormValues>,
  formikContextFallback?: FormikHelpers<FormValues>
): (values: FormValues, formikContext: FormikHelpers<FormValues>) => Promise<void> {
  return async (values: FormValues, formikContext: FormikHelpers<FormValues>) => {
    const formik = formikContext || formikContextFallback
    const { setStatus, setErrors, setSubmitting } = formik

    setSubmitting(true)

    try {
      const response = await onSubmit(values, formik)

      if (response?.message) {
        setStatus(response)
      }
    } catch (unknownException) {
      const exception = unknownException as Exception

      let {
        data,
        headers,
        status: responseStatus,
      } = (exception?.response ?? {}) as Response & { data?: Record<string, unknown> | null }
      let handleData = (data: Record<string, unknown>) => setStatus(data)

      if (!data) {
        data = { message: exception.message }
      }

      if (headers?.get("content-type") === "application/json") {
        // For "errors/status" to persist use - setStatus(data)
        setStatus({})
        handleData = (data) => setErrors(data as FormikErrors<FormValues>)
      } else if (headers?.get("content-type")?.startsWith("text/plain; charset=utf-8")) {
        data = { message: exception.message }
      } else {
        data = { message: "An error occurred." }
      }

      // Allow caller to perform custom processing on exception data:
      if (handleErrorDataFn) {
        const dataOrPromise = handleErrorDataFn(data, values, formik, exception, responseStatus ?? null)
        data = await Promise.resolve(dataOrPromise)
        // use Promise.resolve to collect result from handleErrorDataFn
        // properly regardless of whether or not it's an async function
      }

      // `data` may be returned from `handleErrorDataFn` as `null` if
      // caller wishes to `setErrors` themselves or perform other behavior:
      if (data) {
        handleData(data)
      }

      setSubmitting(false)
    }
  }
}

export default handleErrors
