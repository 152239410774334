import cn from "classnames"
import { Link, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import ScheduleForm from "./ScheduleForm"

import { useRTBrandName } from "components/AccountTypeContext"
import { useHubSpotModal } from "components/HubSpotModal"
import renderKitBlocks from "domains/KitSession/blocks"
import FacilitatorSelectForm from "domains/LeaderKit/FacilitatorSelectForm"
import SessionPreviewMessage from "domains/LeaderKit/SessionPreviewMessage"
import { ArrowRightIcon, EarIcon, PaperPlaneIcon, PenAltIcon, PlayCircleIcon } from "icons/FontAwesomeIcons"
import { useUserAccount } from "resources/billing"
import { getStandaloneExerciseUrl } from "resources/monthly_kit"
import Button from "ui/Button"
import FlairContainer from "ui/FlairContainer"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import useWindowSize from "ui/hooks/useWindowSize"
import PageBreadcrumbs from "ui/PageBreadcrumbs"
import TextContainer from "ui/TextContainer"
import View from "ui/View"
import { KitGroupSize, PromoKitSlug, formatKitSessionTime } from "utils/kit"
import { useHasTeamFeature } from "utils/team"

const ConnectionBoostPrepHome = ({ kitInstance, user, team, className }) => {
  const { data: userAccount } = useUserAccount(team?.account_id)
  const rtBrandName = useRTBrandName()
  const { pathname } = useLocation()
  const { isDesktopOrLarger } = useWindowSize()
  const { enabled: coleadsEnabled } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_COLEADS)
  const exerciseUrl = getStandaloneExerciseUrl({
    slug: kitInstance.slug,
    teamId: kitInstance.team_id,
    onFinishUrl: pathname,
  })
  const isDemoPreview = team.demo && user.id !== team.team_lead_id
  const previewMode = team.preview || isDemoPreview
  const isJumbo = kitInstance.kit.group_size === KitGroupSize.JUMBO
  const isGratitudePromo = !!userAccount?.is_gratitude_promo
  const isGratitudeKit = kitInstance.kit.slug === PromoKitSlug.GRATITUDE
  const showBookCall = !!isGratitudePromo && !!isGratitudeKit
  const sessionTime = formatKitSessionTime(kitInstance.kit.session_time_minutes, { unit: "minute" })
  const goTime = formatKitSessionTime(kitInstance.kit.session_time_minutes)
  const isTeamLead = user.id === team.team_lead_id
  const isLeadOrColead = coleadsEnabled || isTeamLead
  const canSelectFacilitator = !!coleadsEnabled && !isJumbo
  const { setModal } = useHubSpotModal({
    isGratitudePromo: !!isGratitudePromo && !!isGratitudeKit,
  })
  const modalStyles = {
    content: { borderRadius: "8px", border: "3px solid var(--orange-4)" },
  }
  function onBookCallClick() {
    setModal({ styles: modalStyles })
  }

  const BrandBlocks = ({ brand_blocks, kitInstance }) => {
    if (!brand_blocks) {
      return null
    }

    return <View $justifyContent="center">{renderKitBlocks(brand_blocks, { kitInstance })}</View>
  }

  return (
    <main className={cn("align-left full-width", className)}>
      <FlairContainer className="main-container">
        <TextContainer maxWidth={525} mobileMaxWidth={265} className="mb-large">
          <PageBreadcrumbs className="mb-medium">
            <Link to={`/kit?team_id=${team.id}`}>View all kits</Link>
            {kitInstance.kit.title}
          </PageBreadcrumbs>
          <h3 className="text-primary text-bold">{isJumbo ? "Jumbo " : ""}Connection Kit</h3>
          <h1 className="text-gray-9 text-bold py-small pr-medium">{kitInstance.kit.title}</h1>
          <div className="text-gray-9 text-thin">{kitInstance.kit.description}</div>
          {!!isJumbo && (
            <div className="text-gray-9 text-thin mt-medium">
              This version is optimized for groups up to 1000 people.
            </div>
          )}
        </TextContainer>
        <BrandBlocks brand_blocks={kitInstance.kit.brand_blocks} />
        <div>
          <div className="border-radius-small border-left-thick border-orange-2 box-shadow px-large py-medium mt-medium mb-large">
            <View $alignItems="center" $justifyContent="space-between" className="pt-xs">
              <div className="step-title text-gray-9 text-bold">
                <h2 className="pr-small text-orange-2">Schedule</h2>
              </div>
              <h4 className="step-time text-semi-bold text-orange-2 pr-xxs">5 mins</h4>
            </View>
            <div className="text-gray-9 pt-medium pb-medium">
              Choose a {sessionTime} window for your team to do the {rtBrandName} Connection Kit together. Once you
              create the calendar invite, add your team and a video link or conference room if needed. The session can
              be done remotely or in-person, as long as everyone has a computer or mobile device and internet access.
            </div>
            {team.preview ? (
              <SessionPreviewMessage className="mb-xxs">
                To schedule a session, first <Link to="/admin?inviteLeads=true">add yourself as a team lead</Link>.
              </SessionPreviewMessage>
            ) : (
              !isDemoPreview && (
                <View
                  $alignItems="center"
                  $justifyContent="space-between"
                  $flexDirectionMobile="column"
                  $alignItemsMobile="flex-start"
                  $gap="var(--spacing-2)"
                >
                  <ScheduleForm kitInstance={kitInstance} kitInfo={kitInstance.kit} team={team} className="pb-xxs" />
                  {!!canSelectFacilitator && (
                    <FacilitatorSelectForm kitInstance={kitInstance} team={team} options={team.members} />
                  )}
                </View>
              )
            )}
          </div>

          <div className="border-radius-small border-left-thick border-orange-3 box-shadow px-large py-medium mt-small mb-large">
            <View $alignItems="center" $justifyContent="space-between" className="pt-xs">
              <div className="step-title text-gray-9 text-bold">
                <h2 className="pr-small text-orange-3">Prep</h2>
              </div>
              <h4 className="step-time text-semi-bold text-orange-3 pr-xxs">{kitInstance.kit.prep_time} mins</h4>
            </View>
            <div className="text-gray-9 pt-medium pb-medium">{kitInstance.kit.prep_text}</div>
            {kitInstance.kit.prep_links.map((prep_link) => (
              <View key={prep_link.title}>
                <div className="icon-container">
                  <EarIcon color="var(--gray-9)" className="ear-icon" />
                </div>
                <div className="ml-medium mb-xs">
                  {prep_link.slug ? (
                    <Link to={`${kitInstance.prep_url}articles/${prep_link.slug}`}>Read or listen: </Link>
                  ) : (
                    <a target="_blank" rel="noopener noreferrer" href={prep_link.external_url}>
                      Read or listen:{" "}
                    </a>
                  )}
                  {prep_link.title}
                </div>
              </View>
            ))}
            {!!kitInstance.kit.screencast_id && (
              <View className="text-normal text-gray-9">
                <div className="icon-container">
                  <PlayCircleIcon color="var(--gray-9)" />
                </div>
                <div className="ml-medium mb-xs">
                  <Link to={`${kitInstance.prep_url}video-walkthrough`}>Watch a quick video</Link> on how to lead the
                  session with your team.
                </div>
              </View>
            )}
            {!!isLeadOrColead && (
              <View>
                <div className="icon-container">
                  <PaperPlaneIcon color="var(--gray-9)" />
                </div>
                <div className="ml-medium mb-xs">
                  <Link to={kitInstance.session_preview_url}>Preview the session </Link>
                  {kitInstance.kit.session_preview_custom_text || "to walk through the details on each page."}
                </div>
              </View>
            )}
            {!!kitInstance.kit.has_standalone_exercise && (
              <View>
                <div className="icon-container">
                  <PenAltIcon color="var(--gray-9)" />
                </div>
                <div className="ml-medium mb-xs">
                  <Link to={exerciseUrl}>Do the exercise in advance</Link> to complete the interactive activity on your
                  own.
                </div>
              </View>
            )}
            {!!showBookCall && (
              <div>
                <p className="text-semi-bold mt-large">Have questions?</p>
                <p>
                  <Button className="link-blue text-thin" onClick={onBookCallClick}>
                    Book a call
                  </Button>{" "}
                  with one of our experts. We're here to help!
                </p>
              </div>
            )}
          </div>
          <div className="border-radius-small border-left-thick border-rising-green box-shadow px-large py-medium mt-small">
            <View $alignItems="center" $justifyContent="space-between" className="pt-xs">
              <div className="step-title text-gray-9 text-bold">
                <h1 className="text-rising-green">GO</h1>
              </div>
              <h4 className="step-time text-semi-bold text-rising-green pr-xxs">{goTime}</h4>
            </View>
            <div className="text-gray-9 pt-medium">
              All you (and your team) need to do is show up (in person or virtually) and bring a laptop or mobile device
              with internet access.
            </div>
            {!!team.preview && (
              <SessionPreviewMessage className="mt-small">
                To run a session, first <Link to="/admin?inviteLeads=true">add yourself as a team lead</Link>.
              </SessionPreviewMessage>
            )}
            <View
              $alignItems="center"
              $alignItemsMobile="flex-start"
              $flexDirectionMobile="column"
              className="mt-small"
            >
              {!previewMode && !!isLeadOrColead && (
                <Button as={Link} to={!coleadsEnabled ? kitInstance.session_start_url : kitInstance.session_url}>
                  Let's go
                  <ArrowRightIcon />
                </Button>
              )}
              {!!isLeadOrColead ? (
                <Link
                  to={kitInstance.session_preview_url}
                  className={cn("text-semi-bold", {
                    "ml-medium": isDesktopOrLarger && !previewMode,
                    "mt-xs": !isDesktopOrLarger && !previewMode,
                  })}
                >
                  Preview the session
                </Link>
              ) : (
                <div>The facilitator will start the session here.</div>
              )}
            </View>
          </div>
          {showBookCall ? (
            <div className="mt-xl">
              <Link to={`/kit?team_id=${team.id}`} className="text-semi-bold">
                See all Rising Team kits
              </Link>
            </div>
          ) : (
            <div className="mt-xl">
              <h4>Still have questions?</h4>
              <div className="text-gray-9 pt-xs">
                <a href="https://app.risingteam.com/slack/invite" target="_blank" rel="noopener noreferrer">
                  Ask our community of managers
                </a>{" "}
                and coaches in the Huddle on Slack.
              </div>
            </div>
          )}
        </div>
      </FlairContainer>
    </main>
  )
}

export default styled(ConnectionBoostPrepHome)`
  .icon-container {
    font-size: 1.25rem;
    padding-top: 2px;
  }

  .border-left-thick {
    border-top: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }

  .ear-icon {
    margin-right: 5px;
  }

  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    position: relative;

    .step-time {
      text-align: right;
    }

    .border-radius-small {
      border-radius: 2px;
    }

    .border-left-thick {
      border-top: none;
      border-right: none;
      border-bottom: none;
      margin-bottom: 40px;
      border-width: 5px;
    }
  }
`
