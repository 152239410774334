import { createContext, useContext } from "react"

import useEffectAfterChange from "ui/hooks/useEffectAfterChange"

const AccountBrandContext = createContext({
  accountBrand: null,
  setAccountBrand: () => {},
})

const useAccountBrand = () => useContext(AccountBrandContext) || {}

const useSetAccountBrand = ({ accountBrand, shouldSet, setAccountBrand }) => {
  useEffectAfterChange(() => {
    if (shouldSet) {
      setAccountBrand(accountBrand)
    }
  }, [shouldSet, accountBrand, setAccountBrand])
}

const useSetAccountBrandByTeam = (team) => {
  const { setAccountBrand } = useAccountBrand()
  useSetAccountBrand({ ...(team?.account_brand || {}), shouldSet: !!team, setAccountBrand })
}

export { AccountBrandContext, useAccountBrand, useSetAccountBrand, useSetAccountBrandByTeam }
