import { Route, Routes, Outlet, Navigate, useNavigate, useParams } from "react-router-dom"

import { useStartKitSession } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useKitInstanceByID } from "ui/SelectedTeamContext"
import { useHasTeamFeature } from "utils/team"

const StartSessionMain = () => {
  const { id } = useParams()
  const { kitInstance, isFetching: kitInstanceIsFetching } = useKitInstanceByID(id)
  const { data: user } = useUser({ userId: "me" })
  const { data: team } = useTeam({ teamId: kitInstance?.team_id })

  if (!kitInstance) {
    return kitInstanceIsFetching ? <Loading /> : <Navigate to="/code" replace />
  }

  if (!user || !team) {
    return null
  }

  if (team.preview) {
    return <Navigate to="/404" replace />
  }
  if (user.id !== kitInstance.facilitator_id) {
    return <Navigate to="/code" replace />
  }

  return <StartSessionTeamLead kitInstance={kitInstance} team={team} />
}

const StartSessionTeamLead = ({ kitInstance, team }) => {
  const { mutateAsync: startKitSession } = useStartKitSession()
  const navigate = useNavigate()
  const { enabled: coleadsEnabled, isInitialLoading: coleadsFeatureIsLoading } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_COLEADS
  )
  useEffectAfterChange(() => {
    async function startAndRedirect() {
      if (coleadsFeatureIsLoading) {
        return
      }
      if (!coleadsEnabled) {
        await startKitSession({ kitInstanceId: kitInstance.id })
      }
      navigate(kitInstance.session_url, { replace: true })
    }
    startAndRedirect()
  }, [kitInstance, startKitSession, navigate, coleadsEnabled, coleadsFeatureIsLoading])

  return <Loading />
}

const StartSessionLayout = () => (
  <div className="main-container full-width">
    <Outlet />
  </div>
)

const StartSession = () => (
  <Routes>
    <Route element={<StartSessionLayout />}>
      <Route path="/:id" element={<StartSessionMain />} />
    </Route>
  </Routes>
)

export default StartSession
