import cn from "classnames"
import type { ReactNode } from "react"

import SubmitButton from "ui/SubmitButton"
import { colors } from "ui/theme"

interface SaveButtonProps {
  className?: string
  children?: ReactNode
  saving?: boolean | string
  saved?: boolean | string
  error?: boolean | string
}

const SaveButton = ({ className, children, saving = false, saved = false, error = false }: SaveButtonProps) => {
  saving = saving === true ? "Saving" : saving
  saved = saved === true ? "Saved" : saved
  error = error === true ? "Error" : error

  let buttonColor = colors.risingBlue
  if (saving) {
    buttonColor = colors.gray6
  } else if (saved) {
    buttonColor = colors.risingGreen
  } else if (error) {
    buttonColor = colors.danger
  }

  return (
    <SubmitButton
      color={buttonColor}
      className={cn(className, "link-semi-bold p-xs mt-xxs", {
        disabled: saving || saved || error,
      })}
      isSubmitting={!error && !!saving}
      labelSubmitting={(!error && saving) || null}
      label={error || saved || null}
    >
      {children}
    </SubmitButton>
  )
}

export default SaveButton
