import DOMPurify from "dompurify"
import React from "react"

import { BaseInputFieldProps } from "./BaseInputField"

import ArtiInSessionButton from "domains/Arti/ArtiInSessionComponents/ArtiInSessionButton"

interface WithArtiProps {
  enableInSessionArti?: boolean
  initialChoiceMessage?: string
  initialChatType?: string
  initialMessage?: string
  isCopyOption?: boolean
}

function withArti<P extends BaseInputFieldProps>(BaseComponent: React.ComponentType<P>) {
  const WithArtiComponent = ({
    enableInSessionArti = false,
    initialChoiceMessage,
    initialChatType,
    initialMessage,
    isCopyOption,
    ...props
  }: P & WithArtiProps) => {
    const renderAfterField = ({
      name,
      saveOnChange,
    }: {
      name: string
      saveOnChange?: (name: string, value: string) => void
    }) => {
      if (!enableInSessionArti) {
        return null
      }

      const onArtiResponseSelect = (value: string) => {
        const div = document.createElement("div")
        div.innerHTML = DOMPurify.sanitize(value)
        const textContent = div.innerText || ""

        if (saveOnChange) {
          saveOnChange(name, textContent)
        }
      }

      return (
        <ArtiInSessionButton
          onArtiResponseSelect={onArtiResponseSelect}
          initialChoiceMessage={initialChoiceMessage}
          initialChatType={initialChatType}
          initialMessage={initialMessage}
          className="mt-xs"
          isCopyOption={isCopyOption}
        />
      )
    }

    return <BaseComponent {...(props as P)} renderAfterField={renderAfterField} />
  }

  WithArtiComponent.displayName = `WithArti(${BaseComponent.displayName || BaseComponent.name})`
  return WithArtiComponent
}

export type { WithArtiProps }
export default withArti
