import { find, shuffle, first, last, uniq, keyBy } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import PrintButton from "components/PrintButton"
import { getChartConfig } from "domains/Exercise/results_utils/talents"
import TalentCoverage from "domains/Exercise/ResultsComponents/TalentCoverage"
import TalentMashupCompareUsersForm from "domains/Exercise/ResultsComponents/TalentMashupCompareUsersForm"
import TalentOverlaps from "domains/Exercise/ResultsComponents/TalentOverlaps"
import { StarShootingIcon } from "icons/FontAwesomeIcons"
import { useLatestTeamExerciseInstances } from "resources/exercise"
import { sortUsersByShortName } from "resources/users"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import RadarChart from "ui/RadarChart"
import View from "ui/View"

const TalentsResultsReviewMashup = ({ kit, selectedTeam }) => {
  const query = useQueryParams()
  const { data: exerciseInstances, isFetching } = useLatestTeamExerciseInstances({
    teamId: selectedTeam.id,
    exerciseSlug: kit.exercise.slug,
  })

  if (isFetching) {
    return <Loading />
  }

  const initialUserId = parseInt(query.participant)

  return (
    <>
      <View $alignItems="center" className="mb-medium">
        <StarShootingIcon className="text-rising-yellow title-icon mr-medium" />
        <h2 className="text-gray-9">Talent Mashup</h2>
        <PrintButton className="ml-auto" />
      </View>
      <p className="text-gray-9 mb-xxxxl">
        Here is a fun way to explore where the natural talents of two team members are similar and where they complement
        each other.
      </p>
      <MashupResults team={selectedTeam} exerciseInstances={exerciseInstances} initialUserId={initialUserId} />
    </>
  )
}

const MashupResults = styled(function MashupResults({ exerciseInstances, team, initialUserId, className }) {
  const users = team.members.filter((user) => exerciseInstances.find((exercise) => exercise.user_id === user.id))
  const initialUser = find(users, { id: initialUserId })

  const sortedUsers = sortUsersByShortName({ users })
  const shuffledUsers = shuffle(sortedUsers)
  const [selectedUser1, setSelectedUser1] = useState(initialUser ?? first(shuffledUsers))
  const [selectedUser2, setSelectedUser2] = useState(last(shuffledUsers))

  const exerciseInstance1 = find(exerciseInstances, (exercise) => exercise.user_id === selectedUser1.id)
  const exerciseInstance2 = find(exerciseInstances, (exercise) => exercise.user_id === selectedUser2.id)
  const exercisesToCompare = uniq([exerciseInstance1, exerciseInstance2].filter((x) => !!x))

  const userIdMap = keyBy(users, "id")
  const chartConfig = getChartConfig(exercisesToCompare, userIdMap)

  return (
    <div className={className}>
      <TalentMashupCompareUsersForm
        selectedUser1={selectedUser1}
        selectedUser2={selectedUser2}
        setSelectedUser1={setSelectedUser1}
        setSelectedUser2={setSelectedUser2}
        sortedUsers={sortedUsers}
      />
      <View className="talents-analyse-container" $alignItems="center">
        <View className="talents-chart">
          <RadarChart {...chartConfig} showLegend={false} className="mb-xl" />
        </View>
        <View className="talents-coverage">
          <TalentCoverage userIdMap={userIdMap} exercisesToCompare={exercisesToCompare} />
        </View>
      </View>
      <TalentOverlaps userIdMap={userIdMap} exercisesToCompare={exercisesToCompare} />
    </div>
  )
})`
  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    .talents-analyse-container {
      flex-direction: column;
    }
  }

  @media (min-width: ${({ theme }) => theme.tabletMin}) {
    .talents-analyse-container {
      flex-direction: row;
    }

    .talents-chart {
      flex: 2;
      margin-right: var(--spacing-5);
    }

    .talents-coverage {
      flex: 1;
    }
  }
`

export default TalentsResultsReviewMashup
