import cn from "classnames"
import { styled } from "styled-components"

import ExerciseTextareaField from "domains/Exercise/ExerciseComponents/ExerciseTextareaField"
import { StarIcon, TrashCanIcon } from "icons/FontAwesomeIcons"
import { colors } from "ui/theme"
import Tooltip from "ui/Tooltip"
import View from "ui/View"

const EditableStickyNotes = ({ name, stickyNotes, handleOnRemove = () => {}, ...props }) =>
  !!stickyNotes &&
  stickyNotes.map((_, stickyNoteIndex) => {
    const id = `${name}.${stickyNoteIndex}`
    return (
      <EditableStickyNote
        key={id}
        id={id}
        name={id}
        title={`Idea ${stickyNoteIndex + 1}`}
        handleOnRemove={() => handleOnRemove(stickyNoteIndex)}
        {...props}
      />
    )
  })

const EditableStickyNote = styled(function EditableStickyNote({ className, title, handleOnRemove, ...props }) {
  return (
    <>
      <View
        $flexDirection="column"
        $gap="8px"
        $maxHeight="310px"
        $minHeight="310px"
        $maxWidth="256px"
        className={cn(className, "p-medium", "bg-yellow-off-tint", "blur-4", "border-radius-xxs")}
      >
        <View $justifyContent="space-between" $alignItems="center">
          <p className="text-semi-bold text-gray-9 text-center">{title}</p>
          <TrashCanIcon onClick={handleOnRemove} className="text-gray-9 text-right icon" />
        </View>
        <ExerciseTextareaField
          className="sticky-note-text blur-4"
          placeholder="For example: We are all one team."
          {...props}
        />
      </View>
    </>
  )
})`
  .sticky-note-text {
    div {
      background-color: ${colors.yellowTint};
    }

    textarea {
      min-height: 205px;
      max-height: 205px;
      background-color: ${colors.yellowTint};
    }
  }

  .icon:hover {
    cursor: pointer;
  }
`
const ShareoutStickyNotes = ({ name, stickyNotes, className }) =>
  !!stickyNotes &&
  stickyNotes.map(({ value: stickyNote }, stickyNoteIndex) => {
    const id = `${name}.${stickyNoteIndex}`
    return (
      <ShareoutStickyNote
        key={id}
        title={`Idea ${stickyNoteIndex + 1}`}
        stickyNote={stickyNote}
        className={className}
      />
    )
  })

const ShareoutStickyNote = styled(function ShareoutStickyNote({ title, stickyNote, className }) {
  return (
    <>
      <View
        $flexDirection="column"
        $gap="8px"
        $minHeight="310px"
        $maxHeight="310px"
        $maxWidth="256px"
        className={cn(className, "p-medium", "bg-yellow-off-tint", "blur-4", "border-radius-xxs")}
      >
        <View $justifyContent="center" $alignItems="center">
          <p className="text-semi-bold text-gray-9">{title}</p>
        </View>
        <View $height="85%" $justify-content="center" $alignItems="flex-start" className="overflow-scroll-hide">
          <p className="text-prewrap">{stickyNote}</p>
        </View>
      </View>
    </>
  )
})`
  /* Hide scrollbar */
  .overflow-scroll-hide {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    -webkit-scrollbar {
      display: none;
    }
  }
`

const SelectFavoriteStickyNotes = ({ name, stickyNotes, handleOnFavoriteClick }) =>
  !!stickyNotes &&
  stickyNotes.map(({ value: stickyNote, isFavorite }, stickyNoteIndex) => {
    const id = `${name}.${stickyNoteIndex}`
    const color = !!isFavorite ? colors.risingOrange : colors.white
    return (
      <SelectFavoriteStickyNote
        key={id}
        stickyNote={stickyNote}
        color={color}
        stickyNoteIndex={stickyNoteIndex}
        handleOnFavoriteClick={handleOnFavoriteClick}
      />
    )
  })

const SelectFavoriteStickyNote = styled(function SelectFavoriteStickyNote({
  stickyNote,
  color,
  stickyNoteIndex,
  isShareoutPage = false,
  text = "Vote",
  className,
  handleOnFavoriteClick = () => {},
}) {
  return (
    <>
      <View
        $flexDirection="column"
        $alignItems="center"
        key={stickyNoteIndex}
        $maxHeight="310px"
        $minHeight="310px"
        $maxWidth="256px"
        className={cn(className, "text-center p-medium bg-yellow-off-tint border-radius-xxs")}
        $gap="8px"
        onClick={() => handleOnFavoriteClick(stickyNoteIndex)}
      >
        <View $gap="8px" $alignItems="center" $justifyContent="center" className="pb-small text-semi-bold">
          <StarIcon color={color} className="fa-xl" />
          {!isShareoutPage && <p>{text}</p>}
        </View>
        <View
          $height="85%"
          $justify-content="center"
          $alignItems="flex-start"
          className="text-left overflow-scroll-hide"
        >
          <p className="text-prewrap">{stickyNote}</p>
        </View>
      </View>
    </>
  )
})`
  /* Hide scrollbar */
  .overflow-scroll-hide {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    -webkit-scrollbar {
      display: none;
    }
  }
`

const EditableStickyNotesWithFavoritedUsers = ({
  name,
  stickyNotes,
  kitParticipants,
  isFacilitator,
  handleOnFavoriteClick,
  handleOnStickyNoteChange,
  ...props
}) =>
  !!stickyNotes &&
  stickyNotes.map(({ value: stickyNote, isFavorite, favoritedUsers }, stickyNoteIndex) => {
    const id = `${name}.${stickyNoteIndex}`
    const color = !!isFavorite ? colors.risingOrange : colors.white
    return (
      <EditableStickyNoteWithFavoritedUsers
        key={id}
        id={id}
        name={id}
        color={color}
        stickyNote={stickyNote}
        favoritedUsers={favoritedUsers}
        stickyNoteIndex={stickyNoteIndex}
        kitParticipants={kitParticipants}
        isFacilitator={isFacilitator}
        handleOnFavoriteClick={handleOnFavoriteClick}
        handleOnStickyNoteChange={handleOnStickyNoteChange}
        {...props}
      />
    )
  })

const EditableStickyNoteWithFavoritedUsers = styled(function EditableStickyNoteWithFavoritedUsers({
  className,
  color,
  stickyNoteIndex,
  stickyNote,
  favoritedUsers,
  kitParticipants,
  isFacilitator,
  handleOnFavoriteClick,
  handleOnStickyNoteChange,
  text = "Vote",
  ...props
}) {
  const favoritedUserNames = kitParticipants
    ?.filter((participantName) => favoritedUsers?.includes(participantName.id))
    ?.map((participantName) => participantName?.short_name)

  return (
    <View
      $flexDirection="column"
      $alignItems="center"
      key={stickyNoteIndex}
      $maxWidth="256px"
      className={cn(className, "text-left", "p-medium", "bg-yellow-off-tint", "blur-4", "border-radius-xxs")}
      $gap="8px"
    >
      <View $gap="8px" $alignItems="center" $justifyContent="center" className="text-semi-bold">
        {!!isFacilitator ? (
          <>
            <StarIcon color={color} className="fa-xl" onClick={() => handleOnFavoriteClick(stickyNoteIndex)} />
            <p>{text}</p>
          </>
        ) : (
          <StarIcon color={color} className="fa-xl" />
        )}
      </View>
      <View $justifyContent="center" $alignItems="center">
        {isFacilitator ? (
          <ExerciseTextareaField
            className="sticky-note-text"
            saveOnChange={() => handleOnStickyNoteChange()}
            {...props}
          />
        ) : (
          <View $minHeight="205px" $maxHeight="205px" className="overflow-scroll-hide">
            {stickyNote}
          </View>
        )}
      </View>
      <View $justifyContent="center" $alignItems="center" $gap="4px" $flexWrap="wrap">
        {!!favoritedUserNames &&
          favoritedUserNames.map((participantName, userIndex) => (
            <Tooltip float key={userIndex} title={participantName} className="fit-content" top>
              <View
                $alignItems="center"
                $justifyContent="center"
                className="bg-orange-4 text-small text-uppercase text-white border-radius-circle small-logo-icon"
              >
                <p>{participantName?.charAt(0)}</p>
              </View>
            </Tooltip>
          ))}
      </View>
    </View>
  )
})`
  .sticky-note-text {
    div {
      background-color: ${colors.yellowTint};
    }

    textarea {
      min-height: 205px;
      max-height: 205px;
      background-color: ${colors.yellowTint};
    }
  }

  /* Hide scrollbar */
  .overflow-scroll-hide {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    -webkit-scrollbar {
      display: none;
    }
  }
`

export { EditableStickyNotes, ShareoutStickyNotes, SelectFavoriteStickyNotes, EditableStickyNotesWithFavoritedUsers }
