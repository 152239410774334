import { createContext, useContext } from "react"

const ArtiChatContext = createContext({
  showSampleTeam: false,
  showSampleTeamButton: false,
  setShowSampleTeamButton: () => {},
  sampleTeamMembers: [],
})

const useArtiChatContext = () => useContext(ArtiChatContext) || {}

export { ArtiChatContext, useArtiChatContext }
