import { Formik, Form } from "formik"

import ExerciseRichTextField from "domains/Exercise/ExerciseComponents/ExerciseRichTextField"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { ExclamationCircleIcon } from "icons/FontAwesomeIcons"
import { useUser } from "resources/users"
import BulletedList from "ui/BulletedList"
import Loading from "ui/Loading"
import TextContainer from "ui/TextContainer"

const CrisisResponseScenarioThinkTeamLeadStep = ({ kitInstance, className }) => {
  const exercise = kitInstance?.kit?.exercise
  const { initialValues, onSubmit, saveOnChange, isFetching } = useExerciseForm({
    teamId: kitInstance?.team_id,
    slug: exercise?.slug,
    version: exercise?.version,
    teamLevelExercise: true,
  })
  const { data: user } = useUser({ userId: "me" })
  const { facilitator } = useKitSession()
  if (!user || isFetching) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <TextContainer maxWidth={660} className="text-gray-9 mr-xl">
          <h2 className="mb-medium">Think of a scenario</h2>
          <p className="mb-medium">
            Work as a team to identify a possible future crisis scenario that we will use to create a response plan.
          </p>
          <p className="mb-medium">
            Pick a scenario from your own workplace that is big enough to be interesting and has some potential
            constructive actions you can take.
          </p>

          <p className="mb-medium">Here are example scenarios that work well for this exercise:</p>
          <BulletedList className="mb-medium">
            <li>Your website goes down or gets hacked</li>
            <li>A critical team member leaves suddenly and there is no backup plan</li>
            <li>A project you invested a lot into gets cancelled to pursue something new</li>
          </BulletedList>

          <h3 className="mb-xs">Enter your team’s scenario here:</h3>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            <Form>
              <div>
                <p className="text-gray-9 text-bold mb-xs">
                  <ExclamationCircleIcon color="var(--rising-yellow)" className="mr-xs" />
                  Note: {facilitator.short_name} will fill this out on behalf of the group.
                </p>
                <ExerciseRichTextField name="team_scenario" saveOnChange={saveOnChange} />
              </div>
            </Form>
          </Formik>
        </TextContainer>
        <FacilitatorTip className="sidebar-right" icon="share-screen" tip="Read this screen out loud." />
      </SidebarContainer>
    </StepContent>
  )
}

export default CrisisResponseScenarioThinkTeamLeadStep
