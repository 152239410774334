function SvgLogoIcon(props) {
  return (
    <svg width="189" height="35" viewBox="0 0 189 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M128.009 26.9213C127.835 27.0975 127.754 27.3685 127.754 27.7343V28.4117H129.509V29.0621H127.781V34.1701H127.018V29.0621H126V28.4117H127.018V27.7072C127.018 27.1788 127.165 26.7588 127.473 26.4607C127.768 26.149 128.196 26 128.746 26C128.96 26 129.174 26.0271 129.375 26.0948C129.576 26.1626 129.75 26.2439 129.884 26.3658L129.629 26.9484C129.402 26.7588 129.121 26.6639 128.786 26.6639C128.424 26.6504 128.183 26.7452 128.009 26.9213Z"
        fill="#FB6211"
      />
      <path
        d="M131.29 33.8585C130.848 33.6011 130.5 33.2623 130.246 32.8152C129.991 32.3681 129.871 31.8668 129.871 31.2977C129.871 30.7422 129.991 30.2273 130.246 29.7802C130.5 29.3331 130.848 28.9944 131.29 28.7369C131.732 28.493 132.228 28.3711 132.777 28.3711C133.326 28.3711 133.821 28.493 134.263 28.7369C134.705 28.9808 135.054 29.3331 135.295 29.7802C135.549 30.2273 135.67 30.7286 135.67 31.2977C135.67 31.8532 135.549 32.3681 135.295 32.8152C135.04 33.2623 134.705 33.6146 134.263 33.8585C133.821 34.1159 133.326 34.2379 132.777 34.2379C132.228 34.2243 131.732 34.1024 131.29 33.8585ZM133.862 33.2623C134.183 33.0726 134.438 32.8152 134.612 32.4629C134.786 32.1242 134.88 31.7313 134.88 31.2977C134.88 30.8641 134.786 30.4712 134.612 30.1325C134.438 29.7938 134.183 29.5228 133.862 29.3331C133.54 29.1434 133.179 29.0485 132.777 29.0485C132.375 29.0485 132.013 29.1434 131.692 29.3331C131.371 29.5228 131.116 29.7802 130.929 30.1325C130.741 30.4712 130.647 30.8641 130.647 31.2977C130.647 31.7313 130.741 32.1242 130.929 32.4629C131.116 32.8017 131.371 33.0726 131.692 33.2623C132.013 33.452 132.375 33.5469 132.777 33.5469C133.179 33.5469 133.54 33.452 133.862 33.2623Z"
        fill="#FB6211"
      />
      <path
        d="M138.777 28.6692C139.138 28.4659 139.58 28.3711 140.103 28.3711V29.1298L139.915 29.1163C139.312 29.1163 138.844 29.306 138.509 29.6718C138.174 30.0376 138 30.5661 138 31.2435V34.1837H137.237V28.4253H137.973V29.5499C138.147 29.1569 138.415 28.8589 138.777 28.6692Z"
        fill="#FB6211"
      />
      <path
        d="M144.616 34.0347C144.187 33.8993 143.866 33.7367 143.625 33.5199L143.973 32.9102C144.214 33.0999 144.509 33.2489 144.871 33.3708C145.232 33.4928 145.607 33.547 145.996 33.547C146.518 33.547 146.893 33.4657 147.147 33.3031C147.402 33.1405 147.522 32.9102 147.522 32.6121C147.522 32.3953 147.455 32.2327 147.321 32.1108C147.187 31.9888 147.013 31.894 146.799 31.8398C146.585 31.772 146.317 31.7178 145.969 31.6637C145.513 31.5824 145.138 31.4875 144.857 31.3927C144.576 31.2978 144.335 31.1488 144.134 30.932C143.933 30.7152 143.839 30.4036 143.839 30.0242C143.839 29.5364 144.04 29.1435 144.429 28.8454C144.83 28.5338 145.379 28.3848 146.089 28.3848C146.451 28.3848 146.826 28.439 147.187 28.5338C147.549 28.6287 147.857 28.7641 148.098 28.9267L147.763 29.55C147.295 29.2248 146.732 29.0622 146.089 29.0622C145.594 29.0622 145.232 29.1435 144.978 29.3197C144.723 29.4958 144.603 29.7261 144.603 30.0107C144.603 30.2274 144.67 30.4036 144.817 30.5391C144.964 30.6746 145.138 30.7694 145.339 30.8236C145.554 30.8914 145.835 30.9455 146.21 31.0133C146.665 31.0946 147.027 31.1894 147.308 31.2707C147.576 31.352 147.817 31.5146 148.018 31.7178C148.219 31.9346 148.312 32.2192 148.312 32.5985C148.312 33.0999 148.112 33.5063 147.696 33.7909C147.281 34.0889 146.705 34.238 145.969 34.238C145.473 34.2244 145.031 34.1567 144.616 34.0347Z"
        fill="#FB6211"
      />
      <path
        d="M150.549 33.8585C150.107 33.6146 149.759 33.2623 149.504 32.8152C149.25 32.3681 149.129 31.8668 149.129 31.2977C149.129 30.7422 149.25 30.2273 149.504 29.7802C149.759 29.3331 150.107 28.9944 150.549 28.7369C150.991 28.493 151.5 28.3711 152.062 28.3711C152.558 28.3711 152.987 28.4659 153.375 28.6556C153.763 28.8453 154.071 29.1298 154.286 29.5092L153.71 29.9021C153.522 29.6176 153.281 29.4008 153 29.2653C152.705 29.1163 152.397 29.0485 152.062 29.0485C151.647 29.0485 151.286 29.1434 150.951 29.3331C150.616 29.5228 150.362 29.7802 150.187 30.1325C150 30.4712 149.906 30.8641 149.906 31.2977C149.906 31.7448 150 32.1377 150.187 32.4765C150.375 32.8152 150.629 33.0726 150.951 33.2623C151.286 33.452 151.647 33.5469 152.062 33.5469C152.397 33.5469 152.719 33.4791 153 33.3436C153.295 33.2081 153.522 32.9913 153.71 32.7068L154.286 33.0997C154.058 33.4656 153.75 33.7501 153.362 33.9533C152.973 34.143 152.531 34.2379 152.049 34.2379C151.5 34.2243 150.991 34.1024 150.549 33.8585Z"
        fill="#FB6211"
      />
      <path
        d="M160.312 29.0078C160.741 29.4278 160.942 30.0375 160.942 30.8369V34.17H160.179V30.9047C160.179 30.3085 160.031 29.8478 159.737 29.5362C159.442 29.2246 159.013 29.062 158.464 29.062C157.848 29.062 157.366 29.2517 157.004 29.6175C156.643 29.9833 156.469 30.4982 156.469 31.1485V34.17H155.705V26.0405H156.469V29.4143C156.683 29.0755 156.964 28.8181 157.339 28.642C157.701 28.4658 158.129 28.371 158.612 28.371C159.321 28.371 159.897 28.5878 160.312 29.0078Z"
        fill="#FB6211"
      />
      <path
        d="M163.902 33.8585C163.46 33.6011 163.112 33.2623 162.857 32.8152C162.603 32.3681 162.482 31.8668 162.482 31.2977C162.482 30.7422 162.603 30.2273 162.857 29.7802C163.112 29.3331 163.46 28.9944 163.902 28.7369C164.344 28.493 164.839 28.3711 165.388 28.3711C165.938 28.3711 166.433 28.493 166.875 28.7369C167.317 28.9808 167.665 29.3331 167.906 29.7802C168.161 30.2273 168.281 30.7286 168.281 31.2977C168.281 31.8532 168.161 32.3681 167.906 32.8152C167.652 33.2623 167.317 33.6146 166.875 33.8585C166.433 34.1159 165.938 34.2379 165.388 34.2379C164.839 34.2243 164.344 34.1024 163.902 33.8585ZM166.473 33.2623C166.795 33.0726 167.049 32.8152 167.223 32.4629C167.397 32.1242 167.491 31.7313 167.491 31.2977C167.491 30.8641 167.397 30.4712 167.223 30.1325C167.049 29.7938 166.795 29.5228 166.473 29.3331C166.152 29.1434 165.79 29.0485 165.388 29.0485C164.987 29.0485 164.625 29.1434 164.304 29.3331C163.982 29.5228 163.728 29.7802 163.54 30.1325C163.353 30.4712 163.259 30.8641 163.259 31.2977C163.259 31.7313 163.353 32.1242 163.54 32.4629C163.728 32.8017 163.982 33.0726 164.304 33.2623C164.625 33.452 164.987 33.5469 165.388 33.5469C165.79 33.5469 166.152 33.452 166.473 33.2623Z"
        fill="#FB6211"
      />
      <path
        d="M170.692 33.8585C170.25 33.6011 169.902 33.2623 169.647 32.8152C169.393 32.3681 169.272 31.8668 169.272 31.2977C169.272 30.7422 169.393 30.2273 169.647 29.7802C169.902 29.3331 170.25 28.9944 170.692 28.7369C171.134 28.493 171.63 28.3711 172.179 28.3711C172.728 28.3711 173.223 28.493 173.665 28.7369C174.107 28.9808 174.455 29.3331 174.697 29.7802C174.951 30.2273 175.072 30.7286 175.072 31.2977C175.072 31.8532 174.951 32.3681 174.697 32.8152C174.442 33.2623 174.107 33.6146 173.665 33.8585C173.223 34.1159 172.728 34.2379 172.179 34.2379C171.63 34.2243 171.134 34.1024 170.692 33.8585ZM173.264 33.2623C173.585 33.0726 173.839 32.8152 174.014 32.4629C174.188 32.1242 174.281 31.7313 174.281 31.2977C174.281 30.8641 174.188 30.4712 174.014 30.1325C173.839 29.7938 173.585 29.5228 173.264 29.3331C172.942 29.1434 172.58 29.0485 172.179 29.0485C171.777 29.0485 171.415 29.1434 171.094 29.3331C170.772 29.5228 170.518 29.7802 170.33 30.1325C170.143 30.4712 170.049 30.8641 170.049 31.2977C170.049 31.7313 170.143 32.1242 170.33 32.4629C170.518 32.8017 170.772 33.0726 171.094 33.2623C171.415 33.452 171.777 33.5469 172.179 33.5469C172.58 33.5469 172.942 33.452 173.264 33.2623Z"
        fill="#FB6211"
      />
      <path d="M176.625 26.0542H177.388V34.1837H176.625V26.0542Z" fill="#FB6211" />
      <path
        d="M179.745 34.0347C179.317 33.8993 178.995 33.7367 178.754 33.5199L179.103 32.9102C179.344 33.0999 179.638 33.2489 180 33.3708C180.362 33.4928 180.737 33.547 181.125 33.547C181.647 33.547 182.022 33.4657 182.277 33.3031C182.531 33.1405 182.652 32.9102 182.652 32.6121C182.652 32.3953 182.585 32.2327 182.451 32.1108C182.317 31.9888 182.143 31.894 181.929 31.8398C181.714 31.772 181.446 31.7178 181.098 31.6637C180.643 31.5824 180.268 31.4875 179.987 31.3927C179.705 31.2978 179.464 31.1488 179.263 30.932C179.062 30.7152 178.969 30.4036 178.969 30.0242C178.969 29.5364 179.17 29.1435 179.558 28.8454C179.96 28.5338 180.509 28.3848 181.219 28.3848C181.58 28.3848 181.955 28.439 182.317 28.5338C182.678 28.6287 182.987 28.7641 183.228 28.9267L182.893 29.55C182.424 29.2248 181.862 29.0622 181.219 29.0622C180.723 29.0622 180.362 29.1435 180.107 29.3197C179.853 29.4958 179.732 29.7261 179.732 30.0107C179.732 30.2274 179.799 30.4036 179.946 30.5391C180.094 30.6746 180.268 30.7694 180.469 30.8236C180.683 30.8914 180.964 30.9455 181.339 31.0133C181.795 31.0946 182.156 31.1894 182.437 31.2707C182.705 31.352 182.946 31.5146 183.147 31.7178C183.348 31.9346 183.442 32.2192 183.442 32.5985C183.442 33.0999 183.241 33.5063 182.826 33.7909C182.411 34.0889 181.835 34.238 181.098 34.238C180.603 34.2244 180.161 34.1567 179.745 34.0347Z"
        fill="#FB6211"
      />
      <path
        d="M19.2659 22.8461C18.5511 22.1496 17.3995 22.1496 16.6449 22.8461L13.3489 26.0577C12.634 26.7542 12.634 27.8763 13.3489 28.6115L16.6449 31.823C17.3598 32.5195 18.5114 32.5195 19.2659 31.823L22.562 28.6115C23.2768 27.915 23.2768 26.7928 22.562 26.0577L19.2659 22.8461Z"
        fill="#FB6211"
      />
      <path d="M28.0859 11.1449L23.0264 16.2114L26.1336 19.3229L31.1932 14.2564L28.0859 11.1449Z" fill="#FF9F0A" />
      <path d="M24.0483 7.07854L18.9888 12.145L22.041 15.2014L27.1006 10.1349L24.0483 7.07854Z" fill="#FE7A00" />
      <path
        d="M18.0024 11.1706L23.0578 6.10828L21.5023 4.55064L19.5968 2.64253C18.7412 1.78582 17.3413 1.78582 16.4857 2.64253L14.5802 4.55064L2.64165 16.4277C1.78612 17.2844 1.78612 18.6862 2.64165 19.5429L4.54715 21.451C5.40269 22.3077 6.80265 22.3077 7.65818 21.451L18.0024 11.1706Z"
        fill="#FB6211"
      />
      <path
        d="M33.3632 16.4275L32.1965 15.2593L27.1411 20.3216L28.3077 21.4898C29.1633 22.3465 30.5632 22.3465 31.4188 21.4898L33.3243 19.5817C34.2187 18.6861 34.2187 17.2842 33.3632 16.4275Z"
        fill="#FFD257"
      />
      <path
        d="M56.3182 20.068H54.4854C54.2925 20.068 54.196 20.2136 54.196 20.3592V23.7087C54.196 23.9029 54.0513 24 53.9067 24H50.2894C50.0965 24 50 23.8544 50 23.7087V10.6019C50 9.72816 50.7235 9 51.5916 9H56.8487C58.1991 9 59.3567 9.24272 60.3695 9.67961C61.3824 10.1165 62.1058 10.7476 62.6846 11.6214C63.2151 12.4466 63.5045 13.4175 63.5045 14.5825C63.5045 15.6505 63.2633 16.6214 62.7328 17.3981C62.2987 18.1262 61.6717 18.7087 60.8518 19.1456C60.7071 19.2427 60.6589 19.4369 60.7554 19.5825L63.4563 23.5146C63.6009 23.7087 63.4563 24 63.2151 24H59.4531C59.3567 24 59.2602 23.9515 59.212 23.8544L56.704 20.165C56.5111 20.1165 56.4146 20.068 56.3182 20.068ZM58.4885 12.9806C58.0545 12.5922 57.3792 12.3981 56.5111 12.3981H54.4854C54.2925 12.3981 54.196 12.5437 54.196 12.6893V16.4757C54.196 16.6699 54.3407 16.767 54.4854 16.767H56.5111C57.3792 16.767 58.0062 16.5728 58.4885 16.1845C58.9226 15.7961 59.1638 15.2621 59.1638 14.5825C59.1638 13.9029 58.9226 13.3689 58.4885 12.9806Z"
        fill="#484848"
      />
      <path
        d="M73.5346 23.6629C72.7147 23.4685 72.0395 23.2256 71.509 22.9341C71.3643 22.8369 71.316 22.6912 71.3643 22.5454L72.3289 20.3106C72.4253 20.1649 72.6183 20.0677 72.763 20.1649C73.2453 20.4078 73.7276 20.6021 74.3546 20.7965C75.078 20.9908 75.8015 21.088 76.5249 21.088C77.2001 21.088 77.6342 21.0394 77.9236 20.8936C78.213 20.7479 78.3577 20.5535 78.3577 20.3106C78.3577 20.0677 78.213 19.8734 77.8754 19.7762C77.5378 19.679 77.0072 19.5819 76.2838 19.4847C75.3674 19.3875 74.5957 19.2418 73.9205 19.0475C73.2935 18.8531 72.7147 18.5131 72.2324 18.0272C71.7501 17.5414 71.509 16.8126 71.509 15.9382C71.509 15.2094 71.7501 14.5292 72.1842 13.9463C72.6183 13.3633 73.2453 12.926 74.1134 12.5859C74.9333 12.2458 75.9462 12.1001 77.1519 12.1001C77.9718 12.1001 78.84 12.1973 79.6599 12.343C80.3833 12.4888 80.9621 12.6831 81.4444 12.926C81.5891 12.9746 81.6373 13.1689 81.5891 13.3147L80.6245 15.5009C80.5763 15.6467 80.3833 15.7438 80.1904 15.6467C79.274 15.1608 78.2612 14.9665 77.1519 14.9665C76.5249 14.9665 76.0426 15.0637 75.7532 15.2094C75.4639 15.3552 75.3192 15.5495 75.3192 15.7924C75.3192 16.0839 75.4638 16.2297 75.8015 16.3754C76.1391 16.4726 76.6696 16.5697 77.4413 16.7155C78.4059 16.8612 79.1776 17.007 79.8046 17.2013C80.4316 17.3956 80.9621 17.7357 81.4444 18.2216C81.9267 18.7074 82.1196 19.3875 82.1196 20.3106C82.1196 21.0394 81.8785 21.6709 81.4444 22.2539C81.0103 22.8369 80.3351 23.2742 79.5152 23.6143C78.647 23.9543 77.6342 24.1001 76.4285 24.1001C75.4156 24.0029 74.451 23.8572 73.5346 23.6629Z"
        fill="#484848"
      />
      <path
        d="M101.462 13.4992C102.33 14.3918 102.764 15.681 102.764 17.4662V23.9124C102.764 24.1108 102.619 24.21 102.475 24.21H99.0503C98.8574 24.21 98.7609 24.0612 98.7609 23.9124V18.1604C98.7609 16.524 98.1339 15.7306 96.8799 15.7306C96.2047 15.7306 95.6259 15.9786 95.1919 16.4248C94.7578 16.8711 94.5649 17.6149 94.5649 18.5075V23.9124C94.5649 24.1108 94.4202 24.21 94.2755 24.21H90.8511C90.6582 24.21 90.5618 24.0612 90.5618 23.9124V12.7058C90.5618 12.5075 90.7065 12.4083 90.8511 12.4083H94.0826C94.2755 12.4083 94.372 12.5571 94.372 12.7058V13.6976C94.806 13.2017 95.3848 12.8546 96.0118 12.6067C96.6388 12.3587 97.314 12.21 98.0375 12.21C99.4361 12.21 100.594 12.6562 101.462 13.4992Z"
        fill="#484848"
      />
      <path
        d="M117.569 12.4417C117.762 12.4417 117.858 12.5866 117.858 12.7315V21.8594C117.858 23.9845 117.28 25.6266 116.122 26.6891C114.965 27.7516 113.277 28.2828 111.106 28.2828C109.949 28.2828 108.888 28.138 107.923 27.8965C107.055 27.655 106.283 27.3652 105.608 26.9305C105.463 26.834 105.415 26.6891 105.512 26.5442L106.669 24.2743C106.766 24.1294 106.958 24.0811 107.103 24.1777C107.537 24.4674 108.02 24.7089 108.598 24.8538C109.322 25.0953 109.997 25.1919 110.72 25.1919C111.781 25.1919 112.553 24.9504 113.084 24.4674C113.566 23.9845 113.807 23.3083 113.807 22.3907V22.0043C113.035 22.922 111.878 23.3566 110.383 23.3566C109.37 23.3566 108.405 23.1151 107.537 22.6805C106.669 22.1975 105.994 21.5697 105.463 20.7003C104.933 19.8793 104.692 18.8651 104.692 17.7543C104.692 16.6435 104.933 15.6775 105.463 14.8565C105.994 14.0355 106.669 13.3593 107.537 12.8763C108.405 12.3934 109.37 12.2002 110.383 12.2002C112.023 12.2002 113.228 12.7315 114 13.794V12.6832C114 12.49 114.145 12.3934 114.289 12.3934H117.569V12.4417ZM113.132 19.4929C113.614 19.0583 113.855 18.4787 113.855 17.8026C113.855 17.1264 113.614 16.5469 113.132 16.1122C112.65 15.6775 112.071 15.4843 111.347 15.4843C110.624 15.4843 109.997 15.6775 109.515 16.1122C109.032 16.5469 108.791 17.1264 108.791 17.8026C108.791 18.4787 109.032 19.0583 109.515 19.4929C109.997 19.9276 110.576 20.1691 111.299 20.1691C112.071 20.1691 112.65 19.9276 113.132 19.4929Z"
        fill="#484848"
      />
      <path
        d="M84.1452 12.4531H87.5695C87.7624 12.4531 87.8589 12.5999 87.8589 12.7467V23.8066C87.8589 24.0023 87.7142 24.1002 87.5695 24.1002H84.1452C83.9522 24.1002 83.8558 23.9534 83.8558 23.8066V12.7467C83.8076 12.5999 83.9522 12.4531 84.1452 12.4531Z"
        fill="#484848"
      />
      <path
        d="M84.3405 10.647C83.9546 10.3189 83.7617 9.85013 83.7617 9.38138C83.7617 8.91262 83.9546 8.44386 84.3405 8.11573C84.7263 7.7876 85.2086 7.6001 85.7874 7.6001C86.3662 7.6001 86.8967 7.74072 87.2343 8.06886C87.6202 8.39699 87.8131 8.81887 87.8131 9.3345C87.8131 9.85013 87.6202 10.3189 87.2343 10.647C86.8485 10.9752 86.3662 11.1627 85.7874 11.1627C85.2086 11.1627 84.7263 10.9752 84.3405 10.647Z"
        fill="#484848"
      />
      <path
        d="M66.1452 12.1001H69.5695C69.7624 12.1001 69.8589 12.2514 69.8589 12.4026V23.7976C69.8589 23.9993 69.7142 24.1001 69.5695 24.1001H66.1452C65.9522 24.1001 65.8558 23.9488 65.8558 23.7976V12.4026C65.8076 12.2514 65.9522 12.1001 66.1452 12.1001Z"
        fill="#484848"
      />
      <path
        d="M66.3405 10.7394C65.9546 10.4013 65.7617 9.91832 65.7617 9.43535C65.7617 8.95239 65.9546 8.46943 66.3405 8.13136C66.7263 7.79328 67.2086 7.6001 67.7874 7.6001C68.3662 7.6001 68.8967 7.74499 69.2343 8.08306C69.6202 8.42113 69.8131 8.8558 69.8131 9.38706C69.8131 9.91832 69.6202 10.4013 69.2343 10.7394C68.8485 11.0774 68.3662 11.2706 67.7874 11.2706C67.2086 11.2706 66.7263 11.0774 66.3405 10.7394Z"
        fill="#484848"
      />
      <path
        d="M148.224 19.0931H139.832C140.025 19.6761 140.315 20.1619 140.845 20.4534C141.327 20.7935 141.954 20.9393 142.678 20.9393C143.208 20.9393 143.691 20.8421 144.125 20.6964C144.462 20.5506 144.8 20.3563 145.138 20.1134C145.282 20.0162 145.427 20.0162 145.572 20.1134L147.308 21.9109C147.404 22.0567 147.404 22.251 147.308 22.3482C146.199 23.4656 144.607 24 142.581 24C141.231 24 140.025 23.7571 139.012 23.2227C137.999 22.6883 137.18 22.0081 136.649 21.085C136.07 20.1619 135.829 19.1417 135.829 17.9757C135.829 16.8097 136.118 15.7895 136.649 14.915C137.18 13.9919 137.951 13.3117 138.916 12.7773C139.88 12.2429 140.99 12 142.195 12C143.353 12 144.366 12.2429 145.33 12.7287C146.247 13.2146 147.019 13.8947 147.549 14.8178C148.08 15.7409 148.369 16.8097 148.369 18.0243C148.321 18.0729 148.273 18.413 148.224 19.0931ZM140.604 15.4494C140.17 15.7895 139.929 16.2753 139.784 16.9069H144.511C144.414 16.2753 144.125 15.7895 143.691 15.4494C143.257 15.1093 142.726 14.915 142.147 14.915C141.568 14.915 140.99 15.1093 140.604 15.4494Z"
        fill="#484848"
      />
      <path
        d="M159.173 13.2716C160.186 14.1493 160.668 15.5148 160.668 17.3191V23.5123C160.668 23.7074 160.523 23.8049 160.379 23.8049H157.244C157.051 23.8049 156.954 23.6586 156.954 23.5123V22.2932C156.376 23.4148 155.218 24 153.53 24C152.614 24 151.842 23.8537 151.215 23.5123C150.54 23.2198 150.057 22.7809 149.72 22.2444C149.382 21.708 149.238 21.0741 149.238 20.3913C149.238 19.2697 149.672 18.4407 150.492 17.8068C151.311 17.1728 152.614 16.8802 154.35 16.8802H156.279C156.472 16.8802 156.617 16.7339 156.569 16.5389C156.376 15.5148 155.556 15.0271 154.157 15.0271C153.627 15.0271 153.048 15.1247 152.469 15.3197C152.035 15.466 151.649 15.6123 151.311 15.8561C151.167 15.9537 150.926 15.9049 150.877 15.7586L149.816 13.5642C149.768 13.4179 149.768 13.2228 149.913 13.174C150.492 12.8327 151.215 12.5401 151.987 12.345C152.903 12.1012 153.819 12.0037 154.736 12.0037C156.713 11.9549 158.16 12.3938 159.173 13.2716ZM155.893 21.0741C156.231 20.8302 156.52 20.4889 156.665 20.05V19.0259H154.929C153.771 19.0259 153.144 19.416 153.144 20.1963C153.144 20.5376 153.289 20.8302 153.53 21.0741C153.819 21.2691 154.157 21.4154 154.639 21.4154C155.122 21.4154 155.507 21.3179 155.893 21.0741Z"
        fill="#484848"
      />
      <path
        d="M136.081 9H123.247C123.052 9 122.955 9.14611 122.955 9.29221V12.0682C122.955 12.263 123.101 12.3604 123.247 12.3604H127.233C127.427 12.3604 127.525 12.5065 127.525 12.6526V23.7078C127.525 23.9026 127.67 24 127.816 24H131.462C131.657 24 131.754 23.8539 131.754 23.7078V12.7013C131.754 12.5065 131.9 12.4091 132.046 12.4091H136.032C136.227 12.4091 136.324 12.263 136.324 12.1169V9.34091C136.421 9.1461 136.275 9 136.081 9Z"
        fill="#484848"
      />
      <path d="M182.952 19.2998H178.9V21.4248H182.952V19.2998Z" fill="#FE7A00" />
      <path
        d="M178.901 18.595H182.952V17.2562C182.952 15.4711 182.518 14.1818 181.698 13.2893C180.83 12.3967 179.721 12 178.322 12C177.502 12 176.73 12.1488 176.055 12.4959C175.476 12.7438 174.994 13.0909 174.608 13.5372C174.464 13.6859 174.222 13.6859 174.078 13.5372C173.74 13.0909 173.306 12.7438 172.776 12.4959C172.149 12.1983 171.425 12.0496 170.605 12.0496C169.93 12.0496 169.255 12.1488 168.676 12.3967C168.097 12.6446 167.567 12.9917 167.133 13.4876V12.4959C167.133 12.2975 166.988 12.1488 166.795 12.1488H163.612C163.419 12.1488 163.274 12.2975 163.274 12.4959V23.6529C163.274 23.8512 163.419 24 163.612 24H166.988C167.181 24 167.326 23.8512 167.326 23.6529V18.0992C167.326 17.2066 167.518 16.562 167.856 16.1157C168.242 15.6694 168.724 15.4711 169.351 15.4711C170.509 15.4711 171.087 16.2645 171.087 17.9008V23.6529C171.087 23.8512 171.232 24 171.425 24H174.801C174.994 24 175.139 23.8512 175.139 23.6529V18.0992C175.139 17.2066 175.332 16.562 175.669 16.1157C176.055 15.6694 176.537 15.4711 177.116 15.4711C177.647 15.4711 178.081 15.6694 178.418 16.0661C178.708 16.4628 178.853 17.0579 178.853 17.9008V18.595H178.901Z"
        fill="#484848"
      />
      <path
        d="M178.9 22.1001V22.7762C178.9 23.4524 179.431 23.9836 180.106 23.9836H181.794C182.469 23.9836 183 23.4524 183 22.7762V22.1001H178.9Z"
        fill="#FB6211"
      />
    </svg>
  )
}

export default SvgLogoIcon
