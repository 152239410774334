import { groupBy, mapValues } from "lodash-es"
import { useMemo, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import { useIsMenziesAccountType } from "components/AccountTypeContext"
import TeamDetailsPanel from "components/TeamDetailsPanel"
import { TeamSelector, ScheduleModeTeamSelector } from "components/TeamSelector"
import KitFilters from "domains/LeaderKit/KitsTableofContents/components/KitFilters"
import KitTile from "domains/LeaderKit/KitsTableofContents/components/KitTile"
import ScheduleNext from "domains/LeaderKit/KitsTableofContents/components/ScheduleNext"
import ScheduleModeToggle from "domains/LeaderKit/KitsTableofContents/ScheduleModeToggle"
import { getKitStatus, KIT_STATUS } from "domains/LeaderKit/KitsTableofContents/utils"
import { ArrowUpRightFromSquareIcon } from "icons/FontAwesomeIcons"
import { useUserAccount } from "resources/billing"
import { useLatestUserExerciseInstances } from "resources/exercise"
import { useBasicKitInfos, useScheduleNextKits, useTeamKitInstances, useUnavailableKits } from "resources/monthly_kit"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import useWindowSize from "ui/hooks/useWindowSize"
import HorizontalRule from "ui/HorizontalRule"
import Loading from "ui/Loading"
import View from "ui/View"
import { isLeadOrColead } from "utils/team"

const KitsTableOfContents = ({ user, selectedTeam }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const flags = useFeatures()
  const [isScheduleModeActive, setIsScheduleModeActive] = useState(!!location?.state?.fetchScheduleModeStatus)
  const { data: kitInstances } = useTeamKitInstances({ teamId: selectedTeam.id })
  const { data: basicKits } = useBasicKitInfos({ teamId: selectedTeam.id })
  const { data: unavailableKits } = useUnavailableKits({ teamId: selectedTeam.id })

  const { data: exerciseInstances } = useLatestUserExerciseInstances({
    teamId: selectedTeam.id,
    userId: user.id,
    enabled: !isScheduleModeActive,
  })
  const { data: scheduleNextKits } = useScheduleNextKits({ teamId: selectedTeam.id, enabled: !isScheduleModeActive })
  const isMenzies = useIsMenziesAccountType()
  const windowSize = useWindowSize()
  const { isMobileLandscapeOrLarger } = windowSize
  const [visibleKits, setVisibleKits] = useState([])
  const { data: userAccount } = useUserAccount(selectedTeam?.account_id)
  const isAdmin = user.can_manage_billing_for_an_account
  const isConnectAccount = !!userAccount?.is_connect_account
  const isTeamLead = isLeadOrColead(user, selectedTeam)
  const isTrial = !!userAccount?.is_trial

  // Need useMemo otherwise this computation will slow down the page
  const kitInstanceMap = useMemo(
    () => mapValues(groupBy(kitInstances, "slug"), (instances) => instances[0]),
    [kitInstances]
  )

  // Need useMemo otherwise this computation will slow down the page
  const exerciseInstanceMap = useMemo(
    () => mapValues(groupBy(exerciseInstances, "slug"), (instances) => instances[0]),
    [exerciseInstances]
  )

  const onTeamChange = ({ value }) => {
    navigate(`/kit?team_id=${value}`, { state: { fetchScheduleModeStatus: isScheduleModeActive } })
  }

  const onSwitchScheduleMode = () => {
    setIsScheduleModeActive(!isScheduleModeActive)
  }

  if (!kitInstances || !basicKits || !unavailableKits || !userAccount) {
    return <Loading />
  }

  const hasMoreThan3Kits = basicKits.length > 3

  return (
    <>
      <View
        $flexDirectionMobile="column"
        $alignItems="center"
        $alignItemsMobile="flex-start"
        $gap="24px"
        $justifyContent="space-between"
        className="mb-medium"
      >
        <h1>Kits</h1>
        {!!isAdmin && !selectedTeam.demo && (
          <ScheduleModeToggle
            isScheduleModeActive={!!isScheduleModeActive}
            onSwitchScheduleMode={onSwitchScheduleMode}
          />
        )}
      </View>
      {!!isTeamLead && !!isMobileLandscapeOrLarger && (
        <div className="mb-medium">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://risingteam.com/facilitator-guide"
            className="no-underline text-gray-7 text-semi-bold"
          >
            Facilitator guide <ArrowUpRightFromSquareIcon className="ml-xxs" />
          </a>
        </div>
      )}
      <div>
        {!!selectedTeam?.jumbo ? (
          <>Jumbo sessions are optimized for groups up to 1000 people.</>
        ) : (
          <>
            {isMenzies ? (
              <>
                Each Rising Team for Schools kit is based on research about collective efficacy and what drives
                successful teams in schools.{" "}
              </>
            ) : isConnectAccount ? (
              <>Each Rising Team Connection Kit is built to help your team connect more deeply on a human level. </>
            ) : (
              <>Each Rising Team kit is based on research about what drives successful teams. </>
            )}
            {isTeamLead ? (
              isMenzies ? (
                <>
                  We recommend starting with Psychological Safety and completing a session every 3-4 weeks to build
                  consistent growth and connection with your team.
                  <p className="mt-medium">Additional kits will become available next term.</p>
                </>
              ) : (
                <>
                  We recommend completing a session every 4-6 weeks to build consistent growth and connection with your
                  team.
                </>
              )
            ) : (
              <>
                If you missed a team session, you can complete the exercise portions of it by selecting “Take exercise”
                below.
              </>
            )}
          </>
        )}
        <>
          {!isScheduleModeActive ? (
            <TeamDetailsPanel
              TeamSelectorComponent={TeamSelector}
              selectedTeam={selectedTeam}
              onTeamChange={onTeamChange}
              user={user}
              showCreateNewTeam={!!flags[FLAGS.SHOW_CREATE_TEAM]}
            />
          ) : (
            <TeamDetailsPanel
              TeamSelectorComponent={ScheduleModeTeamSelector}
              selectedTeam={selectedTeam}
              onTeamChange={onTeamChange}
              user={user}
            />
          )}
        </>
      </div>
      <HorizontalRule margin="mb-small mt-none" height={1} />
      {!!scheduleNextKits && !!hasMoreThan3Kits && !selectedTeam?.jumbo && !isScheduleModeActive && (
        <ScheduleNext
          scheduleNextKits={scheduleNextKits}
          kitInstanceMap={kitInstanceMap}
          selectedTeam={selectedTeam}
          className="mb-xxl"
        />
      )}
      <KitFilters
        basicKits={basicKits}
        unavailableKits={unavailableKits}
        kitInstanceMap={kitInstanceMap}
        setVisibleKits={setVisibleKits}
        className="mb-medium"
        user={user}
      />
      <div className="full-width">
        <div className="space-y-medium">
          {visibleKits.map((kitInfo, index) => {
            const kitInstance = kitInstanceMap[kitInfo.slug]
            const kitStatus = getKitStatus(kitInstance, kitInfo)
            const exerciseInstance = exerciseInstanceMap[kitInfo.exercise.slug]
            const initiallyExpanded =
              isTrial || (!hasMoreThan3Kits && !kitInfo.unavailable) || kitStatus === KIT_STATUS.SCHEDULED

            return (
              <KitTile
                kitInfo={kitInfo}
                kitInstance={kitInstance}
                exerciseInstance={exerciseInstance}
                user={user}
                selectedTeam={selectedTeam}
                initiallyExpanded={initiallyExpanded}
                key={kitInfo.slug}
                index={index + 1}
                isConnectAccount={isConnectAccount}
                isScheduleModeActive={!!isScheduleModeActive}
                isTeamLead={isTeamLead}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default styled(KitsTableOfContents)`
  .kit-info-icon,
  .kit-info-tooltip {
    height: 16px;
    width: 16px;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .learning-kits {
      flex: 1;
    }
  }
`
