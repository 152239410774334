import cn from "classnames"
import { styled } from "styled-components"

import Tooltip from "ui/Tooltip"
import View from "ui/View"

const AnswerDisplayBox = ({ answers, className }) => (
  <View $alignItems="stretch" $justifyContent="center" className={className}>
    {answers.map(({ title, value }, idx) => (
      <Tooltip
        float
        className={cn("answer border border-gray-4", { "answer-highlight": value })}
        top
        key={idx}
        title={title}
      >
        <div role="checkbox" aria-checked={value} aria-label={title} />
      </Tooltip>
    ))}
  </View>
)

export default styled(AnswerDisplayBox)`
  .answer {
    height: 24px;
    flex-grow: 1;
    background-color: var(--gray-2);

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }

    &-highlight {
      background-color: var(--rising-yellow);
    }
  }
`
