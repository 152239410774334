import { addWeeks, formatISO } from "date-fns"
import { styled } from "styled-components"

import DateField from "forms/fields/DateField"
import View from "ui/View"

const ExerciseDateField = styled(function ExerciseDateField({ className, name, defaultDateType, ...props }) {
  const getDefaultDate = (defaultDateType) => {
    switch (defaultDateType) {
      case "one week":
        return addWeeks(new Date(), 1)
      default:
        return new Date()
    }
  }

  const defaultDate = getDefaultDate(defaultDateType)
  const defaultValue = formatISO(defaultDate)

  return (
    <View className={className}>
      <DateField
        name={`${name}.value`}
        dateFormat="MM-dd-yyyy"
        size="medium"
        className="exercise_date_field text-gray-9"
        defaultValue={defaultValue}
        {...props}
      />
    </View>
  )
})`
  /* stylelint-disable-next-line selector-class-pattern */
  .exercise_date_field {
    max-width: 246px;
  }
`

export default ExerciseDateField
