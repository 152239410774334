import cn from "classnames"
import { useRef } from "react"
import { styled } from "styled-components"

import useEffectAfterChange from "./hooks/useEffectAfterChange"
import useWindowSize from "./hooks/useWindowSize"

import { useAccountBrand } from "components/AccountBrandContext"
import { useIsSchoolAccountType } from "components/AccountTypeContext"
import HeaderLogoIcon from "icons/HeaderLogoIcon"
import HeaderLogoSchoolsIcon from "icons/HeaderLogoSchoolsIcon"
import HeaderLogoSeparatorIcon from "icons/HeaderLogoSeparatorIcon"
import LogoIcon from "icons/LogoIcon"

const MAX_HEADER_HEIGHT = 35
const SEPARATOR_NORMALIZED_WIDTH = 24
const MAX_HEADER_WIDTH_MOBILE = 228
const MAX_HEADER_WIDTH_DESKTOP = 400

const SESSION_MAX_HEADER_HEIGHT_MOBILE = 40
const SESSION_MAX_HEADER_HEIGHT_DESKTOP = 60
const SESSION_SEPARATOR_NORMALIZED_WIDTH = 40
const SESSION_MAX_HEADER_WIDTH_MOBILE = 80
const SESSION_MAX_HEADER_WIDTH_DESKTOP = 120

const LOGIN_LOGO_MAX_WIDTH_DESKTOP = 360
const LOGIN_LOGO_MAX_WIDTH_MOBILE = 300
const LOGIN_LOGO_MAX_HEIGHT = 35
const LOGIN_LOGO_SEPARATOR_NORMALIZED_WIDTH = 24

const LOGIN_SMALL_LOGO_MAX_WIDTH = 67
const LOGIN_SMALL_LOGO_MAX_HEIGHT = 67

const HeaderLogo = ({ showSmallHeaderLogo = false, className }) => {
  const { accountBrand } = useAccountBrand()
  const { RTLogo, baseWidth, baseHeight } = useRTLogo({ useSmallHeaderLogo: showSmallHeaderLogo })

  const { isMobileOrSmaller } = useWindowSize()
  const maxHeaderWidth = isMobileOrSmaller ? MAX_HEADER_WIDTH_MOBILE : MAX_HEADER_WIDTH_DESKTOP
  const maxHeaderHeight = MAX_HEADER_HEIGHT

  return (
    <BrandLogo
      accountLogoUrl={accountBrand?.logo}
      RTLogo={RTLogo}
      rtLogoBaseWidth={baseWidth}
      rtLogoBaseHeight={baseHeight}
      separatorNormalizedWidth={SEPARATOR_NORMALIZED_WIDTH}
      maxHeaderWidth={maxHeaderWidth}
      maxHeaderHeight={maxHeaderHeight}
      className={className}
    />
  )
}

const SessionHeaderLogo = ({ className }) => {
  const { accountBrand } = useAccountBrand()
  const { RTLogo, baseWidth, baseHeight } = useRTLogo({ useSmallHeaderLogo: true })

  const { isMobileOrSmaller } = useWindowSize()
  const maxHeaderWidth = isMobileOrSmaller ? SESSION_MAX_HEADER_WIDTH_MOBILE : SESSION_MAX_HEADER_WIDTH_DESKTOP
  const maxHeaderHeight = isMobileOrSmaller ? SESSION_MAX_HEADER_HEIGHT_MOBILE : SESSION_MAX_HEADER_HEIGHT_DESKTOP

  return (
    <BrandLogo
      accountLogoUrl={accountBrand?.logo}
      RTLogo={RTLogo}
      rtLogoBaseWidth={baseWidth}
      rtLogoBaseHeight={baseHeight}
      separatorNormalizedWidth={SESSION_SEPARATOR_NORMALIZED_WIDTH}
      maxHeaderWidth={maxHeaderWidth}
      maxHeaderHeight={maxHeaderHeight}
      className={className}
    />
  )
}

const LoginLogo = ({ accountBrand, className = "" }) => {
  const accountLogoUrl = accountBrand?.logo
  const useSmallHeaderLogo = !accountLogoUrl

  const { RTLogo, baseWidth, baseHeight } = useRTLogo({ useSmallHeaderLogo })

  const { isMobileOrSmaller } = useWindowSize()
  const maxHeaderWidth = useSmallHeaderLogo
    ? LOGIN_SMALL_LOGO_MAX_WIDTH
    : isMobileOrSmaller
      ? LOGIN_LOGO_MAX_WIDTH_MOBILE
      : LOGIN_LOGO_MAX_WIDTH_DESKTOP
  const maxHeaderHeight = useSmallHeaderLogo ? LOGIN_SMALL_LOGO_MAX_HEIGHT : LOGIN_LOGO_MAX_HEIGHT
  const logoMarginClassName = useSmallHeaderLogo ? "mb-large" : "mb-xxxl"

  return (
    <BrandLogo
      RTLogo={RTLogo}
      rtLogoBaseWidth={baseWidth}
      rtLogoBaseHeight={baseHeight}
      separatorNormalizedWidth={LOGIN_LOGO_SEPARATOR_NORMALIZED_WIDTH}
      accountLogoUrl={accountBrand?.logo}
      maxHeaderWidth={maxHeaderWidth}
      maxHeaderHeight={maxHeaderHeight}
      className={cn(className, logoMarginClassName)}
    />
  )
}

const BrandLogo = ({
  accountLogoUrl,
  RTLogo,
  rtLogoBaseWidth,
  rtLogoBaseHeight,
  separatorNormalizedWidth,
  maxHeaderWidth,
  maxHeaderHeight,
  className,
}) => {
  const { height: rtLogoNormalizedHeight, width: rtLogoNormalizedWidth } = getNormalizedLogoDimensions({
    baseWidth: rtLogoBaseWidth,
    baseHeight: rtLogoBaseHeight,
    normalizedHeight: maxHeaderHeight,
  })

  return accountLogoUrl ? (
    <CoBrandedLogo
      RTLogo={RTLogo}
      accountLogoUrl={accountLogoUrl}
      rtLogoNormalizedHeight={rtLogoNormalizedHeight}
      rtLogoNormalizedWidth={rtLogoNormalizedWidth}
      separatorNormalizedWidth={separatorNormalizedWidth}
      maxHeaderWidth={maxHeaderWidth}
      maxHeaderHeight={maxHeaderHeight}
      className={className}
    />
  ) : (
    <RTBrandedLogo
      RTLogo={RTLogo}
      width={rtLogoNormalizedWidth}
      height={rtLogoNormalizedHeight}
      className={className}
    />
  )
}

const RTBrandedLogo = styled(function StandardHeaderLogo({ RTLogo, className }) {
  return (
    <div className={className}>
      <RTLogo className="logo" />
    </div>
  )
})`
  display: flex;
  align-items: center;
  margin-right: 16px;

  .logo {
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
  }
`

const CoBrandedLogo = styled(function HeaderPlusAccountLogo({
  accountLogoUrl,
  RTLogo,
  rtLogoNormalizedWidth,
  rtLogoNormalizedHeight,
  separatorNormalizedWidth,
  maxHeaderWidth,
  maxHeaderHeight,
  className,
}) {
  const accountLogoRef = useRef(null)
  const rtLogoContainerRef = useRef(null)
  const separatorContainerRef = useRef(null)

  useEffectAfterChange(() => {
    const scaleLogos = () => {
      const accountLogo = accountLogoRef.current
      const rtLogoContainer = rtLogoContainerRef.current
      const separatorContainer = separatorContainerRef.current

      if (!accountLogo || !separatorContainer || !rtLogoContainer) {
        return
      }

      const { width: accountLogoNormalizedWidth, height: accountLogoNormalizedHeight } = getNormalizedLogoDimensions({
        baseWidth: accountLogo.naturalWidth,
        baseHeight: accountLogo.naturalHeight,
        normalizedHeight: maxHeaderHeight,
      })

      // calculate total width of both logos including separator
      const totalNormalizedWidth = accountLogoNormalizedWidth + separatorNormalizedWidth + rtLogoNormalizedWidth
      const totalNormalizedHeight = maxHeaderHeight

      // scale logos down if they exceed maxHeaderWidth
      const scaleFactor = totalNormalizedWidth > maxHeaderWidth ? maxHeaderWidth / totalNormalizedWidth : 1

      accountLogo.style.width = `${accountLogoNormalizedWidth * scaleFactor}px`
      accountLogo.style.height = `${accountLogoNormalizedHeight * scaleFactor}px`
      separatorContainer.style.width = `${separatorNormalizedWidth * scaleFactor}px`
      separatorContainer.style.height = `${totalNormalizedHeight * scaleFactor}px`
      rtLogoContainer.style.width = `${rtLogoNormalizedWidth * scaleFactor}px`
      rtLogoContainer.style.height = `${rtLogoNormalizedHeight * scaleFactor}px`
    }

    // Run adjustment when images load
    const accountLogo = accountLogoRef.current
    if (accountLogo) {
      accountLogo.addEventListener("load", scaleLogos)
    }

    // Run adjustment on state updates as well
    scaleLogos()

    return () => {
      if (accountLogo) {
        accountLogo.removeEventListener("load", scaleLogos)
      }
    }
  }, [rtLogoNormalizedHeight, rtLogoNormalizedWidth, maxHeaderHeight, maxHeaderWidth, separatorNormalizedWidth])

  return (
    <div className={className}>
      <img ref={accountLogoRef} src={accountLogoUrl} alt="Account Logo" className="account-logo" />
      <div ref={separatorContainerRef} className="separator-container">
        <HeaderLogoSeparatorIcon className="separator" />
      </div>
      <div ref={rtLogoContainerRef} className="rt-logo-container">
        <RTLogo className="logo" />
      </div>
    </div>
  )
})`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-size: 16px;

  .rt-logo-container {
    display: flex;
    align-items: center;
  }

  .logo {
    width: inherit;
    height: inherit;
  }

  .separator-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .separator {
    height: inherit;
  }
`

const useRTLogo = ({ useSmallHeaderLogo = false } = {}) => {
  const isSchool = useIsSchoolAccountType()

  if (useSmallHeaderLogo) {
    return {
      RTLogo: LogoIcon,
      baseWidth: 100,
      baseHeight: 100,
    }
  }

  if (isSchool) {
    return {
      RTLogo: HeaderLogoSchoolsIcon,
      baseWidth: 189,
      baseHeight: 35,
    }
  }

  return {
    RTLogo: HeaderLogoIcon,
    baseWidth: 189,
    baseHeight: 35,
  }
}

const getNormalizedLogoDimensions = ({ baseWidth, baseHeight, normalizedHeight }) => {
  const scaleFactor = normalizedHeight / baseHeight
  return {
    height: baseHeight * scaleFactor,
    width: baseWidth * scaleFactor,
  }
}

export { HeaderLogo, SessionHeaderLogo, LoginLogo }
