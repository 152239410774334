import { Form, Formik } from "formik"
import type { ReactNode } from "react"

interface SimpleFormProps<Values extends Record<string, unknown> = {}> {
  className: string
  children: ReactNode
  initialValues: Values
  onSubmit?: null | ((values: Values) => void)
}

function SimpleForm({ className, children, initialValues, onSubmit = null }: SimpleFormProps) {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit ?? (() => {})}>
      <Form className={className}>{children}</Form>
    </Formik>
  )
}

export default SimpleForm

export type { SimpleFormProps }
