import { useState } from "react"

import AddTeamForm from "domains/Admin/AddTeamForm"
import CreateNewTeamForm from "domains/Admin/CreateNewTeamForm"
import EditTeamForm from "domains/Admin/EditTeamForm"
import { canUserEditAccountTeam } from "domains/Admin/utils"
import useFeatures from "ui/hooks/useFeatures"
import Modal from "ui/Modal"

interface TeamModalProps {
  className?: string
  account: AccountData
  user: UserData
  isOpen: boolean
  team?: TeamData | null
  onRequestClose?: null | (() => void)
  hideTeamLeadField?: boolean
  showCreateNewTeam?: boolean
  onTeamChange?: null | (({ value }: { value: TeamID }) => void)
}

const TeamModal = ({
  className,
  account,
  user,
  isOpen,
  team = null,
  onRequestClose = null,
  hideTeamLeadField = false,
  showCreateNewTeam = false,
  onTeamChange = null,
}: TeamModalProps) => {
  const features = useFeatures()

  // We need to adjust modal position up slightly in some states, otherwise
  // the team lead select dropdown will go off the bottom of the screen:
  const defaultModalPositionOffsetY = "-10vh"
  const [modalPositionOffsetY, setModalPositionOffsetY] = useState(defaultModalPositionOffsetY)

  const canUserAddAccountTeams = !!account?.can_user_add_account_teams

  function closeModal() {
    setModalPositionOffsetY(defaultModalPositionOffsetY)
    onRequestClose?.()
  }

  if (!canUserAddAccountTeams && !canUserEditAccountTeam(account, user, team, features)) {
    return null
  }

  const title = showCreateNewTeam ? "Create another team" : team ? "Edit team" : "Add another team"

  return (
    <Modal
      className={className}
      title={title}
      isOpen={isOpen}
      onRequestClose={closeModal}
      positionOffsetY={modalPositionOffsetY}
      content={
        showCreateNewTeam ? (
          <CreateNewTeamForm
            className="full-width"
            account={account}
            user={user}
            onTeamChange={onTeamChange}
            onSubmit={closeModal}
          />
        ) : team ? (
          <EditTeamForm
            className="full-width"
            team={team}
            account={account}
            user={user}
            isInModal={true}
            hideTeamLeadField={hideTeamLeadField}
            onMembersExpand={() => setModalPositionOffsetY("0")}
            onMembersCollapse={() => setModalPositionOffsetY(defaultModalPositionOffsetY)}
          />
        ) : (
          <AddTeamForm className="full-width" account={account} user={user} onSubmit={closeModal} />
        )
      }
    />
  )
}

export default TeamModal

export type { TeamModalProps }
