import { Widget } from "@typeform/embed-react"
import cn from "classnames"
import { Formik, Form } from "formik"
import { pick } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import { useAuth } from "domains/Authentication/resource"
import { TEAM_OTHER } from "domains/Reports/constants"
import FieldMessage from "forms/FieldMessage"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import EmailField from "forms/fields/EmailField"
import handleErrors from "forms/handleErrors"
import Yup, { userSchemas } from "forms/yup"
import { useBenchmarkSurveyInfo, postEngagementSurvey } from "resources/teams"
import { useUser } from "resources/users"
import Button from "ui/Button"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import PageTitle from "ui/PageTitle"

const RT4S_BENCHMARK_SURVEY_TYPE = "rt4s_benchmark"

const RT4SBenchmarkSurvey = styled(function RT4SBenchmarkSurvey({ className }) {
  const { data: auth, isFetching: authIsFetching } = useAuth()
  const { data: currentUser, isFetching: userIsFetching } = useUser({ userId: !!auth && "me" })
  const { token, uid, tid } = useQueryParams()
  const { data: surveyInfo, isFetching: infoIsFetching } = useBenchmarkSurveyInfo({ token, uid, tid })
  const [hiddenFields, setHiddenFields] = useState(null)

  if (authIsFetching || userIsFetching || infoIsFetching || !surveyInfo) {
    return <Loading />
  }

  if (!surveyInfo.is_token_valid) {
    return (
      <>
        <PageTitle>Benchmark survey</PageTitle>
        <div className="p-large">This survey link is invalid or has expired</div>
      </>
    )
  }

  // Use team name as label, rather than manager name for RT4S
  const teamOptions = (surveyInfo?.team_options ?? []).map((team) => ({
    ...team,
    label: team.name,
  }))
  const memberTeams = teamOptions.filter((team) => team.is_member)

  let defaultTeam = null
  if (memberTeams.length) {
    defaultTeam = memberTeams[0].value
  } else if (!teamOptions.length) {
    defaultTeam = TEAM_OTHER
  }

  const initialValues = {
    email: currentUser?.email,
    tid: defaultTeam,
  }

  const validationSchema = Yup.object().shape({
    email: userSchemas.email,
    tid: Yup.string().required("This field is required."),
  })

  const activateSurvey = handleErrors(async (values) => {
    setHiddenFields({
      user_id: currentUser?.id,
      email: currentUser?.email ?? values?.email,
      account_id: surveyInfo?.account_id,
      tid: values?.tid,
    })
  })

  const handleSubmit = async (data) => {
    if (!hiddenFields) {
      return
    }

    const surveyValues = pick(hiddenFields, ["email", "tid"])
    const surveyData = {
      ...surveyValues,
      uid,
      token,
      custom_data: {
        type: RT4S_BENCHMARK_SURVEY_TYPE,
        typeform_form_id: data.formId,
        typeform_response_id: data.responseId,
      },
    }
    await postEngagementSurvey({ surveyData })
  }

  return (
    <>
      <PageTitle>Benchmark survey</PageTitle>
      <div className={cn("main-container full-width", className)}>
        <h1 className="mb-small">Benchmark Survey</h1>
        {!!hiddenFields ? (
          <>
            <Widget
              id={surveyInfo.survey_id}
              hidden={hiddenFields}
              onSubmit={handleSubmit}
              className="typeform-survey"
            />
          </>
        ) : (
          <>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={activateSurvey}>
              <Form>
                <EmailField
                  disabled={!!currentUser?.email}
                  label="Email address"
                  name="email"
                  size="medium"
                  autoComplete="off"
                  className="mb-small"
                />
                {!!teamOptions.length && (
                  <>
                    <div className="text-normal">
                      <span className="text-semi-bold">Team</span> (If your team isn’t in the list below, type “Not
                      listed”)
                    </div>
                    <AdvancedSelectField
                      name="tid"
                      placeholder="Select team..."
                      options={[
                        ...teamOptions,
                        {
                          value: TEAM_OTHER,
                          label: "Not listed",
                        },
                      ]}
                      className="mb-small"
                    />
                    <FieldMessage name="tid" data-cy="field-message-tid" />
                  </>
                )}
                <Button type="submit" className="mt-large">
                  Continue
                </Button>
              </Form>
            </Formik>
          </>
        )}
      </div>
    </>
  )
})`
  .typeform-survey {
    width: 100%;
    height: 1000px;
  }
`
export default RT4SBenchmarkSurvey
