import { groupBy, mapValues } from "lodash-es"
import { useMemo, useState, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { styled } from "styled-components"

import KitFiltersRevamp from "./components/KitFiltersRevamp"
import KitFiltersTour, { KitFiltersTourStepNames } from "./components/KitFiltersTour"

import TeamDetailsPanel from "components/TeamDetailsPanel"
import { TeamSelector, ScheduleModeTeamSelector } from "components/TeamSelector"
import KitTileRevamp from "domains/LeaderKit/KitsTableofContents/components/KitTileRevamp"
import ScheduleNext from "domains/LeaderKit/KitsTableofContents/components/ScheduleNext"
import ScheduleModeToggle from "domains/LeaderKit/KitsTableofContents/ScheduleModeToggle"
import { getKitStatus, KIT_STATUS } from "domains/LeaderKit/KitsTableofContents/utils"
import { ArrowUpRightFromSquareIcon, LightbulbIcon } from "icons/FontAwesomeIcons"
import { useUserAccount } from "resources/billing"
import { useLatestUserExerciseInstances } from "resources/exercise"
import { useBasicKitInfos, useScheduleNextKits, useTeamKitInstances, useUnavailableKits } from "resources/monthly_kit"
import { CalloutName, useCalloutStatus, useUpdateCalloutStatus } from "resources/users"
import Button from "ui/Button"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import useWindowSize from "ui/hooks/useWindowSize"
import Loading from "ui/Loading"
import View from "ui/View"
import { isLeadOrColead } from "utils/team"

const KitsTableOfContentsRevamp = ({ className, user, selectedTeam }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const flags = useFeatures()
  const [isScheduleModeActive, setIsScheduleModeActive] = useState(!!location?.state?.fetchScheduleModeStatus)
  const { data: kitInstances } = useTeamKitInstances({ teamId: selectedTeam.id })
  const { data: basicKits } = useBasicKitInfos({ teamId: selectedTeam.id })
  const { data: unavailableKits } = useUnavailableKits({ teamId: selectedTeam.id })

  const { data: exerciseInstances } = useLatestUserExerciseInstances({
    teamId: selectedTeam.id,
    userId: user.id,
    enabled: !isScheduleModeActive,
  })
  const { data: scheduleNextKits } = useScheduleNextKits({ teamId: selectedTeam.id, enabled: !isScheduleModeActive })
  const windowSize = useWindowSize()
  const { isMobileLandscapeOrLarger } = windowSize
  const [visibleKits, setVisibleKits] = useState([])
  const INITIAL_VISIBLE_KITS_COUNT = 10
  const [visibleKitsCount, setVisibleKitsCount] = useState(INITIAL_VISIBLE_KITS_COUNT)
  const { data: userAccount } = useUserAccount(selectedTeam?.account_id)
  const [displayCloseMatchSearchMessage, setDisplayCloseMatchSearchMessage] = useState(false)
  const isAdmin = user.can_manage_billing_for_an_account
  const isConnectAccount = !!userAccount?.is_connect_account
  // TODO (coleads): Cleanup to remove this check once coleads feature is fully removed
  const isCurrentTeamAnyLead = isLeadOrColead(user, selectedTeam)
  const isTrial = !!userAccount?.is_trial

  const kitFiltersTourIntroJsRef = useRef()
  const {
    data: hasSeenKitFiltersTour,
    isFetching: isFetchingHasSeenKitFiltersTour,
    isError: isErrorHasSeenKitFiltersTour,
  } = useCalloutStatus({
    userId: user.id,
    calloutName: CalloutName.KIT_FILTERS_TOUR,
  })
  const { mutateAsync: updateKitFiltersTourStatus } = useUpdateCalloutStatus({
    userId: user.id,
    calloutName: CalloutName.KIT_FILTERS_TOUR,
  })

  // Need useMemo otherwise this computation will slow down the page
  const kitInstanceMap = useMemo(
    () => mapValues(groupBy(kitInstances, "slug"), (instances) => instances[0]),
    [kitInstances]
  )

  // Need useMemo otherwise this computation will slow down the page
  const exerciseInstanceMap = useMemo(
    () => mapValues(groupBy(exerciseInstances, "slug"), (instances) => instances[0]),
    [exerciseInstances]
  )

  const onTeamChange = ({ value }) => {
    navigate(`/kit?team_id=${value}`, { state: { fetchScheduleModeStatus: isScheduleModeActive } })
  }

  const onSwitchScheduleMode = () => {
    setIsScheduleModeActive(!isScheduleModeActive)
  }

  if (!kitInstances || !basicKits || !unavailableKits || !userAccount) {
    return <Loading />
  }

  const hasMoreThan3Kits = basicKits.length > 3

  const initialVisibleKits = visibleKits.slice(0, visibleKitsCount)

  const handleShowMoreKits = () => {
    // show all kits when "Show more" is clicked
    setVisibleKitsCount(visibleKits.length)
  }

  return (
    <div className={className}>
      <KitFiltersTour
        introJsRef={kitFiltersTourIntroJsRef}
        autoStartTour={!isFetchingHasSeenKitFiltersTour && !isErrorHasSeenKitFiltersTour && !hasSeenKitFiltersTour}
        onTourStart={() => updateKitFiltersTourStatus({ hasViewedCallout: true })}
      />
      <View
        $flexDirectionMobile="column"
        $alignItems="center"
        $alignItemsMobile="flex-start"
        $gap="24px"
        $justifyContent="space-between"
        className="mb-medium"
      >
        <h1>Kits</h1>
        {!!isCurrentTeamAnyLead && !!isMobileLandscapeOrLarger && (
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://risingteam.com/facilitator-guide"
              className="no-underline text-gray-8 text-semi-bold"
            >
              <ArrowUpRightFromSquareIcon className="ml-xxs" /> Facilitator guide
            </a>
          </div>
        )}
      </View>
      {!!isAdmin && !selectedTeam.demo && (
        <ScheduleModeToggle isScheduleModeActive={!!isScheduleModeActive} onSwitchScheduleMode={onSwitchScheduleMode} />
      )}
      <div className="my-large">
        <>
          {!isScheduleModeActive ? (
            <TeamDetailsPanel
              TeamSelectorComponent={TeamSelector}
              selectedTeam={selectedTeam}
              onTeamChange={onTeamChange}
              user={user}
              showCreateNewTeam={!!flags[FLAGS.SHOW_CREATE_TEAM]}
            />
          ) : (
            <TeamDetailsPanel
              TeamSelectorComponent={ScheduleModeTeamSelector}
              selectedTeam={selectedTeam}
              onTeamChange={onTeamChange}
              user={user}
            />
          )}
        </>
      </div>
      {!!scheduleNextKits && !!hasMoreThan3Kits && !selectedTeam?.jumbo && !isScheduleModeActive && (
        <ScheduleNext
          scheduleNextKits={scheduleNextKits}
          kitInstanceMap={kitInstanceMap}
          selectedTeam={selectedTeam}
          className="mb-xxl"
        />
      )}
      <div className="p-medium neg-m-medium" data-kit-filters-tour-step={KitFiltersTourStepNames.FilterControls}>
        <KitFiltersRevamp
          basicKits={basicKits}
          unavailableKits={unavailableKits}
          visibleKits={visibleKits}
          setVisibleKits={setVisibleKits}
          setVisibleKitsCount={setVisibleKitsCount}
          onDisplayCloseMatchSearchMessageChange={setDisplayCloseMatchSearchMessage}
          kitInstanceMap={kitInstanceMap}
          initialVisibleKitsCount={INITIAL_VISIBLE_KITS_COUNT}
        />
      </div>
      <div className="mt-small full-width">
        <div className="space-y-medium">
          {visibleKits.length === 0 && (
            <View $alignItems="center" $gap="var(--spacing-1)" className="p-xs bg-yellow-tint">
              <LightbulbIcon color="var(--rising-yellow)" />
              <div>This search has no matches. Try changing a filter and try again.</div>
            </View>
          )}
          {!!displayCloseMatchSearchMessage && (
            <View $alignItems="center" $gap="var(--spacing-1)" className="p-xs bg-yellow-tint">
              <LightbulbIcon color="var(--rising-yellow)" />
              <div>We couldn’t find an exact match for your search, but these results are close.</div>
            </View>
          )}
          {initialVisibleKits.length > 0 &&
            initialVisibleKits.map((kitInfo, index) => {
              const kitInstance = kitInstanceMap[kitInfo.slug]
              const kitStatus = getKitStatus(kitInstance, kitInfo)
              const exerciseInstance = exerciseInstanceMap[kitInfo.exercise.slug]
              const initiallyExpanded =
                isTrial || (!hasMoreThan3Kits && !kitInfo.unavailable) || kitStatus === KIT_STATUS.SCHEDULED
              return (
                <KitTileRevamp
                  kitInfo={kitInfo}
                  kitInstance={kitInstance}
                  kitStatus={kitStatus}
                  exerciseInstance={exerciseInstance}
                  user={user}
                  selectedTeam={selectedTeam}
                  initiallyExpanded={initiallyExpanded}
                  key={kitInfo.slug}
                  index={index + 1}
                  isConnectAccount={isConnectAccount}
                  isScheduleModeActive={!!isScheduleModeActive}
                  isCurrentTeamAnyLead={isCurrentTeamAnyLead}
                />
              )
            })}
          {visibleKitsCount < visibleKits.length && (
            <View $justifyContent="center">
              <Button onClick={handleShowMoreKits} className="link-blue">
                Show more
              </Button>
            </View>
          )}
        </div>
      </div>
    </div>
  )
}

export default styled(KitsTableOfContentsRevamp)`
  .kit-info-icon,
  .kit-info-tooltip {
    height: 16px;
    width: 16px;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    .learning-kits {
      flex: 1;
    }
  }
`
