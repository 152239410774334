import cn from "classnames"
import { addSeconds, format as formatDate, startOfDay } from "date-fns"
import { styled } from "styled-components"

import Button from "./Button"
import { useCurrentTheme } from "./ThemeUpdateContext"
import View from "./View"

import { PlayIcon, PauseIcon, RepeatIcon } from "icons/FontAwesomeIcons"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"

const Timer = styled(function Timer({
  durationSeconds,
  isRunning,
  onPlay,
  onPause,
  onReset,
  showTimerControls = true,
  invertColors = false,
  warningColorDuration = 1,
  dangerColorDuration = null,
  ariaLabel = "Timer",
  className,
}) {
  const PlayPauseIcon = isRunning ? PauseIcon : PlayIcon
  const theme = useCurrentTheme()

  useEffectAfterChange(() => {
    if (durationSeconds < 1 && isRunning) {
      onPause()
    }
  }, [durationSeconds, isRunning, onPause])

  const formattedTime = formatDate(addSeconds(startOfDay(new Date()), durationSeconds), "m:ss")
  const timerDisplay = durationSeconds < 1 ? <TimesUp /> : formattedTime

  const timerColorGood = durationSeconds > (warningColorDuration ?? -1)
  const timerColorWarning =
    warningColorDuration && durationSeconds <= warningColorDuration && durationSeconds > (dangerColorDuration ?? -1)
  const timerColorDanger = dangerColorDuration && durationSeconds <= dangerColorDuration

  return (
    <View
      className={cn(className, {
        "widescreen-timer": !!theme.isWidescreen,
      })}
      $width="auto"
      $alignItems="center"
    >
      <span
        role="timer"
        aria-label={ariaLabel}
        className={cn("time-remaining text-center text-semi-bold border-radius", {
          "text-fg": !invertColors,
          "bg-rising-green": !invertColors && timerColorGood,
          "bg-orange-3": !invertColors && timerColorWarning,
          "bg-danger": !invertColors && timerColorDanger,
          "invert-timer-green": !!invertColors && timerColorGood,
          "invert-timer-orange": !!invertColors && timerColorWarning,
          "invert-timer-red": !!invertColors && timerColorDanger,
          "px-xs": !!theme.isWidescreen,
          "p-xxs": !theme.isWidescreen,
          "mr-xxs": showTimerControls && !!theme.isWidescreen,
          "mr-xs": showTimerControls && !theme.isWidescreen,
          "border bg-white": !!invertColors,
        })}
      >
        {timerDisplay}
      </span>
      {!!showTimerControls && (
        <>
          <Button
            className={cn("timer-button tertiary p-xs border-radius", {
              "mr-xxs": !!theme.isWidescreen,
              "mr-xs": !theme.isWidescreen,
            })}
            aria-label={isRunning ? "Pause" : "Play"}
            onClick={isRunning ? onPause : onPlay}
          >
            <PlayPauseIcon className="text-gray-8 button-icon" />
          </Button>
          <Button
            className={cn("timer-button tertiary p-xs ml-none border-radius", {
              "mr-xxs": !!theme.isWidescreen,
              "mr-xs": !theme.isWidescreen,
            })}
            aria-label="Reset"
            onClick={onReset}
          >
            <RepeatIcon className="text-gray-8 button-icon" />
          </Button>
        </>
      )}
    </View>
  )
})`
  .time-remaining {
    width: 96px;
    height: 32px;
    font-size: 1.5rem;
    line-height: 1.5rem;

    &.invert-timer-green {
      border-color: var(--rising-green);
      color: var(--rising-green);
    }

    &.invert-timer-orange {
      border-color: var(--rising-orange);
      color: var(--rising-orange);
    }

    &.invert-timer-red {
      border-color: var(--danger);
      color: var(--danger);
    }
  }

  .button-icon {
    width: 16px;
    height: 16px;
  }

  .timer-button {
    height: 32px;

    /* TODO: remove padding rule once dev/prod specificity bug is resolved */
    padding: var(--spacing-1);
  }

  &.widescreen-timer {
    .time-remaining {
      width: 176px;
      height: 68px;
      font-size: 3rem;
      line-height: 4.25rem;
    }

    .button-icon {
      width: 48px;
      height: 48px;
    }

    .timer-button {
      height: 68px;
    }
  }
`

const TimesUp = () => (
  <View $height="100%" $justifyContent="center" $alignItems="center" className="text-semi-bold text-small">
    Time’s up!
  </View>
)

export default Timer
