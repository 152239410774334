import InputField from "forms/fields/InputField"
import InputFieldWithArti from "forms/fields/InputFieldWithArti"
import TextInput from "ui/TextInput"

const ExerciseTextField = ({ name, enableInSessionArti = false, artiInSessionData = {}, ...props }) =>
  !!enableInSessionArti ? (
    <InputFieldWithArti
      forwardedAs={TextInput}
      wrapperAs="div"
      name={`${name}.value`}
      enableInSessionArti={enableInSessionArti}
      initialChoiceMessage={artiInSessionData?.initial_choice_message}
      initialChatType={artiInSessionData?.initial_chat_type}
      initialMessage={artiInSessionData?.initial_message}
      {...props}
    />
  ) : (
    <InputField forwardedAs={TextInput} wrapperAs="div" name={`${name}.value`} {...props} />
  )

export default ExerciseTextField
