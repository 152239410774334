import cn from "classnames"
import { ErrorMessage, useField } from "formik"
import { styled } from "styled-components"

import View from "ui/View"

const ToggleSwitchField = styled(function ToggleSwitchField({
  name,
  className,
  children,
  errorMessagePosition,
  evergreen,
  checked,
  disabled = false,
  labelPosition = "right",
  saveOnChange = () => {},
  ...props
}) {
  const [formikField, formikMeta] = useField(name ?? "standalone")

  const isStandaloneField = typeof checked === "boolean"
  const field = isStandaloneField ? { value: checked } : formikField
  const meta = isStandaloneField ? {} : formikMeta

  const handleChange = (evt) => {
    if (field.onChange) {
      field.onChange(evt)
    }
    saveOnChange(name, evt.target.checked)
  }

  if (errorMessagePosition && !["left", "right"].includes(errorMessagePosition)) {
    throw new Error("Invalid errorMessagePosition prop; must be 'left' or 'right'")
  }
  const errorClass = errorMessagePosition ? `error-${errorMessagePosition}` : ""

  return (
    <div className={cn(className, errorClass, { evergreen, disabled })}>
      <label className="text-small">
        <input
          className={meta.error ? "error" : null}
          name={name}
          type="checkbox"
          checked={field?.value}
          disabled={disabled}
          {...field}
          {...props}
          onChange={handleChange}
        />
        <View>
          {labelPosition === "left" && <div className="mr-small">{children}</div>}
          <div className="slider" />
          {labelPosition === "right" && <div className="ml-small">{children}</div>}
        </View>
      </label>
      {!!meta.error && <ErrorMessage component="div" className="text-danger" data-cy="field-message" name={name} />}
    </div>
  )
})`
  display: inline-block;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  input {
    display: none;
  }

  .slider {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 24px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--border-color);
    transition: 0.4s;
    border-radius: 34px;

    &::before {
      content: "";
      position: absolute;
      height: 18px;
      width: 18px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  /* stylelint-disable-next-line csstools/use-nesting */
  &.evergreen .slider {
    background-color: var(--rising-green);
  }

  input:checked + * .slider {
    background-color: var(--rising-green);

    &::before {
      transform: translateX(15px);
    }
  }

  input.error + * .slider {
    background-color: var(--error-red);
  }

  &.error-left,
  &.error-right {
    position: relative;

    .text-danger {
      position: absolute;
      top: 0;
      white-space: nowrap;
    }
  }

  &.error-left .text-danger {
    right: calc(100% + 1rem);
  }

  &.error-right .text-danger {
    left: calc(100% + 1rem);
  }
`

export default ToggleSwitchField
