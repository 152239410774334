import { Navigate, Route, Routes } from "react-router"

import ManuallyAddAccount from "./ManuallyAddAccount"
import QRCreator from "./QRCreator"

import { SimpleRouteLayout } from "domains/Authentication/Routes"
import { useUser } from "resources/users"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import View from "ui/View"

const RTOnlyLayout = () => {
  const { [FLAGS.RT_ONLY]: rt_only, isFetching } = useFeatures()
  const { data: user } = useUser({ userId: "me" })

  if (!user || (!rt_only && isFetching)) {
    return <Loading />
  }

  if (!user.is_staff || !rt_only) {
    return <Navigate to="/" replace />
  }

  return (
    <View>
      <div className="main-container full-width">
        <SimpleRouteLayout title="RT Only" />
      </div>
    </View>
  )
}

const RTOnly = () => (
  <Routes>
    <Route element={<RTOnlyLayout />}>
      <Route path="/manually_add_account" element={<ManuallyAddAccount />} />
      <Route path="/qr_creator" element={<QRCreator />} />
      <Route path="*" element={<RTOnlyHome />} />
    </Route>
  </Routes>
)

const RTOnlyHome = () => (
  <View>
    <div className="main-container full-width">
      <h1>RT Only</h1>
      <div>
        <a href="/rtadmin">RT Admin</a>
      </div>
      <div>
        <a href="/rtonly/manually_add_account">Manually Add Account</a>
      </div>
      <div>
        <a href="/rtonly/qr_creator">QR Creator</a>
      </div>
    </div>
  </View>
)

export default RTOnly
