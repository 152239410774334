import cn from "classnames"
import { range } from "lodash-es"
import { styled } from "styled-components"

import ChoicesField from "./ChoicesField"

import FieldMessage from "forms/FieldMessage"
import Tooltip from "ui/Tooltip"
import View from "ui/View"

const fieldNameSuffix = ".value"

function removeLineRatingFieldNameSuffix(name) {
  return !name.endsWith(fieldNameSuffix) ? name : name.substring(0, name.length - fieldNameSuffix.length)
}

function getColorForValue(colors, value) {
  const index = Number(value) - 1
  return (Array.isArray(colors) && Number.isInteger(index) && colors[index % colors?.length]) || null
}

const LineRatingField = ({
  name,
  numOptions,
  showNumbers,
  colors,
  axisLabels,
  ariaLabels,
  className,
  disabled,
  iconRating = false,
  optionTransformer = null,
  flexGrowItems = null,
  barMode = false,
  ...props
}) => {
  const options = range(numOptions).map((idx) => {
    const {
      value,
      label = null,
      tooltip = null,
    } = optionTransformer
      ? optionTransformer(idx + 1)
      : {
          value: idx + 1,
        }
    return {
      value: value.toString(),
      ...(label ? { label } : {}),
      ...(!flexGrowItems || flexGrowItems.includes(idx + 1) ? { flexGrow: true } : {}),
      ariaLabel: ariaLabels?.[idx],
      showNumbers,
      colors,
      barMode,
      tooltip,
      disabled,
    }
  })

  return (
    <div className={cn("pt-xs", className, { "line-rating-bar-mode": barMode })}>
      {!barMode && <div className="line-rating-bar bg-gray-2 border border-gray-5 border-radius" />}
      <ChoicesField
        name={name + fieldNameSuffix}
        type="radio"
        options={options}
        iconRating={iconRating}
        component={LineRatingRadioInput}
        className="line-rating-input"
        {...props}
      />
      {!!axisLabels && (
        <View aria-hidden={true} className="mt-xs" $justifyContent="space-between">
          {axisLabels.map((label) => (
            <div key={label} className="axis-label text-gray-8">
              {label}
            </div>
          ))}
        </View>
      )}
      <FieldMessage name={name + fieldNameSuffix} data-cy={`field-message-${name + fieldNameSuffix}`} />
      <FieldMessage name={name} data-cy={`field-message-${name}`} />
    </div>
  )
}

const LineRatingRadioInput = styled(function LineRatingRadioInput({
  name,
  value,
  type,
  className,
  onBlur,
  checked,
  onChange,
  iconRating = false,
  showNumbers = false,
  barMode = false,
  disabled = false,
  flexGrow = false,
  label = null,
  tooltip = null,
  ariaLabel = null,
}) {
  return (
    <label
      role={type}
      aria-checked={checked}
      aria-disabled={disabled}
      aria-label={ariaLabel}
      className={cn(className, {
        "line-rating-label-bar-mode": barMode,
        "line-rating-label-flex-grow": flexGrow,
        "cursor-default": disabled,
      })}
    >
      <Tooltip top key={value} title={tooltip} disabled={!tooltip} className="inline-block">
        <input
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          checked={checked}
          disabled={disabled}
        />
        <div
          className={cn("line-rating-item", {
            "line-rating-item-checked": checked,
            "line-rating-item-disabled": disabled,
            "icon-rating-item": !!iconRating,
          })}
        >
          {label}
          {!!showNumbers && value}
        </div>
      </Tooltip>
    </label>
  )
})`
  input {
    display: none;
  }

  .line-rating-item {
    width: 24px;
    height: 24px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-color);
    border-radius: 50%;
    background-color: var(--fg);
    cursor: pointer;
    margin: 0;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1.5rem;

    &.icon-rating-item {
      height: 46px;
      line-height: 22px;
      padding: var(--spacing-1);

      .icon {
        font-size: 24px;
        margin-top: 2px; /* adjust vertical centering of icons within container */
      }
    }

    &.line-rating-item-disabled {
      background-color: var(--gray-1);
    }
  }

  .line-rating-item-checked {
    border: none;
    color: var(--fg);
    background-color: ${({ colors, value }) => getColorForValue(colors, value) ?? "var(--blue-2)"};

    &.line-rating-item-disabled {
      background-color: ${({ colors, value }) => getColorForValue(colors, value) ?? "var(--gray-9)"};
    }
  }

  @media (min-width: ${({ theme }) => theme.desktopMin}) {
    .line-rating-item {
      font-size: 0.875rem;
    }
  }

  &.line-rating-label-bar-mode {
    min-width: 4rem;

    &.line-rating-label-flex-grow {
      flex-grow: 1;
    }

    .line-rating-item {
      padding-bottom: 24px;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      border-radius: 0;
      margin-right: -1px; /* prevent double-thick borders from being displayed */
      transition:
        color 0.1s ease-in-out,
        background-color 0.1s ease-in-out,
        border-color 0.1s ease-in-out;

      &.icon-rating-item {
        padding: var(--spacing-1);
      }

      &:hover {
        color: var(--fg);
        background-color: var(--rising-blue);
        border: 1px solid var(--rising-blue);
      }
    }

    .line-rating-item-checked {
      border: 1px solid var(--blue-2);
    }

    &:first-child .line-rating-item {
      border-top-left-radius: 1000px;
      border-bottom-left-radius: 1000px;
    }

    &:last-child .line-rating-item {
      border-top-right-radius: 1000px;
      border-bottom-right-radius: 1000px;
    }

    @media (max-width: ${({ theme }) => theme.mobileMax}) {
      min-width: 1rem;

      .line-rating-item {
        margin-bottom: 0.25rem;
        border-radius: 1000px;
      }
    }
  }
`

export default styled(LineRatingField)`
  .line-rating-input {
    display: flex;
    justify-content: space-between;

    label {
      z-index: var(--z-above-zero);
    }
  }

  .axis-label {
    font-size: 0.8125rem;
    line-height: 1.1875rem;
    text-align: center;

    @media (max-width: ${({ theme }) => theme.mobileMax}) {
      display: flex;
      width: 75px;

      &:nth-child(even):not(:last-child) {
        display: none;
      }
    }

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  .line-rating-bar {
    width: 100%;
    height: 8px;
    margin-bottom: -16px;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    &.line-rating-bar-mode .line-rating-input {
      flex-direction: column;
    }
  }
`

export { removeLineRatingFieldNameSuffix }
