import { getMarkRange } from "@tiptap/core"
import { Image } from "@tiptap/extension-image"
import { Link } from "@tiptap/extension-link"
import { Placeholder } from "@tiptap/extension-placeholder"
import { Typography } from "@tiptap/extension-typography"
import { EditorProvider, useCurrentEditor } from "@tiptap/react"
import { StarterKit } from "@tiptap/starter-kit"
import cn from "classnames"
import { Form, Formik, useField } from "formik"
import { uniqueId } from "lodash-es"
import { useRef } from "react"
import { styled } from "styled-components"
import urlRegexSafe from "url-regex-safe"
import * as Yup from "yup"

import ArtiInSessionButton from "domains/Arti/ArtiInSessionComponents/ArtiInSessionButton"
import InputField from "forms/fields/InputField"
import { BoldIcon, ItalicIcon, LinkIcon, ListIcon, ListOlIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useEffectAfterFirstRender from "ui/hooks/useEffectAfterFirstRender"
import { useModal } from "ui/ModalContext"
import SubmitButton from "ui/SubmitButton"
import Tooltip from "ui/Tooltip"
import View from "ui/View"
import { ensureAbsoluteUrlHasProtocol } from "utils/string"

const URL_REGEX_PERMISSIVE = RegExp(urlRegexSafe(), "i") // allow any TLD
const URL_REGEX_STRICT = RegExp(
  // allow only specific TLDS (used for auto-formatting links when typed into editor)
  urlRegexSafe({
    tlds: ["com", "edu", "gov", "mil", "org", "net"],
  }),
  "i"
)

const RichText = styled(function RichText({
  className,
  name,
  onBlur = null,
  disabled = false,
  placeholder = null,
  enableInSessionArti = false,
  artiInSessionData = {},
}) {
  const [field, _meta, { setValue, setTouched }] = useField(name)
  const labelId = uniqueId("rich-text-editor-label-")

  function onArtiResponseSelect(message) {
    setValue(message)
    onBlur?.({
      target: {
        name,
        value: message,
      },
    })
  }

  return (
    <div className={cn(className, { disabled })} role="application" dir="ltr" lang="en" aria-labelledby={labelId}>
      <label id={labelId} className="display-none">
        Rich Text Editor
      </label>
      <EditorProvider
        content={field.value}
        editable={!disabled}
        extensions={[
          StarterKit,
          Image,
          Typography,
          Placeholder.configure({ placeholder: placeholder ?? "" }),
          Link.configure({
            validate: (value) => URL_REGEX_STRICT.test(value),
          }).extend({
            inclusive: false,
            // When you continue typing after entering a link,
            // cursor should enter new text _outside_ of link.
          }),
        ]}
        onUpdate={({ editor }) => setValue(editor.getHTML())}
        onFocus={() => setTouched(true)}
        onBlur={({ editor }) =>
          onBlur?.({
            target: {
              name,
              value: editor.getHTML(),
            },
          })
        }
        editorProps={{
          attributes: {
            role: "textbox",
            "aria-multiline": true,
            "aria-label": "Rich Text Editor, main",
            ...(disabled ? { "aria-readonly": true } : {}),
            ...(placeholder ? { "aria-placeholder": placeholder } : {}),
            lang: "en",
            dir: "ltr",
          },
        }}
      >
        <RichTextControls
          disabled={disabled}
          field={field}
          enableInSessionArti={enableInSessionArti}
          onArtiResponseSelect={onArtiResponseSelect}
          artiInSessionData={artiInSessionData}
        />
      </EditorProvider>
    </div>
  )
})`
  width: 100%;
  position: relative;

  &.disabled {
    cursor: text;
    opacity: 0.6;
  }

  &:not(.disabled) {
    .tiptap:focus-visible,
    .tiptap:hover {
      box-shadow: var(--lift-6);
    }
  }

  --editor-controls-height: var(--spacing-6);

  .tiptap {
    padding: var(--spacing-2) var(--spacing-3);
    padding-bottom: var(--editor-controls-height);
    outline: none;
    font-weight: 400;
    border-radius: var(--border-radius);
    font-size: 16px;
    max-width: calc(100% - 2px); /* TODO: Fix hack */
    box-shadow: var(--blur-4);

    /* Placeholder styles: */
    p.is-editor-empty:first-child::before {
      color: var(--placeholder);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    min-height: calc(100px - var(--editor-controls-height));

    .medium & {
      min-height: calc(140px - var(--editor-controls-height));
    }

    .large & {
      min-height: calc(250px - var(--editor-controls-height));
    }

    /* List styling: */
    ul,
    ol {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding-left: 2rem;

      li {
        margin: 0;
      }

      ul,
      ol {
        margin: 0; /* no vertical margins on nested lists */
      }

      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    em {
      color: unset;
      /* Unset our theme's gray coloring of <em> elements because we we don't
      want italic text to turn gray within rich-text editors. */
    }
  }
`

const RichTextControls = styled(function RichTextControls({
  className,
  field,
  disabled = false,
  enableInSessionArti = false,
  onArtiResponseSelect,
  artiInSessionData = {},
}) {
  const { setModal } = useModal()
  const { editor } = useCurrentEditor()

  useEffectAfterChange(() => {
    if (editor && field.value !== editor.getHTML()) {
      editor.commands.setContent(field.value)
    }
  }, [field, editor])

  if (!editor) {
    return null
  }
  function format(control) {
    switch (control) {
      case "bold":
        return editor.chain().focus().toggleBold().run()
      case "italic":
        return editor.chain().focus().toggleItalic().run()
      case "ul":
        return editor.chain().focus().toggleBulletList().run()
      case "ol":
        return editor.chain().focus().toggleOrderedList().run()
      case "link": {
        const { from, to } = editor.view.state.selection
        const originalSelectionRange = { from, to }

        let linkTextRange = { from, to }

        if (from === to) {
          // In cases where there is no current text selection (from === to) the
          // cursor may be placed somewhere within or around an existing link. If so:
          // - We'll get the existing link's text with getMarkRange.
          // - Setting text selection to mark range allows reading correct URL below.
          const linkMark = editor.state.schema.marks.link
          let markRange = getMarkRange(editor.state.doc.resolve(from), linkMark)
          if (!markRange) {
            // In cases where cursor is at start of link, no markRange will be found.
            // Try getting it again with cursor one character forward to fix this:
            markRange = getMarkRange(editor.state.doc.resolve(from + 1), linkMark)
          }
          if (markRange) {
            linkTextRange = markRange
            editor.chain().setTextSelection(markRange).run()
            // In cases where cursor is within a link, setting text selection here
            // allows the correct reading of linkUrl and linkText below.
          }
        }

        const linkUrl = editor.getAttributes("link").href ?? ""
        const linkText = editor.state.doc.textBetween(linkTextRange.from, linkTextRange.to, "")

        async function updateLink(values) {
          const newUrl = values.url?.trim() ?? ""
          const newText = values.text?.trim() ?? ""
          const { from, to } = editor.view.state.selection
          if (newUrl.length) {
            // Set link:
            editor
              .chain()
              .focus()
              // Update link display text in editor:
              .insertContentAt({ from, to }, newText || newUrl)
              // Need to set text selection first, or else .setLink below won't work:
              .setTextSelection({ from, to: from + (newText || newUrl).length })
              // Edit whole link mark even if only part of it is currently selected:
              .extendMarkRange("link")
              // Set the link url:
              .setLink({ href: ensureAbsoluteUrlHasProtocol(newUrl) })
              // Place cursor at END of link when focus returns to editor:
              .setTextSelection(from + (newText || newUrl).length)
              .run()
          } else {
            // Remove link:
            editor
              .chain()
              .focus()
              .unsetLink()
              // Maintain original cursor position / text selection state:
              .setTextSelection(originalSelectionRange)
              .run()
          }
        }

        setModal({
          title: linkUrl?.length ? "Edit link" : "Add link",
          content: <UrlModal url={linkUrl} text={linkText} onSubmit={updateLink} setModal={setModal} />,
        })

        return null
      }
      default:
        return null
    }
  }
  return (
    <View
      className={cn(className, "p-xs", { disabled })}
      $gap="var(--spacing-2)"
      role="toolbar"
      aria-orientation="horizontal"
      aria-label="Editor toolbar"
      $alignItems="center"
    >
      {!!enableInSessionArti && (
        <ArtiInSessionButton
          onArtiResponseSelect={artiInSessionData?.onArtiResponseSelect ?? onArtiResponseSelect}
          initialChatType={artiInSessionData?.initialChatType}
          initialMessage={artiInSessionData?.initialMessage}
          initialChoiceMessage={artiInSessionData?.initialChoiceMessage}
          isCopyOption={!!artiInSessionData?.isCopyOption}
        />
      )}
      <Tooltip top title="Bold ⌘-B">
        <BoldIcon onClick={() => format("bold")} />
      </Tooltip>
      <Tooltip top title="Italic ⌘-I">
        <ItalicIcon onClick={() => format("italic")} />
      </Tooltip>
      <Tooltip top title="Link">
        <LinkIcon onClick={() => format("link")} />
      </Tooltip>
      <Tooltip top title="Bulleted List">
        <ListIcon onClick={() => format("ul")} />
      </Tooltip>
      <Tooltip top title="Numbered List">
        <ListOlIcon onClick={() => format("ol")} />
      </Tooltip>
    </View>
  )
})`
  width: fit-content;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 3px; /* center controls in corner of editor area */

  &.disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  *:hover {
    cursor: pointer;
    color: var(--gray-6);
  }
`

const UrlModal = styled(function UrlModal({ className, url = null, text = null, onSubmit, setModal }) {
  const urlInput = useRef(null)
  useEffectAfterFirstRender(() => urlInput.current?.focus())
  // Note: autoFocus prop doesn't work on InputField below for some reason.
  const textInputId = uniqueId("rich-text-editor-link-modal-text-input-")
  const urlInputId = uniqueId("rich-text-editor-link-modal-url-input-")
  const schema = Yup.object().shape({
    text: Yup.string().label("Link text"),
    url: Yup.string().matches(URL_REGEX_PERMISSIVE, "Please enter a valid URL.").label("Link URL"),
  })
  return (
    <div className={cn(className, "full-width")}>
      <Formik
        validationSchema={schema}
        initialValues={{ url: url || text || "", text: text || "" }}
        onSubmit={(values) => {
          setModal(null)
          onSubmit(values)
        }}
      >
        <Form>
          <label htmlFor="text" id={textInputId} className="text-semi-bold">
            Text
          </label>
          <InputField
            name="text"
            aria-labelledby={textInputId}
            className="full-width mt-xxs mb-medium"
            placeholder="Leave blank to use link as text"
          />
          <label htmlFor="url" id={urlInputId} className="text-semi-bold">
            Link
          </label>
          <InputField name="url" aria-labelledby={urlInputId} inputRef={urlInput} className="full-width mt-xxs" />
          <View $justifyContent="space-between" $flexDirection="row-reverse" className="mt-xxl">
            <SubmitButton className="m-none">OK</SubmitButton>
            {!!url?.length && (
              <Button
                className="tertiary m-none"
                onClick={() => {
                  setModal(null)
                  onSubmit({ url: "", text: "" })
                }}
              >
                Remove link
              </Button>
            )}
          </View>
        </Form>
      </Formik>
    </div>
  )
})`
  .field-message {
    margin-bottom: -2rem;
    /* prevent field validation error message from taking up space */
    /* to fix issue with modal close button shifting on initial click */
  }
`

const RichTextField = (props) => <InputField forwardedAs={RichText} wrapperAs="div" {...props} />

export default RichTextField
