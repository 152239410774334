import { Form, Formik } from "formik"
import { find, shuffle, first, last } from "lodash-es"
import { styled } from "styled-components"

import { useKitSession } from "domains/KitSession/KitSessionContext"
import SelectField from "forms/fields/SelectField"
import Button from "ui/Button"
import View from "ui/View"
import { scrollToTop } from "utils/browser"

const TalentMashupCompareUsersForm = styled(function TalentMashupCompareUsersForm({
  selectedUser1,
  selectedUser2,
  setSelectedUser1,
  setSelectedUser2,
  sortedUsers,
  realtimeShareoutUserActive,
  pageSyncEnabled,
  className,
}) {
  const { isFacilitator } = useKitSession()
  const getUserChangeFunction =
    (setSelectedUserFunction) =>
    ({ target }) => {
      const user = find(sortedUsers, {
        id: parseInt(target.value),
      })
      setSelectedUserFunction(user)
    }

  const randomUsers = () => {
    const shuffledUsers = shuffle(sortedUsers)
    setSelectedUser1(first(shuffledUsers))
    setSelectedUser2(last(shuffledUsers))
    scrollToTop()
  }

  const options = sortedUsers.map(({ id, short_name }) => (
    <option key={id} value={id}>
      {short_name}
    </option>
  ))

  return (
    <div className={className}>
      <Formik initialValues={{ team_member_1: selectedUser1?.id, team_member_2: selectedUser2?.id }}>
        <Form>
          {!realtimeShareoutUserActive || !pageSyncEnabled || (!!realtimeShareoutUserActive && isFacilitator) ? (
            <View $flexWrap="wrap" $alignItems="flex-end">
              <SelectField
                label="Team Member 1:"
                name="team_member_1"
                className="team-member-select"
                size="medium"
                value={selectedUser1?.id}
                onChange={getUserChangeFunction(setSelectedUser1)}
              >
                {options}
              </SelectField>
              <SelectField
                label="Team Member 2:"
                name="team_member_2"
                className="team-member-select"
                size="medium"
                value={selectedUser2?.id}
                onChange={getUserChangeFunction(setSelectedUser2)}
              >
                {options}
              </SelectField>
              <Button className="tertiary mb-large" onClick={randomUsers}>
                Pick for me
              </Button>
            </View>
          ) : (
            <View className="mb-medium" $justifyContent="space-between">
              <View $gap="4px" $justifyContent="flex-start">
                <h3 className="mb-small">Team Member 1: </h3>
                <h3>{selectedUser1?.short_name}</h3>
              </View>
              <View $gap="4px" $justifyContent="flex-end">
                <h3 className="mb-small">Team Member 2: </h3>
                <h3>{selectedUser2?.short_name}</h3>
              </View>
            </View>
          )}
        </Form>
      </Formik>
    </div>
  )
})`
  .team-member-select {
    max-width: 360px;
    margin-bottom: 24px;
  }
`

export default TalentMashupCompareUsersForm
