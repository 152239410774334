import { keyBy } from "lodash-es"

import { useKitSession } from "../KitSessionContext"

import { getUserExerciseInstance } from "domains/Exercise/results_utils"
import FeedbackFlexUserResults from "domains/Exercise/ResultsComponents/FeedbackFlexUserResults"
import SessionSelectedUsersShareView from "domains/KitSession/components/SessionSelectedUsersShareView"
import StepContent from "domains/KitSession/components/StepContent"
import { useSessionExerciseInstances, useTeamLevelExerciseInstance } from "resources/exercise"
import { useKitParticipants } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useSelectedUser } from "ui/SelectedUserContext"
import TextContainer from "ui/TextContainer"
import { useHasTeamFeature } from "utils/team"

const FeedbackFlexShareStep = ({ sessionStep, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 10000,
  })

  if (isFetching && !exerciseInstances) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <TextContainer className="text-gray-9">
        <h2 className="text-gray-9 mb-medium">Deliver the feedback</h2>
        <p className="mb-medium">
          Now each person will get a turn to share their feedback with their recipient. If you are receiving feedback,
          your only job is to say thank you. After everyone has shared, take a moment and share how that felt.
        </p>
      </TextContainer>
      <SessionSelectedUsersShareView
        sessionStep={sessionStep}
        kitInstance={kitInstance}
        minMinutesPerUser={2}
        maxMinutesPerUser={3}
      >
        <UserResults exerciseInstances={exerciseInstances} kitInstance={kitInstance} team={team} />
      </SessionSelectedUsersShareView>
    </StepContent>
  )
}

const UserResults = ({ exerciseInstances, kitInstance, team }) => {
  const { preview } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: allParticipants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })
  const { selectedUser } = useSelectedUser()
  const selectedExercise = getUserExerciseInstance(exerciseInstances, selectedUser)
  const { data: teamLevelExerciseInstance, isInitialLoading: isInitialLoadingTeamLevelExerciseInstance } =
    useTeamLevelExerciseInstance({
      teamId: kitInstance.team_id,
      kitInstanceId: kitInstance?.id,
      slug: kitInstance.kit.exercise.slug,
      sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    })

  if (!selectedExercise || isInitialLoadingTeamLevelExerciseInstance) {
    return null
  }

  const participantIdMap = keyBy(allParticipants, "id")

  return (
    <FeedbackFlexUserResults
      selectedUser={selectedUser}
      teamLevelExerciseInstance={teamLevelExerciseInstance}
      exercise={selectedExercise}
      userIdMap={participantIdMap}
    />
  )
}

export default FeedbackFlexShareStep
