import { Link, Navigate, useNavigate } from "react-router-dom"

import { TeamSelector } from "components/TeamSelector"
import KitTile from "domains/LeaderKit/KitsTableofContents/components/KitTile"
import KitTileRevamp from "domains/LeaderKit/KitsTableofContents/components/KitTileRevamp"
import { CircleInfoIcon } from "icons/FontAwesomeIcons"
import { useUserAccount } from "resources/billing"
import { useLatestUserExerciseInstances } from "resources/exercise"
import { useBasicKitInfos, useTeamKitInstances, useScheduleNextKits } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useModal } from "ui/ModalContext"
import { useSelectedTeamIdWithTeamIdQueryParam } from "ui/SelectedTeamContext"
import View from "ui/View"
import { isLeadOrColead } from "utils/team"

const ScheduleTeamSessionsStepComponent = () => {
  const features = useFeatures()
  const navigate = useNavigate()
  const { setModal } = useModal()
  const { selectedTeamId, redirectTeamId } = useSelectedTeamIdWithTeamIdQueryParam()
  const { data: selectedTeam } = useTeam({ teamId: selectedTeamId })
  const { data: user } = useUser({ userId: "me" })
  const { data: kitInstances } = useTeamKitInstances({ teamId: selectedTeam?.id })
  const { data: basicKitInfos } = useBasicKitInfos({ teamId: selectedTeam?.id })
  const { data: scheduleNextKitInfos } = useScheduleNextKits({
    teamId: selectedTeam?.id,
    includeScheduled: true,
  })
  const { data: exerciseInstances } = useLatestUserExerciseInstances({ teamId: selectedTeam?.id, userId: user?.id })
  const { data: userAccount } = useUserAccount(selectedTeam?.account_id)

  if (redirectTeamId) {
    return <Navigate to={`/setup/schedule-team-sessions?team_id=${redirectTeamId}`} replace />
  }

  const stepTitle = <h3 className="mb-medium">Schedule your first session</h3>

  if (!kitInstances || !basicKitInfos || !userAccount || !selectedTeam || !exerciseInstances) {
    return (
      <>
        {stepTitle}
        <Loading className="mt-xxxxl" />
      </>
    )
  }

  const isStarted = (kitInstance) => !!(kitInstance.session_started_at || kitInstance.session_completed_at)
  const started = new Set(kitInstances.filter(isStarted).map(({ slug }) => slug))
  const preferred = new Set(["psychological-safety"])

  // Choose kit info based on "preferred" list, and whether session has been started.
  // - If no "preferred" kits are started, pick 1st preferred-list kit.
  // - If all "preferred" kits are started, pick 1st non-started "schedule-next" kit.
  // - If all "schedule-next" kits are started, pick 1st available kit that's not.
  // - If all available kits have been started, pick 1st "preferred" kit.
  const kitInfo =
    basicKitInfos.find(({ slug }) => preferred.has(slug) && !started.has(slug)) ||
    scheduleNextKitInfos.find(({ slug }) => !started.has(slug)) ||
    basicKitInfos.find(({ slug }) => !started.has(slug)) ||
    basicKitInfos.find(({ slug }) => preferred.has(slug)) ||
    basicKitInfos[0] // Fall back to first available kit if nothing else is found.

  const kitInstance = kitInstances.find(({ slug }) => slug === kitInfo.slug)
  const exerciseSlug = kitInstance?.basic_kit?.exercise?.slug ?? null
  const exerciseInstance = exerciseInstances.find(({ slug }) => slug === exerciseSlug)

  const isConnectAccount = !!userAccount?.is_connect_account

  const showModal = () =>
    setModal({
      title: "Scheduling tips",
      content: <SchedulingTips />,
      size: "medium",
      styles: { content: { overflow: "auto" } },
    })

  const onTeamChange = ({ value }) => {
    navigate(`/setup/schedule-team-sessions?team_id=${value}`)
  }

  const KitTileComponent = !!features[FLAGS.RTDEV_KITS_TAB_REVAMP] ? KitTileRevamp : KitTile

  return (
    <div>
      {stepTitle}
      <TeamSelector selectedTeam={selectedTeam} onTeamChange={onTeamChange} user={user} width={null} />
      <p className="mt-medium mb-small">
        Select a date and time below, then press Add to Calendar to copy session instructions to your calendar. To
        finish setting up your session, add your team to the calendar invite, as well as Zoom or video conference link,
        and physical conference room (if needed).
      </p>
      <p className="mb-small">
        You don’t need to invite your team to the app. They will be prompted to set up accounts at the beginning of the
        session.
      </p>
      <View $justifyContent="end" className="mb-medium">
        <p className="text-gray-7 text-semi-bold cursor-pointer" onClick={showModal}>
          Scheduling tips
          <CircleInfoIcon className="ml-xs" />
        </p>
      </View>
      <KitTileComponent
        kitInfo={kitInfo}
        kitInstance={kitInstance}
        exerciseInstance={exerciseInstance}
        user={user}
        selectedTeam={selectedTeam}
        initiallyExpanded={true}
        isConnectAccount={isConnectAccount}
        isScheduleModeActive={false}
        isSetupStep={true}
        isCurrentTeamAnyLead={isLeadOrColead(user, selectedTeam)}
      />
      <View className="mt-medium">
        <a
          href="https://risingteam.com/faq"
          target="_blank"
          rel="noopener noreferrer"
          className="text-semi-bold mr-medium"
        >
          FAQ
        </a>
        {!!user.can_manage_billing_for_non_trial_account && (
          <Link to="/kit" className="text-semi-bold">
            See all kits
          </Link>
        )}
      </View>
    </div>
  )
}

const SchedulingTips = () => (
  <div className="text-normal space-y-small">
    <p>
      Sessions can be done remotely or in-person, as long as everyone has a computer or mobile device and internet
      access.
    </p>
    <p>
      <span className="text-bold">Session length:</span> Our calendar invites book what should be the maximum amount of
      time for your team to complete each session. On average, most teams finish in less time, but larger and more
      extroverted teams can take longer.
    </p>
    <p>
      <span className="text-bold">Team size:</span> Rising Team sessions are highly interactive with frequent
      opportunities for sharing. To ensure everyone has time to share we limit the team size to a facilitator + 10
      participants.
    </p>
    <p>
      <span className="text-bold">Timing:</span> We recommend completing a session every 4-6 weeks to build consistent
      growth and connection with your team.
    </p>
    <p>
      <span className="text-bold">Recommended order:</span> Available kits are presented in the order recommended for
      most teams, but feel free to adjust the order if there is a topic that is especially relevant to your team’s
      immediate needs.
    </p>
  </div>
)

export default ScheduleTeamSessionsStepComponent
