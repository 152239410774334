import { CHAT_TYPE } from "../constants"

import View from "ui/View"

const ArtiInSessionChatIntro = ({ chatType }) => {
  let introText = null
  if (chatType === CHAT_TYPE.ACTION_ITEMS) {
    introText = <h4>Let’s brainstorm potential action items.</h4>
  } else if (chatType === CHAT_TYPE.PSYCHOLOGICAL_SAFETY_ANIMAL) {
    introText = <p className="text-semi-bold">Let’s brainstorm ideas for the animal you are most like at work.</p>
  } else if (chatType === CHAT_TYPE.AT_MY_BEST_NICKNAME) {
    introText = <p className="text-semi-bold">Let’s brainstorm ideas for your best nickname.</p>
  }

  return (
    <View className="intro" $flexDirection="column">
      <div className="mb-xxs text-semi-bold">
        <img src="https://static.risingteam.com/rtai/arti-chat-icon.png" width="16px" alt="arti-chat-icon" />
        <span className="ml-xxs">aRTi</span>
      </div>
      <View $flexDirection="column" className="mb-medium px-medium py-small message bot">
        {introText}
        <p className="text-normal">
          Would you like me to base my suggestions on what you’ve written so far or would you like to add any more
          details?
        </p>
      </View>
    </View>
  )
}

export default ArtiInSessionChatIntro
