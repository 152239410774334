import { format as formatDate, parseISO } from "date-fns"
import fileDownload from "js-file-download"
import { fromPairs } from "lodash-es"
import { useState } from "react"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import HubSpotModal from "components/HubSpotModal"
import { SurveyQuestionType } from "domains/EngagementSurvey/constants"
import { ALL_TEAMS, TEAM_OTHER } from "domains/Reports/constants"
import { CopyIcon, CircleInfoIcon, TriangleExclamationIcon, SparklesIcon } from "icons/FontAwesomeIcons"
import { useAccount } from "resources/billing"
import {
  getEngagementSurveyResultsExport,
  useEngagementSurveyAverage,
  useEngagementSurveyLink,
  useEngagementSurveyAISummary,
  useAccountReportAIAccess,
} from "resources/teams"
import AnswerDisplayContent from "ui/AnswerDisplayContent"
import Button from "ui/Button"
import CopyButton from "ui/CopyButton"
import DownloadCSVLink from "ui/DownloadCSVLink"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import LineChart from "ui/LineChart"
import Loading from "ui/Loading"
import { useModal } from "ui/ModalContext"
import NpsChart from "ui/NpsChart"
import SegmentChart from "ui/SegmentChart"
import { colors } from "ui/theme"
import Tooltip from "ui/Tooltip"
import TrendLineChart from "ui/TrendLineChart"
import View from "ui/View"

const SURVEY_MESSAGE_PROMPT = {
  firstSurvey: {
    message:
      "I’m excited to share that we’re going to use a new tool called Rising Team " +
      "to help us build deeper connections and work even better together as a team. " +
      "Before we start, we’re going to take a short survey to capture some key team health questions. " +
      "We'll run this survey once now before we start the " +
      "Rising Team sessions, and then every few months to check in. " +
      "Responses are only used to generate team averages so no one will see who answered what. " +
      "Here is a link to the survey—it's very brief, so please take a few minutes to fill it out today! ",
    prompt:
      "Ask your team to complete this survey by sending this message " +
      "by email or in your favorite messaging platform. Feel free to edit!",
  },
  runSurvey: {
    message:
      "You may remember filling out a brief survey when we first started using Rising Team. " +
      "This survey is designed to help me gauge our connection " +
      "and team health, and learn how Rising Team impacts that over time. " +
      "It's been a while so I'd like us all to take it " +
      "again to check in on the same set of questions as before. " +
      "As a reminder, responses are entirely anonymous and only used to generate averages. " +
      "The survey takes only a few minutes to complete, so please take a moment to fill it out! ",
    prompt:
      "Run the survey again to track progress over time. To start a new session of the survey, send " +
      "this message by email or in your favorite messaging platform. Feel free to edit!",
  },
  sendReminder: {
    message:
      "Friendly reminder to ️please fill out ️the Rising Team survey I sent around earlier. " +
      "It only takes a few minutes to do and it helps me understand how " +
      "we're feeling collectively and how we’re working together as a team. " +
      "Thank you to those who have filled it out so far! For those who haven’t yet, here's the link: ",
    prompt:
      "Remind your team to complete the survey by sending this message " +
      "by email or in your favorite messaging platform. Feel free to edit!",
  },
}

const AGREE_DISAGREE_AXIS_LEFT_LABEL = {
  1: "Strongly disagree",
  2: "Disagree",
  3: "Somewhat disagree",
  4: "Neutral",
  5: "Somewhat agree",
  6: "Agree",
  7: "Strongly agree",
}

const NUMBER_AXIS_LEFT_LABEL = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
}

const NPS_AXIS_LEFT_LABEL = {
  "-100": "-100",
  "-75": "-75",
  "-50": "-50",
  "-25": "-25",
  0: "0",
  25: "25",
  50: "50",
  75: "75",
  100: "100",
}

const agreeDisagreeColors = (y) => {
  if (y >= 6) {
    return "var(--green-4)"
  } else if (y >= 5) {
    return "var(--green-2)"
  } else if (y >= 3) {
    return "var(--gray-6)"
  } else if (y >= 2) {
    return "var(--rising-yellow)"
  } else {
    return "var(--orange-3)"
  }
}

const numberColors = (y) => {
  if (y >= 9) {
    return "var(--green-4)"
  } else if (y >= 8) {
    return "var(--green-2)"
  } else if (y >= 6) {
    return "var(--gray-6)"
  } else if (y >= 4) {
    return "var(--rising-yellow)"
  } else {
    return "var(--orange-3)"
  }
}

const npsColors = (y) => {
  if (y >= 71) {
    return "var(--green-4)"
  } else if (y >= 30) {
    return "var(--green-2)"
  } else if (y >= 1) {
    return "var(--gray-6)"
  } else {
    return "var(--orange-3)"
  }
}

const EngagementSurveyResults = styled(function EngagementSurveyResults({
  className,
  accountId,
  selectedTeam = null,
  allTeamsSelected = false,
  teamOtherSelected = false,
  teamTags = [],
  teamsMatchingTags = [],
  includeArchivedTeams = false,
  hideIncompleteSurveyeeResponses = false,
}) {
  const [fetchEngagementSurveyAIData, setFetchEngagementSurveyAIData] = useState(false)
  const features = useFeatures()

  let teamId = selectedTeam?.id ?? null
  if (allTeamsSelected) {
    teamId = ALL_TEAMS
  } else if (teamOtherSelected) {
    teamId = TEAM_OTHER
  }

  const { data } = useEngagementSurveyAverage({
    accountId,
    teamId,
    teamTags,
    includeArchivedTeams,
    hideIncompleteSurveyeeResponses,
  })

  const { data: aiAccess } = useAccountReportAIAccess({
    enabled: !!features[FLAGS.RTDEV_AI_SUMMARIZATION_ENGAGEMENT_SURVEY],
    accountId,
  })
  const canAISummarize = !!aiAccess?.has_access && !!features[FLAGS.RTDEV_AI_SUMMARIZATION_ENGAGEMENT_SURVEY]

  const { data: engagementSurveyAIData, isFetching: isFetchingEngagementSurveyAIData } = useEngagementSurveyAISummary({
    enabled: !!(features[FLAGS.RTDEV_AI_SUMMARIZATION_ENGAGEMENT_SURVEY] && fetchEngagementSurveyAIData),
    accountId,
    teamId,
    teamTags,
    includeArchivedTeams,
    hideIncompleteSurveyeeResponses,
  })

  let surveyTeam
  if (teamTags?.length && (teamsMatchingTags?.length ?? 0) > 1) {
    surveyTeam = null
  } else if (teamTags?.length && teamsMatchingTags?.length === 1) {
    surveyTeam = teamsMatchingTags[0]
  } else if (selectedTeam) {
    surveyTeam = selectedTeam
  } else {
    surveyTeam = null
  }

  const { data: surveyLink, isFetching } = useEngagementSurveyLink({ accountId, teamId: surveyTeam?.id })
  const { data: account, isFetching: isFetchingAccount } = useAccount(accountId)
  const { setModal } = useModal()

  if (!data || isFetching || isFetchingAccount) {
    return null
  }

  if (data.survey_unavailable) {
    return <EngagementSurveyUpsell className={className} />
  }

  const surveyQuestions = data.survey_questions

  const isConnectAccount = account?.is_connect_account

  const downloadCSVExport = async () => {
    const csvExport = await getEngagementSurveyResultsExport({
      accountId,
      teamId,
      teamTags,
      includeArchivedTeams,
      hideIncompleteSurveyeeResponses,
    })
    fileDownload(csvExport, "team_health_survey_results_export.csv")
  }

  const viewSurvey = () => {
    window.open(surveyLink, "_blank")
  }

  const openSurveyModal = (title, message, prompt) =>
    setModal({
      content: <SurveyModal message={message} prompt={prompt} surveyLink={surveyLink} surveyTeam={surveyTeam} />,
      title,
      size: "medium",
    })

  const hasAtLeastTwoSurveys = data.survey_count >= 2

  return (
    <div className={className}>
      <View $alignItems="center" $justifyContent="space-between" className="mb-large">
        <h2>
          <View>Team health survey results</View>
        </h2>
        {!!hasAtLeastTwoSurveys && (
          <div>
            <Button
              color={colors.risingBlue}
              className="link-semi-bold text-small"
              onClick={() =>
                openSurveyModal(
                  "Run survey again",
                  SURVEY_MESSAGE_PROMPT.runSurvey.message,
                  SURVEY_MESSAGE_PROMPT.runSurvey.prompt
                )
              }
            >
              Run survey again
            </Button>
            <Button color={colors.risingBlue} className="link-semi-bold text-small" onClick={viewSurvey}>
              View survey
            </Button>
          </div>
        )}
      </View>
      {hasAtLeastTwoSurveys ? (
        <>
          <View $alignItems="center" className="mb-medium">
            <b className="ml-xl">Participants</b>
            <span className="mx-medium">{data.latest_run_survey_count} (latest run)</span>
            <Button
              color={colors.risingBlue}
              className="link-semi-bold text-small"
              onClick={() =>
                openSurveyModal(
                  "Send survey reminder",
                  SURVEY_MESSAGE_PROMPT.sendReminder.message,
                  SURVEY_MESSAGE_PROMPT.sendReminder.prompt
                )
              }
            >
              Send reminder
            </Button>
          </View>
          <View $alignItems="center" className="mb-medium">
            <b className="ml-xl">Latest run</b>
            <span className="mx-medium">{formatDate(parseISO(data.latest_survey_run_start_date), "MM/dd/yyyy'")}</span>
          </View>
          <p>
            This survey measures how well your team is doing on key team health factors. In order to preserve anonymity,
            we only show results when 3 or more participants respond.
          </p>
        </>
      ) : (
        <>
          <p>
            This survey measures how well your team is doing on key team health factors. We recommend you ask your team
            to fill this out before you get started with Rising Team so that you can track progress.
          </p>
          <View $alignItems="center" $justifyContent="space-between">
            <h4 className="mt-large mb-small">How to run the survey with your team</h4>
            <Button color={colors.risingBlue} className="link-semi-bold text-small" onClick={viewSurvey}>
              View survey
            </Button>
          </View>
          <SurveyModal
            message={SURVEY_MESSAGE_PROMPT.firstSurvey.message}
            prompt={SURVEY_MESSAGE_PROMPT.firstSurvey.prompt}
            surveyLink={surveyLink}
            surveyTeam={surveyTeam}
          />
        </>
      )}
      <div className="my-large">
        {data.survey_count >= 3 ? (
          <>
            {!isConnectAccount && (
              <View $justifyContent="end">
                <DownloadCSVLink downloadCSVExport={downloadCSVExport} loadingIconLocation="left" />
              </View>
            )}
            <OverallEngagementRatingResult data={data} />
            {surveyQuestions.map((field, index) => (
              <div key={field.name}>{getSurveyQuestionResult({ field, index, data })}</div>
            ))}
            <NPSResult data={data} />
            {!!data.latest_survey_run_additional_comments && (
              <>
                <p className="text-field-label pb-medium">
                  {surveyQuestions.length + 3}. Additional comments (latest run):
                </p>
                <div className="pl-large">
                  {data.latest_survey_run_additional_comments.map(
                    (comment, idx) => comment && <AnswerDisplayContent key={idx} answer={comment} />
                  )}
                </div>
                {!!canAISummarize && (
                  <Button
                    disabled={isFetchingEngagementSurveyAIData || !!fetchEngagementSurveyAIData}
                    onClick={() => setFetchEngagementSurveyAIData(true)}
                    className="mt-small tertiary"
                  >
                    Generate Additional Comments AI Summary
                  </Button>
                )}
                {!!canAISummarize &&
                  !!fetchEngagementSurveyAIData &&
                  (!!isFetchingEngagementSurveyAIData ? (
                    <div className="my-small">
                      <h4 className="py-small">Generating your summary</h4>
                      <Loading inline />
                    </div>
                  ) : (
                    <div className="my-small">
                      <h4 className="mb-medium">
                        AI-Generated Summary <SparklesIcon className="text-rising-yellow" />
                      </h4>
                      <p className="pb-medium">
                        Below is an AI-generated summary of your team's optional responses regarding what they think is
                        going well, and what they think could be going better on your team. You can read their full
                        responses below.
                      </p>
                      <div className="answer bg-gray-1 border border-gray-4 border-radius py-small px-medium mb-large ai-summary">
                        {!!engagementSurveyAIData ? (
                          engagementSurveyAIData.output
                        ) : (
                          <p className="pb-medium">Unable to generate AI Summary</p>
                        )}
                      </div>
                    </div>
                  ))}
              </>
            )}
          </>
        ) : (
          <>
            {!!hasAtLeastTwoSurveys && (
              <View>
                <TriangleExclamationIcon className="fa-2x mr-small" color="var(--rising-yellow)" />
                <div>
                  <b>Alert: </b>Too few responses to show results. Results are hidden to retain anonymity.
                </div>
              </View>
            )}
          </>
        )}
      </div>
    </div>
  )
})`
  .tooltip-content {
    font-weight: 700;
  }

  .ai-summary {
    white-space: pre-wrap;
  }

  .trend-line-chart {
    padding-right: 50px;

    @media (max-width: ${({ theme }) => theme.mobileMax}) {
      padding-right: 0;
    }
  }
`

const SurveyModal = ({ message, prompt, surveyLink, surveyTeam = null }) => {
  const messageCopy = message + surveyLink
  return (
    <div>
      <div className="mb-small text-thin text-normal text-gray-8 border border-light border-radius p-xs bg-gray-1">
        <CircleInfoIcon className="mr-xs text-gray-8" />
        This survey is for{" "}
        {surveyTeam ? (
          <>
            team <span className="text-semi-bold">{surveyTeam.name}</span>
          </>
        ) : (
          <span className="text-semi-bold">all teams</span>
        )}
      </div>
      <p className="my-small text-thin text-normal">{prompt}</p>
      <p className="text-field-label mb-small">The message</p>
      <div className="mb-small text-thin text-normal text-gray-8 border border-light border-radius p-xs bg-gray-1">
        {messageCopy}
      </div>
      <CopyButton buttonClassName="link-semi-bold text-button" clipboardText={messageCopy}>
        <CopyIcon color="var(--rising-blue)" className="mr-small fa-lg" />
        Copy message and link
      </CopyButton>
    </div>
  )
}

const EngagementSurveyUpsell = ({ className }) => (
  <div className={className}>
    <View $alignItems="center" $justifyContent="space-between" className="mb-large">
      <h2>Team health survey results</h2>
    </View>
    <p>
      This survey measures how well your team is doing on key team health factors. To access to this survey you will
      need a subscription. <Link to="/subscribe">Click here to select a subscription</Link> or book a demo with us
      below.
    </p>
    <HubSpotModal />
  </div>
)

const getSurveyQuestionResult = ({ field, index, data }) => {
  const label = `${index + 1}. ${field.label}`
  switch (field.type) {
    case SurveyQuestionType.AGREE_SCALE_7_POINTS:
      return <AgreeScale7PointsResult field={field} label={label} data={data} />
    case SurveyQuestionType.LIKELY_SCALE_10_POINTS:
      return <LikelyScale10PointsResult field={field} label={label} data={data} />
    case SurveyQuestionType.MULTIPLE_CHOICE:
      return <MultipleChoiceResult field={field} label={label} data={data} />
    default:
      return null
  }
}

const AgreeScale7PointsResult = ({ field, label, data }) => {
  const trendData = filterTrendData(data, field.name)
  if (!trendData) {
    return null
  }

  return (
    <div>
      <p className="text-field-label my-medium">
        {label}
        {data.survey_run_count > 1 ? (
          <>
            {" "}
            - <span className="text-rising-orange">Current Score: {latestRunTrendData(data, field.name)["y"]}</span>
          </>
        ) : (
          <>
            : <span className="text-rising-orange">{trendData["y"]} (average)</span>
          </>
        )}
      </p>
      {data.survey_run_count > 1 ? (
        <View $width="100%" $height="350px" className="trend-line-chart">
          <TrendLineChart
            data={data.trend_data.filter((x) => x.id === field.name)}
            axistLeftLabels={AGREE_DISAGREE_AXIS_LEFT_LABEL}
            yScaleMax={7}
            leftMargin={190}
            hasNPSDetails={false}
            colorFunc={agreeDisagreeColors}
          />
        </View>
      ) : (
        <LineChart labels={data.agree_disagree_label} average={trendData["y"]} showTick={true} />
      )}
    </div>
  )
}

const LikelyScale10PointsResult = ({ field, label, data }) => {
  const trendData = filterTrendData(data, field.name)
  if (!trendData) {
    return null
  }

  return (
    <div>
      <p className="text-field-label my-medium">
        {label}
        {data.survey_run_count > 1 ? (
          <>
            {" "}
            - <span className="text-rising-orange">Current Score: {latestRunTrendData(data, field.name)["y"]}</span>
          </>
        ) : (
          <>
            : <span className="text-rising-orange">{trendData["y"]} (average)</span>
          </>
        )}
      </p>
      {data.survey_run_count > 1 ? (
        <View $width="100%" $height="400px" className="trend-line-chart">
          <TrendLineChart
            data={data.trend_data.filter((x) => x.id === field.name)}
            axistLeftLabels={NUMBER_AXIS_LEFT_LABEL}
            yScaleMax={10}
            gridYValues={10}
            tickValues={10}
            hasNPSDetails={false}
            colorFunc={numberColors}
          />
        </View>
      ) : (
        <LineChart
          labels={data.number_label}
          endLabels={data.likely_unlikely_label}
          average={trendData["y"]}
          showTick={false}
        />
      )}
    </div>
  )
}

const MultipleChoiceResult = ({ field, label, data }) => {
  if (field.result_type !== "scale") {
    return null
  }

  const trendData = filterTrendData(data, field.name)
  if (!trendData) {
    return null
  }

  const numOptions = field.options.length
  const yValueDistance = (numOptions - 1) / numOptions
  const gridYValues = Array.from({ length: numOptions + 1 }, (_, i) => i * yValueDistance + 1)
  const tickValueStart = 1 + yValueDistance / 2
  const tickValues = Array.from({ length: numOptions }, (_, i) => i * yValueDistance + tickValueStart)
  const axistLeftLabels = fromPairs([
    ...gridYValues.map((y) => [y, ""]),
    ...tickValues.map((y, idx) => [y, field.options[idx].label]),
  ])

  const colorFunc = (y) => {
    for (let i = field.options.length - 1; i >= 0; i--) {
      const lowerBound = gridYValues[i]
      if (y >= lowerBound) {
        return field.options[i].color
      }
    }
    return "var(--orange-3)"
  }

  const segmentLabels = field.options.map((option) => option.label)
  const segmentBounds = field.options.map((option, idx) => ({
    lower_bound: gridYValues[idx],
    upper_bound: gridYValues[idx + 1],
  }))

  return (
    <div>
      <p className="text-field-label my-medium">
        {label}
        {data.survey_run_count > 1 ? (
          <>
            {" "}
            - <span className="text-rising-orange">Current Score: {latestRunTrendData(data, field.name)["value"]}</span>
          </>
        ) : (
          <>
            : <span className="text-rising-orange">{trendData["value"]} (average)</span>
          </>
        )}
      </p>
      {data.survey_run_count > 1 ? (
        <View $width="100%" $height="350px" className="trend-line-chart">
          <TrendLineChart
            data={data.trend_data.filter((x) => x.id === field.name)}
            axistLeftLabels={axistLeftLabels}
            yScaleMax={4}
            gridYValues={gridYValues}
            tickValues={tickValues}
            tickSize={0}
            leftMargin={180}
            useCustomGridLine={true}
            hasNPSDetails={false}
            colorFunc={colorFunc}
          />
        </View>
      ) : (
        <SegmentChart labels={segmentLabels} average={trendData["y"]} bounds={segmentBounds} />
      )}
    </div>
  )
}

const OverallEngagementRatingResult = ({ data }) => {
  const surveyQuestions = data.survey_questions
  const numberedSurveyQuestions = surveyQuestions.map((question, idx) => [idx + 1, question])
  const numberedCategoryEngagementQuestions = numberedSurveyQuestions.filter(
    ([_num, question]) => question.category === "engagement"
  )
  const categoryEngagementQuestions = numberedCategoryEngagementQuestions.map(([_num, question]) => question)
  const questionNumbers = numberedCategoryEngagementQuestions.map(([num, _question]) => num)

  if (!categoryEngagementQuestions.length) {
    return null
  }

  const trendData = filterTrendData(data, "overall_engagement")
  if (!trendData) {
    return null
  }

  const questionNumberSequences = questionNumbers.reduce((acc, currentNumber, idx) => {
    const previousNumber = idx > 0 ? questionNumbers[idx - 1] : null

    if (previousNumber && currentNumber === previousNumber + 1) {
      // create a pair with the first number in the sequence and the last number in the sequence
      acc[acc.length - 1][1] = currentNumber
    } else {
      // start a new sequence
      acc.push([currentNumber])
    }

    return acc
  }, [])

  const questionNumbersStr = questionNumberSequences.map((sequence) => sequence.join("-")).join(", ")
  const description = `The overall team health rating is the average of questions ${questionNumbersStr}.`

  return (
    <div>
      <p key="overall" className="text-field-label">
        Overall team health rating
        {data.survey_run_count > 1 ? (
          <>
            {" "}
            -{" "}
            <span className="text-rising-orange">
              Current Score: {latestRunTrendData(data, "overall_engagement")["y"]}
            </span>
          </>
        ) : (
          <>
            : <span className="text-rising-orange">{trendData["y"]} (average)</span>
          </>
        )}
      </p>
      <p className="text-gray-8 my-medium">{description}</p>
      {data.survey_run_count > 1 ? (
        <View $width="100%" $height="350px" className="trend-line-chart">
          <TrendLineChart
            data={data.trend_data.filter((x) => x.id === "overall_engagement")}
            axistLeftLabels={AGREE_DISAGREE_AXIS_LEFT_LABEL}
            yScaleMax={7}
            leftMargin={190}
            hasNPSDetails={false}
            colorFunc={agreeDisagreeColors}
          />
        </View>
      ) : (
        <LineChart labels={data.agree_disagree_label} average={trendData["y"]} showTick={true} />
      )}
    </div>
  )
}

const NPSResult = ({ data }) => {
  const hasNPS = !!data.survey_questions.find((question) => question.category === "nps")
  if (!hasNPS) {
    return null
  }
  const trendData = filterTrendData(data, "nps")
  if (!trendData) {
    return null
  }

  const npsTooltipTitle = !trendData.nps_details ? (
    ""
  ) : (
    <>
      <div>NPS Score: {trendData.nps_details.nps_score}</div>
      <div>NPS Promoters: {trendData.nps_details.nps_promoters}</div>
      <div>NPS Passive: {trendData.nps_details.nps_passive}</div>
      <div>NPS Detractors: {trendData.nps_details.nps_detractors}</div>
    </>
  )
  const npsToolTip = (
    <Tooltip float title={npsTooltipTitle}>
      <View $alignItems="center" $justifyContent="center" $flexDirection="column">
        <p className="score-label text-semi-bold">NPS Score</p>
        <div className="score-tick"></div>
      </View>
    </Tooltip>
  )
  const npsExplanationTooltipTitle = (
    <>
      <h3 className="text-semi-bold">What’s NPS?</h3>
      <p className="text-thin">
        NPS stands for Net Promoter Score and is a measurement of favorability. It is calculated by measuring promoters
        (scores of 9 or 10) minus detractors (scores of 6 and below). Any NPS score above 0 is "good." It means that
        your employees are more loyal than not. Bain & Co., who created the NPS system, suggests that above 20 is
        favorable, above 50 is excellent, and above 80 is world class.
      </p>
    </>
  )

  return (
    <div>
      <View $flexDirection="row" className="mb-medium">
        <Tooltip float title={npsExplanationTooltipTitle}>
          <View $flexDirection="row" $alignItems="center">
            <p key="employee_nps" className="text-field-label">
              Employee NPS
              {data.survey_run_count > 1 ? (
                <>
                  {" "}
                  - <span className="text-rising-orange">Current Score: {latestRunTrendData(data, "nps")["y"]}</span>
                </>
              ) : (
                <>
                  : <span className="text-rising-orange ml-xxs">{trendData["y"]}</span>
                </>
              )}
            </p>
            <CircleInfoIcon className="ml-small text-gray-8" />
          </View>
        </Tooltip>
      </View>
      {data.survey_run_count > 1 ? (
        <View $width="100%" $height="400px" className="trend-line-chart">
          <TrendLineChart
            data={data.trend_data.filter((x) => x.id === "nps")}
            axistLeftLabels={NPS_AXIS_LEFT_LABEL}
            yScaleMin={-100}
            yScaleMax={100}
            gridYValues={10}
            tickValues={10}
            hasNPSDetails={true}
            colorFunc={npsColors}
          />
        </View>
      ) : (
        <NpsChart npsScore={trendData["y"]} npsToolTip={npsToolTip} bounds={data.nps_bounds} labels={data.nps_label} />
      )}
    </div>
  )
}

const latestRunTrendData = (data, trend_data_item) => {
  const trendDataArray = data.trend_data?.filter((x) => x.id === `${trend_data_item}`)[0].data
  const trendDataArrayLength = trendDataArray.length
  return trendDataArray[trendDataArrayLength - 1]
}

const filterTrendData = (data, trend_data_item) =>
  data.trend_data?.filter((x) => x.id === `${trend_data_item}`)[0]?.data[0]

export default EngagementSurveyResults
