import cn from "classnames"
import { Form, Formik } from "formik"
import { useState } from "react"

import KitTypePill from "domains/LeaderKit/KitsTableofContents/components/KitTypePill"
import ScheduleForm from "domains/LeaderKit/ScheduleForm"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { ClockIcon, CircleInfoIcon } from "icons/FontAwesomeIcons"
import { useScheduleNextKits } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useWindowSize from "ui/hooks/useWindowSize"
import Tooltip from "ui/Tooltip"
import View from "ui/View"
import { formatKitSessionTime } from "utils/kit"

const ScheduleSession = ({ kitInstance, children }) => {
  const selectedTeamId = kitInstance["team_id"]
  const { data: selectedTeam } = useTeam({ teamId: selectedTeamId })
  const { data: user } = useUser({ userId: "me" })
  const isFacilitator = kitInstance.facilitator_id === user.id

  const { data: scheduleNextKits } = useScheduleNextKits({
    teamId: selectedTeamId,
    skipIfHasScheduled: true,
    limit: null,
  })
  const { isMobileOrSmaller, isTabletOrLarger } = useWindowSize()
  const [selectedKit, setSelectedKit] = useState()

  useEffectAfterChange(() => {
    if (scheduleNextKits?.length && !selectedKit) {
      setSelectedKit(scheduleNextKits[0])
    }
  }, [selectedKit, scheduleNextKits])

  if (!scheduleNextKits?.length || !selectedKit || !isFacilitator) {
    return null
  }

  const onKitChange = ({ value }) => {
    setSelectedKit(scheduleNextKits.find((kit) => kit.slug === value) ?? null)
  }

  return (
    <div>
      {children}
      <View
        className="bg-white-2 lift-4 p-medium pr-none border-left-thick border-rising-yellow"
        $radius="8px"
        $alignSelf="stretch"
        $gap="16px"
        $alignItems="flex-start"
        $flexDirection="column"
      >
        <View className="fit-content" $alignItems="center">
          <Formik initialValues={{ kitSlug: selectedKit.slug }} enableReinitialize>
            <Form className="mr-small">
              <AdvancedSelectField
                name="kitSlug"
                onChange={onKitChange}
                options={scheduleNextKits.map((kit) => ({
                  value: kit.slug,
                  label: kit.title,
                }))}
              />
            </Form>
          </Formik>
          <Tooltip wrap right float className="inline-block fit-content" title={selectedKit.description}>
            <CircleInfoIcon className="align-self-center-mobile-never text-gray-8" />
          </Tooltip>
        </View>
        <View $alignItems="center" $justifyContent="right" className="fit-content">
          {(!!isMobileOrSmaller || !!isTabletOrLarger) && (
            <KitTypePill selectedKitInfo={selectedKit} className={cn("mr-xs", { "mb-xxs": isMobileOrSmaller })} />
          )}
          <p className="text-gray-8 text-nowrap">
            <ClockIcon className="text-gray-8 mr-xxs" />
            {formatKitSessionTime(selectedKit.session_time_minutes)}
          </p>
        </View>
        <ScheduleForm
          kitInstance={kitInstance}
          kitInfo={selectedKit}
          team={selectedTeam}
          className="fit-content mt-none"
        />
      </View>
    </div>
  )
}

export default ScheduleSession
