enum StorageKey {
  LastForcedReloadTimeMs = "RISINGTEAM_LAST_FORCED_RELOAD_TIME_MS",
  FirebaseAuthToken = "RISINGTEAM_FIREBASE_AUTH_TOKEN",
  FirebaseAuthUserName = "RISINGTEAM_FIREBASE_AUTH_USER_NAME",
  FirebaseAuthProviderInfo = "RISINGTEAM_FIREBASE_AUTH_PROVIDER_INFO",
  RaisedHandPickerUserIds = "RISINGTEAM_HAND_PICKER_PREV_SELECTED_USER_IDS_FOR_KIT_INSTANCE_ID",
}

function storageKeyWithId(storageKey: StorageKey, id: string): string {
  return `${storageKey}:${id}`
}

export { StorageKey, storageKeyWithId }
