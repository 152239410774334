import cn from "classnames"
import { useField } from "formik"
import type { Ref, ReactNode, ElementType, ChangeEvent, FocusEvent, ComponentProps, CSSProperties } from "react"
import { styled } from "styled-components"

import FieldMessage from "forms/FieldMessage"
import View from "ui/View"

interface BaseInputFieldProps {
  className?: string
  name: string
  type: string
  id?: string
  label?: ReactNode
  wrapperAs?: ElementType
  as?: ElementType
  saveOnChange?: (name: string, value: string) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  placeholder?: string
  size?: string
  autoFocus?: boolean
  style?: CSSProperties
  inputRef?: Ref<HTMLInputElement> | null
  disableAutoComplete?: boolean
  renderAfterField?: (props: { name: string; saveOnChange?: (name: string, value: string) => void }) => ReactNode
}

const BaseInputField = ({
  className,
  name,
  type,
  id,
  label,
  wrapperAs,
  as: AsComponent,
  saveOnChange,
  onChange,
  disabled,
  placeholder,
  size,
  autoFocus,
  style,
  inputRef = null,
  disableAutoComplete = false,
  renderAfterField,
  ...props
}: BaseInputFieldProps & ComponentProps<"input">) => {
  const [field, meta] = useField(name)
  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    field.onBlur(event)
    if (props.onBlur) {
      props.onBlur(event)
    }
    if (saveOnChange && !meta.error) {
      saveOnChange(name, event.target.value)
    }
  }
  const fieldOnChange = function (event: ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(event)
    } else {
      field.onChange(event)
    }
  }
  AsComponent = AsComponent || StyledInput

  const editInactive = !field.value

  if (disableAutoComplete) {
    props = { ...props, autoComplete: "one-time-code" }
    // As of 2023 autocomplete="one-time-code" works to disable autocomplete in
    // Chrome, Firefox, and Safari. Other values that DON'T work include:
    // "off", "false", "none", "no", "new-password"
    // https://stackoverflow.com/a/30976223/11776945
  }

  const fieldComponent = (
    <AsComponent
      id={id}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      className={cn(size, { "full-width": className?.split(" ").includes("full-width") })}
      {...field}
      onBlur={onBlur}
      {...props}
      onChange={fieldOnChange}
      autoFocus={autoFocus}
      ref={inputRef}
      name={name}
    />
  )
  if (type === "hidden") {
    return fieldComponent
  }

  return (
    <View
      as={wrapperAs}
      htmlFor={id}
      className={cn(className, { disabled, editInactive })}
      style={style}
      $flexDirection="column"
    >
      <View $alignItems="center" $gap="var(--spacing-2)">
        {!!label && <div className="text-field-label label">{label}</div>}
      </View>
      {fieldComponent}
      <FieldMessage name={name} data-cy={`field-message-${name}`} />
      {!!renderAfterField && renderAfterField({ name, saveOnChange })}
    </View>
  )
}

BaseInputField.defaultProps = {
  wrapperAs: "label",
}

const StyledInput = styled.input`
  border-radius: var(--border-radius);
  padding: var(--spacing-2);
  border: none;
  outline: none;
  color: var(--subtitle);
  box-shadow: var(--blur-4);
  appearance: none;

  &:focus-visible,
  &:hover {
    box-shadow: var(--lift-6);
  }

  &.medium {
    min-width: 330px;
  }

  &.large {
    min-width: 366px;
  }

  &.full-width {
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.mobileMax}) {
    &.large {
      min-width: 330px;
    }
  }
`

const StyledBaseInputField = styled(BaseInputField)`
  /* stylelint-disable csstools/use-nesting */
  input {
    color: inherit;
  }

  &.full-width input {
    width: 100%;
  }

  &.disabled {
    cursor: auto;
  }

  &.disabled input {
    background-color: var(--gray-3);
  }

  input[disabled] {
    box-shadow: none;
  }

  .label {
    margin-bottom: var(--spacing-1);
  }
`

// Place all exports at the end of the file
export type { BaseInputFieldProps }
export { StyledInput, StyledBaseInputField }
export default StyledBaseInputField
