import KitExerciseResultsReport from "./KitExerciseResultsReport"
import MiniSessionSurveyReport from "./MiniSessionSurveyReport"
import SessionReflectionsReport from "./SessionReflectionsReport"
import SessionSummaryPhoto from "./SessionSummaryPhoto"
import SessionSurveyReport from "./SessionSurveyReport"

import { TEAM_FLAGS } from "ui/hooks/useFeatures"
import { formatTimestampAsDate } from "utils/date"
import { KitGroupSize, getKitTypeInfo } from "utils/kit"
import { useHasTeamFeature } from "utils/team"

const SessionSummaryReportDetail = ({
  className,
  kitInstance,
  selectedKit,
  kitSlug,
  selectedTeam,
  teamId,
  teamTags,
  accountId,
  accountTags,
  multiAccountIds,
  includeArchivedTeams,
}) => {
  const formattedCompletedOn =
    kitInstance?.session_completed_at && formatTimestampAsDate(kitInstance.session_completed_at)
  const isJumbo = selectedKit?.group_size === KitGroupSize.JUMBO
  const { enabled: isJumboPromoTeamFlagEnabled } = useHasTeamFeature(selectedTeam, TEAM_FLAGS.JUMBO_PROMO_SESSIONS)
  const isJumboPromo = selectedTeam && isJumbo && isJumboPromoTeamFlagEnabled
  const kitTypeInfo = getKitTypeInfo(selectedKit?.type)

  return (
    <div className={className}>
      {!!selectedKit && (
        <>
          <h2 className="mb-small">{selectedKit.title}</h2>
          <div className="mb-small">{selectedKit.description}</div>
        </>
      )}
      {!kitTypeInfo.hasReportExerciseResponses && (
        <div className="mb-small">
          Because the purpose of the session is connection and team building, individual responses are not included in
          this report.
        </div>
      )}
      {!!formattedCompletedOn && (
        <div className="mb-medium">
          <span className="text-semi-bold">Completed on</span> {formattedCompletedOn}
        </div>
      )}
      <SessionSurveyReport
        kitInstance={kitInstance}
        selectedKit={selectedKit}
        isJumbo={isJumbo}
        isJumboPromo={isJumboPromo}
        teamId={teamId}
        teamTags={teamTags}
        accountId={accountId}
        accountTags={accountTags}
        multiAccountIds={multiAccountIds}
        includeArchivedTeams={includeArchivedTeams}
        className="mb-large"
      />
      {!kitInstance && (
        <SessionReflectionsReport
          className="mb-large"
          kitSlug={kitSlug}
          teamId={teamId}
          teamTags={teamTags}
          accountId={accountId}
          accountTags={accountTags}
          multiAccountIds={multiAccountIds}
          includeArchivedTeams={includeArchivedTeams}
        />
      )}
      {!!kitInstance && !!kitTypeInfo.hasReportExerciseResponses && !isJumbo && (
        <>
          <SessionReflectionsReport kitInstance={kitInstance} className="mb-large" />
          <KitExerciseResultsReport
            kitInstance={kitInstance}
            accountId={accountId}
            accountTags={accountTags}
            multiAccountIds={multiAccountIds}
            teamId={teamId}
            teamTags={teamTags}
            includeArchivedTeams={includeArchivedTeams}
            className="mb-large"
          />
        </>
      )}
      <SessionSummaryPhoto kitInstance={kitInstance} selectedKit={selectedKit} />
      <MiniSessionSurveyReport
        kitInstance={kitInstance}
        selectedKit={selectedKit}
        teamId={teamId}
        teamTags={teamTags}
        accountId={accountId}
        accountTags={accountTags}
        multiAccountIds={multiAccountIds}
        includeArchivedTeams={includeArchivedTeams}
        className="mb-large"
      />
    </div>
  )
}

export default SessionSummaryReportDetail
