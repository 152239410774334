import cn from "classnames"
import { last, sortBy } from "lodash-es"

import { getExerciseAnswer, getExerciseComponent } from "../results_utils"

import renderBlocks from "components/Blocks/renderBlocks"
import { CircleInfoIcon } from "icons/FontAwesomeIcons"
import Tooltip from "ui/Tooltip"
import View from "ui/View"

const LineRatingFieldMultiUserRatingAverage = ({ exerciseInstances, identifier, colors, tooltip, className }) => {
  const latestExerciseInstance = last(sortBy(exerciseInstances, "created_at"))
  const exerciseComponent = getExerciseComponent(latestExerciseInstance, identifier)
  const sentence = exerciseComponent.sentence
  const labels = exerciseComponent.labels
  const answers = exerciseInstances.map((exercise) => getExerciseAnswer(exercise, identifier)).filter(Boolean)

  const average = answers.reduce((sum, answer) => sum + Number(answer), 0) / answers.length
  const roundedDownAverage = Math.floor(average)

  const averageLabel = labels[roundedDownAverage - 1] || ""
  const labelColor = colors[roundedDownAverage - 1]

  return (
    <View
      $alignItems="center"
      $justifyContent="space-between"
      className={cn(className, "border border-radius-small p-small")}
    >
      <View $flexDirection="row" $alignItems="center">
        <p className="text-field-label">{sentence}</p>
        {!!tooltip && (
          <Tooltip className="fit-content ml-xxs show-on-desktop" title={renderBlocks(tooltip)}>
            <CircleInfoIcon className="text-gray-8" />
          </Tooltip>
        )}
      </View>
      <p className={`text-${labelColor} text-semi-bold text-align-right text-nowrap`}>{averageLabel}</p>
    </View>
  )
}

export default LineRatingFieldMultiUserRatingAverage
