import { useField } from "formik"
import { styled } from "styled-components"

import { Choice } from "forms/fields/ChoicesField"
import View from "ui/View"

const GridLayout = styled(function GridLayout({ className, children }) {
  return <View className={className}>{children}</View>
})`
  width: 100%;
  display: grid;
  gap: 4px;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
`

const ExerciseCheckboxTableGroupField = styled(function ExerciseCheckboxTableGroupField({
  name,
  rows,
  cols,
  saveOnChange,
  className,
  ComponentColumnHeader = (col) => col.label,
  ...props
}) {
  const [field, _meta, { setValue }] = useField(name)
  const value = props.value || field.value

  const handleOnChange = (row, col) => () => {
    const rowValue = (field.value && field.value[row.value]) ?? []
    const newValue = {
      ...field.value,
      [row.value]: rowValue.includes(col.value) ? rowValue.filter((v) => v !== col.value) : [...rowValue, col.value],
    }
    setValue(newValue)
    saveOnChange && saveOnChange(field.name, newValue)
  }

  return (
    <div className={className}>
      <GridLayout columns={cols.length + 1} className="mb-xl">
        <span />
        {cols.map((col) => (
          <View key={col.value} $justifyContent="center" className="label text-small text-nowrap">
            <ComponentColumnHeader col={col} />
          </View>
        ))}
      </GridLayout>
      {rows.map((row) => {
        const rowValue = (value && value[row.value]) ?? []
        return (
          <GridLayout key={row.value} columns={cols.length + 1} className="mb-large">
            <span className="label text-field-label text-gray-9">{row.label}</span>
            {cols.map((col) => (
              <View key={`${row.value}_${col.value}`} $justifyContent="center" className="checkbox-wrapper">
                <Choice type="checkbox" checked={rowValue.includes(col.value)} onChange={handleOnChange(row, col)} />
              </View>
            ))}
          </GridLayout>
        )
      })}
    </div>
  )
})`
  .checkbox-wrapper label {
    width: auto;
  }

  span.label {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export default ExerciseCheckboxTableGroupField
