import { Formik } from "formik"
import { find, keyBy } from "lodash-es"
import { useState } from "react"
import { styled } from "styled-components"

import ShareResultsButton from "./ShareResultsButton"
import UpdateResultsButton from "./UpdateResultsButton"

import { getUserExerciseInstance, getExerciseAnswer } from "domains/Exercise/results_utils"
import AssessmentAccuracyForm from "domains/Exercise/ResultsComponents/AssessmentAccuracyForm"
import CrisisResponsePreviousCrisisUserResults from "domains/Exercise/ResultsComponents/CrisisResponsePreviousCrisisUserResults"
import CrisisResponseTeamSummary from "domains/Exercise/ResultsComponents/CrisisResponseTeamSummary"
import CrisisResponseUserResponderTypeResults from "domains/Exercise/ResultsComponents/CrisisResponseUserResponderTypeResults"
import LineRatingField from "forms/fields/LineRatingField"
import SelectField from "forms/fields/SelectField"
import { SirenOnIcon } from "icons/FontAwesomeIcons"
import { useLatestTeamExerciseInstances } from "resources/exercise"
import { sortUsersByShortName } from "resources/users"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import View from "ui/View"

const CrisisResponseResultsReview = ({ kit, selectedTeam }) => {
  const query = useQueryParams()
  const { data: exerciseInstances, isFetching } = useLatestTeamExerciseInstances({
    teamId: selectedTeam.id,
    exerciseSlug: kit.exercise.slug,
  })

  if (isFetching) {
    return <Loading />
  }

  const users = selectedTeam.members.filter((x) => getUserExerciseInstance(exerciseInstances, x))
  const userIdMap = keyBy(users, "id")
  const initialUserId = parseInt(query.participant)
  const initialUser = find(users, { id: initialUserId })
  const filteredExerciseInstances = exerciseInstances.filter((x) => userIdMap[x.user_id])

  return (
    <>
      <View $alignItems="last baseline" className="mb-medium">
        <SirenOnIcon className="text-orange-3 title-icon mr-medium" />
        <h1 className="text-gray-9">Crisis Response</h1>
      </View>
      <SelectedUserResults
        users={users}
        userIdMap={userIdMap}
        exerciseInstances={filteredExerciseInstances}
        initialUser={initialUser}
        kit={kit}
        team={selectedTeam}
      />
    </>
  )
}

const TEAM_ALL = 0

const SelectedUserResults = ({ users, userIdMap, exerciseInstances, initialUser, kit, team }) => {
  const [shareLink, setShareLink] = useState(null)
  const [selectedUserId, setSelectedUserId] = useState(initialUser?.id || TEAM_ALL)
  const sortedUsers = sortUsersByShortName({ users })
  const initialValues = { current_team_member: selectedUserId }
  const selectedExercise = getUserExerciseInstance(exerciseInstances, { id: selectedUserId })

  const handleUserChange = ({ target }) => {
    const user = find(users, {
      id: parseInt(target.value),
    })
    setSelectedUserId(user?.id || TEAM_ALL)
    setShareLink(null)
  }

  return (
    <>
      <p className="text-normal text-gray-9 mb-large">
        This summary was created during your team session. You can view each person’s individual results and the team as
        a whole.
      </p>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-xl mt-medium">
        <View $flexDirection="row" $flexDirectionTablet="column" $flexDirectionMobile="column" $alignItems="center">
          <View $flexDirection="column">
            <Formik initialValues={initialValues}>
              <>
                <SelectField
                  name="current_team_member"
                  size="medium"
                  value={selectedUserId}
                  onChange={handleUserChange}
                >
                  <option value={TEAM_ALL}>Team Overview</option>
                  {sortedUsers.map(({ id, short_name }) => (
                    <option key={id} value={id}>
                      {short_name}
                    </option>
                  ))}
                </SelectField>
              </>
            </Formik>
          </View>
          <View $justifyContent="flex-end" $justifyContentTablet="flex-start" $justifyContentMobile="flex-start">
            <ShareResultsButton
              selectedUserId={selectedUserId}
              selectedExercise={selectedExercise}
              shareLink={shareLink}
              setShareLink={setShareLink}
              shareAssessmentTitle="Crisis Response"
            />
            <UpdateResultsButton selectedUserId={selectedUserId} kitSlug={kit.slug} selectedTeam={team} />
          </View>
        </View>
      </View>
      {selectedUserId === TEAM_ALL ? (
        <CrisisResponseTeamSummary
          exerciseInstances={exerciseInstances}
          userIdMap={userIdMap}
          teamResults={true}
          team={team}
        />
      ) : (
        <>
          <CrisisResponsePreviousCrisisUserResults exercise={selectedExercise} hideIfNoAnswers={true} />
          <h2 className="text-bold text-gray-9 mb-xs">Responder types</h2>
          <p className="text-normal text-gray-9 mb-medium">Top 3 matches:</p>
          <CrisisResponseUserResponderTypeResults exercise={selectedExercise} className="mb-large" />
          <CrisisResponseAccuracyReview selectedExercise={selectedExercise} />
        </>
      )}
    </>
  )
}

const CrisisResponseAccuracyReview = styled(function CrisisResponseAccuracyReview({ selectedExercise, className }) {
  const values = {
    assessment_feel: getExerciseAnswer(selectedExercise, "assessment_feel"),
    assessment_feel_reason: getExerciseAnswer(selectedExercise, "assessment_feel_reason"),
  }
  const lineRatingLabels = ["Not at all", "Just a bit", "Somewhat", "Pretty much", "A lot"]

  return (
    <AssessmentAccuracyForm title="Reflection" className={className}>
      <div className="text-field-label mb-xs">How much do these results feel like you?</div>
      <LineRatingField
        numOptions={5}
        axisLabels={lineRatingLabels}
        ariaLabels={lineRatingLabels}
        className="mb-large px-small"
        disabled
        value={values?.assessment_feel}
      />
      <p className="text-field-label mb-xs">Why or why not?</p>
      <div className="text-gray-8 box-shadow border-radius p-medium pb-xl mb-xl">{values?.assessment_feel_reason}</div>
    </AssessmentAccuracyForm>
  )
})`
  .box-shadow {
    box-shadow: 0 0 4px rgb(0 0 0 / 2%);
  }
`

export default CrisisResponseResultsReview

export { CrisisResponseAccuracyReview }
