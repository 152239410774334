import { styled } from "styled-components"

const PsychologicalSafetyTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <div>This kit's theme is psychological safety. In these three short training articles, we’ll cover:</div>
      <div className="py-xs ml-xl">
        <ul>
          <li>the definition of psychological safety, how it works, {"&"} why it matters for your team</li>
          <li>how understanding team dynamics can build trust to underpin psychological safety</li>
          <li>tips to continue growing an environment of psychological safety in your team</li>
        </ul>
      </div>
      <div>
        The most well known academic expert in psychological safety is Amy Edmondson, professor of leadership and
        management at Harvard Business School. She writes about the topic in her book,{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.amazon.com/Fearless-Organization-Psychological-Workplace-Innovation/dp/1119477247"
        >
          The Fearless Organization
        </a>
        , and defines psychological safety as “
        <span className="text-bold">An environment where people believe that candor is welcome</span>.” She adds that it
        is a “team climate characterized by interpersonal trust and mutual respect in which people are comfortable being
        themselves.” Others have described it as when people have a sense of confidence that they will not be
        embarrassed or punished for speaking up with ideas, questions, concerns or mistakes.
      </div>
    </div>
    <h3 className="text-gray-9 pt-large pb-xs">Teams with high psychological safety perform better. Period.</h3>
    <div className="text-gray-9">
      <div>
        In Google’s{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://rework.withgoogle.com/print/guides/5721312655835136/"
        >
          Project Aristotle
        </a>{" "}
        they studied hundreds of teams globally to determine what makes effective teams. They found that the{" "}
        <span className="text-bold">#1 most important factor for high-performing teams was psychological safety</span>,
        which they define as “Team members feel safe to take risks and be vulnerable in front of each other.” In fact,
        they found that high psychological safety was strongly correlated to everything from sales and revenue
        performance to ratings of team performance by leadership and customers, to faster development of innovative
        products, and adaptation to new market circumstances.
      </div>
      <div className="pt-xs">
        The science is clear on this across many sources. Amy Edmondson{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.myhrfuture.com/digital-hr-leaders-podcast/2020/7/13/how-to-create-psychological-safety-at-work?format=amp"
        >
          cites this data too
        </a>
        , saying in her research that teams with high psychological safety have better teamwork, more innovation, higher
        creativity, more inclusion, and better error prevention.
      </div>
    </div>
    <h3 className="text-gray-9 pt-large pb-xs">It happens in stages.</h3>
    <div className="text-gray-9">
      <div>
        While it’s clear that psychological safety is critical for high-performance teams, it’s also clear that you
        cannot build it overnight. Timothy Clark, the author of{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.amazon.com/Stages-Psychological-Safety-Inclusion-Innovation/dp/1523087684/"
        >
          The 4 Stages of Psychological Safety
        </a>{" "}
        suggests that it actually builds over 4 stages:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">Stage 1: Inclusion safety</span> - when you feel safe to be yourself and feel
            accepted including all of your unique attributes; satisfies the human need to belong
          </li>
          <li>
            <span className="text-bold">Stage 2: Learner safety</span> - when you feel safe to learn by asking
            questions, experimenting, and giving and receiving feedback; satisfies the need to learn and grow
          </li>
          <li>
            <span className="text-bold">Stage 3: Contributor safety</span> - when you feel safe to use your skills and
            talents to contribute to the team; satisfies the need to make a difference
          </li>
          <li>
            <span className="text-bold">Stage 4: Challenger safety</span> - when you feel safe to question or challenge
            the status quo if you believe there is a chance to make improvements; satisfies the need to make things
            better.
          </li>
        </ul>
      </div>
      <div>
        The fact that psychological safety builds in stages over time is precisely why we start the Rising Team series
        with this theme. That exercise helps build trust among the team, and specifically builds towards Inclusion
        Safety as a place to start. In other kits some of our other themes and exercises will cover other aspects of
        these stages as well.
      </div>
    </div>
    <h3 className="text-gray-9 pt-large pb-xs">Your leadership style matters.</h3>
    <div className="text-gray-9">
      <div>
        In a{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.mckinsey.com/business-functions/organization/our-insights/psychological-safety-and-the-critical-role-of-leadership-development"
        >
          {" "}
          recent study by McKinsey {"&"} Company
        </a>
        , their research found that a positive team climate—in which team members value one another’s contributions,
        care about one another’s well-being, and have input into how the team carries out its work—is the most important
        driver of a team’s psychological safety. Yet, they also found that only a small percentage of leaders
        demonstrate the behaviors to create a positive team climate.
      </div>
      <div>
        McKinsey found that there are two types of leadership that are most highly correlated with creating a positive
        team climate that leads to psychological safety:
      </div>
      <div className="py-xs ml-xl">
        <ul>
          <li>
            <span className="text-bold">Consultative Leadership</span> - When leaders consult their team members,
            solicit input, and consider the team’s views on issues that affect them.
          </li>
          <li>
            <span className="text-bold">Supportive Leadership</span> - When leaders demonstrate concern and support for
            team members, not only as employees, but also as individuals.
          </li>
          <li>
            A third type of leadership, <span className="text-bold">Challenging Leadership</span>, can also lead to
            higher levels of psychological safety, but only when done after a positive climate is in place. Challenging
            Leadership encourages employees to do more than they initially think they can by asking team members to
            rethink assumptions about their work and how it can be changed in order to exceed expectations and fulfill
            their potential.
          </li>
          <li>
            And finally, <span className="text-bold">Authoritative Leadership</span>, a “command and control” style
            where leaders dictate what teams should do, is actively detrimental to psychological safety.
          </li>
        </ul>
      </div>
      <div>
        To sum it up, the McKinsey research found that the highest likelihood of achieving psychological safety happens
        when leaders first create a positive team climate through frequent supportive and consultative leadership, and
        then move on to challenging their team.{" "}
        <span className="text-bold">
          The best way for leaders to create a positive team climate is through role modeling and reinforcing the
          behaviors they expect from the rest of their team.{" "}
        </span>
      </div>
    </div>
    <div className="pt-small">
      <img
        src="https://static.risingteam.com/kit_assets/psychological-safety/kit-training-pyschological-safety.png"
        alt="kit-training-pyschological-safety"
      />
    </div>
    <h3 className="text-gray-9 pt-large pb-xs">What it looks like when it’s working</h3>
    <div className="text-gray-9">
      <div>
        In teams that feel psychologically safe, team members feel comfortable to be themselves, actively learn new
        things, and raise questions, concerns, and new ideas when they believe things could be better.
      </div>
      <div className="pt-xs">
        When it’s working, teammates express mutual respect, trust, and care for each other as people. Team members
        don’t criticize each others’ motivation, competence or character, and people separate opinions and arguments
        from the character of the person who is expressing them. Psychologically safe teams are also more innovative and
        faster learning.
      </div>
      <div className="pt-xs">
        So now that we know why psychological safety is so valuable, let’s talk about how to get started building it!
      </div>
    </div>
  </div>
)

export default styled(PsychologicalSafetyTraining1)`
  img {
    max-width: 100%;
    height: auto;
  }
`
