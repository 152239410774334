import { keyBy } from "lodash-es"

import { useKitSession } from "../KitSessionContext"

import { getUserExerciseInstance } from "domains/Exercise/results_utils"
import LearnerAgilityChart from "domains/Exercise/ResultsComponents/LearnerAgilityChart"
import LearnerAgilityLearningPreferencesResults from "domains/Exercise/ResultsComponents/LearnerAgilityLearningPreferencesResults"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SessionSelectedUsersShareView from "domains/KitSession/components/SessionSelectedUsersShareView"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { useSessionExerciseInstances } from "resources/exercise"
import { useKitParticipants } from "resources/monthly_kit"
import BulletedList from "ui/BulletedList"
import Callout from "ui/Callout"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useSelectedUser } from "ui/SelectedUserContext"
import TextContainer from "ui/TextContainer"
import { useHasTeamFeature } from "utils/team"

const LearnerAgilityLearningPreferencesShareStep = ({ sessionStep, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (isFetching && !exerciseInstances) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <TextContainer className="text-gray-9 mr-xl">
          <h2 className="mb-medium">Share out</h2>
          <p className="mb-large">
            Take turns sharing any insights that stood out to you, and especially anything you want the team to know
            about how to support you when learning something new.
          </p>
          <Callout className="text-gray-9 mb-xl">
            <h4 className="mb-medium">When sharing</h4>
            <BulletedList>
              <li>Share an example when you learned something using your top style</li>
              <li>What would you like the team to understand about how you like to learn?</li>
            </BulletedList>
          </Callout>
        </TextContainer>
        <FacilitatorTip className="sidebar-right" icon="share-screen" />
      </SidebarContainer>
      <SessionSelectedUsersShareView
        sessionStep={sessionStep}
        kitInstance={kitInstance}
        minMinutesPerUser={2}
        maxMinutesPerUser={5}
      >
        <UserResults exerciseInstances={exerciseInstances} kitInstance={kitInstance} team={team} />
      </SessionSelectedUsersShareView>
    </StepContent>
  )
}

const UserResults = ({ exerciseInstances, kitInstance, team }) => {
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { preview } = useKitSession()
  const { data: participants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })
  const { selectedUser } = useSelectedUser()
  const selectedExercise = getUserExerciseInstance(exerciseInstances, selectedUser)

  if (!selectedExercise) {
    return null
  }

  const participantIdMap = keyBy(participants, "id")

  return (
    <div>
      <h2 className="text-bold text-gray-9 mt-xl mb-medium">{selectedUser.first_name}'s top learning preferences</h2>
      <LearnerAgilityLearningPreferencesResults exercise={selectedExercise} isCompact={true} className="mb-xxl" />
      <LearnerAgilityChart
        userIdMap={participantIdMap}
        exerciseInstances={exerciseInstances}
        focusedUser={selectedUser}
      />
    </div>
  )
}

export default LearnerAgilityLearningPreferencesShareStep
