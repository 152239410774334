import cn from "classnames"
import { useState } from "react"
import { styled } from "styled-components"

import { useKitSession } from "domains/KitSession/KitSessionContext"
import { ArrowLeftIcon, ArrowRightIcon } from "icons/FontAwesomeIcons"
import {
  useRealtimeSelectedSlideIndex,
  useUpdateSelectedSlideIndex,
  useUpdateSlideRevealStatus,
  useKitParticipants,
} from "resources/monthly_kit"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import usePageSyncEnabled from "ui/hooks/usePageSyncEnabled"
import Loading from "ui/Loading"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const RealtimeSlideshow = styled(function RealtimeSlideshow({
  title,
  nextText = "Next",
  slides,
  identifiers,
  realtimeSlideShowRevealKey,
  isSlideRevealable = false,
  className,
}) {
  const { kitInstance, team, preview, isFacilitator } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: kitParticipants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })
  const [pageSyncEnabled] = usePageSyncEnabled()
  const { enabled: isRealtimeSlideshowFeatureActive, isInitialLoading: realtimeSlideshowFeatureLoading } =
    useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_SLIDESHOW)
  const isRealtimeSlideshowActive = isRealtimeSlideshowFeatureActive && kitParticipants?.length > 1
  const realtimeSlideshowIndexKey = !!isSlideRevealable ? identifiers?.join("-") : "non-revealable"

  const { data: selectedShareoutIndex, isInitialLoading } = useRealtimeSelectedSlideIndex({
    kitInstanceId: kitInstance?.id,
    key: realtimeSlideshowIndexKey,
    enabled: !!(kitInstance && isRealtimeSlideshowActive),
  })

  const { mutateAsync: updatedSelectedSlideshowIndex } = useUpdateSelectedSlideIndex({
    kitInstanceId: kitInstance?.id,
    key: realtimeSlideshowIndexKey,
  })

  const { mutateAsync: updateSlideRevealStatus } = useUpdateSlideRevealStatus({
    kitInstanceId: kitInstance?.id,
    key: realtimeSlideShowRevealKey,
  })

  const [slideIdx, setSlideIdx] = useState(0)
  const length = slides?.length
  const nextSlide = async (slideshowIndex) => {
    const newSlideShowIndex = slideshowIndex === length - 1 ? slideshowIndex : slideshowIndex + 1
    setSlideIdx(newSlideShowIndex)

    if (!!isRealtimeSlideshowActive && !!isFacilitator && !!pageSyncEnabled) {
      !!isSlideRevealable && (await updateSlideRevealStatus(false))
      updatedSelectedSlideshowIndex(newSlideShowIndex)
    }
  }
  const backSlide = async (slideshowIndex) => {
    const newSlideShowIndex = slideshowIndex === 0 ? slideshowIndex : slideshowIndex - 1
    setSlideIdx(newSlideShowIndex)
    if (!!isRealtimeSlideshowActive && !!isFacilitator && !!pageSyncEnabled) {
      !!isSlideRevealable && (await updateSlideRevealStatus(false))
      updatedSelectedSlideshowIndex(newSlideShowIndex)
    }
  }

  useEffectAfterChange(() => {
    if (!isFacilitator && !!selectedShareoutIndex && !!pageSyncEnabled) {
      setSlideIdx(selectedShareoutIndex["slide_index"])
    }
  }, [selectedShareoutIndex, slideIdx, isFacilitator, pageSyncEnabled])

  if ((!!isRealtimeSlideshowActive && isInitialLoading) || !!realtimeSlideshowFeatureLoading) {
    return <Loading />
  }

  const realtimeSlideshowIndex = !!selectedShareoutIndex ? selectedShareoutIndex["slide_index"] : 0
  const slideshowIndex =
    !!isRealtimeSlideshowActive && !!pageSyncEnabled && !isFacilitator ? realtimeSlideshowIndex : slideIdx

  return (
    <div className={className}>
      <View $justifyContent="space-between" className="mb-small mb-xs-mobile-only">
        <div className="slideshow-title text-semi-bold text-gray-8">{!!title && title}</div>
        <span className="count-slide text-semi-bold text-gray-7 hide-on-mobile">
          {slideshowIndex + 1} of {length}
        </span>
      </View>
      {slides[slideshowIndex]}
      {(!isRealtimeSlideshowActive || !pageSyncEnabled || (!!isRealtimeSlideshowActive && !!isFacilitator)) && (
        <View $justifyContent="flex-end">
          {slideshowIndex !== length - 1 ? (
            <View $justifyContent="space-between" className="next-text">
              <Button
                className={cn("link text-button text-medium-mobile-never", { hidden: slideshowIndex === 0 })}
                onClick={() => backSlide(slideshowIndex)}
              >
                <ArrowLeftIcon />
                <div className="text-semi-bold">Back</div>
              </Button>
              <Button className="link text-button text-medium-mobile-never" onClick={() => nextSlide(slideshowIndex)}>
                <div className="text-semi-bold">{nextText}</div>
                <ArrowRightIcon />
              </Button>
            </View>
          ) : (
            <p className="end-text text-semi-bold text-gray-7 py-xxs text-medium-mobile-never">The end</p>
          )}
        </View>
      )}
    </div>
  )
})`
  ${({ theme }) =>
    theme.isWidescreen
      ? `
    .slideshow-title, .count-slide, .next-text, .end-text {
      font-size: 2rem; /* matches h2 widescreen styling */
      line-height: 2.75rem;
      font-weight: 600;
    }
  `
      : `
    .slide-text {
      line-height: var(--spacing-7);
    }

    .slideshow-title {
      font-size: 1.625rem; /* matches h2 styling */
      line-height: 2.25rem;
      font-weight: 600;
    }

    .count-slide, .next-text, .end-text {
      font-size: 1.063rem;  /* ~ 17px */
      line-height: 1.5rem;
    }
  `}

  @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
    .slideshow-title {
      font-size: 1.0625rem; /* matches h4 styling */
      line-height: 1.5rem;
    }
  }
`

export default RealtimeSlideshow
