import cn from "classnames"
import { groupBy, mapValues } from "lodash-es"
import { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import TeamDetailsPanel from "components/TeamDetailsPanel"
import { TeamSelector } from "components/TeamSelector"
import MiniKitFilters from "domains/MiniKit/MiniKitsTableofContents/components/MiniKitFilters"
import MiniKitTile from "domains/MiniKit/MiniKitsTableofContents/components/MiniKitTile"
import { getSubTypeFilter } from "domains/MiniKit/MiniKitsTableofContents/utils"
import {
  ArrowUpRightFromSquareIcon,
  ClockIcon,
  PeopleGroupIcon,
  LaptopMobileIcon,
  InfoCircleIcon,
  LightbulbIcon,
  PartyHornIcon,
} from "icons/FontAwesomeIcons"
import { useUserAccount } from "resources/billing"
import { useBasicMiniKitInfos, useTeamKitInstances, useMiniUnavailableKits } from "resources/monthly_kit"
import { CalloutName, useCalloutStatus, useUpdateCalloutStatus } from "resources/users"
import BulletedList from "ui/BulletedList"
import Button from "ui/Button"
import Grid from "ui/Grid"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useQueryParams from "ui/hooks/useQueryParams"
import useWindowSize from "ui/hooks/useWindowSize"
import HorizontalRule from "ui/HorizontalRule"
import Loading from "ui/Loading"
import { useModal } from "ui/ModalContext"
import Tooltip from "ui/Tooltip"
import View from "ui/View"
import { plural } from "utils/string"
import { isLeadOrColead } from "utils/team"

const MiniKitsTableOfContents = ({ user, selectedTeam }) => {
  const isCurrentTeamAnyLead = isLeadOrColead(user, selectedTeam)
  const { mini_sub_type: miniSubType } = useQueryParams()
  const initialMiniSubType = useMemo(() => getSubTypeFilter(miniSubType), [miniSubType])

  const navigate = useNavigate()
  const { data: kitInstances } = useTeamKitInstances({ teamId: selectedTeam.id })
  const { data: basicKits } = useBasicMiniKitInfos({ teamId: selectedTeam.id })
  const { data: unavailableKits } = useMiniUnavailableKits({ teamId: selectedTeam.id })

  const [visibleKits, setVisibleKits] = useState([])
  const { data: userAccount } = useUserAccount(selectedTeam?.account_id)
  const isConnectAccount = !!userAccount?.is_connect_account
  const maxSessionParticipants = userAccount?.max_session_participants
  const isDemo = selectedTeam.demo
  const displayMaxSessionParticipants = isDemo ? 14 : maxSessionParticipants
  const { isMobileOrSmaller } = useWindowSize()

  const calloutName = CalloutName.MINIS_INFO_MODAL
  const {
    data: hasViewedMiniInfoModal,
    isFetching: modalStatusIsFetching,
    isError: modalStatusIsError,
  } = useCalloutStatus({
    userId: user.id,
    calloutName,
    enabled: !!isCurrentTeamAnyLead,
  })
  const { mutateAsync: updateMiniInfoModalStatus } = useUpdateCalloutStatus({ userId: user.id, calloutName })
  const { setModal } = useModal()
  const showMiniInfoModal = useCallback(() => {
    setModal({
      title: "Meet the Minis",
      content: <MiniInfoModal closeModal={() => setModal(null)} />,
      size: "medium",
    })
  }, [setModal])

  useEffectAfterChange(() => {
    if (!modalStatusIsFetching && !modalStatusIsError && !hasViewedMiniInfoModal && isCurrentTeamAnyLead) {
      showMiniInfoModal()
      updateMiniInfoModalStatus({ hasViewedCallout: true })
    }
  }, [
    user,
    modalStatusIsFetching,
    modalStatusIsError,
    showMiniInfoModal,
    hasViewedMiniInfoModal,
    isCurrentTeamAnyLead,
    updateMiniInfoModalStatus,
  ])

  const miniKitDescriptions = [
    selectedTeam.jumbo
      ? null
      : {
          icon: <PeopleGroupIcon color="var(--orange-2)" className="fa-sm  pr-xxs" />,
          text: `up to ${displayMaxSessionParticipants}`,
          title: `Minis are highly interactive with frequent opportunities for sharing. To ensure everyone has time to share we limit the team size to ${displayMaxSessionParticipants} total participants.`,
        },
    {
      icon: <ClockIcon color="var(--green-2)" className="fa-sm pr-xxs" />,
      text: "10-15 mins",
      title: "On average, most teams finish 10 to 15 minutes, but larger and more extroverted teams can take longer.",
    },
    {
      icon: <LaptopMobileIcon color="var(--gray-9)" className="fa-sm pr-xxs" />,
      text: "Device needed",
      title:
        "Minis can be done remotely or in-person, as long as everyone has a computer or mobile device and internet access.",
    },
    {
      icon: <InfoCircleIcon color="var(--gray-8)" className="fa-xs pr-xxs" />,
      text: "Mini types",
      title: (
        <div>
          Minis come in three main types:
          <BulletedList className="my-xs">
            <li>Connect Minis help you understand and build trust with your teammates.</li>
            <li>Understand Minis help you learn key insights about how your teammates like to work. </li>
            <li> Play Minis are designed just to have fun!</li>
          </BulletedList>
        </div>
      ),
    },
  ].filter(Boolean)

  const kitInstanceMap = useMemo(
    () => mapValues(groupBy(kitInstances, "slug"), ([firstInstance]) => firstInstance),
    [kitInstances]
  )

  const onTeamChange = ({ value }) => {
    navigate(`/minis?team_id=${value}`)
  }

  if (!kitInstances || !basicKits || !unavailableKits || !userAccount) {
    return <Loading />
  }

  return (
    <>
      <div className="mb-medium">
        <View $alignItems="flex-start">
          <h1>Minis</h1>
        </View>
        <View className="mb-small" $flexWrap="wrap" $gap={isMobileOrSmaller ? "8px" : "24px"}>
          {miniKitDescriptions.map((miniKitDescription) => (
            <View key={miniKitDescription.text} $width="max-content">
              <Tooltip bottom float title={miniKitDescription.title} className="inline-block fit-content">
                <div>
                  {miniKitDescription.icon}
                  <p className="text-gray-7 inline-block">{miniKitDescription.text}</p>
                </div>
              </Tooltip>
            </View>
          ))}
          <View $width="max-content">
            {!!isCurrentTeamAnyLead && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://risingteam.com/resources/mini-session-guide"
                className="no-underline text-gray-7"
              >
                <ArrowUpRightFromSquareIcon color="var(--rising-blue)" className="ml-xxs" /> Guide
              </a>
            )}
          </View>
        </View>
        Mini sessions are quick, in-person or virtual team-building exercises to build connection, gain insights, and
        have fun.
      </div>
      <TeamDetailsPanel
        TeamSelectorComponent={TeamSelector}
        selectedTeam={selectedTeam}
        onTeamChange={onTeamChange}
        user={user}
      />
      {!!basicKits.length && !!unavailableKits.length && (
        <p className="bg-highlight py-large px-medium">
          <PartyHornIcon color="var(--rising-yellow)" className="mr-xxs" />
          Enjoy your free {plural(basicKits.length, "Mini", { showCount: false })}! To access the full Minis catalogue,{" "}
          <Link to="/subscribe">subscribe to Rising Team.</Link>
        </p>
      )}
      {!basicKits?.length ? (
        <View className="mb-medium px-medium py-large bg-yellow-3 border-radius-small" $alignItems="center" $gap="8px">
          <LightbulbIcon color="var(--yellow-2)" className="fa-xl icon" />
          <p>This team currently does not have access to Minis. </p>
        </View>
      ) : (
        !isCurrentTeamAnyLead &&
        !isDemo && (
          <View
            className="mb-medium px-medium py-large bg-yellow-3 border-radius-small"
            $alignItems="center"
            $gap="8px"
          >
            <LightbulbIcon color="var(--yellow-2)" className="fa-xl" />
            <p>You are a member of this team. Contact your team lead to request a session. </p>
          </View>
        )
      )}
      <HorizontalRule margin="mb-small mt-none" height={1} />
      {!!basicKits?.length && (
        <>
          <MiniKitFilters
            userKits={basicKits}
            unavailableKits={unavailableKits}
            kitInstanceMap={kitInstanceMap}
            setVisibleKits={setVisibleKits}
            initialMiniSubType={initialMiniSubType}
            className="my-medium"
            user={user}
          />
          <Grid $columns="3" $columnsMobile="1" $columnsTablet="2" $gap="20px">
            {visibleKits.map((kitInfo, index) => {
              const kitInstance = kitInstanceMap[kitInfo.slug]
              const initiallyExpanded = false

              return (
                <MiniKitTile
                  kitInfo={kitInfo}
                  kitInstance={kitInstance}
                  selectedTeam={selectedTeam}
                  user={user}
                  initiallyExpanded={initiallyExpanded}
                  key={kitInfo.slug}
                  index={index + 1}
                  isConnectAccount={isConnectAccount}
                  isTeamLead={isCurrentTeamAnyLead}
                />
              )
            })}
          </Grid>
        </>
      )}
    </>
  )
}

const MiniInfoModal = styled(function MiniInfoModal({ className, closeModal }) {
  const { isMobileOrSmaller } = useWindowSize()
  const subTypesDescription = [
    {
      title: "Understand",
      description: "Understand Minis help you learn key insights about how your teammates like to work.",
      titleClassName: "bg-orange-tint border-radius-xxl px-xs py-xxs",
    },
    {
      title: "Connect",
      description: "Connect Minis help you understand and build trust with your teammates on a more personal level.",
      titleClassName: "bg-yellow-tint border-radius-xxl px-xs py-xxs",
    },
    {
      title: "Play",
      description: "Play Minis are designed just to have fun!",
      titleClassName: "bg-green-tint border-radius-xxl px-xs py-xxs",
    },
  ]
  return (
    <div className={className}>
      <p className="mb-large">
        Mini sessions are quick, in-person or virtual team-building exercises to build connection, gain insights, and
        have fun. Mini sessions require very little prep! Choose any Mini that’s available and run it with your
        group.{" "}
      </p>
      <h4>Minis come in 3 flavors</h4>
      <p className="my-xs">
        There are 3 Mini types to promote different types of sharing for teams to quickly connect and build trust over
        time.
      </p>
      <View
        $gap="12px"
        $alignSelf="stretch"
        $alignItems="flex-start"
        className="pt-small mb-large"
        $flexDirectionMobile="column"
      >
        {subTypesDescription.map(({ title, description, titleClassName }) => (
          <View
            key={title}
            $flexDirection="column"
            $gap="12px"
            $alignSelf="stretch"
            $alignItems="flex-start"
            className={cn("text-gray-9", { "mini-sub-type-content": !isMobileOrSmaller })}
          >
            <h5 className={titleClassName}>{title}</h5>
            <p className="text-gray-9 align-self-stretch">{description}</p>
          </View>
        ))}
      </View>
      <View $alignItems="center" $gap="16px">
        <Button className="tertiary" onClick={closeModal}>
          Choose a Mini
        </Button>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://risingteam.com/resources/mini-session-guide"
          className="no-underline text-semi-bold"
        >
          View Guide
        </a>
      </View>
    </div>
  )
})`
  .mini-sub-type-content {
    border-right: 1px solid var(--gray-4);

    :last-child {
      border-right: none;
    }
  }
`

export default MiniKitsTableOfContents
