import { Link } from "react-router-dom"

import PageBreadcrumbs from "ui/PageBreadcrumbs"

const TeamMemberHome = ({ kitInstance }) => (
  <div className="main-container">
    <PageBreadcrumbs className="mb-medium">
      <Link to={`/kit?team_id=${kitInstance.team_id}`}>View all kits</Link>
      {kitInstance.kit.title}
    </PageBreadcrumbs>
    <div className="text-gray-9 pt-xs">
      It looks like this session might still be in progress.{" "}
      {!kitInstance.is_participant ? (
        <Link to="/code">Join the session</Link>
      ) : (
        <Link to={kitInstance.session_url}>Rejoin the session</Link>
      )}{" "}
      or remind your facilitator to finish the session.
    </div>
  </div>
)

export default TeamMemberHome
