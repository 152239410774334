function SvgLogoIcon(props) {
  return (
    <svg width="189" height="35" viewBox="0 0 189 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.2659 22.8461C18.5511 22.1496 17.3995 22.1496 16.6449 22.8461L13.3489 26.0577C12.634 26.7542 12.634 27.8763 13.3489 28.6115L16.6449 31.823C17.3598 32.5195 18.5114 32.5195 19.2659 31.823L22.562 28.6115C23.2768 27.915 23.2768 26.7928 22.562 26.0577L19.2659 22.8461Z"
        fill="#FB6211"
      />
      <path d="M28.0859 11.1449L23.0264 16.2114L26.1336 19.3229L31.1932 14.2564L28.0859 11.1449Z" fill="#FF9F0A" />
      <path d="M24.0483 7.07854L18.9888 12.145L22.041 15.2014L27.1006 10.1349L24.0483 7.07854Z" fill="#FE7A00" />
      <path
        d="M18.0024 11.1706L23.0578 6.10828L21.5023 4.55064L19.5968 2.64253C18.7412 1.78582 17.3413 1.78582 16.4857 2.64253L14.5802 4.55064L2.64165 16.4277C1.78612 17.2844 1.78612 18.6862 2.64165 19.5429L4.54715 21.451C5.40269 22.3077 6.80265 22.3077 7.65818 21.451L18.0024 11.1706Z"
        fill="#FB6211"
      />
      <path
        d="M33.3632 16.4275L32.1965 15.2593L27.1411 20.3216L28.3077 21.4898C29.1633 22.3465 30.5632 22.3465 31.4188 21.4898L33.3243 19.5817C34.2187 18.6861 34.2187 17.2842 33.3632 16.4275Z"
        fill="#FFD257"
      />
      <path
        d="M56.3182 20.068H54.4854C54.2925 20.068 54.196 20.2136 54.196 20.3592V23.7087C54.196 23.9029 54.0513 24 53.9067 24H50.2894C50.0965 24 50 23.8544 50 23.7087V10.6019C50 9.72816 50.7235 9 51.5916 9H56.8487C58.1991 9 59.3567 9.24272 60.3695 9.67961C61.3824 10.1165 62.1058 10.7476 62.6846 11.6214C63.2151 12.4466 63.5045 13.4175 63.5045 14.5825C63.5045 15.6505 63.2633 16.6214 62.7328 17.3981C62.2987 18.1262 61.6717 18.7087 60.8518 19.1456C60.7071 19.2427 60.6589 19.4369 60.7554 19.5825L63.4563 23.5146C63.6009 23.7087 63.4563 24 63.2151 24H59.4531C59.3567 24 59.2602 23.9515 59.212 23.8544L56.704 20.165C56.5111 20.1165 56.4146 20.068 56.3182 20.068ZM58.4885 12.9806C58.0545 12.5922 57.3792 12.3981 56.5111 12.3981H54.4854C54.2925 12.3981 54.196 12.5437 54.196 12.6893V16.4757C54.196 16.6699 54.3407 16.767 54.4854 16.767H56.5111C57.3792 16.767 58.0062 16.5728 58.4885 16.1845C58.9226 15.7961 59.1638 15.2621 59.1638 14.5825C59.1638 13.9029 58.9226 13.3689 58.4885 12.9806Z"
        fill="#484848"
      />
      <path
        d="M73.5346 23.6629C72.7147 23.4685 72.0395 23.2256 71.509 22.9341C71.3643 22.8369 71.316 22.6912 71.3643 22.5454L72.3289 20.3106C72.4253 20.1649 72.6183 20.0677 72.763 20.1649C73.2453 20.4078 73.7276 20.6021 74.3546 20.7965C75.078 20.9908 75.8015 21.088 76.5249 21.088C77.2001 21.088 77.6342 21.0394 77.9236 20.8936C78.213 20.7479 78.3577 20.5535 78.3577 20.3106C78.3577 20.0677 78.213 19.8734 77.8754 19.7762C77.5378 19.679 77.0072 19.5819 76.2838 19.4847C75.3674 19.3875 74.5957 19.2418 73.9205 19.0475C73.2935 18.8531 72.7147 18.5131 72.2324 18.0272C71.7501 17.5414 71.509 16.8126 71.509 15.9382C71.509 15.2094 71.7501 14.5292 72.1842 13.9463C72.6183 13.3633 73.2453 12.926 74.1134 12.5859C74.9333 12.2458 75.9462 12.1001 77.1519 12.1001C77.9718 12.1001 78.84 12.1973 79.6599 12.343C80.3833 12.4888 80.9621 12.6831 81.4444 12.926C81.5891 12.9746 81.6373 13.1689 81.5891 13.3147L80.6245 15.5009C80.5763 15.6467 80.3833 15.7438 80.1904 15.6467C79.274 15.1608 78.2612 14.9665 77.1519 14.9665C76.5249 14.9665 76.0426 15.0637 75.7532 15.2094C75.4639 15.3552 75.3192 15.5495 75.3192 15.7924C75.3192 16.0839 75.4638 16.2297 75.8015 16.3754C76.1391 16.4726 76.6696 16.5697 77.4413 16.7155C78.4059 16.8612 79.1776 17.007 79.8046 17.2013C80.4316 17.3956 80.9621 17.7357 81.4444 18.2216C81.9267 18.7074 82.1196 19.3875 82.1196 20.3106C82.1196 21.0394 81.8785 21.6709 81.4444 22.2539C81.0103 22.8369 80.3351 23.2742 79.5152 23.6143C78.647 23.9543 77.6342 24.1001 76.4285 24.1001C75.4156 24.0029 74.451 23.8572 73.5346 23.6629Z"
        fill="#484848"
      />
      <path
        d="M101.462 13.4992C102.33 14.3918 102.764 15.681 102.764 17.4662V23.9124C102.764 24.1108 102.619 24.21 102.475 24.21H99.0503C98.8574 24.21 98.7609 24.0612 98.7609 23.9124V18.1604C98.7609 16.524 98.1339 15.7306 96.8799 15.7306C96.2047 15.7306 95.6259 15.9786 95.1919 16.4248C94.7578 16.8711 94.5649 17.6149 94.5649 18.5075V23.9124C94.5649 24.1108 94.4202 24.21 94.2755 24.21H90.8511C90.6582 24.21 90.5618 24.0612 90.5618 23.9124V12.7058C90.5618 12.5075 90.7065 12.4083 90.8511 12.4083H94.0826C94.2755 12.4083 94.372 12.5571 94.372 12.7058V13.6976C94.806 13.2017 95.3848 12.8546 96.0118 12.6067C96.6388 12.3587 97.314 12.21 98.0375 12.21C99.4361 12.21 100.594 12.6562 101.462 13.4992Z"
        fill="#484848"
      />
      <path
        d="M117.569 12.4417C117.762 12.4417 117.858 12.5866 117.858 12.7315V21.8594C117.858 23.9845 117.28 25.6266 116.122 26.6891C114.965 27.7516 113.277 28.2828 111.106 28.2828C109.949 28.2828 108.888 28.138 107.923 27.8965C107.055 27.655 106.283 27.3652 105.608 26.9305C105.463 26.834 105.415 26.6891 105.512 26.5442L106.669 24.2743C106.766 24.1294 106.958 24.0811 107.103 24.1777C107.537 24.4674 108.02 24.7089 108.598 24.8538C109.322 25.0953 109.997 25.1919 110.72 25.1919C111.781 25.1919 112.553 24.9504 113.084 24.4674C113.566 23.9845 113.807 23.3083 113.807 22.3907V22.0043C113.035 22.922 111.878 23.3566 110.383 23.3566C109.37 23.3566 108.405 23.1151 107.537 22.6805C106.669 22.1975 105.994 21.5697 105.463 20.7003C104.933 19.8793 104.692 18.8651 104.692 17.7543C104.692 16.6435 104.933 15.6775 105.463 14.8565C105.994 14.0355 106.669 13.3593 107.537 12.8763C108.405 12.3934 109.37 12.2002 110.383 12.2002C112.023 12.2002 113.228 12.7315 114 13.794V12.6832C114 12.49 114.145 12.3934 114.289 12.3934H117.569V12.4417ZM113.132 19.4929C113.614 19.0583 113.855 18.4787 113.855 17.8026C113.855 17.1264 113.614 16.5469 113.132 16.1122C112.65 15.6775 112.071 15.4843 111.347 15.4843C110.624 15.4843 109.997 15.6775 109.515 16.1122C109.032 16.5469 108.791 17.1264 108.791 17.8026C108.791 18.4787 109.032 19.0583 109.515 19.4929C109.997 19.9276 110.576 20.1691 111.299 20.1691C112.071 20.1691 112.65 19.9276 113.132 19.4929Z"
        fill="#484848"
      />
      <path
        d="M84.1452 12.4531H87.5695C87.7624 12.4531 87.8589 12.5999 87.8589 12.7467V23.8066C87.8589 24.0023 87.7142 24.1002 87.5695 24.1002H84.1452C83.9522 24.1002 83.8558 23.9534 83.8558 23.8066V12.7467C83.8076 12.5999 83.9522 12.4531 84.1452 12.4531Z"
        fill="#484848"
      />
      <path
        d="M84.3405 10.647C83.9546 10.3189 83.7617 9.85013 83.7617 9.38138C83.7617 8.91262 83.9546 8.44386 84.3405 8.11573C84.7263 7.7876 85.2086 7.6001 85.7874 7.6001C86.3662 7.6001 86.8967 7.74072 87.2343 8.06886C87.6202 8.39699 87.8131 8.81887 87.8131 9.3345C87.8131 9.85013 87.6202 10.3189 87.2343 10.647C86.8485 10.9752 86.3662 11.1627 85.7874 11.1627C85.2086 11.1627 84.7263 10.9752 84.3405 10.647Z"
        fill="#484848"
      />
      <path
        d="M66.1452 12.1001H69.5695C69.7624 12.1001 69.8589 12.2514 69.8589 12.4026V23.7976C69.8589 23.9993 69.7142 24.1001 69.5695 24.1001H66.1452C65.9522 24.1001 65.8558 23.9488 65.8558 23.7976V12.4026C65.8076 12.2514 65.9522 12.1001 66.1452 12.1001Z"
        fill="#484848"
      />
      <path
        d="M66.3405 10.7394C65.9546 10.4013 65.7617 9.91832 65.7617 9.43535C65.7617 8.95239 65.9546 8.46943 66.3405 8.13136C66.7263 7.79328 67.2086 7.6001 67.7874 7.6001C68.3662 7.6001 68.8967 7.74499 69.2343 8.08306C69.6202 8.42113 69.8131 8.8558 69.8131 9.38706C69.8131 9.91832 69.6202 10.4013 69.2343 10.7394C68.8485 11.0774 68.3662 11.2706 67.7874 11.2706C67.2086 11.2706 66.7263 11.0774 66.3405 10.7394Z"
        fill="#484848"
      />
      <path
        d="M148.224 19.0931H139.832C140.025 19.6761 140.315 20.1619 140.845 20.4534C141.327 20.7935 141.954 20.9393 142.678 20.9393C143.208 20.9393 143.691 20.8421 144.125 20.6964C144.462 20.5506 144.8 20.3563 145.138 20.1134C145.282 20.0162 145.427 20.0162 145.572 20.1134L147.308 21.9109C147.404 22.0567 147.404 22.251 147.308 22.3482C146.199 23.4656 144.607 24 142.581 24C141.231 24 140.025 23.7571 139.012 23.2227C137.999 22.6883 137.18 22.0081 136.649 21.085C136.07 20.1619 135.829 19.1417 135.829 17.9757C135.829 16.8097 136.118 15.7895 136.649 14.915C137.18 13.9919 137.951 13.3117 138.916 12.7773C139.88 12.2429 140.99 12 142.195 12C143.353 12 144.366 12.2429 145.33 12.7287C146.247 13.2146 147.019 13.8947 147.549 14.8178C148.08 15.7409 148.369 16.8097 148.369 18.0243C148.321 18.0729 148.273 18.413 148.224 19.0931ZM140.604 15.4494C140.17 15.7895 139.929 16.2753 139.784 16.9069H144.511C144.414 16.2753 144.125 15.7895 143.691 15.4494C143.257 15.1093 142.726 14.915 142.147 14.915C141.568 14.915 140.99 15.1093 140.604 15.4494Z"
        fill="#484848"
      />
      <path
        d="M159.173 13.2716C160.186 14.1493 160.668 15.5148 160.668 17.3191V23.5123C160.668 23.7074 160.523 23.8049 160.379 23.8049H157.244C157.051 23.8049 156.954 23.6586 156.954 23.5123V22.2932C156.376 23.4148 155.218 24 153.53 24C152.614 24 151.842 23.8537 151.215 23.5123C150.54 23.2198 150.057 22.7809 149.72 22.2444C149.382 21.708 149.238 21.0741 149.238 20.3913C149.238 19.2697 149.672 18.4407 150.492 17.8068C151.311 17.1728 152.614 16.8802 154.35 16.8802H156.279C156.472 16.8802 156.617 16.7339 156.569 16.5389C156.376 15.5148 155.556 15.0271 154.157 15.0271C153.627 15.0271 153.048 15.1247 152.469 15.3197C152.035 15.466 151.649 15.6123 151.311 15.8561C151.167 15.9537 150.926 15.9049 150.877 15.7586L149.816 13.5642C149.768 13.4179 149.768 13.2228 149.913 13.174C150.492 12.8327 151.215 12.5401 151.987 12.345C152.903 12.1012 153.819 12.0037 154.736 12.0037C156.713 11.9549 158.16 12.3938 159.173 13.2716ZM155.893 21.0741C156.231 20.8302 156.52 20.4889 156.665 20.05V19.0259H154.929C153.771 19.0259 153.144 19.416 153.144 20.1963C153.144 20.5376 153.289 20.8302 153.53 21.0741C153.819 21.2691 154.157 21.4154 154.639 21.4154C155.122 21.4154 155.507 21.3179 155.893 21.0741Z"
        fill="#484848"
      />
      <path
        d="M136.081 9H123.247C123.052 9 122.955 9.14611 122.955 9.29221V12.0682C122.955 12.263 123.101 12.3604 123.247 12.3604H127.233C127.427 12.3604 127.525 12.5065 127.525 12.6526V23.7078C127.525 23.9026 127.67 24 127.816 24H131.462C131.657 24 131.754 23.8539 131.754 23.7078V12.7013C131.754 12.5065 131.9 12.4091 132.046 12.4091H136.032C136.227 12.4091 136.324 12.263 136.324 12.1169V9.34091C136.421 9.1461 136.275 9 136.081 9Z"
        fill="#484848"
      />
      <path d="M182.952 19.2998H178.9V21.4248H182.952V19.2998Z" fill="#FE7A00" />
      <path
        d="M178.901 18.595H182.952V17.2562C182.952 15.4711 182.518 14.1818 181.698 13.2893C180.83 12.3967 179.721 12 178.322 12C177.502 12 176.73 12.1488 176.055 12.4959C175.476 12.7438 174.994 13.0909 174.608 13.5372C174.464 13.6859 174.222 13.6859 174.078 13.5372C173.74 13.0909 173.306 12.7438 172.776 12.4959C172.149 12.1983 171.425 12.0496 170.605 12.0496C169.93 12.0496 169.255 12.1488 168.676 12.3967C168.097 12.6446 167.567 12.9917 167.133 13.4876V12.4959C167.133 12.2975 166.988 12.1488 166.795 12.1488H163.612C163.419 12.1488 163.274 12.2975 163.274 12.4959V23.6529C163.274 23.8512 163.419 24 163.612 24H166.988C167.181 24 167.326 23.8512 167.326 23.6529V18.0992C167.326 17.2066 167.518 16.562 167.856 16.1157C168.242 15.6694 168.724 15.4711 169.351 15.4711C170.509 15.4711 171.087 16.2645 171.087 17.9008V23.6529C171.087 23.8512 171.232 24 171.425 24H174.801C174.994 24 175.139 23.8512 175.139 23.6529V18.0992C175.139 17.2066 175.332 16.562 175.669 16.1157C176.055 15.6694 176.537 15.4711 177.116 15.4711C177.647 15.4711 178.081 15.6694 178.418 16.0661C178.708 16.4628 178.853 17.0579 178.853 17.9008V18.595H178.901Z"
        fill="#484848"
      />
      <path
        d="M178.9 22.1001V22.7762C178.9 23.4524 179.431 23.9836 180.106 23.9836H181.794C182.469 23.9836 183 23.4524 183 22.7762V22.1001H178.9Z"
        fill="#FB6211"
      />
    </svg>
  )
}

export default SvgLogoIcon
