import cn from "classnames"

import { useKitSession } from "../KitSessionContext"

import { getExerciseAnswer } from "domains/Exercise/results_utils"
import StepContent from "domains/KitSession/components/StepContent"
import StepTitle from "domains/KitSession/components/StepTitle"
import { useTeamLevelExerciseInstance } from "resources/exercise"
import { useKitParticipants } from "resources/monthly_kit"
import { sortUsersByShortName } from "resources/users"
import BulletedList from "ui/BulletedList"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const FeedbackFlexSelectNonFacilitator = ({ sessionStep, className }) => {
  const { kitInstance, team, preview } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { minutes, auto_start_timer, show_timer_controls } = sessionStep
  const { data: participants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: teamLevelExerciseInstance, isInitialLoading: isInitialLoadingTeamLevelExerciseInstance } =
    useTeamLevelExerciseInstance({
      kitInstanceId: kitInstance?.id,
      teamId: team.id,
      slug: kitInstance.kit.exercise.slug,
      sessionRealtimeUpdates,
    })

  if (!participants || isInitialLoadingTeamLevelExerciseInstance) {
    return <Loading />
  }

  const sortedParticipants = sortUsersByShortName({ users: participants })

  return (
    <StepContent className={className}>
      <StepTitle
        sessionStep={sessionStep}
        minutes={minutes}
        startSeconds={minutes * 60}
        autoStartTimer={auto_start_timer}
        showTimerAll={true}
        showTimerControls={show_timer_controls}
        className="mb-medium"
      >
        <h2 className="text-gray-9">Assignments</h2>
      </StepTitle>
      <div className="text-gray-9">
        <p className="mb-large">
          Review the assignments below. Everyone should be assigned someone else to give feedback to. If anything about
          the assignments doesn’t work for you, feel free to change it.
        </p>
        <BulletedList className="mb-large">
          {sortedParticipants?.map((participant) => {
            const assignmentUserId = getExerciseAnswer(
              teamLevelExerciseInstance,
              `flex_feedback_assignment_${participant.id}`
            )
            const assignmentUser = participants.find(({ id }) => id === assignmentUserId)
            return (
              <li key={participant.id}>
                <span className="text-semi-bold">{participant.short_name}</span> will give feedback to{" "}
                <span className={cn({ "text-semi-bold": assignmentUser, "text-italic": !assignmentUser })}>
                  {assignmentUser ? assignmentUser.short_name : "[not selected]"}
                </span>
              </li>
            )
          })}
        </BulletedList>
      </div>
    </StepContent>
  )
}

export default FeedbackFlexSelectNonFacilitator
