const WorkingStylesTraining1 = ({ className }) => (
  <div className={className}>
    <div className="mt-medium">
      <p className="mb-medium">
        This kit's theme is Working Styles. Learning to understand and appreciate each other’s working styles fosters
        trust and connection within your team. By exploring what matters most to each person and how they work best,
        teams can build stronger relationships and create an environment where everyone feels understood and valued.
      </p>
      <h2 className="text-rising-orange pb-xs">Appreciating different working styles builds psychological safety</h2>
      <p className="mb-medium">
        Appreciating different working styles also helps to build the foundation for psychological safety, which is one
        of the most important drivers of high-performing teams.
      </p>
      <p className="mb-medium">
        According to Amy Edmondson, professor of leadership and management at Harvard Business School and author of{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.amazon.com/Fearless-Organization-Psychological-Workplace-Innovation/dp/1119477247"
          className="text-underline text-gray-9"
        >
          The Fearless Organization
        </a>
        , psychological safety is “An environment where people believe that candor is welcome.” It is a “team climate
        characterized by interpersonal trust and mutual respect in which people are comfortable being themselves.” It’s
        about creating an environment where team members feel confident that they won’t be embarrassed or punished for
        speaking up with ideas, questions, concerns, or mistakes.
      </p>
      <h2 className="text-rising-orange pb-xs">Teams with high psychological safety perform better. Period.</h2>
      <p className="mb-medium">
        In{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://rework.withgoogle.com/en/guides/understanding-team-effectiveness#introduction"
          className="text-underline text-gray-9"
        >
          Google’s Project Aristotle
        </a>
        , they studied hundreds of teams globally to determine what makes effective teams. They found that the #1 most
        important factor for high-performing teams was psychological safety, which they defined as “Team members feel
        safe to take risks and be vulnerable in front of each other.” High psychological safety was strongly correlated
        with:
      </p>
      <div className="pt-xs ml-xl pb-large">
        <ul>
          <li>Improved sales and revenue performance</li>
          <li>Higher ratings of team performance by leadership and customers</li>
          <li>Faster development of innovative products</li>
          <li>Better adaptation to new market circumstances</li>
        </ul>
      </div>
      <p className="mb-medium">
        The science is clear on this across many sources. Amy Edmondson{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.myhrfuture.com/digital-hr-leaders-podcast/2020/7/13/how-to-create-psychological-safety-at-work?format=amp"
          className="text-underline text-gray-9"
        >
          cites this data too
        </a>
        , saying in her research that teams with high psychological safety have better teamwork, more innovation, higher
        creativity, more inclusion, and better error prevention.
      </p>
      <h2 className="text-rising-orange pb-xs">It happens in stages.</h2>
      <p className="mb-medium">
        While it’s clear that psychological safety is critical for high-performance teams, it’s also clear that you
        cannot build it overnight. Timothy Clark, the author of{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.amazon.com/Stages-Psychological-Safety-Inclusion-Innovation/dp/1523087684/"
          className="text-underline text-gray-9"
        >
          The 4 Stages of Psychological Safety
        </a>{" "}
        suggests that it actually builds over 4 stages:
      </p>
      <div className="pt-xs ml-xl pb-large">
        <ul>
          <li>
            Stage 1: Inclusion safety - When you feel safe to be yourself and feel accepted for who you are, including
            all of your unique attributes. This satisfies the human need to belong.
          </li>
          <li>
            Stage 2: Learner safety - When you feel safe to learn by asking questions, experimenting, and giving and
            receiving feedback. This satisfies the need to learn and grow.
          </li>
          <li>
            Stage 3: Contributor safety - When you feel safe to use your skills and talents to contribute to the team.
            This satisfies the need to make a difference.
          </li>
          <li>
            Stage 4: Challenger safety - When you feel safe to question or challenge the status quo to make
            improvements. This satisfies the need to make things better.
          </li>
        </ul>
      </div>
      <p className="mb-medium">
        This kit focuses on Inclusion Safety by helping team members appreciate each other’s working styles. By creating
        a team environment where everyone feels understood and valued for who they are and how they work best, you are
        laying the foundation for psychological safety.
      </p>
      <h2 className="text-rising-orange pb-xs">Why working styles matter for inclusion safety </h2>
      <p className="mb-medium">
        One of the most effective ways to create inclusion safety is by exploring and appreciating each other’s working
        styles. During this session, your team will be guided through a series of questions to explore what matters most
        to each person at work and how they prefer to collaborate. This exercise is also called a “User Manual” because
        each person will create an overview of how best to work with them, as if they were an appliance you were
        learning how to operate. This activity builds trust by encouraging openness and understanding, ensuring that
        each person feels accepted and valued.
      </p>
      <h2 className="text-rising-orange pb-xs">What it looks like when it’s working</h2>
      <p className="mb-medium">
        In teams that understand each other's working styles and feel psychologically safe, team members feel
        comfortable to be themselves, actively learn new things, and raise questions, concerns, and new ideas when they
        believe things could be better.
      </p>
      <p className="mb-medium">
        When it’s working, teammates express mutual respect, trust, and care for each other as people and people
        separate opinions and arguments from the character of the person who is expressing them. Psychologically safe
        teams are also more innovative and faster learning, so it’s important to build the foundation for that.
      </p>
      <p>Now that we know why psychological safety is so valuable, let’s talk about how to get started building it!</p>
      <h3 className="text-gray-9 pt-medium pb-xs">Additional resources</h3>
      <div className="text-gray-9">
        <div>If you want to keep learning more about this topic, here are some additional resources:</div>
        <div className="py-xs ml-xl">
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hbr.org/2017/08/high-performing-teams-need-psychological-safety-heres-how-to-create-it"
              >
                High Performing Teams Need Psychological Safety. Here’s How to Create It
              </a>{" "}
              (Harvard Business Review)
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.mckinsey.com/business-functions/organization/our-insights/psychological-safety-and-the-critical-role-of-leadership-development"
              >
                Psychological Safety and the Critical Role of Leadership Development
              </a>{" "}
              (McKinsey)
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/learning/psychological-safety-clear-blocks-to-innovation-collaboration-and-risk-taking/psychological-safety-clear-blocks-to-problem-solving-and-innovation"
              >
                Amy Edmondson course on Psychological Safety
              </a>{" "}
              (LinkedIn Learning)
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.ccl.org/articles/leading-effectively-articles/what-is-psychological-safety-at-work/"
              >
                What is Psychological Safety at Work?
              </a>{" "}
              (Center for Creative Leadership)
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hbr.org/2021/04/what-psychological-safety-looks-like-in-a-hybrid-workplace"
              >
                What Psychological Safety Looks Like in a Hybrid Workplace
              </a>{" "}
              (HBR)
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default WorkingStylesTraining1
