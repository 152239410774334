import { useParams } from "react-router-dom"

import ConnectionBoostPrepHome from "./ConnectionBoostPrepHome"
import MiniKitPrepHome from "./MiniKitPrepHome"
import PromoPrepHome from "./PromoPrepHome"
import SessionCompleteHome from "./SessionCompleteHome"
import SessionPrepHome from "./SessionPrepHome"
import TeamMemberHome from "./TeamMemberHome"

import NotFound from "components/NotFound"
import { useUserAccount } from "resources/billing"
import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import Loading from "ui/Loading"
import { useKitInstanceByID } from "ui/SelectedTeamContext"
import { KitType, PromoKitSlug } from "utils/kit"
import { isLeadOrColead } from "utils/team"

const LeaderKitDetail = () => {
  const { id } = useParams()
  const { kitInstance, isFetching: kitInstanceIsFetching } = useKitInstanceByID(id)
  const { data: user } = useUser({ userId: "me" })
  const { data: team } = useTeam({ teamId: kitInstance?.team_id })
  const { data: account } = useUserAccount(team?.account_id)

  if (!kitInstance) {
    return kitInstanceIsFetching ? <Loading /> : <NotFound />
  }

  if (!user || !team || !account) {
    return null
  }
  const PrepHome = getPrepHome(kitInstance.kit, account)

  if (team.demo && !isLeadOrColead(user, team)) {
    return <PrepHome kitInstance={kitInstance} user={user} team={team} />
  }

  if (kitInstance.session_completed_at) {
    return <SessionCompleteHome kitInstance={kitInstance} user={user} />
  }

  if (isLeadOrColead(user, team)) {
    return <PrepHome kitInstance={kitInstance} user={user} team={team} />
  } else {
    return <TeamMemberHome kitInstance={kitInstance} />
  }
}

const getPrepHome = (kit, account) => {
  if (
    (account.is_gratitude_promo || account.is_shrm_promo) &&
    (kit.slug === PromoKitSlug.GRATITUDE || kit.slug === PromoKitSlug.CIVIL_CONVERSATIONS)
  ) {
    return PromoPrepHome
  }

  switch (kit.type) {
    case KitType.BOOST:
      return ConnectionBoostPrepHome
    case KitType.MINI:
      return MiniKitPrepHome
    default:
      return SessionPrepHome
  }
}

export default LeaderKitDetail
