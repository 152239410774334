import * as Sentry from "@sentry/browser"
import cn from "classnames"
import { addWeeks, formatISO } from "date-fns"
import { Formik, Form, useFormikContext } from "formik"
import { styled } from "styled-components"

import { CHAT_TYPE, ARTI_ACTION_ITEM_INITIAL_MESSAGE } from "domains/Arti/constants"
import DateField from "forms/fields/DateField"
import RichTextField from "forms/fields/RichTextField"
import Yup from "forms/yup"
import { useReflection, useUpdateReflection } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import { useUser } from "resources/users"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const ReflectionsForm = ({ kitInstance, calendarReminderText, className }) => {
  const { data } = useReflection({ kitInstanceId: kitInstance.id })

  if (!data) {
    return <Loading />
  }

  const oneWeekFromNow = addWeeks(new Date(), 1)
  const initialValues = {
    reflection_text: data.reflection_text ?? "",
    action_items: data.action_items ?? "",
    reminder_date: data.reminder_date ?? formatISO(oneWeekFromNow),
  }

  return (
    <div className={cn("space-y-xl", className)}>
      <Formik initialValues={initialValues} validationSchema={ReflectionSchema}>
        <ReflectionsInnerForm
          kitInstance={kitInstance}
          calendarReminderText={calendarReminderText}
          reflectionId={data.id}
        />
      </Formik>
    </div>
  )
}

const ReflectionsInnerForm = ({ kitInstance, calendarReminderText, reflectionId }) => {
  const { values, setFieldValue } = useFormikContext()
  const { mutateAsync: updateReflection } = useUpdateReflection({ reflectionId })
  const { data: user } = useUser({ userId: "me" })
  const { data: team, isInitialLoading: isInitialLoadingTeam } = useTeam({ teamId: kitInstance.team_id })
  const { enabled: isInSessionArtiEnabled, isInitialLoading: isInitialLoadingInSessionArtiEnabled } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_IN_SESSION_ARTI
  )
  const isDemoModeActive = user.is_demo_mode_active
  const enableInSessionArti = isInSessionArtiEnabled && !isDemoModeActive

  const saveOnChange = async () => {
    const date = values.reminder_date && new Date(values.reminder_date)
    const reminder_date = date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

    await updateReflection({ ...values, reminder_date })
  }

  const reflectionLabel = (
    <div>
      Reflection: {""}
      <span className="text-thin">{kitInstance.kit.reflection_prompt}</span>
    </div>
  )
  const actionItemsLabel = (
    <div>
      Action Item: {""}
      <span className="text-thin">{kitInstance.kit.action_items_prompt}</span>
    </div>
  )

  async function onArtiResponseSelect(message) {
    const date = values.reminder_date && new Date(values.reminder_date)
    const reminder_date = date && `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

    setFieldValue("action_items", message)
    await updateReflection({
      ...values,
      action_items: message,
      reminder_date,
    }).catch((error) => {
      Sentry.captureException(error)
    })
  }

  if (!!isInitialLoadingTeam || !!isInitialLoadingInSessionArtiEnabled) {
    return <Loading />
  }

  return (
    <Form style={{ display: "flex", flexDirection: "column" }}>
      <RichTextField name="reflection_text" saveOnChange={saveOnChange} label={reflectionLabel} className="mt-large" />
      <RichTextField
        name="action_items"
        saveOnChange={saveOnChange}
        label={actionItemsLabel}
        className="mt-large"
        enableInSessionArti={enableInSessionArti}
        artiInSessionData={{
          onArtiResponseSelect,
          initialChatType: CHAT_TYPE.ACTION_ITEMS,
          initialMessage: ARTI_ACTION_ITEM_INITIAL_MESSAGE,
          initialChoiceMessage: "Here are some ideas for your action items.",
          isCopyOption: true,
        }}
      />
      <DateField
        name="reminder_date"
        dateFormat="yyyy-MM-dd"
        saveOnChange={saveOnChange}
        label={calendarReminderText ?? "When would you like to be reminded of this action item?"}
        size="medium"
        className="reminder-date mt-large"
      />
    </Form>
  )
}

const ReflectionSchema = Yup.object().shape({
  reflection_text: Yup.string(),
  action_items: Yup.string(),
  reminder_date: Yup.date(),
})

export default styled(ReflectionsForm)`
  .reminder-date {
    .label {
      font-weight: 700;
    }

    .react-datepicker-wrapper {
      width: 240px;
    }
  }
`
