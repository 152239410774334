import { useQueryClient } from "@tanstack/react-query"

import { getRefetchKitHomeKitsFunction } from "domains/LeaderKit/KitsTableofContents/utils"
import ScheduleForm from "domains/LeaderKit/ScheduleForm"
import View from "ui/View"
import { isLeadOrColead } from "utils/team"

const RescheduleKitScheduleModeExpandedContent = ({
  kitInstance,
  kitInfo,
  user,
  selectedTeam,
  className,
  isScheduleModeActive,
}) => {
  const queryClient = useQueryClient()

  return (
    <div className={className}>
      <div className="text-gray-9">{kitInfo.description}</div>
      <View
        $alignItems="center"
        $alignItemsMobile="flex-start"
        $justifyContent={selectedTeam.preview ? "flex-end" : "space-between"}
        $flexDirectionMobile="column-reverse"
        className="mt-medium"
      >
        {!selectedTeam.preview && (!!isScheduleModeActive || isLeadOrColead(user, selectedTeam)) && (
          <ScheduleForm
            kitInstance={kitInstance}
            kitInfo={kitInfo}
            onAddToCalendar={getRefetchKitHomeKitsFunction(selectedTeam.id, queryClient)}
            team={selectedTeam}
            className="fit-content"
          />
        )}
      </View>
    </div>
  )
}

export default RescheduleKitScheduleModeExpandedContent
