import cn from "classnames"
import { Formik } from "formik"
import { useState } from "react"
import { styled } from "styled-components"

import { useHubSpotModal } from "components/HubSpotModal"
import SubscribeModal from "domains/Subscribe/SubscribeModal"
import ToggleSwitchField from "forms/fields/ToggleSwitchField"
import { CircleCheckIcon, SquareCheckIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import HorizontalRule from "ui/HorizontalRule"
import { useModal } from "ui/ModalContext"
import View from "ui/View"
import { formatDollarAmount } from "utils/number"

const OFFER_TIER_IDS = ["team_all_access", "enterprise_all_access"]

const SubscribeOffersForm = ({ accountOffers, onOfferSelected, isSHRMPromo, className }) => {
  const [teamOffer, enterpriseOffer] = OFFER_TIER_IDS

  return (
    <div className={className}>
      {!!isSHRMPromo && (
        <p className="text-bold text-gray-8 mb-medium">Exclusive Offer through SHRM – Limited Time Only</p>
      )}
      <View $flexDirection="column">
        {[teamOffer, enterpriseOffer].map((offerId) => (
          <Offer
            key={offerId}
            offerId={offerId}
            accountOffers={accountOffers}
            onOfferSelected={onOfferSelected}
            isSHRMPromo={isSHRMPromo}
          />
        ))}
      </View>
    </div>
  )
}

const Offer = styled(function Offer({ offerId, accountOffers, onOfferSelected, isSHRMPromo, className }) {
  const { setModal: setSubscribeModal } = useModal()
  const [annual, setAnnual] = useState(true)
  const { setModal: setHubSpotModal } = useHubSpotModal()
  const accountOffer = accountOffers[offerId]

  if (!accountOffer) {
    return null
  }

  const onModalOfferSelected = async (props) => {
    setSubscribeModal(null)
    await onOfferSelected(props)
  }

  const openSubscribeModal = () => {
    setSubscribeModal({
      title: (
        <div className="text-white">
          <SquareCheckIcon className="mr-xxs" /> Get Rising Team
        </div>
      ),
      content: (
        <SubscribeModal
          accountOffer={accountOffer}
          tierName={accountOffer.name}
          originalDollarAmount={accountOffer.original_dollar_amount}
          selectedMonthly={!annual}
          onOfferSelected={onModalOfferSelected}
        />
      ),
      size: "medium",
      modalWithColorTitleBar: true,
      modalWithColorBorder: true,
    })
  }

  const {
    name: offerName,
    subheading,
    description,
    feature_list: featuresList = [],
    monthly_dollar_amount: monthlyDollarAmount = null,
    yearly_dollar_amount: yearlyDollarAmount = null,
    original_dollar_amount: originalDollarAmount = null,
  } = accountOffer
  const isTeamOffer = offerId === "team_all_access"
  const isEnterpriseOffer = offerId === "enterprise_all_access"
  const currentDollarAmount = annual ? yearlyDollarAmount : monthlyDollarAmount

  const onClickInquirePricing = () => {
    setHubSpotModal()
  }

  return (
    <View $flexDirection="column" className={cn(className, "p-large mb-medium")}>
      <View $justifyContent="space-between" $alignItems="center" className="full-width">
        <h2 className="text-center text-gray-9 mb-xs">{offerName}</h2>
        {!!isTeamOffer && (
          <View $alignItems="center" $flexWrap="nowrap" $width="content-max" className="">
            <label htmlFor="annual" className="mr-small" onClick={() => setAnnual(false)}>
              <span className={cn("text-semi-bold", { "text-gray-6": !!annual })}>Pay monthly</span>
            </label>
            <Formik>
              <ToggleSwitchField name="annual" evergreen checked={annual} saveOnChange={() => setAnnual(!annual)} />
            </Formik>
            <label htmlFor="annual" onClick={() => setAnnual(true)}>
              <span className={cn("text-semi-bold", { "text-gray-6": !annual })}>Pay annually</span>
            </label>
          </View>
        )}
      </View>
      {!!isTeamOffer && (
        <>
          <View $alignItems="center" className="space-x-small">
            <View $alignItems="center" $width="content-max" className="my-small space-x-small">
              {!!(currentDollarAmount < originalDollarAmount) && (
                <h3 className="text-large text-semi-bold text-strikethrough text-gray-7">
                  ${formatDollarAmount(originalDollarAmount)}
                </h3>
              )}
              <h3 className="text-large text-semi-bold text-rising-green">
                {`$${formatDollarAmount(currentDollarAmount)}`}
              </h3>
            </View>
            <div>
              <div className="text-semi-bold text-rising-green neg-mb-xxs">
                Per person/month, billed {annual ? "annually" : "monthly"}
              </div>
              <div className="text-xs text-gray-8">
                {!!isSHRMPromo
                  ? `${annual ? 32 : 10}% SHRM discount available for a limited time only`
                  : "For a limited time only"}
              </div>
            </div>
          </View>
          <Button onClick={openSubscribeModal}>Get started today</Button>
          <HorizontalRule className="full-width" />
        </>
      )}
      {!!subheading && <p className="text-align-left text-green-2 text-semi-bold mb-xxs">{subheading}</p>}
      <p className="text-align-left text-gray-8 pb-small">{description}</p>
      <ul className="text-gray-8 text-left full-width pb-xs">
        {featuresList.map((feature) => (
          <li key={!!feature.text_bold ? feature.text_bold : feature.text_normal}>
            <View $alignItems="top">
              <CircleCheckIcon className="mr-xs text-rising-green circle-check-icon" />
              <View className="inline-block">
                {!!feature.text_bold && <span className="text-bold text-gray-9 mr-xxs">{feature.text_bold}</span>}
                {!!feature.text_normal && feature.text_normal}
              </View>
            </View>
          </li>
        ))}
      </ul>
      {!!isEnterpriseOffer && (
        <Button onClick={onClickInquirePricing} className="text-wrap mt-small secondary px-xl">
          Inquire for pricing
        </Button>
      )}
    </View>
  )
})`
  height: 100%;
  box-shadow: var(--blur-4);
  border-radius: 8px;

  ul {
    list-style-type: none;
    font-size: 0.938rem; /* ~15px */
    line-height: 1.75rem; /* ~28px */
  }

  .circle-check-icon {
    margin-top: 6px;
  }
`

export default SubscribeOffersForm
