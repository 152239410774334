import { Formik, Form } from "formik"
import { keyBy } from "lodash-es"

import { useKitSession } from "../KitSessionContext"

import { RoleTooltip } from "./CrisisResponseScenarioResultStep"

import ExerciseCheckboxTableGroupField from "domains/Exercise/ExerciseComponents/ExerciseCheckboxTableGroupField"
import { getExerciseAnswer } from "domains/Exercise/results_utils"
import { RESPONDER_TYPES_DATA } from "domains/Exercise/results_utils/crisis_response"
import { CrisisResponseChart } from "domains/Exercise/ResultsComponents/CrisisResponseTeamSummary"
import useExerciseForm from "domains/Exercise/useExerciseForm"
import StepContent from "domains/KitSession/components/StepContent"
import { InfoCircleIcon } from "icons/FontAwesomeIcons"
import { useSessionExerciseInstances, useTeamLevelExerciseInstance } from "resources/exercise"
import { useKitParticipants } from "resources/monthly_kit"
import AnswerDisplayContent from "ui/AnswerDisplayContent"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const CrisisResponseScenarioAssignTeamLeadStep = ({ className }) => {
  const { kitInstance, team, preview } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const exercise = kitInstance?.kit?.exercise
  const { data: participants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })
  const { initialValues, onSubmit, saveOnChange } = useExerciseForm({
    teamId: kitInstance?.team_id,
    slug: exercise?.slug,
    version: exercise?.version,
    teamLevelExercise: true,
  })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: teamLevelExerciseInstance, isInitialLoading: isInitialLoadingTeamLevelExerciseInstance } =
    useTeamLevelExerciseInstance({
      teamId: kitInstance.team_id,
      kitInstanceId: kitInstance?.id,
      slug: kitInstance.kit.exercise.slug,
      sessionRealtimeUpdates,
    })
  const { data: exerciseInstances } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (!participants || !exerciseInstances || isInitialLoadingTeamLevelExerciseInstance) {
    return <Loading />
  }

  const team_scenario = getExerciseAnswer(teamLevelExerciseInstance, "team_scenario")
  const participantIdMap = keyBy(participants, "id")

  const responderTypes = Object.keys(RESPONDER_TYPES_DATA).map((r) => ({
    label: r,
    value: r,
  }))
  const users = participants.map((u) => ({
    label: u.name,
    value: u.id,
  }))

  return (
    <StepContent className={className}>
      <h2 className="text-gray-9 mb-medium">Develop the response plan</h2>
      <h3 className="text-gray-9 mb-medium text-bold">Assign people to responder type roles</h3>
      <p className="text-small text-gray-9 mb-large">
        Ask people if there are any particular roles they would like to play. If a response doesn’t seem important,
        leave it blank. Try to distribute the work evenly, so no one person has too much.
      </p>

      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        <Form className="mb-xxxl">
          <ExerciseCheckboxTableGroupField
            name="team_responder_assign"
            rows={users}
            cols={responderTypes}
            saveOnChange={saveOnChange}
            className="text-gray-9"
            ComponentColumnHeader={({ col }) => (
              <RoleTooltip role={col.value}>
                <View $alignItems="center">
                  {col.label}
                  <InfoCircleIcon className="text-rising-blue ml-xxs" />
                </View>
              </RoleTooltip>
            )}
          />
        </Form>
      </Formik>

      <h3 className="text-gray-9 mb-xs text-bold">Your team scenario (for reference)</h3>
      <AnswerDisplayContent className="mb-large" answer={team_scenario} />

      <h3 className="text-gray-9 mb-medium text-bold">Team Responder Types (for reference)</h3>
      <p className="text-gray-8">Use this chart to help identify the best team members for each role.</p>
      <CrisisResponseChart
        userIdMap={participantIdMap}
        kitInstance={kitInstance}
        exerciseInstances={exerciseInstances}
      />
    </StepContent>
  )
}

export default CrisisResponseScenarioAssignTeamLeadStep
