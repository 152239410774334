import { styled } from "styled-components"

const TeamNormsTraining1 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <div>
        The most effective teams are driven by more than the skills of individual members, they are able to harness the
        team’s collective strengths. One way for teams to cultivate this ability is to develop clear and well-understood
        team norms.  Team norms are the unwritten rules and expectations that guide group behavior, interaction, and
        collaboration. Essentially, team norms define 'how things are done' within a team, shaping its culture and
        dynamics.
      </div>
      <div>
        <h3 className="text-rising-orange pt-large pb-xs">Why are team norms so crucial?</h3>
        <div className="pb-xs">Here are some benefits:</div>
        <div className="pr-medium">
          <ul>
            <li>
              <span className="text-bold">Enhanced communication:</span> Clear norms open pathways for honest and
              transparent communication among team members, ensuring everyone feels comfortable expressing their ideas,
              concerns, and feedback.
            </li>
            <li>
              <span className="text-bold">Improved collaboration:</span> When team members are aligned on norms around
              responsibilities and timelines, it fosters smoother and more effective teamwork.
            </li>
            <li>
              <span className="text-bold">Better conflict resolution:</span> Establishing norms for addressing conflicts
              constructively can prevent misunderstandings from escalating and facilitate respectful resolution.
            </li>
            <li>
              <span className="text-bold">Increased accountability:</span> Defined norms set expectations for individual
              and collective responsibility, encouraging team members to take ownership of their roles and commitments.
            </li>
            <li>
              <span className="text-bold">More cohesion and trust:</span> Discussing and developing team norms fosters a
              cohesive team culture built on mutual respect, trust, and support, which is essential for achieving shared
              goals and accelerating the{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hr.mit.edu/learning-topics/teams/articles/stages-development"
              >
                stages of team development.
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="pb-medium">
        <h3 className="text-rising-orange pt-large pb-xs">6 tips for creating team norms that stick</h3>
        <div className="pb-xs">
          Here are some tips for how to create strong team norms that your team will use consistently:
        </div>
        <ol>
          <li>
            <span className="text-bold">Make it a collaborative process:</span> Involve all team members in establishing
            norms to ensure buy-in and ownership. Start with our Rising Team session and then schedule dedicated
            follow-up meetings as needed to finalize the team norms collectively.
          </li>
          <li>
            <span className="text-bold">Clarify expectations:</span> Clearly articulate the expectations associated with
            each norm. Use specific examples to illustrate desired behaviors and outcomes. Document the final norms in a
            written format, such as a team charter or guidelines. 
          </li>
          <li>
            <span className="text-bold">Lead by example:</span> When team leaders and managers serve as role models for
            the team, it reinforces the value and importance of adhering to the team norms.
          </li>
          <li>
            <span className="text-bold">Celebrate successes:</span> Acknowledge and celebrate moments where team members
            exemplify the desired behaviors and values outlined in the team norms. 
          </li>
          <li>
            <span className="text-bold">Address outliers promptly:</span> If deviations from team norms occur, address
            them promptly and constructively. Use these instances as opportunities for learning and reinforcement rather
            than punitive measures.
          </li>
          <li>
            <span className="text-bold">Stay agile:</span> As teams evolve and circumstances change, revisit and revise
            team norms as needed. Flexibility and adaptability are vital in maintaining the relevance and effectiveness
            of your team norms. 
          </li>
        </ol>
      </div>
      <div>
        Team norms are a cornerstone of effective teamwork. By engaging in a collaborative process to establish clear
        and constructive norms, teams can foster an environment of trust, accountability, and mutual respect, ultimately
        driving success and achieving their goals.
        <p className="my-medium text-semi-bold">More resources:</p>
        <ul className="ml-xl">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ccl.org/articles/leading-effectively-articles/the-real-world-guide-to-team-norms/"
            >
              10 Steps for Establishing Team Norms
            </a>{" "}
            The Center for Creative Leadership
          </li>
          <li>
            <a target="_blank" rel="noopener noreferrer" href="https://asana.com/resources/group-norms-examples">
              Tips to create group norms for high-performance teams,
            </a>{" "}
            Asana
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.forbes.com/sites/forbeshumanresourcescouncil/2019/07/01/how-to-define-your-teams-norms-and-why-its-important/?sh=33216f781000"
            >
              How To Define Your Team's Norms (And Why It's Important)
            </a>{" "}
            Forbes
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.wiley.com/en-us/Effective+Teamwork%3A+Practical+Lessons+from+Organizational+Research%2C+3rd+Edition-p-9780470974971"
            >
              Effective teamwork: Practical lessons from organizational research.
            </a>{" "}
            John Wiley & Sons
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://web.archive.org/web/20230727040807/https://www.nytimes.com/guides/business/manage-a-successful-team"
            >
              How to Build a Successful Team
            </a>{" "}
            New York Times
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default styled(TeamNormsTraining1)`
  ol,
  ul {
    margin: 0;
    padding-left: 24px;

    li {
      color: inherit;
    }
  }

  ol > li::marker {
    font-weight: 700;
  }
`
