import { styled } from "styled-components"

function Dropdown({ className, onItemClick = null, children }) {
  const links = children.map((link) => (
    <li key={link.key} onClick={onItemClick}>
      {link}
    </li>
  ))

  return (
    <div className={className}>
      <ul className="link-list">{links}</ul>
    </div>
  )
}

export default styled(Dropdown)`
  padding-top: 10px;
  border: 1px solid #e5e5e5;
  border-top: none;
  width: 300px;
  background-color: #fff;
  margin: 0 auto;

  .link-list {
    list-style-type: none;
    text-decoration: none;
    display: block;
    font-size: 16px;
    text-align: center;
    padding: 6px;
  }

  a {
    color: var(--gray-9);
    display: inline-block;
    width: 100%;

    &:link,
    &:visited {
      text-decoration: none;
    }

    &:hover {
      font-weight: 600;
    }
  }
`
