import { styled } from "styled-components"

const MenziesExpectationsTraining2 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-large">
        Now that you understand the benefits of clarifying expectations, how do you help the team build that discipline
        together? During this Rising Team session, your team will learn how to use the ROAD framework for clarifying
        expectations, then apply it to clarifying an individual workplace goal.
      </p>
      <h2 className="text-rising-orange mb-medium">Build the discipline of clarity as a team</h2>
      <p className="mb-medium">
        Whether setting a goal for someone on your team or clarifying expectations for your own goals, the ROAD
        framework outlines specific criteria needed to set both parties up for success. This framework can be used
        alongside other goal setting frameworks—think OKRs or SMART goals—it's about <strong>how</strong> you set and
        commit to goals, whichever model you use. The ROAD framework helps ensure you have clarity and commitment around
        goals and makes it more likely you will reach them. The ROAD model can be especially helpful to revisit
        expectations and commitments as things change.
      </p>
      <h3 className="mb-medium text-semi-bold">The ROAD model</h3>
      <div className="road-framework">
        <div className="dash-border">
          <div className="letter-bubble-container">
            <div className="letter-bubble">R</div>
            <div className="mb-large">
              <p className="text-field-label text-semi-bold mt-xs">Request and measurement</p>
              <p className="mb-medium">Clarify what needs to be done by when and how success will be measured.</p>
              <p className="mb-medium">Questions to consider:</p>
              <ul className="ml-xl">
                <li>What is the request or what are we trying to achieve?</li>
                <li>When do we want to achieve this by?</li>
                <li>How will we know if we’re successful? (metric, feedback, something else?)</li>
                <li>Who are the stakeholders that need to be involved?</li>
              </ul>
            </div>
          </div>
          <div className="letter-bubble-container">
            <div className="letter-bubble">O</div>
            <div className="mb-large">
              <p className="text-field-label text-semi-bold mt-xs">Obstacles and support</p>
              <p className="mb-medium">
                Highlight potential roadblocks that could derail success. Identify and agree on the type of support and
                resources needed along the way.
              </p>
              <p className="mb-medium">Questions to consider:</p>
              <ul className="ml-xl">
                <li>What new or existing barriers could get in the way of success?</li>
                <li>What support and/or resources do we need and from whom?</li>
              </ul>
            </div>
          </div>
          <div className="letter-bubble-container">
            <div className="letter-bubble">A</div>
            <div className="mb-large">
              <p className="text-field-label text-semi-bold mt-xs">Align and commit</p>
              <p className="mb-medium">
                Once both parties have a common understanding of the criteria for success, it’s time to commit.
              </p>
              <p className="mb-medium">Questions to consider:</p>
              <ul className="ml-xl">
                <li>Are the expectations reasonable? If not, what can be negotiated?</li>
                <li>Do we feel aligned and ready to commit?</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="letter-bubble-container">
          <div className="letter-bubble">D</div>
          <div className="mb-large">
            <p className="text-field-label text-semi-bold mt-xs">Deliver and assess</p>
            <p className="mb-medium">After results are complete, weigh them against the original request.</p>
            <p className="mb-medium">Questions to consider:</p>
            <ul className="ml-xl">
              <li>How well did we achieve what we set out to do?</li>
              <li>What worked well and what could have been done differently?</li>
            </ul>
          </div>
        </div>
      </div>
      <p className="mb-medium">
        Walk through this framework before committing to new responsibilities, then continue to reassess along the way
        because things can change.
      </p>
    </div>
  </div>
)

export default styled(MenziesExpectationsTraining2)`
  .dash-border {
    border-left: 3px dashed var(--rising-yellow);
    margin-left: 24px;
  }

  .letter-bubble-container {
    display: grid;
    width: 100%;
    grid-template-columns: 48px auto;
    grid-gap: 24px;
    margin-left: -25px;
  }

  .letter-bubble {
    font-size: 2rem;
    font-weight: 600;
    color: white;
    background-color: var(--gray-9);
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .road-framework > :last-child {
    margin-left: 0;
  }
`
