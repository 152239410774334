import { useKitSession } from "../KitSessionContext"

import AggregatedResultsBlocks from "domains/Exercise/ResultsBlocks/AggregatedResultsBlocks"
import { getUseGetAggregatedResultsDataForKitInstanceFunction, useKitParticipants } from "resources/monthly_kit"
import { sortUsersByShortName } from "resources/users"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const AggregatedResultsBlocksContainer = ({ kitInstance, content }) => {
  const { team, preview } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const useAggregatedResultsData = getUseGetAggregatedResultsDataForKitInstanceFunction({
    kitInstanceId: kitInstance.id,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  const { data: participants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })

  if (!participants) {
    return <Loading />
  }

  const sortedParticipants = sortUsersByShortName({ users: participants })

  return (
    <AggregatedResultsBlocks
      blocks={content}
      useAggregatedResultsData={useAggregatedResultsData}
      sortedUsers={sortedParticipants}
    />
  )
}

export default AggregatedResultsBlocksContainer
