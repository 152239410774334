import { useRef, useState } from "react"

import { ArtiHistoryOrder, IGNORE_USER_MESSAGE } from "../constants"
import useArtiChat from "../hooks/useArtiChat"
import { getHistorySession, useArtiHistoryTopExchanges, sendAndProcessArtiInSessionChat } from "../resource"
import ArtiChatStyles from "../utils/ArtiChatStyles"
import { processInSessionChatSubmission } from "../utils/ArtiChatSubmission"

import ArtiChatTypeInSession from "./ArtiChatTypeInSession"
import ArtiInSessionChatIntro from "./ArtiInSessionChatIntro"

import { useScrollToTopContext } from "components/ScrollToTop"
import { Choice } from "forms/fields/ChoicesField"
import handleErrors from "forms/handleErrors"
import { HandWaveIcon } from "icons/FontAwesomeIcons"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useEffectAfterFirstRender from "ui/hooks/useEffectAfterFirstRender"
import Loading from "ui/Loading"
import View from "ui/View"

const ArtiInSessionChat = function ArtiInSessionChat({
  className,
  user,
  kitInstance,
  team,
  initialChatType,
  initialMessage,
  onArtiMessageAccept,
  initialChoiceMessage,
  isCopyOption = false,
}) {
  const componentRef = useRef(null)

  const {
    chatInputRef,
    messages,
    setMessages,
    loading,
    setLoading,
    llm,
    setLLM,
    sessionStartedAt,
    setSessionStartedAt,
    startExchangeId,
    setStartExchangeId,
    prevExchangeId,
    setPrevExchangeId,
    latestExchangeId,
    setLatestExchangeId,
    excludeFromManualReview,
    setExcludeFromManualReview,
    hasLoadedHistory,
    setHasLoadedHistory,
    chatTypeSelected,
    setChatTypeSelected,
    showExcludeFromManualReview,
    isComponentMounted,
    updatePersistedValue,
    onExcludeFromManualReviewChange,
    defaultLLM,
    availableLLMs,
    llmPersistenceKey,
    showChatHistory,
    testId,
    queryClient,
  } = useArtiChat({ user, initialChatType })

  const [showInputArea, setShowInputArea] = useState(false)

  const { data: historyExchanges, isFetching: isFetchingHistoryExchanges } = useArtiHistoryTopExchanges({
    enabled: !!showChatHistory,
    chatType: chatTypeSelected,
    teamId: team?.id,
    kitInstanceId: kitInstance?.id,
    order: ArtiHistoryOrder.OLDEST,
  })

  const onSubmit = handleErrors(async (values, { resetForm }) => {
    const processChatParams = {
      team,
      useStreaming: false,
      artiChatParams: {
        llm,
        sessionStartedAt,
        startExchangeId,
        prevExchangeId,
        testId,
        chatTypeSelected,
        kitInstanceId: kitInstance.id,
      },
    }

    await processInSessionChatSubmission({
      values,
      resetForm,
      sendChatFunction: sendAndProcessArtiInSessionChat,
      processChatParams,
      messages,
      setMessages,
      loading,
      setLoading,
      setSessionStartedAt,
      setStartExchangeId,
      setPrevExchangeId,
      setLatestExchangeId,
      queryClient,
    })()

    setLoading(false)
    setShowInputArea(false)
    chatInputRef.current?.focus()
    chatInputRef.current?.scrollIntoView()
  })

  // Manage loading and rendering of history exchange (if selected):
  useEffectAfterChange(() => {
    if (hasLoadedHistory) {
      messages.length && componentRef.current?.scrollIntoView()
      return
    }

    if (!isFetchingHistoryExchanges) {
      const session = getHistorySession(historyExchanges)

      const sessionMessages = session.messages.filter((message) => !IGNORE_USER_MESSAGE.includes(message.text))

      setMessages([...sessionMessages])
      const sessionChatType = session.chatType

      setChatTypeSelected(sessionChatType ?? chatTypeSelected)
      setStartExchangeId(session.firstExchangeId)
      setLatestExchangeId(session.lastExchangeId)
      setPrevExchangeId(session.lastExchangeId)
      setExcludeFromManualReview(session.excludeFromManualReview)

      if (session.llm && Object.values(availableLLMs).includes(session.llm)) {
        setLLM(session.llm)
        updatePersistedValue(llmPersistenceKey, session.llm, { defaultValue: defaultLLM })
      }
      setHasLoadedHistory(true)
      if (sessionMessages.length > 0) {
        componentRef.current?.focus()
        componentRef.current?.scrollIntoView()
      }
    }
  }, [
    user,
    team,
    latestExchangeId,
    availableLLMs,
    defaultLLM,
    hasLoadedHistory,
    chatTypeSelected,
    historyExchanges,
    messages,
    onSubmit,
    isFetchingHistoryExchanges,
    initialMessage,
    llmPersistenceKey,
    setChatTypeSelected,
    setExcludeFromManualReview,
    setHasLoadedHistory,
    setLatestExchangeId,
    setMessages,
    setPrevExchangeId,
    setStartExchangeId,
    updatePersistedValue,
    setLLM,
  ])

  const { resetNoScrollToTop } = useScrollToTopContext()
  useEffectAfterFirstRender(() => () => resetNoScrollToTop()) // reset when component unmounts

  if (!!isFetchingHistoryExchanges) {
    return <Loading />
  }

  return (
    <ArtiChatStyles as={View} className={className} style={{ height: "100vh" }}>
      <View className="main-container full-width neg-mt-large full-height relative-position" $flexDirection="column">
        <ArtiInSessionChatIntro chatType={chatTypeSelected} />
        <ArtiChatTypeInSession
          user={user}
          team={team}
          llm={llm}
          messages={messages}
          setMessages={setMessages}
          loading={loading}
          chatInputRef={chatInputRef}
          isComponentMounted={isComponentMounted}
          onSubmit={onSubmit}
          chatTypeSelected={chatTypeSelected}
          showInputArea={showInputArea}
          setShowInputArea={setShowInputArea}
          onArtiMessageAccept={onArtiMessageAccept}
          componentRef={componentRef}
          initialChoiceMessage={initialChoiceMessage}
          isCopyOption={isCopyOption}
        />
        <div className="arti-footer">
          <View $alignItems="center" $gap="var(--spacing-3)" className="p-medium bg-gray-2 border-radius-medium">
            <HandWaveIcon color="var(--rising-yellow)" className="small-logo-icon" />
            <div>
              <span className="text-semi-bold">What's aRTi?</span> aRTi is your AI leadership coach, available anytime
              from the side panel.
            </div>
          </View>
          {!!showExcludeFromManualReview && !!startExchangeId && (
            <div className="mt-small">
              <Choice
                type="checkbox"
                label="Exclude conversation from manual review (internal use only)"
                onChange={onExcludeFromManualReviewChange}
                checked={excludeFromManualReview}
              />
            </div>
          )}
        </div>
      </View>
    </ArtiChatStyles>
  )
}

export default ArtiInSessionChat
