import { useRef, useState } from "react"

import { useKitSession } from "../KitSessionContext"

import SharingNow from "./SharingNow"
import StepTitle from "./StepTitle"

import {
  useRealtimeSelectedShareoutUser,
  useKitParticipants,
  useUpdateSelectedShareoutUser,
} from "resources/monthly_kit"
import { sortUsersByShortName } from "resources/users"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import usePageSyncEnabled from "ui/hooks/usePageSyncEnabled"
import SelectedUserContext from "ui/SelectedUserContext"
import { KitGroupSize } from "utils/kit"
import { REALTIME_KEY_PATH_PREFIX } from "utils/query"
import { useHasTeamFeature } from "utils/team"

const EMPTY_USER = { id: "" }
const getRealtimeShareoutUserKey = (path) => REALTIME_KEY_PATH_PREFIX + path

const SessionSelectedUsersShareView = (props) => {
  const { kitInstance, team, preview } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { enabled: realtimeShareoutUserActive } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_SHAREOUT_USER)
  const forceFullUserNames = kitInstance?.kit?.group_size === KitGroupSize.JUMBO
  const realtimeShareoutUserKey = getRealtimeShareoutUserKey(props.sessionStep.path)
  const { data: selectedShareoutUser, isInitialLoading: isInitialLoadingSelectedShareoutUser } =
    useRealtimeSelectedShareoutUser({
      kitInstanceId: kitInstance?.id,
      key: realtimeShareoutUserKey,
      forceFullUserNames,
      enabled: !!(kitInstance && realtimeShareoutUserActive),
    })
  const { data: participants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })

  if (!participants || (realtimeShareoutUserActive && isInitialLoadingSelectedShareoutUser)) {
    return null
  }

  return (
    <ViewWithParticipants
      participants={participants}
      team={team}
      selectedShareoutUser={selectedShareoutUser}
      {...props}
    />
  )
}

const ViewWithParticipants = ({
  participants,
  team,
  sessionStep,
  totalMinutes,
  minMinutesPerUser,
  maxMinutesPerUser,
  initialSelectionEmpty,
  selectedShareoutUser,
  kitInstance,
  labelText = "Sharing now:",
  children,
}) => {
  const { enabled: realtimeShareoutUserActive } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_SHAREOUT_USER)
  const { facilitator, isFacilitator } = useKitSession()
  const realtimeShareoutUserKey = getRealtimeShareoutUserKey(sessionStep.path)
  const { mutateAsync: updateSelectedShareoutUser } = useUpdateSelectedShareoutUser({
    kitInstanceId: kitInstance?.id,
    key: realtimeShareoutUserKey,
  })
  const [pageSyncEnabled] = usePageSyncEnabled()
  const componentRef = useRef(null)
  const timerRef = useRef()
  const initialManuallySelectedUser = initialSelectionEmpty
    ? EMPTY_USER
    : pageSyncEnabled && selectedShareoutUser
      ? selectedShareoutUser
      : facilitator || participants[0]
  const [manuallySelectedUser, setManuallySelectedUser] = useState(initialManuallySelectedUser)
  const sortedParticipants = sortUsersByShortName({ users: participants })

  const onSelectUser = (user) => {
    setManuallySelectedUser(user)
    realtimeShareoutUserActive && isFacilitator && pageSyncEnabled && updateSelectedShareoutUser(user.id)
    timerRef.current?.resetAndPlay?.()
    componentRef.current.scrollIntoView()
  }

  useEffectAfterChange(() => {
    if (!!realtimeShareoutUserActive && !!pageSyncEnabled) {
      componentRef?.current?.scrollIntoView()

      // Keep non-facilitator manually selected user in sync with realtime shareout user
      if (!isFacilitator && !!selectedShareoutUser) {
        setManuallySelectedUser(selectedShareoutUser)
      }
    }
  }, [
    selectedShareoutUser,
    pageSyncEnabled,
    realtimeShareoutUserActive,
    isFacilitator,
    manuallySelectedUser,
    initialManuallySelectedUser,
  ])

  const teamSize = participants.length

  const timePerMember = teamShareMemberTime({
    teamSize,
    totalMinutes: totalMinutes ?? sessionStep.minutes,
    minMinutesPerUser: minMinutesPerUser ?? 1,
    maxMinutesPerUser,
  })

  const selectedRealtimeUser = !!selectedShareoutUser ? selectedShareoutUser : facilitator || participants[0]
  const selectedUser =
    realtimeShareoutUserActive && pageSyncEnabled && !isFacilitator ? selectedRealtimeUser : manuallySelectedUser

  return (
    <div ref={componentRef}>
      <h3 className="text-gray-9 text-bold">{labelText}</h3>
      <StepTitle
        sessionStep={sessionStep}
        minutes={timePerMember * teamSize}
        startSeconds={timePerMember * 60}
        timerRef={timerRef}
        showTimerLead
        className="mb-large"
      >
        <SharingNow users={sortedParticipants} selectedUser={selectedUser} onSelectUser={onSelectUser} />
      </StepTitle>
      {!!selectedUser && (
        <SelectedUserContext.Provider value={{ selectedUser }}>{children}</SelectedUserContext.Provider>
      )}
    </div>
  )
}

const teamShareMemberTime = ({ teamSize, totalMinutes, maxMinutesPerUser, minMinutesPerUser }) => {
  if (!totalMinutes) {
    return minMinutesPerUser
  }

  const calculatedMinutes = Math.floor(totalMinutes / teamSize)
  if (maxMinutesPerUser && calculatedMinutes > maxMinutesPerUser) {
    return maxMinutesPerUser
  }
  if (calculatedMinutes < minMinutesPerUser) {
    return minMinutesPerUser
  }
  return calculatedMinutes
}

export default SessionSelectedUsersShareView
