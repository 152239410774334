import { darken } from "polished"
import { styled } from "styled-components"

import { colors } from "ui/theme"

interface ButtonProps {
  type?: string
  color?: string | null
  onClick?: (event: MouseEvent) => void
}

// Internal function for managing configurable-color props for the Button component:
function configurableColor({
  propName,
  defaultThemeColor,
  themeColorVariant,
}: {
  propName: "color"
  defaultThemeColor: "primary" | "primary-active" | "primary-hover"
  themeColorVariant?: "active" | "hover"
}) {
  return (props: ButtonProps) => {
    const color = props[propName]
    if (color && !Object.values(colors).includes(color)) {
      throw new Error(`Button.js: Invalid prop value for ${propName}="${color}" (not a theme color)`)
    }
    if (themeColorVariant === "active" || themeColorVariant === "hover") {
      return color ? darken(0.1, color) : `var(--${defaultThemeColor})`
      // NOTE: "darken" logic here is specific to how "active" and "hover" theme
      //       color variants are currently implemented in the theme -- it's intended
      //       to match the variants there. If adding further variants here, ensure
      //       the logic matches their implementations in the theme.
    } else {
      return color ?? `var(--${defaultThemeColor})`
    }
  }
}

const Button = styled.button<ButtonProps>`
  background-color: ${configurableColor({
    propName: "color",
    defaultThemeColor: "primary",
  })};

  /* Set border on all buttons, even though it won't be visible on primary buttons.
   * This prevents buttons from changing width slightly when they change between
   * primary and secondary states.
   */
  border: 1px solid
    ${configurableColor({
      propName: "color",
      defaultThemeColor: "primary",
    })};
  display: inline-flex;
  gap: var(--spacing-1); /* xs space between elements in button */
  align-items: center;
  justify-content: center;
  font-weight: 600;
  padding: 6px 24px;
  height: 36px;
  border-radius: var(--border-radius-small);
  box-shadow: var(--blur-4);
  white-space: nowrap;
  outline: 0;
  cursor: pointer;
  margin-right: 8px;
  color: var(--fg);
  text-decoration: none;
  transition-property: background-color, color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;

  &.text-left {
    justify-content: left;
  }

  &:hover,
  &:focus-visible {
    text-decoration: none;
    box-shadow: var(--lift-4);
  }

  & + & {
    margin-left: 8px;
  }

  &:active {
    background-color: ${configurableColor({
      propName: "color",
      defaultThemeColor: "primary-active",
      themeColorVariant: "active",
    })};
    border: 1px solid
      ${configurableColor({
        propName: "color",
        defaultThemeColor: "primary-active",
        themeColorVariant: "active",
      })};
  }

  &:last-child {
    margin-right: 0;
  }

  > svg {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.secondary {
    color: ${configurableColor({
      propName: "color",
      defaultThemeColor: "primary",
    })};
    background-color: var(--white);
    border: 1px solid
      ${configurableColor({
        propName: "color",
        defaultThemeColor: "primary",
      })};

    &.link {
      color: var(--secondary);

      &:hover,
      &:focus-visible {
        color: var(--secondary-hover);
      }
    }
  }

  &.tertiary {
    color: var(--gray-9);
    background-color: var(--white);
    border: 1px solid var(--gray-6);
    font-weight: 500;
    font-size: 15px;

    &.link {
      color: var(--tertiary);
      border: none;
    }
  }

  &[disabled],
  &.disabled {
    pointer-events: none;
    cursor: default;
    color: var(--gray-7);
    background-color: var(--gray-5);
    border: 1px solid var(--gray-5);
    box-shadow: none;

    &:hover,
    &:focus-visible {
      background-color: var(--gray-5);
      border: 1px solid var(--gray-5);
    }
  }

  &.link {
    font-weight: 400;
  }

  &.link,
  &.link-semi-bold,
  &.link-blue,
  &.link-gray {
    color: ${configurableColor({
      propName: "color",
      defaultThemeColor: "primary",
    })};
    padding-left: 0;
    padding-right: 0;
    background-color: transparent !important;
    border: 1px solid transparent !important;
    box-shadow: none;

    > svg {
      margin: 0 8px;

      &:first-child {
        margin-left: 0;
      }
    }

    &:hover,
    &:focus-visible {
      color: ${configurableColor({
        propName: "color",
        defaultThemeColor: "primary-hover",
        themeColorVariant: "hover",
      })};
      background-color: transparent;
      border: 1px solid transparent;
      text-decoration: underline;
    }

    &.text-button {
      color: var(--secondary);

      &:focus-visible {
        color: var(--secondary-hover);
      }
    }

    &.secondary {
      &:hover,
      &:focus-visible {
        color: var(--secondary-hover);
      }
    }

    &.danger {
      color: var(--danger);

      &:hover,
      &:focus-visible {
        color: var(--danger-hover);
      }
    }

    &.success {
      color: var(--success);

      &:hover,
      &:focus-visible {
        color: var(--success-hover);
      }
    }
  }

  &.link-blue,
  &.link-blue:hover {
    color: var(--rising-blue);
  }

  &.link-gray {
    color: var(--gray-7);
  }

  &.large {
    height: 48px;
  }

  &.small {
    font-size: 14px;
    height: 32px;
  }

  &.xs {
    font-size: 0.8em;
    height: 20px;
    padding: 2px 16px;
  }

  &.inline-block {
    display: inline-block;
  }
`

Button.defaultProps = {
  type: "button",
  color: null,
}

export default Button
export type { ButtonProps }
