import cn from "classnames"
import { merge } from "lodash-es"
import type { ReactNode, CSSProperties } from "react"
import ReactModal from "react-modal"
import { styled } from "styled-components"

import useWindowSize from "./hooks/useWindowSize"
import View from "./View"

import { ArrowRightToLineIcon } from "icons/FontAwesomeIcons"
import type { ViewProps } from "ui/View"
import { ZIndex } from "ui/z-index"

const DEFAULT_STYLES = {
  overlay: {
    background: "var(--overlay)",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-end",
    padding: 0,
    zIndex: ZIndex.Modal,
    opacity: 0,
    visibility: "hidden",
    transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out",
  },
  content: {
    position: "absolute",
    top: "0 !important",
    right: "0 !important",
    bottom: "0 !important",
    left: "auto !important",
    borderRadius: 0,
    inset: "initial",
    padding: 0,
    width: "70%",
    height: "100%",
    lineHeight: "1.5",
    background: "var(--fg)",
    border: "none",
    outline: "none",
    transition: "transform 0.3s ease-in-out",
    transform: "translateX(100%)",
    overflow: "auto",
  },
}

interface SidePanelStyles {
  overlay?: CSSProperties
  content?: CSSProperties
}

interface SidePanelHeaderProps {
  className?: string
  onRequestClose?: () => void
  children?: ReactNode
}

interface SidePanelProps extends SidePanelHeaderProps {
  title?: string
  content?: ReactNode
  isOpen?: boolean
  width?: string
  styles?: SidePanelStyles
  zIndex?: null | ZIndex
  maxWidth?: string
}

interface SidePanelContentProps extends ViewProps {
  className?: string
}

const SidePanelHeader = styled(function SidePanelHeader({ className, onRequestClose, children }: SidePanelHeaderProps) {
  return (
    <View className={cn("side-panel-header p-large", className)} $justifyContent="space-between" $alignItems="center">
      <h2 className="text-gray-9 mb-none">{children}</h2>
      {!!onRequestClose && (
        <ArrowRightToLineIcon
          className="cursor-pointer text-gray-7 small-logo-icon"
          onClick={onRequestClose}
          data-testid="side-panel-close"
        />
      )}
    </View>
  )
})``

const SidePanelContent = ({ className, ...props }: SidePanelContentProps) => (
  <View
    $flexDirection="column"
    $height="100%"
    className={cn("side-panel-content my-large p-large", className)}
    {...props}
  />
)

const SidePanel = styled(function SidePanel({
  className,
  title,
  content,
  isOpen,
  onRequestClose,
  children,
  maxWidth = "100%", // eslint-disable-line @typescript-eslint/no-unused-vars -- used in css
  width = "70%",
  styles = {},
  zIndex = null,
}: SidePanelProps) {
  const { isMobileOrSmaller } = useWindowSize()
  const style = merge({}, DEFAULT_STYLES, styles, zIndex ? { overlay: { zIndex } } : {}, {
    overlay: {
      opacity: isOpen ? 1 : 0,
      visibility: isOpen ? "visible" : "hidden",
    },
    content: {
      width: !!isMobileOrSmaller ? "100%" : width,
      transform: isOpen ? "translateX(0)" : "translateX(100%)",
    },
  })

  return (
    <ReactModal
      isOpen={!!isOpen}
      onRequestClose={onRequestClose}
      className={cn(className, "p-medium")}
      style={style}
      closeTimeoutMS={1000}
    >
      {!!title && <SidePanelHeader onRequestClose={onRequestClose}>{title}</SidePanelHeader>}
      {!!content && <SidePanelContent>{content}</SidePanelContent>}
      {children}
    </ReactModal>
  )
})`
  max-width: ${({ maxWidth }) => maxWidth};
`

export default SidePanel
export { SidePanelHeader, SidePanelContent }
export type { SidePanelProps, SidePanelHeaderProps }
