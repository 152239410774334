import cn from "classnames"
import { keyBy, orderBy, times } from "lodash-es"
import { Fragment } from "react"
import { styled } from "styled-components"

import { getExerciseAnswer } from "../results_utils"

import Tooltip from "ui/Tooltip"
import View from "ui/View"

const RadioGroupMultiUserCircleCounter = styled(function RadioGroupMultiUserCircleCounter({
  identifier,
  exerciseInstances,
  exerciseComponent,
  sortedUsers,
  use_short_label,
  showCount = false,
  className,
}) {
  const choiceOptions = exerciseComponent.options
  const choiceOptionsWithCounts = choiceOptions.map((option) => {
    const count = exerciseInstances.filter((instance) =>
      getExerciseAnswer(instance, identifier)?.includes(option.value)
    ).length
    return { ...option, count }
  })
  const sortedChoiceOptions = orderBy(choiceOptionsWithCounts, "count", "desc")
  const numExercises = exerciseInstances.length
  const userIdMap = keyBy(sortedUsers, "id")

  return (
    <div className={cn("mb-xl", className)}>
      {sortedChoiceOptions.map((choiceOption) => {
        const filledExerciseInstances = exerciseInstances.filter((instance) =>
          getExerciseAnswer(instance, identifier)?.includes(choiceOption.value)
        )
        const unfilledCount = numExercises - filledExerciseInstances.length
        // TODO (loranne): Refactor to return only one list of components, reversing the list where needed
        // Need assistance with this
        return (
          <Fragment key={choiceOption.value}>
            {!showCount ? (
              <>
                <Circles
                  filledExerciseInstances={filledExerciseInstances}
                  unfilledCount={unfilledCount}
                  userIdMap={userIdMap}
                  choiceOption={choiceOption}
                />
                <div className="text-normal text-gray-9">
                  {use_short_label ? choiceOption.short_label : choiceOption.label}
                </div>
              </>
            ) : (
              <>
                <div className="text-normal text-gray-9 text-align-right">
                  {use_short_label ? choiceOption.short_label : choiceOption.label}
                </div>
                <div
                  className="text-bold text-utility text-center ml-small-mobile-only filled-count"
                  style={{ color: `${choiceOption.color}` }}
                >
                  {filledExerciseInstances.length}
                </div>
                <Circles
                  className="hide-on-mobile"
                  filledExerciseInstances={filledExerciseInstances}
                  unfilledCount={unfilledCount}
                  userIdMap={userIdMap}
                  choiceOption={choiceOption}
                />
              </>
            )}
          </Fragment>
        )
      })}
    </div>
  )
})`
  & {
    width: 100%;
    display: grid;
    grid-template-columns: ${({ showCount }) =>
      !showCount ? "auto 1fr" : "minmax(max-content, 200px) minmax(max-content, 30px) 1fr"};
    row-gap: 16px;
    column-gap: ${({ showCount }) => (!showCount ? "60px" : "20px")};
    align-items: center;

    @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
      grid-template-columns: ${({ showCount }) => (!showCount ? "1fr" : "auto 1fr")};
    }

    .filled-count {
      text-align: left;
    }
  }
`

const Circles = ({ filledExerciseInstances, unfilledCount, userIdMap, choiceOption, className }) => (
  <View $gap="4px" className={className}>
    {filledExerciseInstances.map(({ id, user_id }) => (
      <div key={id}>
        <Tooltip top float title={userIdMap[user_id]?.short_name}>
          <CounterCircle color={choiceOption.color} isFilled={true} />
        </Tooltip>
      </div>
    ))}
    {times(unfilledCount, (index) => (
      <CounterCircle key={index} color={choiceOption.color} isFilled={false} />
    ))}
  </View>
)

const CounterCircle = styled(function CounterCircle({ isFilled, className }) {
  return <div className={cn({ "counter-circle-filled": isFilled }, className)} />
})`
  & {
    position: relative;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: var(--gray-4);
    border: 1px solid var(--gray-5);

    &.counter-circle-filled {
      background-color: ${({ color }) => color};
      border: 0;
    }
  }
`

export default RadioGroupMultiUserCircleCounter
