import cn from "classnames"

import { useKitSession } from "domains/KitSession/KitSessionContext"
import { ExclamationTriangleIcon } from "icons/FontAwesomeIcons"
import { useKitParticipants } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const NumParticipantsWarning = ({ className }) => {
  const { kitInstance, team, preview } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({
    kitInstance,
    refetchInterval: 3000,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    preview,
  })
  const numParticipants = participants?.length ?? 0
  const maxParticipants = kitInstance.max_session_participants
  const displayMaxParticipants = team.demo ? 14 : maxParticipants
  const warnSessionParticipantsCount = maxParticipants - 2

  if (numParticipants < warnSessionParticipantsCount) {
    return (
      <div className={cn("text-gray-8 text-small", className)}>
        There can be a maximum of {displayMaxParticipants} participants.
      </div>
    )
  } else if (numParticipants < maxParticipants) {
    return (
      <View className={cn("text-small", className)} $alignItems="center">
        <ExclamationTriangleIcon color="var(--orange-4)" className="mr-medium fa-2xl" />
        <div>
          <b>Alert</b>: Sessions are intended for up to {warnSessionParticipantsCount - 1} participants. You can add up
          to {maxParticipants} as a maximum, though keep in mind, the session will take longer and it becomes harder for
          people to share openly.
        </div>
      </View>
    )
  }

  return (
    <View className={cn("text-small", className)} $alignItems="center">
      <ExclamationTriangleIcon color="var(--error-red)" className="mr-medium fa-2xl" />
      <div>
        <b>Alert</b>: This session has reached the maximum number of participants. Additional participants will not be
        able to join and the session may go over time. If you need assistance, please reach out to{" "}
        <a href="mailto:support@risingteam.com">support@risingteam.com</a>.
      </div>
    </View>
  )
}

export default NumParticipantsWarning
