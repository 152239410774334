import { styled } from "styled-components"

import { useKitSession } from "../KitSessionContext"

import { UserIcon } from "icons/FontAwesomeIcons"
import { useKitParticipantCount, useStartKitSession } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { plural } from "utils/string"
import { useHasTeamFeature } from "utils/team"

const COLUMNS = 20
const MAX_ROWS = 7

const ParticipantCountGrid = ({ className, kitInstance }) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { mutateAsync: startKitSession } = useStartKitSession()
  const { enabled: coleadsEnabled, isInitialLoading: coleadsFeatureIsLoading } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_COLEADS
  )
  const startSessionIfNeeded = async (data) => {
    if (!kitInstance.session_started_at && data.length > 1 && !coleadsEnabled) {
      await startKitSession({ kitInstanceId: kitInstance.id })
    }
  }

  const { data: { participant_count: participantCount } = {} } = useKitParticipantCount({
    kitInstance,
    refetchInterval: 3000,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    enabled: !coleadsFeatureIsLoading,
    onSuccess: startSessionIfNeeded,
  })

  if (!participantCount) {
    return <Loading />
  }
  const iconCount = Math.min(participantCount, COLUMNS * MAX_ROWS)

  return (
    <div className={className}>
      <p className="mb-large text-gray-8">
        In the session:{" "}
        <span className="text-bold text-gray-9">{plural(participantCount, "person", { pluralForm: "people" })}</span>
      </p>
      <div className="participant-icon-grid">
        {Array(iconCount)
          .fill()
          .map((_, idx) => (
            <UserIcon key={idx} className="participant-icon gradient-icon" />
          ))}
      </div>
    </div>
  )
}

export default styled(ParticipantCountGrid)`
  --participant-icon-width: 28px;

  .participant-icon-grid {
    display: grid;
    gap: var(--spacing-2) var(--spacing-1);
    ${({ theme }) =>
      theme.isWidescreen
        ? `grid-template-columns: repeat(${COLUMNS}, var(--participant-icon-width));`
        : "grid-template-columns: repeat(auto-fit, var(--participant-icon-width));"}

    .participant-icon {
      height: 32px;
    }
  }
`
