import { useBenchmarkSurveyLink, useEngagementSurveyAverage } from "resources/teams"
import Button from "ui/Button"
import HorizontalRule from "ui/HorizontalRule"
import { colors } from "ui/theme"
import View from "ui/View"

const RT4SBenchmarkSurveyReport = ({ className, accountId, surveyTeam }) => {
  const { data: surveyLink, isFetching } = useBenchmarkSurveyLink({ accountId, teamId: surveyTeam?.id })
  const { data: surveyData, isInitialLoading: surveyDataIsInitialLoading } = useEngagementSurveyAverage({ accountId })

  if (isFetching || surveyDataIsInitialLoading) {
    return null
  }

  const viewSurvey = () => {
    window.open(surveyLink, "_blank")
  }

  return (
    <div className={className}>
      <HorizontalRule />
      <h2 className="mb-medium">RT4S Benchmark Survey</h2>
      <p className="mb-small">
        This survey measures how well your team is doing on key team health factors. In order to preserve anonymity, we
        only provide results when 3 or more participants respond. Reach out to{" "}
        <a href="mailto:hello@risingteam.com" target="_blank" rel="noopener noreferrer">
          hello@risingteam.com
        </a>{" "}
        once all participants have completed to receive your results.
      </p>
      <p>
        Response count: <span className="text-semi-bold">{surveyData?.survey_count ?? 0}</span>
      </p>
      <View $alignItems="center" $justifyContent="space-between">
        <h4 className="mt-large mb-small">How to run the survey with your team</h4>
        <Button color={colors.risingBlue} className="link-semi-bold text-small" onClick={viewSurvey}>
          View survey
        </Button>
      </View>
      <p className="mb-small">
        Ask your team to complete this survey by sending this message by email or in your favorite messaging platform.
        Feel free to edit!
      </p>
      <p className="text-field-label mb-small">The message</p>
      <div className="mb-small text-thin text-normal text-gray-8 border border-light border-radius p-xs bg-gray-1">
        I’m excited to share that we’re going to use a new tool called Rising Team to help us build deeper connections
        and work even better together as a team.Before we start, we’re going to take a short survey to capture some key
        team health questions. We'll run this survey once now before we start the Rising Team sessions, and then every
        few months to check in. Responses are only used to generate team averages so no one will see who answered what.
        Here is a link to the survey—it's very brief, so please take a few minutes to fill it out today! {surveyLink}
      </div>
    </div>
  )
}

export default RT4SBenchmarkSurveyReport
