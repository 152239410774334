import cn from "classnames"

import {
  ArtiSender,
  VANILLA_LLM_TYPES,
  ARTI_RATING_NA_VALUE,
  MIN_RATEABLE_MESSAGE_LENGTH,
  CHAT_TYPE,
} from "../constants"
import { updateArtiExchangeRating } from "../resource"

import ArtiBotMessage from "./ArtiBotMessage"
import ArtiChatMessage from "./ArtiChatMessage"
import ArtiCopyButton from "./ArtiCopyButton"
import ArtiFlagButton from "./ArtiFlagButton"

import { removeLineRatingFieldNameSuffix } from "forms/fields/LineRatingField"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import useScrollElementIntoViewRef from "ui/hooks/useScrollElementIntoViewRef"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const ArtiChatMessages = ({
  messages,
  user,
  team,
  loading,
  llm,
  historyExchangeId,
  chatInputRef,
  chatTypeSelected,
  isMessageChoice,
  onArtiMessageAccept,
  initialChoiceMessage,
  isCopyOption = false,
  className,
}) => {
  // Manage auto-scrolling to a selected message loaded from history:
  const scrollToLoadedMessageRef = useScrollElementIntoViewRef()
  const { enabled: hideRatingFeedback, isInitialLoading: hideRatingFeedbackLoading } = useHasTeamFeature(
    team,
    SHARED_FLAGS.ARTI_HIDE_RATING_FEEDBACK
  )
  const { enabled: kitRecommendationsFeature } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_ARTI_KIT_RECS)
  const showKitRecommendations = kitRecommendationsFeature && chatTypeSelected !== CHAT_TYPE.PERFORMANCE_REVIEW // do not show kit recs in perf helper conversation type

  const showRatingFeedback =
    chatTypeSelected !== CHAT_TYPE.PERFORMANCE_REVIEW && !hideRatingFeedback && !hideRatingFeedbackLoading && !team.demo

  function getOnRatingFunction({ exchangeId }) {
    return async function onRating(name, value) {
      const fieldNameWithoutSuffix = removeLineRatingFieldNameSuffix(name)
      await updateArtiExchangeRating(exchangeId, {
        [fieldNameWithoutSuffix]: value === ARTI_RATING_NA_VALUE ? -1 : value,
      })
    }
  }

  return (
    <div className={cn(className, "mb-medium")}>
      {messages.map((message, index) => (
        <ArtiChatMessage key={index} sender={message.sender}>
          {!!index && !isMessageChoice && message.sender === ArtiSender.BOT && (
            <View className="icon-buttons">
              <ArtiFlagButton exchangeId={message.exchangeId} initialValue={message.inappropriateFlag} />
              <ArtiCopyButton clipboardText={message.text} className="text-gray-7-important" />
            </View>
          )}
          {message.sender === ArtiSender.BOT ? (
            <ArtiBotMessage
              message={message}
              showLoading={!!loading && index === messages.length - 1}
              showKitRecommendations={showKitRecommendations}
              showRatingFields={
                !!showRatingFeedback &&
                index > 0 &&
                !VANILLA_LLM_TYPES.includes(llm) &&
                message?.text?.length >= MIN_RATEABLE_MESSAGE_LENGTH
              }
              onRating={getOnRatingFunction({ exchangeId: message.exchangeId })}
              onKeyPressFocusElement={chatInputRef.current}
              user={user}
              team={team}
              messageIndex={index}
              isMessageChoice={isMessageChoice}
              onArtiMessageAccept={onArtiMessageAccept}
              initialChoiceMessage={initialChoiceMessage}
              isCopyOption={isCopyOption}
            />
          ) : (
            <div
              className={cn("text-prewrap", {
                "arti-message-highlighted": message.exchangeId === historyExchangeId,
              })}
              ref={message.exchangeId === historyExchangeId ? scrollToLoadedMessageRef : null}
            >
              {message.text?.trim()}
            </div>
          )}
        </ArtiChatMessage>
      ))}
    </div>
  )
}

export default ArtiChatMessages
