import cn from "classnames"

import ArtiChatInputArea from "../components/ArtiChatInputArea"
import ArtiChatMessages from "../components/ArtiChatMessages"
import ArtiTeamMemberOptedOutMessage from "../components/ArtiTeamMemberOptedOutMessage"

import ArtiInSessionMessageResponseOptions from "./ArtiInSessionMessageResponseOptions"

const ArtiChatTypeInSession = ({
  user,
  team,
  llm,
  chatTypeSelected,
  isFetchingHistorySession,
  messages,
  setMessages,
  loading,
  historyExchangeId,
  chatInputRef,
  isComponentMounted,
  showInputArea,
  setShowInputArea,
  onSubmit,
  onArtiMessageAccept,
  componentRef,
  initialChoiceMessage,
  isCopyOption = false,
}) => {
  const memberOptedOut = user?.arti_disabled
  return (
    <>
      {!memberOptedOut ? (
        <div
          className={cn("fadeable conversation-container", {
            hidden: !user || isFetchingHistorySession,
          })}
        >
          <ArtiChatMessages
            messages={messages}
            user={user}
            team={team}
            loading={loading}
            llm={llm}
            historyExchangeId={historyExchangeId}
            chatInputRef={chatInputRef}
            chatTypeSelected={chatTypeSelected}
            isMessageChoice={true}
            onArtiMessageAccept={onArtiMessageAccept}
            initialChoiceMessage={initialChoiceMessage}
            isCopyOption={isCopyOption}
          />
          {!loading && (
            <div ref={componentRef}>
              {!!showInputArea ? (
                <ArtiChatInputArea
                  loading={loading}
                  isComponentMounted={isComponentMounted}
                  onSubmit={onSubmit}
                  chatInputRef={chatInputRef}
                  messages={messages}
                  user={user}
                  team={team}
                  chatTypeSelected={chatTypeSelected}
                  className="mb-medium"
                />
              ) : (
                <ArtiInSessionMessageResponseOptions
                  showInputArea={showInputArea}
                  setShowInputArea={setShowInputArea}
                  messages={messages}
                  setMessages={setMessages}
                  onArtiMessageAccept={onArtiMessageAccept}
                  chatTypeSelected={chatTypeSelected}
                  onSubmit={onSubmit}
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <ArtiTeamMemberOptedOutMessage selectedChatMember={user} />
      )}
    </>
  )
}

export default ArtiChatTypeInSession
