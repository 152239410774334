import cn from "classnames"
import DOMPurify from "dompurify"
import parse from "html-react-parser"
import { marked } from "marked"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { styled } from "styled-components"

import ArtiCopyButton from "domains/Arti/components/ArtiCopyButton"
import { CircleCheckIcon } from "icons/FontAwesomeIcons"
import PurifyInnerHTMLDiv from "ui/PurifyInnerHTMLDiv"
import View from "ui/View"
import { sanitizeAndStripAllHTMLTags } from "utils/string"

const ArtiMessageChoiceOptions = styled(function ArtiMessageChoiceOptions({
  className,
  message,
  onArtiMessageAccept,
  initialChoiceMessage,
  isCopyOption = false,
}) {
  const findUlElement = (element) => {
    if (Array.isArray(element)) {
      return element.find((item) => item.type === "ul") || null
    }
    return element?.type === "ul" ? element : null
  }

  function extractListItems(markedText) {
    const htmlMessage = marked(markedText)
    const parsedHtmlMessage = parse(htmlMessage)

    const ulElement = findUlElement(parsedHtmlMessage)
    if (!ulElement || !ulElement.props?.children) {
      return []
    }

    const children = Array.isArray(ulElement.props.children) ? ulElement.props.children : [ulElement.props.children]

    return children
      .filter((item) => item.type === "li")
      .map((item) => {
        const stringifyContent = (node) => {
          if (typeof node === "string") {
            return node
          }
          if (Array.isArray(node)) {
            return node.map(stringifyContent).join("")
          }
          if (node.type === "strong") {
            return `<strong>${stringifyContent(node.props?.children || "")}</strong>`
          }
          if (node.props?.children) {
            return stringifyContent(node.props.children)
          }
          return ""
        }
        return stringifyContent(item.props?.children || "")
      })
  }

  const options = extractListItems(message?.text)?.map((choice) => ({
    value: choice,
    label: choice,
  }))
  const choiceOptions = options.map(({ value, label }) => (
    <View key={value} $width="100%">
      <input
        type="radio"
        name="choice"
        onChange={() => onArtiMessageAccept(value)}
        value={value}
        id={`choice-${value}`}
        className="display-none"
      />
      <View
        as="label"
        htmlFor={`choice-${value}`}
        $alignItems="center"
        $justifyContent="flex-start"
        className={cn(
          "blur-4",
          "border border-gray-6",
          "border-radius-small",
          "bg-white",
          "px-medium",
          "py-xs",
          "choice-option"
        )}
        role="radio"
        aria-checked={false}
        $gap="16px"
      >
        <CircleCheckIcon className="icon" />
        <PurifyInnerHTMLDiv>{label}</PurifyInnerHTMLDiv>
      </View>
    </View>
  ))
  const copyOptions = options.map(({ value, label }) => (
    <View key={value} $width="100%" $alignItems="center" $justifyContent="space-between" $gap="8px">
      <span className="text-gray-9" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }} />
      <div className="fit-content">
        <ArtiCopyButton clipboardText={sanitizeAndStripAllHTMLTags(value)} className="text-gray-7-important" />
      </div>
    </View>
  ))

  return (
    <View $flexDirection="column" $gap="16px" className={className}>
      {options?.length === 0 ? (
        <Markdown remarkPlugins={[remarkGfm]}>{message?.text.trim()}</Markdown>
      ) : (
        <>
          <div className="mb-medium">
            <p className="text-semi-bold">{initialChoiceMessage} </p>
            <p className="text-normal">
              {!!isCopyOption
                ? "Choose one or more of these to copy into your exercise responses or ask aRTi for more ideas."
                : "Select one, or ask aRTi for more ideas."}
            </p>
          </div>
          {isCopyOption ? copyOptions : choiceOptions}
        </>
      )}
    </View>
  )
})`
  .choice-option .icon {
    opacity: 0;
  }

  .choice-option:hover {
    background-color: var(--green-tint);
    border-color: var(--green-3);

    .icon {
      opacity: 1;
      color: var(--green-3);
    }
  }
`

export default ArtiMessageChoiceOptions
