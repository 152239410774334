import { useQueryClient } from "@tanstack/react-query"
import cn from "classnames"
import { parseISO } from "date-fns"
import { Form, Formik } from "formik"
import { useNavigate } from "react-router"
import { styled } from "styled-components"

import ProgressBar from "components/ProgressBar"
import { useSteps } from "components/Steps/StepsContext"
import ThemeHeader from "components/ThemeHeader"
import SessionQRCode from "domains/KitSession/components/SessionQRCode"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { isTranslatableKit } from "domains/KitSession/translation"
import FacilitatorSelectForm from "domains/LeaderKit/FacilitatorSelectForm"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { StarIcon, ExclamationTriangleIcon, ClockIcon, CheckIcon } from "icons/FontAwesomeIcons"
import { getAppCodeURL, getKitInstanceCacheKey, useKitParticipants } from "resources/monthly_kit"
import { SHARED_FLAGS, TEAM_FLAGS } from "ui/hooks/useFeatures"
import useQueryParams from "ui/hooks/useQueryParams"
import useWindowSize from "ui/hooks/useWindowSize"
import { useCurrentTheme } from "ui/ThemeUpdateContext"
import View from "ui/View"
import { getNumMinutesPassed } from "utils/date"
import { KitGroupSize, formatKitSessionTime, roundKitSessionTime } from "utils/kit"
import { useHasTeamFeature } from "utils/team"

const SessionHeader = ({ allSessionSteps, className, isTeamLead }) => {
  const { kitInstance, team, isFacilitator, preview } = useKitSession()
  const queryClient = useQueryClient()
  const { currentIndex, count } = useSteps()

  const theme = useCurrentTheme()
  const { isTabletOrLarger } = useWindowSize()
  const { enabled: coleadsEnabled } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_COLEADS)
  const isJumbo = kitInstance?.kit?.group_size === KitGroupSize.JUMBO
  const canSelectFacilitator = !!coleadsEnabled && !isJumbo
  const { data: participants = [] } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates: true,
    preview,
    enabled: canSelectFacilitator,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getKitInstanceCacheKey({ kitInstanceId: kitInstance.id }) })
    },
  })

  const sumStepMinutes = (steps) => steps.reduce((time, step) => time + (step.minutes ?? 0), 0)

  const totalStepMinutes = sumStepMinutes(allSessionSteps)
  const completedStepMinutes = sumStepMinutes(allSessionSteps.slice(0, currentIndex))

  const roundedTotalSessionMinutes = roundKitSessionTime(kitInstance.kit.session_time_minutes)
  const formattedTotalSessionMinutes = formatKitSessionTime(roundedTotalSessionMinutes)

  const renderSessionTimer = () => {
    const sessionStartedAt = kitInstance.session_started_at
    const minutesElapsed = !sessionStartedAt ? 0 : getNumMinutesPassed(parseISO(sessionStartedAt))

    if (minutesElapsed === 0 || minutesElapsed - completedStepMinutes > totalStepMinutes) {
      return (
        <>
          <ClockIcon color="var(--gray-7)" className="mr-xs" />
          {formattedTotalSessionMinutes} total time
        </>
      )
    }
    if (currentIndex === count - 1) {
      return (
        <>
          <CheckIcon color="var(--rising-green)" className="mr-xs" />
          Finished!
        </>
      )
    }
    if (minutesElapsed <= completedStepMinutes - 5) {
      // Never display a "minutes ahead" value greater than listed total session time:
      const minutesAhead = Math.min(completedStepMinutes - minutesElapsed, roundedTotalSessionMinutes)
      return (
        <>
          <ClockIcon color="var(--gray-7)" className="mr-xs" />
          {minutesAhead} minutes ahead
        </>
      )
    }
    if (
      (minutesElapsed < completedStepMinutes + 5 && completedStepMinutes <= 30) ||
      (minutesElapsed < completedStepMinutes + 2 && completedStepMinutes > 30)
    ) {
      return (
        <>
          <StarIcon color="var(--rising-green)" className="mr-xs" />
          On schedule
        </>
      )
    }
    // Never display a "minutes behind" value greater than listed total session time:
    const minutesBehind = Math.min(minutesElapsed - completedStepMinutes, roundedTotalSessionMinutes)
    return (
      <>
        <ExclamationTriangleIcon color="var(--orange-2)" className="mr-xs" />
        {minutesBehind} minutes behind
      </>
    )
  }

  // Only show a code url (and QR code) in kit header if both:
  // - this is not the welcome step (welcome step has QR code + url in step body)
  // - the session has a code (is not yet completed)
  const isWelcomeStep = allSessionSteps[currentIndex]?.path === "welcome"
  const showSessionCode = !isWelcomeStep && !!kitInstance?.session_code

  // TODO(hackathon,kit-translation,evnp): Clean up code below after hackathon
  // TRANSLATION LAYER MANAGEMENT
  const { lang } = useQueryParams()
  const navigate = useNavigate()
  function changeTranslation(option) {
    if (team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION)) {
      navigate(option.value?.length ? `?lang=${option.value}` : "?")
    }
  }

  const languageSwitcher = (className) => (
    <>
      {!!team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION) && !!isTranslatableKit(kitInstance?.kit?.slug) && (
        <View
          $justifyContent="flex-end"
          className={cn(className, "language-switcher mt-xs mb-small", {
            "text-xs": isTeamLead && kitInstance?.kit?.group_size === "jumbo",
          })}
        >
          <Formik initialValues={{ lang: lang ?? "" }}>
            <Form className="fit-content">
              <AdvancedSelectField
                name="lang"
                onChange={changeTranslation}
                options={[
                  { value: "", label: "English" },
                  { value: "zh-CN", label: "简体中文 (Chinese — Simplified)" },
                  // TODO(kit-translation,evnp) Clean up special-casing below
                  // Special-case intro-kickoff step since we only have these translations done there:
                  //...(!pathname.endsWith('/intro-kickoff')
                  //  ? []
                  //  : [
                  //      { value: 'es', label: 'Español (Spanish)' },
                  //      { value: 'fr', label: 'Français (French)' },
                  //      { value: 'hi', label: 'हिंदी (Hindi)' },
                  //      { value: 'ru', label: 'Русский (Russian)' },
                  //      { value: 'vi', label: 'Tiếng Việt (Vietnamese)' },
                  //      { value: 'zh', label: '中文 (Chinese)' },
                  //      { value: 'pl', label: '🐷 (Pig-Latin)' },
                  //    ]),
                ]}
              />
            </Form>
          </Formik>
        </View>
      )}
    </>
  )
  // END TRANSLATION LAYER MANAGEMENT

  return (
    <View
      className={cn("mb-small", className, {
        "kit-translation-available":
          team?.features?.includes(TEAM_FLAGS.RTDEV_KIT_TRANSLATION) && isTranslatableKit(kitInstance?.kit?.slug),
      })}
      $flexDirection="column"
      data-testid="session-step-header"
    >
      <View $justifyContent="space-between" $alignItems="flex-end" className="mt-xs">
        <ThemeHeader kitInstance={kitInstance} showHeader={true} />
        {!!isFacilitator && !!showSessionCode && !!isTabletOrLarger ? (
          <View
            className={cn("fit-content", { "text-medium": theme.isWidescreen })}
            $gap={theme.isWidescreen ? "3rem" : "2rem"}
          >
            <div>
              <p className="session-url mt-small text-gray-8">
                <span>{getAppCodeURL()}/</span>
                {kitInstance.session_code}
              </p>
              {languageSwitcher()}
            </div>
            <SessionQRCode className="session-qr-code" kitInstance={kitInstance} size={theme.isWidescreen ? 100 : 75} />
          </View>
        ) : (
          !!isTabletOrLarger && languageSwitcher("fit-content")
        )}
      </View>
      {!isTabletOrLarger && languageSwitcher("fit-content")}
      {!isFacilitator && !!isJumbo ? (
        <hr
          className={cn("border border-gray-4 full-width", {
            "mt-large": isTabletOrLarger,
            "mt-small": !isTabletOrLarger,
          })}
        />
      ) : (
        <ProgressBar
          className={cn("full-width", { "mt-large": isTabletOrLarger, "mt-xs": !isTabletOrLarger })}
          max={totalStepMinutes}
          value={completedStepMinutes}
        />
      )}
      <View $justifyContent="space-between" $alignItems="center" className="mt-xxs">
        {!!canSelectFacilitator && (
          <FacilitatorSelectForm kitInstance={kitInstance} team={team} options={participants} subtle={true} />
        )}
        {!theme.isWidescreen && !!isTabletOrLarger && !!isFacilitator && (
          <div className="text-semi-bold text-gray-8 ml-auto">{renderSessionTimer()}</div>
        )}
      </View>
    </View>
  )
}

export default styled(SessionHeader)`
  .duration-div {
    background-color: var(--rising-green);
    color: white;
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;

    .separator {
      color: var(--gray-4);
    }
  }

  .session-qr-code {
    margin-top: -1.5rem;
    margin-bottom: -1rem;
  }

  /* TODO(hackathon,kit-translation,evnp): Clean up code below after hackathon */
  /* TRANSLATION LAYER MANAGEMENT */
  &.kit-translation-available {
    .session-qr-code {
      margin-top: 0;
    }

    .session-url {
      margin-top: -0.3rem;
    }

    .language-switcher {
      position: relative;
      z-index: var(--z-above-sticky);
    }
  }
  /* END TRANSLATION LAYER MANAGEMENT */
`
