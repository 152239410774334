import { useQueryClient } from "@tanstack/react-query"
import { useRef, useState } from "react"
import { useLocation } from "react-router-dom"

import { LLMType } from "../constants"
import { updateExcludeFromManualReview } from "../resource"
import { availableLLMsForUser } from "../utils"

import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useEffectAfterFirstRender from "ui/hooks/useEffectAfterFirstRender"
import useQueryParams from "ui/hooks/useQueryParams"
import { isProductionEnv } from "utils/env"

export default function useArtiChat({ user, initialChatType = null }) {
  const { state } = useLocation()
  const { testId } = useQueryParams()
  const queryClient = useQueryClient()

  const chatInputRef = useRef()

  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const defaultLLM = isProductionEnv() ? LLMType.GPT_4o : LLMType.GPT_3_5
  const [llm, setLLM] = useState(defaultLLM)
  const [sessionStartedAt, setSessionStartedAt] = useState(null)
  const [startExchangeId, setStartExchangeId] = useState(null)
  const [prevExchangeId, setPrevExchangeId] = useState(null)
  const [latestExchangeId, setLatestExchangeId] = useState(null)
  const [excludeFromManualReview, setExcludeFromManualReview] = useState(false)
  const [hasLoadedHistory, setHasLoadedHistory] = useState(false)
  const [chatTypeSelected, setChatTypeSelected] = useState(state?.chatTypeSelected ?? initialChatType)
  const [reviewType, setReviewType] = useState(state?.reviewType ?? null)
  const [performanceReviewOptions, setPerformanceReviewOptions] = useState(state?.performanceReviewOptions ?? null)
  const [chatMemberId, setChatMemberId] = useState(state?.chatMemberId ?? null)
  const [chatTeamId, setChatTeamId] = useState(state?.chatTeamId ?? null)
  const showChatHistory = !user.is_demo_mode_active
  const [isInitialized, setIsInitialized] = useState(false)

  useEffectAfterChange(() => {
    setIsInitialized(true)
    return () => {}
  }, [user])

  useEffectAfterFirstRender(() => {
    setIsComponentMounted(true)
    return () => {
      setIsComponentMounted(false)
    }
  })

  const availableLLMs = availableLLMsForUser(user)
  const isDefaultLLM = llm === defaultLLM
  const showExcludeFromManualReview = !!user.is_staff && !user.is_demo_mode_active

  function onLLMChange({ value }) {
    setLLM(value)
    updatePersistedValue(llmPersistenceKey, value, { defaultValue: defaultLLM })
  }

  const llmPersistenceKey = "RISINGTEAM_ARTI_LLM"

  // On initial render, load values from session storage.
  // If a value is invalid or matches default, clear it from session storage.
  function loadPersistedValue(key, { defaultValue = null, isValid, setValue }) {
    const value = window.sessionStorage.getItem(key)
    const isInvalid = isValid(value)
    const isDefault = value === defaultValue
    if (isInvalid || isDefault) {
      window.sessionStorage.removeItem(key)
    } else {
      setValue(value)
    }
  }
  function updatePersistedValue(key, value, { defaultValue } = {}) {
    if (value === defaultValue) {
      window.sessionStorage.removeItem(key)
    } else {
      window.sessionStorage.setItem(key, value)
    }
  }

  useEffectAfterFirstRender(() =>
    loadPersistedValue(llmPersistenceKey, {
      defaultValue: defaultLLM,
      isValid: (value) => !Object.values(availableLLMs).includes(value),
      setValue: (value) => setLLM(value),
    })
  )

  function onExcludeFromManualReviewChange(event) {
    const newValue = event.target.checked
    setExcludeFromManualReview(newValue)
    updateExcludeFromManualReview(startExchangeId, newValue)
  }

  const [isComponentMounted, setIsComponentMounted] = useState(true)
  useEffectAfterFirstRender(() => () => setIsComponentMounted(false))

  function startNewConversation() {
    setMessages([])
    setChatMemberId(null)
    setChatTeamId(null)
    setChatTypeSelected(null)
    setReviewType(null)
    setPerformanceReviewOptions(null)
    setSessionStartedAt(null)
    setStartExchangeId(null)
    setPrevExchangeId(null)
    setLatestExchangeId(null)
    setExcludeFromManualReview(false)
  }

  return {
    chatInputRef,
    messages,
    setMessages,
    loading,
    setLoading,
    llm,
    setLLM,
    defaultLLM,
    isDefaultLLM,
    sessionStartedAt,
    setSessionStartedAt,
    startExchangeId,
    setStartExchangeId,
    prevExchangeId,
    setPrevExchangeId,
    latestExchangeId,
    setLatestExchangeId,
    excludeFromManualReview,
    setExcludeFromManualReview,
    hasLoadedHistory,
    setHasLoadedHistory,
    chatTypeSelected,
    setChatTypeSelected,
    reviewType,
    setReviewType,
    performanceReviewOptions,
    setPerformanceReviewOptions,
    availableLLMs,
    showExcludeFromManualReview,
    isComponentMounted,
    chatMemberId,
    setChatMemberId,
    chatTeamId,
    setChatTeamId,
    loadPersistedValue,
    updatePersistedValue,
    onExcludeFromManualReviewChange,
    onLLMChange,
    startNewConversation,
    llmPersistenceKey,
    showChatHistory,
    testId,
    queryClient,
    isInitialized,
  }
}
