import cn from "classnames"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import { useAccountBrand, useSetAccountBrand } from "./AccountBrandContext"
import UserMenu from "./UserMenu"

import { useAuth } from "domains/Authentication/resource"
import DemoToggle from "domains/Demo/DemoToggle"
import { useUser } from "resources/users"
import { HeaderLogo } from "ui/HeaderLogo"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import useWindowSize from "ui/hooks/useWindowSize"
import View from "ui/View"

const Header = ({ sidebarVisible, className, showSmallHeaderLogo = false }) => {
  const { data: auth } = useAuth()

  if (auth) {
    return (
      <HeaderLoggedIn sidebarVisible={sidebarVisible} className={className} showSmallHeaderLogo={showSmallHeaderLogo} />
    )
  }
  return <HeaderLoggedOut className={className} showSmallHeaderLogo={showSmallHeaderLogo} />
}

const HeaderLoggedIn = ({ sidebarVisible, showSmallHeaderLogo = false }) => {
  const { data: user, isFetching: userIsFetching } = useUser({ userId: "me" })
  const { [FLAGS.DEMO_MODE]: demo_mode } = useFeatures()
  const { accountBrand, setAccountBrand } = useAccountBrand()

  // set the account brand to the user's default if there is no account brand set
  useSetAccountBrand({ accountBrand: user?.default_account_brand, shouldSet: !accountBrand, setAccountBrand })

  // Ensure only RT staff with correct feature flag may ever access demo mode:
  const canAccessDemoMode = Boolean(user?.is_staff && demo_mode)
  const incompleteData = userIsFetching && !user

  return (
    <HeaderBase sidebarVisible={sidebarVisible}>
      <View $alignItems="stretch">
        <Link to="/" style={{ display: "flex" }} className="no-underline">
          <HeaderLogo showSmallHeaderLogo={showSmallHeaderLogo} />
        </Link>
      </View>
      {!incompleteData && (
        <>
          {!!canAccessDemoMode && (
            <View $flexShrink={0} $alignItems="center" $width="auto">
              <DemoToggle />
            </View>
          )}
          <View $flexShrink={0} $alignItems="center" $width="auto">
            <UserMenu user={user} />
          </View>
        </>
      )}
    </HeaderBase>
  )
}

const HeaderLoggedOut = ({ showSmallHeaderLogo = false }) => (
  <HeaderBase sidebarVisible={false}>
    <View $alignItems="stretch">
      <Link to="/" style={{ display: "flex" }} className="no-underline px-xs">
        <HeaderLogo showSmallHeaderLogo={showSmallHeaderLogo} />
      </Link>
    </View>
  </HeaderBase>
)

const HeaderBase = styled(function HeaderBase({ sidebarVisible, children, className }) {
  const { isMobileOrSmaller, isMobileLandscapeOrLarger } = useWindowSize()

  return (
    <View className={className}>
      <View
        as="main"
        $alignItems="stretch"
        className={cn(
          "mx-auto",
          {
            "content-max-width": !sidebarVisible,
            "screen-max-width": sidebarVisible,
            "px-large": isMobileOrSmaller,
            "px-medium": isMobileLandscapeOrLarger,
          },
          className
        )}
      >
        {children}
      </View>
    </View>
  )
})`
  background-color: var(--fg);
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 5%);
  height: 80px;
  flex-shrink: 0;
  position: relative;

  > * {
    display: flex;
    height: 100%;
  }

  @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
    height: 90px;
  }

  @media print {
    /* When printing, hide everything in header _except_ Rising Team logo at top left: */
    box-shadow: none;
    margin: 20px 20px 20px 48px;
    margin-bottom: -40px;

    main > :not(:first-child) {
      display: none;
    }
  }
`

export default Header

export { HeaderLoggedOut }
