import { Fragment } from "react"
import { styled } from "styled-components"

import getIconOrError from "icons"
import Loading from "ui/Loading"
import Tooltip from "ui/Tooltip"
import View from "ui/View"

const IconGroupRatingFieldAggregatedMultiUserBarChart = styled(
  function IconGroupRatingFieldAggregatedMultiUserBarChart({
    identifiers,
    useAggregatedResultsData,
    has_sensitive_data,
    refetch_interval,
    show_legend_with_icons,
    className,
  }) {
    const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
      component: "IconGroupRatingFieldAggregatedMultiUserBarChart",
      identifiers,
      has_sensitive_data,
      refetch_interval,
    })

    if (!aggregatedResults && isFetching) {
      return <Loading />
    }

    if (!aggregatedResults) {
      return null
    }

    if (aggregatedResults.insufficient_data) {
      return (
        <p className="p-large bg-gray-1">
          To maintain anonymity, we only share results when there are at least three team members with complete results.
        </p>
      )
    }

    const { results, components } = aggregatedResults
    const options = components[identifiers[0]]?.options ?? []
    const sortedOptions = options.every(({ value }) => Number.isInteger(parseInt(value)))
      ? [...options].sort(({ value: value1 }, { value: value2 }) => parseInt(value1) - parseInt(value2))
      : [...options]

    return (
      <View className={className} $flexDirection="column" $alignItems="center">
        <div className="results-grid full-width">
          <div className="label-column" />
          {/* div is necessary to maintain grid layout with no header above stacked bars */}
          <div />
          <div className="text-gray-7">Average</div>
          {results.map(({ identifier, label, rating_percentages, rating_counts, rating_average }) => {
            const roundedAverage = Math.round(rating_average)
            const {
              icon = null,
              color = null,
              icon_label: ratingLabel = null,
            } = options.find((option) => option.value === roundedAverage) ?? options[roundedAverage - 1] ?? {}
            const Icon = icon && getIconOrError(icon)
            const ChartTooltip = (props) => (
              <Tooltip
                top
                float
                wrapInView={false}
                maxWidth={350}
                title={
                  <>
                    <div>
                      <strong>{label}</strong>
                    </div>
                    <div className="text-nowrap">
                      <strong>{props.ratingLabel ?? ratingLabel}</strong>
                      {props.desc ?? " on average"}
                    </div>
                  </>
                }
              >
                {props.children}
              </Tooltip>
            )
            return (
              <Fragment key={identifier}>
                <div className="label-column text-right text-semi-bold">{label}</div>
                <View className="full-width">
                  {sortedOptions.map((ratingOption, idx) => {
                    const optionValue = ratingOption?.value ?? idx + 1
                    const percentage = rating_percentages?.[optionValue] ?? 0
                    const count = rating_counts?.[optionValue]
                    return (
                      percentage > 0 && (
                        <ChartTooltip
                          key={idx}
                          ratingLabel={ratingOption.icon_label}
                          desc={
                            <>
                              <span className="text-bold">:</span> {Math.round(percentage)}%
                              <div>
                                <span className="text-bold">Count:</span> {count}
                              </div>
                            </>
                          }
                        >
                          <div
                            style={{
                              backgroundColor: ratingOption.color,
                              height: "100%",
                              width: `${percentage}%`,
                            }}
                            className="rating-bar-segment"
                          ></div>
                        </ChartTooltip>
                      )
                    )
                  })}
                </View>
                <ChartTooltip>
                  <div className="text-center neg-my-xxs">{!!Icon && <Icon color={color} className="fa-2x" />}</div>
                </ChartTooltip>
              </Fragment>
            )
          })}
        </div>
        {!!show_legend_with_icons && (
          <View
            className="border border-radius-small bg-gray-1 py-xs mt-large fit-content px-small"
            $alignItems="center"
            $justifyContent="center"
          >
            <p className="text-field-label text-gray-8 mr-xxs">KEY</p>
            {options.map(({ icon, color, icon_label }) => {
              const Icon = icon && getIconOrError(icon)
              return (
                <View key={icon_label} className="fit-content" $alignItems="center">
                  <Icon color={color} className="fa-2x pr-xs pl-small" />
                  <p className="text-small show-on-desktop">{icon_label}</p>
                </View>
              )
            })}
          </View>
        )}
      </View>
    )
  }
)`
  .results-grid {
    display: grid;
    grid-template-columns: max-content auto max-content;
    gap: var(--spacing-2) var(--spacing-2);

    .rating-bar-segment {
      &:not(:last-child) {
        /* Add space between bars without moving the right edge of aggregate bars: */
        border-right: 6px solid white;
      }

      &:first-child {
        border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
      }

      &:last-child {
        border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
      }

      &:only-child {
        border-radius: var(--border-radius-small);
      }
    }
  }
`

export default IconGroupRatingFieldAggregatedMultiUserBarChart
