import Table from "components/Table"
import ConfirmOperationButton from "domains/Admin/ConfirmOperationButton"
import ResendInviteLink from "domains/Admin/ResendInviteLink"
import { useResendAdminInvite, useRemoveAdminInvite } from "resources/billing"
import View from "ui/View"
import { getUserNameOrEmail } from "utils/team"

const ManageAdminsTable = ({ className, account, user, invitedAdmins, acceptedAdmins, query }) => {
  const { mutateAsync: resendAdminInvite } = useResendAdminInvite(account?.id)
  const { mutateAsync: removeAdminInvite } = useRemoveAdminInvite(account?.id)

  const admins = (invitedAdmins ?? []).concat(acceptedAdmins ?? [])
  const invitedAdminIds = new Set((invitedAdmins ?? []).map(({ id }) => id))
  const isAdminInvitePending = (user) => invitedAdminIds.has(user.id)

  return (
    <Table
      className={className}
      query={query}
      rows={admins ?? []}
      empty="No account admins yet."
      notFound="No matching account admins found."
      defaultSort={[{ id: "status", desc: true }, { id: "name" }]}
      columns={[
        {
          id: "name",
          Header: `Admin (${admins.length})`,
          searchable: true,
          accessor: (admin) => {
            if (admin.id === user.id) {
              return <span className="text-semi-bold">You</span>
            } else {
              return getUserNameOrEmail(admin)
            }
          },
          tooltip: (user) => (user.name ? user.email : null),
          // This looks odd, but it is correct - if admin has name set, show their email in a tooltip.
          // Otherwise, don't show a tooltip at all (send null) since email will already be showing in table cell.
          title: (user) => (user.name ? null : `No user name set for ${user.email}`),
          // When email is showing, use a standard HTML title attr to show a less-obtrusive tooltip
          // that only appears after a delay. If someone _really_ needs to know why a name isn't showing up,
          // they'll end up seing this browser-native tip when the hover and delay.
        },
        {
          id: "status",
          Header: "Invite status",
          reverseHeaderSortArrow: true,
          accessor: (admin) => (isAdminInvitePending(admin) ? "Not yet accepted" : "Accepted"),
        },
        {
          id: "controls",
          disableSortBy: true,
          accessor: (admin) =>
            admin.id !== user.id &&
            (isAdminInvitePending(admin) ? (
              <View>
                <ResendInviteLink text="Resend invite" resendInvite={resendAdminInvite} resendInviteArgs={[admin.id]} />
                {admin.id !== account.owner.id && (
                  <ConfirmOperationButton
                    userId={admin.id}
                    buttonText="Cancel invite"
                    confirmTitle="Cancel invite?"
                    confirmBody="Are you sure you want to cancel this admin invite?"
                    confirmText="Yes"
                    cancelText="No"
                    onConfirm={() => removeAdminInvite(admin.id)}
                  />
                )}
              </View>
            ) : (
              admin.id !== account.owner.id && (
                <ConfirmOperationButton
                  userId={admin.id}
                  buttonText="Revoke admin privileges"
                  confirmTitle="Revoke admin privileges?"
                  confirmBody="Are you sure you want to revoke admin privileges for this admin?"
                  confirmText="Revoke"
                  cancelText="Cancel"
                  onConfirm={() => removeAdminInvite(admin.id)}
                />
              )
            )),
        },
      ]}
    />
  )
}

export default ManageAdminsTable
