import { useField } from "formik"
import { styled } from "styled-components"

import renderBlocks from "components/Blocks/renderBlocks"
import { blockIcons } from "icons"
import TextInput from "ui/TextInput"
import View from "ui/View"

const ExercisePercentageFieldBody = styled(function ExercisePercentageFieldBody({
  id,
  sentence,
  custom_field,
  CustomFieldComponent,
  saveOnChange,
  customSaveOnChange,
  style,
  className,
}) {
  const name = `${id}.percentage`
  const [field] = useField(name)

  const onChange = (evt) => {
    const v = parseInt(evt.target.value)
    saveOnChange && saveOnChange(name, v)
  }

  return (
    <>
      <View className={className} $flex={1} $justifyContent="space-between" $alignItems="center">
        <div className="text-gray-9">{renderBlocks(sentence, blockIcons)}</div>
        {!!custom_field && (
          <CustomFieldComponent
            name={custom_field.identifier}
            saveOnChange={customSaveOnChange}
            className="custom-field ml-xs"
          />
        )}
        <View className="text-bold ml-medium" $width="auto" $alignItems="center">
          <TextInput
            type="number"
            name={name}
            min={0}
            max={100}
            step={1}
            value={field.value ?? ""}
            onChange={onChange}
            placeholder="0"
            style={style}
          />
          <small className="ml-xs text-bold">%</small>
        </View>
      </View>
    </>
  )
})`
  input[type="number"] {
    max-width: 40px;
    border: none;
    padding: var(--spacing-0) !important;
    text-align: right;
    -moz-appearance: textfield;
  }

  .custom-field input {
    padding: var(--spacing-0);
  }

  /* Hides number spinners on input */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export default ExercisePercentageFieldBody
