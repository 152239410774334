import cn from "classnames"
import { useFormikContext } from "formik"
import { styled } from "styled-components"

import {
  ArtiSender,
  ARTI_MORE_IDEAS_MESSAGE,
  ARTI_TRY_AGAIN_MESSAGE,
  ARTI_IN_SESSION_ASK_ME_MESSAGE,
} from "../constants"

import { RotateRightIcon, CommentQuestionIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import View from "ui/View"

const ArtiInSessionMessageResponseOptions = styled(function ArtiInSessionMessageResponseOptions({
  className,
  messages,
  setMessages,
  setShowInputArea,
  chatTypeSelected,
  onSubmit,
}) {
  const formik = useFormikContext()

  function updateNewBotMessage({ newMessageText }) {
    setMessages([
      ...messages,
      {
        text: newMessageText,
        sender: ArtiSender.BOT,
      },
    ])
  }

  function onMoreIdeasClick() {
    onSubmit(
      {
        message: messages.length ? ARTI_TRY_AGAIN_MESSAGE : ARTI_MORE_IDEAS_MESSAGE,
        ignoreUserMessage: false,
      },
      formik
    )
    setShowInputArea(true)
  }

  function onAskQuestionsClick() {
    setShowInputArea(true)
    updateNewBotMessage({
      newMessageText: ARTI_IN_SESSION_ASK_ME_MESSAGE[chatTypeSelected],
    })
  }

  return (
    <div className={className}>
      <View
        className={cn("bg-gray-2", "border border-gray-5", "border-radius-small", "p-medium", "blur-4", "my-small")}
        $gap="16px"
        $flexDirectionMobile="column"
      >
        <Button className="secondary m-none-mobile-only" onClick={onMoreIdeasClick}>
          <RotateRightIcon color="var(--rising-orange)" />
          <div>{messages.length ? "Try again" : "Give me ideas"}</div>
        </Button>
        <Button className="secondary m-none-mobile-only" onClick={onAskQuestionsClick}>
          <CommentQuestionIcon color="var(--rising-orange)" />
          <div>Add more details</div>
        </Button>
      </View>
    </div>
  )
})``

export default ArtiInSessionMessageResponseOptions
