import cn from "classnames"

import RichTextField from "forms/fields/RichTextField"

const ExerciseRichTextField = ({ name, className, ...props }) => {
  const baseClasses = className
    ? className
        .split(" ")
        .filter((cls) => cls !== "large" && cls !== "medium")
        .join(" ")
    : ""

  const sizeClass = className?.includes("large") ? "large" : "medium"

  return <RichTextField className={cn(baseClasses, sizeClass)} name={`${name}.value`} {...props} />
}

export default ExerciseRichTextField
