import cn from "classnames"
import { marked } from "marked"
import { useState } from "react"

import ArtiInSessionChat from "./ArtiInSessionChat"

import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useUser } from "resources/users"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import SidePanel from "ui/SidePanel"
import { useHasTeamFeature } from "utils/team"

const ArtiInSessionButton = function ArtiInSessionButton({
  className,
  initialChatType,
  initialMessage,
  onArtiResponseSelect,
  initialChoiceMessage,
  isCopyOption = false,
}) {
  const { data: user } = useUser({ userId: "me" })
  const { kitInstance, team } = useKitSession()
  const isDemoModeActive = user.is_demo_mode_active
  const hasArtiAccess = user.has_arti_access
  const { enabled: isInSessionArtiEnabled, isInitialLoading: isInitialLoadingInSessionArtiEnabled } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_IN_SESSION_ARTI
  )

  const [showArtiSidePanel, setShowArtiSidePanel] = useState(false)

  async function onArtiMessageAccept(message) {
    setShowArtiSidePanel(false)
    const markedMessage = await marked(message)
    onArtiResponseSelect(markedMessage)
  }

  if (isInitialLoadingInSessionArtiEnabled || isDemoModeActive || !isInSessionArtiEnabled || !hasArtiAccess) {
    return null
  }

  function onShowButtonClick(e) {
    e.preventDefault()
    setShowArtiSidePanel(true)
  }

  return (
    <div className={className}>
      <button
        className={cn(
          "width-max-content",
          "border",
          "border-radius-xs",
          "text-xs",
          "text-semi-bold",
          "bg-white",
          "py-xxs",
          "px-xs",
          "flex",
          "gap-xxs",
          "justify-content-center",
          "align-items-center"
        )}
        onClick={onShowButtonClick}
      >
        <img
          src="https://static.risingteam.com/rtai/arti-chat-icon.png"
          width="16px"
          height="16px"
          alt="arti-chat-icon"
        />
        Give me ideas...
      </button>
      <SidePanel
        isOpen={showArtiSidePanel}
        onRequestClose={() => setShowArtiSidePanel(false)}
        title="aRTi"
        content={
          <ArtiInSessionChat
            user={user}
            kitInstance={kitInstance}
            initialChatType={initialChatType}
            initialMessage={initialMessage}
            team={team}
            onArtiMessageAccept={onArtiMessageAccept}
            initialChoiceMessage={initialChoiceMessage}
            isCopyOption={isCopyOption}
          />
        }
        width="60%"
        maxWidth="800px"
        className="p-medium"
      />
    </div>
  )
}

export default ArtiInSessionButton
