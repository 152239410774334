import { ArtiSender } from "../constants"
import { prependHistorySessionExchangeToQueryCache } from "../resource"
import { isValidMessageText } from "../utils"

function processChatSubmission({
  values,
  resetForm,
  sendChatFunction,
  processChatParams,
  messages,
  setMessages,
  loading,
  setLoading,
  setSessionStartedAt,
  setStartExchangeId,
  setPrevExchangeId,
  setLatestExchangeId,
  queryClient,
}) {
  return async () => {
    const { selectedChatMember, selectedChatTeam } = processChatParams
    if ((!selectedChatMember && !selectedChatTeam) || loading || !isValidMessageText(values?.message)) {
      // if a request is already in flight, or message is blank, ignore this submit
      return false
    } else {
      setLoading(true)
    }

    const newUserMessage = { sender: ArtiSender.USER, text: values.message }
    const newMessages = [...messages, newUserMessage]
    const updateNewBotMessage = ({ text, exchangeId }) => {
      const newBotMessage = { sender: ArtiSender.BOT, text, exchangeId }
      setMessages([...newMessages, newBotMessage])
    }

    updateNewBotMessage({ text: "" }) // show empty bot response with loading icon
    resetForm()

    const artiData = await sendChatFunction({
      ...processChatParams,
      newUserMessage,
      updateNewBotMessage,
    })

    const artiExchange = artiData.arti_exchange

    setLatestExchangeId(artiExchange?.id ?? null)

    if (artiExchange) {
      setSessionStartedAt(artiExchange.session_started_at ?? null)
      setStartExchangeId(artiExchange.start_exchange_id ?? null)
      setPrevExchangeId(artiExchange.id ?? null)
      prependHistorySessionExchangeToQueryCache(
        queryClient,
        { ...artiExchange, query: values.message }
        // must add query to exchange here since we only get basic exchange from API
      )
    }

    return artiExchange
  }
}

function processInSessionChatSubmission({
  values,
  sendChatFunction,
  processChatParams,
  messages,
  setMessages,
  loading,
  setLoading,
  setSessionStartedAt,
  setStartExchangeId,
  setPrevExchangeId,
  setLatestExchangeId,
  queryClient,
}) {
  return async () => {
    if (loading || !isValidMessageText(values?.message)) {
      // if a request is already in flight, or message is blank, ignore this submit
      return false
    } else {
      setLoading(true)
    }

    const newUserMessage = { sender: ArtiSender.USER, text: values.message }
    let newMessages = [...messages]
    if (!values?.ignoreUserMessage) {
      newMessages = [...messages, newUserMessage]
    }

    const updateNewBotMessage = ({ text, exchangeId }) => {
      const newBotMessage = { sender: ArtiSender.BOT, text, exchangeId }
      setMessages([...newMessages, newBotMessage])
    }

    updateNewBotMessage({ text: "" }) // show empty bot response with loading icon

    const artiData = await sendChatFunction({
      ...processChatParams,
      newUserMessage,
      updateNewBotMessage,
    })

    const artiExchange = artiData.arti_exchange

    setLatestExchangeId(artiExchange?.id ?? null)

    if (artiExchange) {
      setSessionStartedAt(artiExchange.session_started_at ?? null)
      setStartExchangeId(artiExchange.start_exchange_id ?? null)
      setPrevExchangeId(artiExchange.id ?? null)
      prependHistorySessionExchangeToQueryCache(
        queryClient,
        { ...artiExchange, query: values.message }
        // must add query to exchange here since we only get basic exchange from API
      )
    }

    return artiExchange
  }
}

export { processChatSubmission, processInSessionChatSubmission }
