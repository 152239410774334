import { loadStripe } from "@stripe/stripe-js"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import api from "api"
import { MARKETING_PRICING_URL } from "resources/get_started"
import { getTeamSelectorTeamsCacheKey, getTeamsCacheKey } from "resources/teams_cache_keys"
import { fetcher } from "utils/query"
import { buildUrl } from "utils/string"

// Lazily initialize Stripe instance since we don't need it on every page.
// (helps avoid "Error: Failed to load Stripe.js" issues when it's not even used)
let stripeInstancePromise = null
function getStripeInstance() {
  if (!stripeInstancePromise) {
    stripeInstancePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY)
  }
  return stripeInstancePromise
}

const SubscriptionType = {
  monthly: "monthly",
  yearly: "yearly",
  single: "single",
}

const leadUrlBase = "lead_and_admin_accounts"
const accountUrl = (id = null, suffix = null, { params = {}, urlBase = "admin_accounts" } = {}) =>
  buildUrl(["billing", urlBase, id, suffix], { urlQueryParams: params })

const updateAccountData = (data, queryClient) => {
  queryClient.setQueryData(getLeadAndAdminAccountCacheKey(data.id), data)
  queryClient.refetchQueries({ queryKey: getAccountAdminsCacheKey(data.id, { accepted: false }), exact: true })
  queryClient.refetchQueries({ queryKey: getAccountAdminsCacheKey(data.id, { accepted: true }), exact: true })
}

const refetchAccountAndTeams = (accountId, queryClient) => () => {
  queryClient.refetchQueries({ queryKey: getLeadAndAdminAccountCacheKey(accountId), exact: true })
  queryClient.refetchQueries({ queryKey: getTeamsCacheKey(), exact: true })
  queryClient.refetchQueries({ queryKey: getTeamSelectorTeamsCacheKey(), exact: true })
  queryClient.refetchQueries({ queryKey: getAccountTeamsCacheKey(accountId), exact: true })
  queryClient.refetchQueries({ queryKey: getAccountTeamLeadsCacheKey(accountId), exact: true })
}

const convertAccountToPromo = async (accountId, code, queryClient) => {
  await api.post(accountUrl(accountId, "convert_to_promo", { params: { code } }))
  queryClient.clear() // need to clear cache to reset state
}

const createGratitudePromoAccount = async (accountId, queryClient) => {
  await api.post(accountUrl(accountId, "gratitude_promo"))
  queryClient.clear() // need to clear cache to reset state
}

const createCheckoutSessionAndRedirect = async ({ billingData, billingAccountId, checkoutCancelUrl, fromDemo }) => {
  const successUrl = getCheckoutSuccessUrl({ billingData, fromDemo })
  const cancelUrl =
    checkoutCancelUrl ??
    buildUrl([MARKETING_PRICING_URL], { urlQueryParams: { package: billingData.subscriptionType } })
  const postData = {
    successUrl,
    cancelUrl,
    billingData,
    billingAccountId,
  }

  const { data } = await api.post(buildUrl(["billing", "create_checkout_session"]), postData)

  const stripe = await getStripeInstance()
  await stripe.redirectToCheckout({
    sessionId: data.sessionId,
  })
}

const getCheckoutSuccessUrl = ({ billingData, fromDemo }) =>
  buildUrl([window.location.origin, "get-started", "complete-checkout"], {
    // Need to specify checkout_session_id separately because we need to not
    // url-encode the {} which gets passed to Stripe's templating language:
    unencodedUrlQueryParams: {
      checkout_session_id: "{CHECKOUT_SESSION_ID}",
    },
    urlQueryParams: {
      ...(billingData.freeTrial ? { free_trial: true } : {}),
      ...(fromDemo ? { from_demo: true } : {}),
    },
  })

const completedCheckoutSession = async (checkoutSessionId, queryClient) => {
  await api.post(buildUrl(["billing", "completed_checkout_session", checkoutSessionId]))
  queryClient.clear() // need to clear cache to reset state of user and account
}

const getCustomerPortalSession = async (id, returnUrl) => {
  const postData = { returnUrl }
  const { data } = await api.post(accountUrl(id, "get_customer_portal_session"), postData)
  return data
}

const useCanPayOpenInvoice = (id) => {
  const url = accountUrl(id, "get_can_pay_open_invoice")
  return useQuery([url], () => fetcher({ url }), { enabled: !!id })
}

const cancelSubscription = (accountId) => async (feedback) => {
  const { data } = await api.post(accountUrl(accountId, "cancel_subscription"), { feedback })
  return data
}

const useCancelSubscription = (accountId) => {
  const queryClient = useQueryClient()
  return useMutation(cancelSubscription(accountId), {
    onSuccess: (data) => updateAccountData(data, queryClient),
  })
}

const reactivateSubscription = (accountId) => async () => {
  const { data } = await api.post(accountUrl(accountId, "reactivate_subscription"))
  return data
}

const useReactivateSubscription = (accountId) => {
  const queryClient = useQueryClient()
  return useMutation(reactivateSubscription(accountId), {
    onSuccess: (data) => updateAccountData(data, queryClient),
  })
}

const useAddTeamAccounts = () => {
  const url = buildUrl(["billing", "user_accounts", "get_accounts_user_can_create_teams_in"])
  return useQuery([url], () => fetcher({ url }))
}

const useAdminAccounts = () => {
  const url = accountUrl(null, "get_active", { urlBase: leadUrlBase })
  return useQuery([url], () => fetcher({ url }))
}

const ownedAccountURL = accountUrl(null, "get_owned")
const getOwnedAccount = () => fetcher({ url: ownedAccountURL })
const useOwnedAccount = () => useQuery([ownedAccountURL], getOwnedAccount)

const getLeadAndAdminAccountURL = (id) => accountUrl(id, null, { urlBase: leadUrlBase })
const getLeadAndAdminAccountCacheKey = (id) => [getLeadAndAdminAccountURL(id)]

const useUserAccount = (accountId) => {
  const url = buildUrl(["billing", "user_accounts", accountId])
  return useQuery([url], () => fetcher({ url }), { enabled: !!accountId })
}

const useAccount = (id) => {
  const url = getLeadAndAdminAccountURL(id)
  return useQuery(getLeadAndAdminAccountCacheKey(id), () => fetcher({ url }), { enabled: !!id })
}

const useDemoAccount = () => {
  const url = accountUrl(null, "get_demo_account")
  return useQuery([url], () => fetcher({ url }))
}

const updateAccount = (id) => async (values) => {
  const { data } = await api.patch(accountUrl(id), values)
  return data
}

const useUpdateAccount = (accountId) => {
  const queryClient = useQueryClient()
  return useMutation(updateAccount(accountId), {
    onSuccess: (data) => updateAccountData(data, queryClient),
  })
}

const useAccountTeams = (id) => {
  const url = getAccountTeamsURL(id)
  return useQuery(getAccountTeamsCacheKey(id), () => fetcher({ url }), { enabled: !!id })
}

const getAccountTeamsURL = (accountId) => accountUrl(accountId, "teams", { urlBase: leadUrlBase })
const getAccountTeamsCacheKey = (accountId) => [getAccountTeamsURL(accountId)]

const getAccountTeamsExport = async (id) => fetcher({ url: accountUrl(id, "teams_export", { urlBase: leadUrlBase }) })
const getAccountUsersExport = async (id) => fetcher({ url: accountUrl(id, "users_export", { urlBase: leadUrlBase }) })

function updateReportAccountData(data, queryClient) {
  for (const [queryKey, accounts] of queryClient.getQueriesData(reportAccountsCacheKeyRoot)) {
    if (Array.isArray(accounts)) {
      queryClient.setQueryData(
        queryKey,
        accounts.map((account) => (account.id === data.id ? data : account))
      )
    }
  }
}

const addAccountTag = async ({ accountId, tag, includeAllAccounts }) => {
  const { data } = await api.post(buildUrl(["billing", "admin_accounts", accountId, "add_tag"]), {
    tag,
    ...(includeAllAccounts ? { include_all_accounts: true } : {}),
  })
  return data
}

const useAddAccountTag = () => {
  const queryClient = useQueryClient()
  return useMutation(addAccountTag, {
    onSuccess: (data) => updateReportAccountData(data, queryClient),
  })
}

const removeAccountTag = async ({ accountId, tag }) => {
  const { data } = await api.post(buildUrl(["billing", "admin_accounts", accountId, "remove_tag"]), { tag })
  return data
}

const useRemoveAccountTag = () => {
  const queryClient = useQueryClient()
  return useMutation(removeAccountTag, {
    onSuccess: (data) => updateReportAccountData(data, queryClient),
  })
}

const removeAccountTagSuffix = async ({ accountId, tag, suffix }) => {
  const { data } = await api.post(buildUrl(["billing", "admin_accounts", accountId, "remove_tag_suffix"]), {
    tag,
    suffix,
  })
  return data
}

const useRemoveAccountTagSuffix = () => {
  const queryClient = useQueryClient()
  return useMutation(removeAccountTagSuffix, {
    onSuccess: (data) => updateReportAccountData(data, queryClient),
  })
}

const useAccountTeamLeads = (id) => {
  const url = getAccountTeamLeadsURL(id)
  return useQuery(getAccountTeamLeadsCacheKey(id), () => fetcher({ url }), { enabled: !!id })
}

const getAccountTeamLeadsURL = (accountId) => accountUrl(accountId, "team_leads")
const getAccountTeamLeadsCacheKey = (accountId) => [getAccountTeamLeadsURL(accountId)]

const searchAccountUsers = async (searchTerm, accountId) => {
  const url = accountUrl(accountId, "search_users", { params: { search_term: searchTerm } })
  return fetcher({ url })
}

const useAccountAdmins = (id, { accepted } = {}) => {
  const url = getAccountAdminsURL(id, { accepted })
  return useQuery(getAccountAdminsCacheKey(id, { accepted }), () => fetcher({ url }), { enabled: !!id })
}

const getAccountAdminsURL = (accountId, { accepted }) => accountUrl(accountId, "admins", { params: { accepted } })
const getAccountAdminsCacheKey = (accountId, { accepted }) => [getAccountAdminsURL(accountId, { accepted })]

const previewPurchaseAccountQuantity = (accountId, newQuantity) =>
  fetcher({ url: accountUrl(accountId, "preview_purchase_quantity", { params: { new_quantity: newQuantity } }) })

const purchaseAccountQuantity = (accountId) => async (values) => {
  const { data } = await api.post(accountUrl(accountId, "purchase_quantity"), {
    ...values,
    account_id: accountId,
  })
  return data
}

const usePurchaseAccountQuantity = (accountId) => {
  const queryClient = useQueryClient()
  return useMutation(purchaseAccountQuantity(accountId), {
    onSuccess: (data) => updateAccountData(data, queryClient),
  })
}

const createAccountTeam = (accountId) => async (values) => {
  const { data } = await api.post(accountUrl(accountId, "create_team", { urlBase: leadUrlBase }), {
    ...values,
    account_id: accountId,
  })
  return data
}

const useCreateAccountTeam = (accountId) => {
  const queryClient = useQueryClient()
  return useMutation(createAccountTeam(accountId), {
    onSuccess: refetchAccountAndTeams(accountId, queryClient),
  })
}

const createBillingAccount = async () => {
  const { data } = await api.post(accountUrl("create_as_owner"))
  return data
}

const inviteTeamLeads = (accountId) => async (values) => {
  const { data } = await api.post(accountUrl(accountId, "invite_team_leads"), values)
  return data
}

const useInviteTeamLeads = (accountId) => {
  const queryClient = useQueryClient()
  const refetchData = refetchAccountAndTeams(accountId, queryClient)
  return useMutation(inviteTeamLeads(accountId), {
    onSuccess: (accountData) => {
      refetchData()
      updateAccountData(accountData, queryClient)
    },
  })
}

const resendTeamLeadInvite = (accountId) => async (team_id) => {
  const { data } = await api.post(accountUrl(accountId, "resend_team_lead_invite"), {
    team_id,
  })
  return data
}

const useResendTeamLeadInvite = (accountId) => useMutation(resendTeamLeadInvite(accountId))

const inviteAdmin = (accountId) => async (values) => {
  const { data } = await api.post(accountUrl(accountId, "invite_admin"), values)
  return data
}

const useInviteAdmin = (accountId) => {
  const queryClient = useQueryClient()
  return useMutation(inviteAdmin(accountId), {
    onSuccess: (data) => updateAccountData(data, queryClient),
  })
}

const resendAdminInvite = (accountId) => async (admin_id) => {
  const { data } = await api.post(accountUrl(accountId, "resend_admin_invite"), {
    admin_id,
  })
  return data
}

const useResendAdminInvite = (accountId) => useMutation(resendAdminInvite(accountId))

const removeTeamLeadInvite = (accountId) => async (team_lead_id) => {
  const { data } = await api.post(accountUrl(accountId, "remove_team_lead_invite"), {
    team_lead_id,
  })
  return data
}

const useRemoveTeamLeadInvite = (accountId) => {
  const queryClient = useQueryClient()
  return useMutation(removeTeamLeadInvite(accountId), {
    onSuccess: refetchAccountAndTeams(accountId, queryClient),
  })
}

const removeAdminInvite = (accountId) => async (admin_id) => {
  const { data } = await api.post(accountUrl(accountId, "remove_admin_invite"), {
    admin_id,
  })
  return data
}

const useRemoveAdminInvite = (accountId) => {
  const queryClient = useQueryClient()
  return useMutation(removeAdminInvite(accountId), {
    onSuccess: (data) => updateAccountData(data, queryClient),
  })
}

const getAccountOffers =
  ({ customBilling }) =>
  () =>
    fetcher({
      url: buildUrl(["billing", "get_account_offers"], {
        urlQueryParams: { custom_billing: customBilling },
      }),
    })
const useAccountOffers = ({ customBilling } = {}) =>
  useQuery(getAccountOffersCacheKey({ customBilling }), getAccountOffers({ customBilling }))

const refetchAccountOffers = (queryClient) =>
  queryClient.refetchQueries({ queryKey: getAccountOffersCacheKey(), exact: true })

const getAccountOffersCacheKey = ({ customBilling } = {}) => {
  const cacheKeyBase = ["billing", "account_offers"]
  return customBilling ? [...cacheKeyBase, customBilling] : cacheKeyBase
}

const useReportAccounts = (parsedIncludeAllAccounts) => {
  const suffix =
    parsedIncludeAllAccounts.activeAccounts && parsedIncludeAllAccounts.activeAndInactiveAccounts ? "" : "get_active"
  const url = buildUrl(["billing", "account_reports", suffix], {
    urlQueryParams: parsedIncludeAllAccounts.activeAccounts ? { include_all_accounts: true } : {},
  })
  return useQuery([...reportAccountsCacheKeyRoot, url], () => fetcher({ url }))
}

const reportAccountsCacheKeyRoot = ["useReportAccounts"]

const contactAccountManager = async () => {
  const { data } = await api.post(accountUrl("contact_account_manager"))
  return data
}

const promoAccountUpgrade = async (accountId) => {
  const { data } = await api.post(accountUrl(accountId, "promo_account_upgrade"))
  return data
}

const freePromoKitSignup = async (values) => {
  const { data } = await api.post(buildUrl(["billing", "free_promo_kit_signup"]), values)
  return data
}

function buildAccountReportURL(
  pathArray,
  accountId,
  accountTags = null,
  multiAccountIds = null,
  kitSlug = null,
  teamId = null,
  teamTags = null,
  includeArchivedTeams = null,
  includeEngagementSurveyCounts = null
) {
  return !accountId
    ? null
    : buildUrl(["billing", "account_reports", accountId, ...pathArray], {
        urlQueryParams: {
          // Don't send multi-account IDs and tags at same time; IDs take precedence:
          ...(multiAccountIds?.length ? { multi_account_ids: multiAccountIds } : {}),
          ...(!multiAccountIds?.length && accountTags?.length ? { account_tags: accountTags } : {}),

          // Don't send team ID and tags at same time; ID takes precedence:
          ...(teamId ? { team_id: teamId } : {}),
          ...(!teamId && teamTags?.length ? { team_tags: teamTags } : {}),

          ...(kitSlug ? { kit_slug: kitSlug } : {}),
          ...(!!includeArchivedTeams ? { include_archived_teams: includeArchivedTeams } : {}),
          ...(!!includeEngagementSurveyCounts
            ? { include_engagement_survey_counts: includeEngagementSurveyCounts }
            : {}),
        },
      })
}

function buildAccountReportHook(pathArray) {
  return ({
    accountId,
    accountTags = null,
    multiAccountIds = null,
    kitSlug = null,
    teamId = null,
    teamTags = null,
    includeArchivedTeams = null,
    includeEngagementSurveyCounts = null,
  }) => {
    const url = buildAccountReportURL(
      pathArray,
      accountId,
      accountTags,
      multiAccountIds,
      kitSlug,
      teamId,
      teamTags,
      includeArchivedTeams,
      includeEngagementSurveyCounts
    )
    const cacheKey = [...getAccountReportCacheKeyRoot(accountId, { pathArray }), url]
    return useQuery(cacheKey, () => fetcher({ url }), { enabled: !!accountId })
  }
}

function getAccountReportCacheKeyRoot(accountId, { pathArray }) {
  let parsedAccountId = accountId
  if (accountId) {
    // In our code accountId can sometimes be passed here as int, sometimes as string.
    // These type descrepancies will cause cache keys to not match when they should, so
    // we need to always convert it to integer before using it in the cache key here:
    parsedAccountId = parseInt(accountId)
  }
  const isPositiveInteger = Number.isInteger(parsedAccountId) && parsedAccountId > 0
  if (!(isPositiveInteger || parsedAccountId == null)) {
    throw new Error(`Expected positive integer or null accountId, not ${JSON.stringify([accountId])}`)
  }
  return ["AccountReport", parsedAccountId, ...pathArray]
}

const useReportAccountTeams = buildAccountReportHook(["teams"])
const useKitUsageSummaryReportData = buildAccountReportHook(["get_kit_usage_summary"])
const useKitUsageTeamReportData = buildAccountReportHook(["get_kit_usage_team_data"])
const useKitUsageKitsReportData = buildAccountReportHook(["get_kit_usage_kits_data"])

const getKitUsageKitsExport = ({
  accountId,
  accountTags = null,
  multiAccountIds = null,
  kitSlug = null,
  teamId = null,
  teamTags = null,
  includeArchivedTeams = null,
}) =>
  fetcher({
    url: buildAccountReportURL(
      ["get_kit_usage_kits_export"],
      accountId,
      accountTags,
      multiAccountIds,
      kitSlug,
      teamId,
      teamTags,
      includeArchivedTeams
    ),
  })

const getKitUsageTeamKitsExport = ({
  accountId,
  accountTags = null,
  multiAccountIds = null,
  kitSlug = null,
  teamId = null,
  teamTags = null,
  includeArchivedTeams = null,
}) =>
  fetcher({
    url: buildAccountReportURL(
      ["get_kit_usage_team_kits_export"],
      accountId,
      accountTags,
      multiAccountIds,
      kitSlug,
      teamId,
      teamTags,
      includeArchivedTeams
    ),
  })

const getKitUsageTeamsExport = ({
  accountId,
  accountTags = null,
  multiAccountIds = null,
  kitSlug = null,
  teamId = null,
  teamTags = null,
  includeArchivedTeams = null,
}) =>
  fetcher({
    url: buildAccountReportURL(
      ["get_kit_usage_teams_export"],
      accountId,
      accountTags,
      multiAccountIds,
      kitSlug,
      teamId,
      teamTags,
      includeArchivedTeams
    ),
  })

function createSelectFieldSearchAccountsFunction({ includeInactiveAccounts } = {}) {
  return async (searchTerm) => {
    if (!searchTerm?.length || searchTerm.length < 2) {
      return null
    }
    const accounts = await fetcher({
      url: accountUrl(null, "search", {
        urlBase: leadUrlBase,
        params: { search_term: searchTerm, include_inactive: includeInactiveAccounts },
      }),
    })
    return accounts.map((account) => ({
      value: account.id,
      label: account.name,
    }))
  }
}

export {
  SubscriptionType,
  convertAccountToPromo,
  createGratitudePromoAccount,
  createCheckoutSessionAndRedirect,
  completedCheckoutSession,
  getCustomerPortalSession,
  useCanPayOpenInvoice,
  useCancelSubscription,
  useReactivateSubscription,
  useAddTeamAccounts,
  useAdminAccounts,
  useKitUsageSummaryReportData,
  useKitUsageTeamReportData,
  useKitUsageKitsReportData,
  getAccountTeamsExport,
  getAccountUsersExport,
  getKitUsageKitsExport,
  getKitUsageTeamKitsExport,
  getKitUsageTeamsExport,
  getOwnedAccount,
  useOwnedAccount,
  useAccount,
  useDemoAccount,
  useUpdateAccount,
  useAddAccountTag,
  useRemoveAccountTag,
  useRemoveAccountTagSuffix,
  useAccountTeams,
  useAccountTeamLeads,
  useAccountAdmins,
  usePurchaseAccountQuantity,
  useCreateAccountTeam,
  createBillingAccount,
  useInviteTeamLeads,
  useResendTeamLeadInvite,
  useInviteAdmin,
  useResendAdminInvite,
  useRemoveTeamLeadInvite,
  useRemoveAdminInvite,
  useAccountOffers,
  refetchAccountOffers,
  useReportAccounts,
  useReportAccountTeams,
  previewPurchaseAccountQuantity,
  contactAccountManager,
  promoAccountUpgrade,
  freePromoKitSignup,
  useUserAccount,
  getAccountTeamsCacheKey,
  getAccountReportCacheKeyRoot,
  createSelectFieldSearchAccountsFunction,
  searchAccountUsers,
}
