import { styled } from "styled-components"

const TeamNormsTraining2 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 pt-medium">
      <div>
        After the session and your team has shared their aspirations and collectively defined team norms, follow up to
        refine and make a plan to activate these shared values.
      </div>
      <div>
        <h3 className="text-rising-orange pt-large pb-xs">Make team norms a part of the conversation</h3>
        <p>Identify a visible spot to post or publish your team’s norms so that it’s easy to find and reference. </p>
      </div>
      <div>
        <h3 className="text-rising-orange pt-large pb-xs">Reflect on and refine team norms</h3>
        <div className="pb-xs">
          During your next team meeting, add an agenda item to review and finalize the team norms you created during the
          session. After the session, the list will be available from your{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://risingteam.com/resources/facilitator-guide#After-session"
          >
            Team results page.
          </a>
        </div>
        <div className="pb-xs">Connect aspirations with reality with the following prompts:</div>
        <div className="pb-xs">
          <ul>
            <li>Have any of these team norms come to mind recently? If so, how?</li>
            <li>How do these team norms resonate with how work gets done here today? </li>
            <li>
              Do any of these team norms feel more aspirational than lived? If so, how might we create a plan to lean
              into our values?
            </li>
            <li>Are there any team norms we should revise?</li>
          </ul>
        </div>
        <div>
          You may also want to explore concerns or misalignment with the team norms that were identified in the exercise
          by reaching out to your team members 1:1 to confirm they felt heard, understood, and comfortable with the
          final list.
        </div>
      </div>
      <div className="mb-medium">
        <h3 className="text-rising-orange pt-large pb-xs">Take action</h3>
        <div className="pb-xs">
          Transform your team norms into specific and actionable agreements. This helps the team make practical
          commitments to take action. 
        </div>
        <div className="pb-xs">
          Example: Because we identified “We continually improve through introspection and experimentation” as a team
          norm, we commit to these actions:
        </div>
        <ul>
          <li>
            Weekly retrospectives in which we take time to reflect and share honestly about what’s going well and what
            could be improved. 
          </li>
          <li>
            We focus on ensuring our experiment’s risk is appropriately managed and the has a plan for how to take
            action on what they’ve learned.
          </li>
        </ul>
      </div>
      <div>
        <p className="mb-medium text-semi-bold">More resources:</p>
        <ul className="mb-xxxxl ml-xl">
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.uvm.edu/sites/default/files/working-agreements-defined.pdf"
            >
              Working Agreements
            </a>{" "}
            Jane Haskell
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default styled(TeamNormsTraining2)`
  ol,
  ul {
    margin: 0;
    padding-left: 24px;

    li {
      color: inherit;
    }
  }

  ol > li::marker {
    font-weight: 700;
  }
`
