const LearnerAgilityTraining3 = ({ className }) => (
  <div className={className}>
    <div className="text-gray-9 mt-medium">
      <p className="mb-medium">
        After your Rising Team session on boosting learning agility, here's how to nurture a culture of continuous
        learning and growth.
      </p>
      <h3 className="mb-medium">1. Reward learning behaviors</h3>
      <p className="mb-medium">
        To foster a learning culture, managers can actively encourage continuous learning on a regular basis. Here are a
        few strategies to reward learning agility behaviors that can be easily integrated into already existing
        structures.
      </p>
      <ul className="ml-xl mb-medium">
        <li>
          <u>Celebrate mistakes.</u> In a standing meeting, consider a "festival of failure" agenda item. Invite team
          members to share something they tried that failed, and that created a learning moment for them. This will not
          only highlight the value of trying new things, but also demonstrate that failing is part of the growth
          process.
        </li>
        <li>
          <u>Ask, "What questions do you have?".</u> In every meeting add a standing agenda item that creates a moment
          for team members to ask questions. After time, team members will come to understand that asking questions for
          clarification is part of the team's success.
        </li>
        <li>
          <u>Allow time for learning to happen.</u> Whether dedicating a standing block of times every month or
          empowering individuals to take time of their choice, even just a few hours dedicated to learning can shift the
          culture of a team. This time can be used for formal training, collaboration, cross-training, or even
          networking (in fact, Rising Team sessions are times set aside for learning!).
        </li>
        <li>
          <u>Recognize learning achievements.</u> It's most common to recognize team members' accomplishments and
          measurable results. However, celebrating learning achievements as well highlights the importance that you and
          your team place on growth. In{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.chieflearningofficer.com/2021/04/21/5-ways-to-foster-a-learning-culture/than-finkelstein/"
          >
            <i>Chief Learning Officer</i> magazine
          </a>
          , author Jonathan Finkelstein shares that "Acknowledgement can take many forms. On the informal end of the
          spectrum are shout outs at team and company meetings, but recognition programs can also be more official,
          structured and closely aligned with your organization's learning programs and company values."
        </li>
      </ul>
      <h3 className="mb-medium">2. Appeal to individual learning styles</h3>
      <p className="mb-medium">
        Now that you have greater awareness about each team member's learning preferences, you can better customize how
        you communicate information. Whether allowing a little extra time for those who prefer to process the details,
        prescribing a podcast or video lesson to those who are more visual or auditory, or partnering team members who
        will benefit from collaborating, tailoring your learning methods to individual styles will only help team
        members grow and ultimately help the team innovate and prosper.
      </p>
      <h3 className="mb-medium">3. Connect learning to career goals</h3>
      <p className="mb-medium">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://learning.linkedin.com/resources/workplace-learning-report#ch03"
        >
          LinkedIn's 2022 Workforce Learning Report
        </a>{" "}
        highlights that we are most motivated to learn when the learning is connected to our careers and interests. They
        report that "Employees' top three motivations to learn are all connected to careers." This includes learning
        that:
      </p>
      <ul className="ml-xl mb-medium">
        <li>helps them to stay up to date in their field,</li>
        <li>is personalized to their specific interests and career goals, or</li>
        <li>helps them get another job internally, be promoted, or get closer to reaching their career goals.</li>
      </ul>
      <p className="mb-medium">
        As team leaders we can help team members make these connections by empowering them to set personal learning
        goals. It can be as straightforward as asking team members in one-on-one meetings "What do you want to learn
        this month?" and "What support do you need?". If they don't know, tap into Rising Team's kit "Explore Career
        Horizons." This will help the team gain more clarity on their longer-term goals and interests and make the
        connections to learning more clear.
      </p>
      <p className="mb-medium">
        You can also have people on the team set learning and growth goals alongside their other outcome-based goals.
        This way you can track together the progress they are making on both work and learning goals, with the same
        amount of rigor.
      </p>
      <p className="mb-medium">
        With some open dialogue and practice, teams can learn how to learn and cultivate a safe space to accelerate
        their learning agility as a team.
      </p>
      <p className="mb-medium">
        If you want to keep learning more about this topic, here are some additional resources:
      </p>
      <ul className="ml-xl mb-medium">
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://www.leaderfactor.com/post/stage-2-learner-safety">
            Stage 2: Learner safety
          </a>{" "}
          (Lead Factor)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://learning.linkedin.com/resources/workplace-learning-report"
          >
            LinkedIn's 2022 Workforce Learning Report
          </a>{" "}
          (LinkedIn)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/learning/creating-a-culture-of-learning-2020"
          >
            Creating a Culture of Learning
          </a>{" "}
          (LinkedIn Learning)
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.forbes.com/sites/forbesbusinessdevelopmentcouncil/2021/03/04/how-to-foster-a-culture-of-continuous-learning/?sh=5dc29d93296c"
          >
            How To Foster A Culture Of Continuous Learning
          </a>{" "}
          (Forbes)
        </li>
      </ul>
    </div>
  </div>
)

export default LearnerAgilityTraining3
