import cn from "classnames"
import { Link } from "react-router-dom"

import { SessionHeaderLogo } from "ui/HeaderLogo"
import View from "ui/View"
import { getKitTypeInfo } from "utils/kit"

const ThemeHeader = ({ kitInstance, showHeader, className }) => {
  const kitDefinition = kitInstance.kit
  const kitHeader = getKitTypeInfo(kitDefinition.type).header
  const homeUrl = kitInstance.home_url

  return (
    <Link className={cn("no-underline", className)} to={homeUrl}>
      <View $alignItems="center">
        {!!showHeader && <SessionHeaderLogo />}
        <div>
          <div className="text-rising-orange text-small text-semi-bold">{kitHeader}</div>
          <h3 className="text-gray-9 text-semi-bold">{kitDefinition.title}</h3>
        </div>
      </View>
    </Link>
  )
}

export default ThemeHeader
