import { sortBy } from "lodash-es"
import qs from "qs"

import type { SelectOption } from "forms/fields/AdvancedSelectField"
import useFeatures, { ACCOUNT_FLAGS, FLAGS, SHARED_FLAGS } from "ui/hooks/useFeatures"
import { uniqueById } from "utils/array"

type FlagKey = keyof typeof FLAGS
type FlagValue = (typeof FLAGS)[FlagKey]
type Features = Record<FlagKey, FlagValue>

function useHasAccountFeature(
  account: AccountData | null,
  flag: FlagValue
): { enabled: boolean; isInitialLoading: boolean } {
  const isSharedFlag = Object.values(SHARED_FLAGS).includes(flag)
  const { [flag]: hasFlagEnabled, isInitialLoading: featuresIsLoading } = useFeatures({ enabled: !!isSharedFlag })
  const hasAccountFeature = !!account?.features?.includes(flag)

  if (!account?.features || (isSharedFlag && featuresIsLoading)) {
    // Loading case:
    return {
      enabled: false,
      isInitialLoading: true,
    }
  } else if (isSharedFlag) {
    // Shared flag case:
    return {
      enabled: !!(hasAccountFeature || hasFlagEnabled),
      isInitialLoading: false,
    }
  } else {
    // Standard flag case:
    return {
      enabled: !!hasAccountFeature,
      isInitialLoading: false,
    }
  }
}

function canUserManageAccount(account: AccountData | null, user: UserData | null, features: Features): boolean {
  if (canUserManageAllAccountsStaffOnly(user, features)) {
    return true
  }
  return !!account?.can_manage_billing && !user?.is_demo_mode_active
}

function isSingleTeamProduct(account: AccountData | null): boolean {
  return account?.product?.product_type === "SINGLE_KIT"
}

function canUserEditAccountTeam(
  account: AccountData | null,
  user: UserData | null,
  team: TeamData | null,
  features: Features
): boolean {
  if (canUserManageAccount(account, user, features)) {
    return true
  }
  const userId = user?.id
  const leadId = team?.team_lead_id
  const teamColeads = team?.coleads
  if (!!account?.is_current_user_lead_or_colead) {
    return userId === leadId || !!teamColeads?.find((c) => c.id === userId)
  }
  return false
}

function canUserInviteTeamLeads(
  account: AccountData | null,
  user: UserData | null,
  teams: TeamData[],
  features: Features
): boolean {
  if (teams?.length > 1) {
    return false
    // only show the "invite team leads" section while the user has 1 or 0 teams
  }
  return canUserManageAccount(account, user, features) && hasAccountMaxTeamsRemaining(account, teams)
}

function canUserManageAccountBilling(account: AccountData | null, user: UserData | null, features: Features): boolean {
  return canUserManageAccount(account, user, features) && account?.product?.product_type === "LEADER_KIT"
}

function canUserUpdateAccountSubscription(
  account: AccountData | null,
  user: UserData | null,
  features: Features
): boolean {
  return canUserManageAccountBilling(account, user, features)
}

function canUserCancelAccountSubscription(
  account: AccountData | null,
  user: UserData | null,
  features: Features
): boolean {
  return canUserManageAccountBilling(account, user, features) && account?.product?.payment_status === "PAID"
}

function canUserReactivateAccountSubscription(
  account: AccountData | null,
  user: UserData | null,
  features: Features
): boolean {
  return canUserManageAccountBilling(account, user, features) && account?.product?.payment_status === "CANCELED"
}

function canUserSeeAccountMaxTeams(account: AccountData | null, user: UserData | null, features: Features): boolean {
  if (!account) {
    return false
  }
  // Max teams is not relevant to accounts set to per-user billing:
  if (account.is_per_user_billing) {
    return false
  }
  if (isSingleTeamProduct(account)) {
    return false
  }
  return canUserManageAccount(account, user, features)
}

function canUserSeeAccountMaxUsers(account: AccountData | null, user: UserData | null, features: Features): boolean {
  if (!account) {
    return false
  }
  // Max teams is not relevant to accounts set to per-user billing:
  if (!account.is_per_user_billing) {
    return false
  }
  if (isSingleTeamProduct(account)) {
    return false
  }
  if (!account.features.includes(ACCOUNT_FLAGS.SHOW_ADMIN_TAB_PEOPLE_SECTION)) {
    return false
  }
  return canUserManageAccount(account, user, features)
}

function canUserPurchaseAccountTeams(account: AccountData | null, user: UserData | null, features: Features): boolean {
  if (!account) {
    return false
  }
  // Max teams is not relevant to accounts set to per-user billing:
  if (account.is_per_user_billing) {
    return false
  }
  if (isSingleTeamProduct(account)) {
    return false
  }
  if (numAccountMaxTeams(account) >= 10) {
    return false
    // While account quantity is less than 10, user can self-serve purchase
    // more account quantity as necessary up to 10. After that, RT staff must
    // increase quantity manually before they'll be able to add more teams:
    // TODO(admin): Replace magic number 10 with a shared constant here.
  }
  return canUserManageAccountBilling(account, user, features) && !!account.is_subscription_active
}

function canUserRequestMoreAccountTeams(
  account: AccountData | null,
  user: UserData | null,
  features: Features
): boolean {
  return !canUserPurchaseAccountTeams(account, user, features) && account?.product?.product_type !== "LEADER_KIT_FREE"
}

function canUserManageAllAccountsStaffOnly(user: UserData | null, features: Features): boolean {
  // TODO(typescript) @evnp Remove "as FlagKey" cast after we convert useFeatures.js to TypeScript.
  return !!user?.is_staff && !!features?.[FLAGS.SUPERUSER_MANAGE_ALL_ACCOUNTS as FlagKey] && !user.is_demo_mode_active
}

function canUserSeeExtendedFeaturesStaffOnly(user: UserData | null, features: Features): boolean {
  // TODO(typescript) @evnp Remove "as FlagKey" cast after we convert useFeatures.js to TypeScript.
  return !!user?.is_staff && !!features?.[FLAGS.ADMIN_EXTENDED_FEATURES as FlagKey] && !user.is_demo_mode_active
}

function numAccountMaxUsers(account: AccountData | null): number {
  const total = account?.product?.max_users
  return total && Number.isInteger(total) ? total : 0
}

function numAccountCurrentUsers(account: AccountData | null): number {
  const total = account?.current_user_count
  return total && Number.isInteger(total) ? total : 0
}

function numAccountMaxTeams(account: AccountData | null): number {
  const total = account?.product?.max_teams
  return total && Number.isInteger(total) ? total : 0
}

function numAccountMaxTeamsRemaining(account: AccountData | null, teams: TeamData[]): number {
  if (!Array.isArray(teams)) {
    return 0 // teams are still loading
  } else {
    return Math.max(0, numAccountMaxTeams(account) - teams.length)
  }
}

function hasAccountMaxTeamsRemaining(account: AccountData | null, teams: TeamData[]): boolean {
  if (!account) {
    return true
  }
  // Max teams is not relevant to accounts set to per-user billing:
  if (account.is_per_user_billing) {
    return true
  }
  return numAccountMaxTeamsRemaining(account, teams) > 0
}

function getAdminUrl({
  accountId,
  includeAllAccounts,
  inviteLeads,
}: {
  accountId: AccountID
  includeAllAccounts?: string
  inviteLeads?: string
}): string {
  const queryParams = qs.stringify({
    accountId,
    includeAllAccounts,
    inviteLeads,
  })
  return `/admin?${queryParams}`
}

function parseIncludeAllAccountsQueryParam(includeAllAccounts: string): {
  activeAccounts: boolean
  activeAndInactiveAccounts: boolean
} {
  switch (includeAllAccounts) {
    case "activeAccounts":
      return { activeAccounts: true, activeAndInactiveAccounts: false }
    case "activeAndInactiveAccounts":
      return { activeAccounts: true, activeAndInactiveAccounts: true }
    default:
      return { activeAccounts: false, activeAndInactiveAccounts: false }
  }
}

function createIncludeAllAccountsQueryParam({
  activeAccounts,
  activeAndInactiveAccounts,
}: {
  activeAccounts?: boolean
  activeAndInactiveAccounts?: boolean
}): string | null {
  if (activeAccounts && activeAndInactiveAccounts) {
    return "activeAndInactiveAccounts"
  } else if (activeAccounts) {
    return "activeAccounts"
  } else {
    return null
  }
}

function getTeamLeadOptions(
  account: AccountData | null,
  user: UserData | null,
  team: TeamData | null,
  canSearchUsers: boolean
): SelectOption[] {
  let teams = account?.teams ?? []

  if (team) {
    teams = uniqueById([team, ...teams])
  }

  const teamMemberIds = new Set(team?.members.map((u) => u.id) ?? [])
  let users = teams.flatMap((t) => t.members)

  if (user) {
    users = [user].concat(users)
  }

  const currentLeadId = team?.team_lead_id

  if (currentLeadId) {
    // filter out current lead -- cannot reassign to same lead again
    users = users.filter((u) => u.id !== currentLeadId)
  }

  const teamLeads = sortBy(uniqueById(users), [
    (u) => u.id !== user?.id, // your user listed first
    ...(team ? [(u: UserData) => !teamMemberIds.has(u.id)] : []), // sort team members first
    (u) => !u.name, // users with only emails listed last
    (u) => u.name || u.email, // sort rest alpha
  ])

  const searchUsersOptions = canSearchUsers
    ? [
        {
          value: "",
          label: "Type to search users...",
          isDisabled: true,
        },
      ]
    : []

  return [
    ...searchUsersOptions,
    ...teamLeads.map(({ id, name, email }) => ({
      value: id.toString(),
      label: name || email,
    })),
  ]
}

export {
  canUserCancelAccountSubscription,
  canUserEditAccountTeam,
  canUserInviteTeamLeads,
  canUserManageAccount,
  canUserManageAccountBilling,
  canUserManageAllAccountsStaffOnly,
  canUserPurchaseAccountTeams,
  canUserRequestMoreAccountTeams,
  canUserReactivateAccountSubscription,
  canUserSeeAccountMaxTeams,
  canUserSeeAccountMaxUsers,
  canUserSeeExtendedFeaturesStaffOnly,
  canUserUpdateAccountSubscription,
  useHasAccountFeature,
  getAdminUrl,
  getTeamLeadOptions,
  hasAccountMaxTeamsRemaining,
  numAccountMaxTeamsRemaining,
  numAccountMaxTeams,
  numAccountMaxUsers,
  numAccountCurrentUsers,
  parseIncludeAllAccountsQueryParam,
  createIncludeAllAccountsQueryParam,
}
